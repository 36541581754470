import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { useStatusUpdateForm } from '@vivli/features/data-requests/infrastructure/hook';
import { ButtonComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { useDataRequestContext, useStatusUpdateContext } from '@vivli/features/data-requests/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { NotificationFeature } from '@vivli/shared/features/notification/feature';
import { useNotificationContext } from '@vivli/shared/features/notification/infrastructure/context';

const containerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`,
    height: '100%',
};
const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
};

const tabButtonsStyle: CSSProperties = {
    display: 'flex',
    gap: '10px',
    height: '100%',
    position: 'absolute',
    right: '30px',
};

interface IStatusUpdateTab {
    name: string;
    sortOrder: number;
    title: string;
}

export const DataRequestNotificationsSubTabComponent = () => {
    const { dataRequest } = useDataRequestContext();
    const { setFormApi } = useStatusUpdateContext();
    const { resetNotificationForm, handleSubmit, notifications, isSaving, showSaveButton } = useNotificationContext();
    const activeUser = useActiveUser();
    const formApi = useStatusUpdateForm(dataRequest.statusUpdate);

    useEffect(() => {
        setFormApi(formApi);
    }, []);

    enum TabTitleEnum {
        notification = 'Notifications',
    }

    enum TabRouteEnum {
        notification = 'Notifications',
    }

    const getTabs = () => {
        const defaultTabs: IStatusUpdateTab[] = [
            {
                name: TabRouteEnum.notification,
                sortOrder: 0,
                title: TabTitleEnum.notification,
            },
        ];
        return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
    };

    const [tabs, setTabs] = useState<IStatusUpdateTab[]>();
    const [activeTab, setActiveTab] = useState('');
    const handleTabClick = (tab: IStatusUpdateTab) => {
        setActiveTab(tab.name);
    };

    useEffect(() => {
        if (activeUser.isVivliAdmin === true) {
            setTabs(getTabs());
        } else {
            setTabs([getTabs()[0]]);
        }
    }, []);

    useEffect(() => {
        if (tabs !== undefined) setActiveTab(tabs[0].name);
    }, [tabs]);

    const buttonsDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Notifications':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            {notifications[0] && (
                                <ButtonComponent style={{ maxWidth: '200px' }} onClick={resetNotificationForm}>
                                    {'Create New Notification'}
                                </ButtonComponent>
                            )}
                            <ButtonComponent
                                style={{ maxWidth: '200px' }}
                                disabled={!showSaveButton}
                                isLoading={isSaving}
                                onClick={handleSubmit}
                            >
                                {'Save Notification'}
                            </ButtonComponent>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    const tabDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Notifications':
                return <NotificationFeature topicId={dataRequest.id} />;
            default:
                return null;
        }
    };

    return (
        <div style={containerStyle}>
            <div style={containerStyle}>
                <TabsComponent defaultTab={0}>
                    {tabs?.map((tab, i) => {
                        const { title } = tab;
                        return (
                            <TabComponent
                                key={i}
                                title={title}
                                onTabClick={() => handleTabClick(tab)}
                                rightHeaderContent={<div style={tabButtonsStyle}>{buttonsDisplayed(activeTab)}</div>}
                            >
                                {tabDisplayed(activeTab)}
                            </TabComponent>
                        );
                    })}
                </TabsComponent>
            </div>
        </div>
    );
};
