import React from 'react';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { OrgInviteRowComponent } from './org-invite-row.component';
import { useOrganizationDetailsContext } from '@vivli/features/organizations/infrastructure/context';

export const PendingInvitesComponent = () => {
    const { invitations } = useOrganizationDetailsContext();
    const inviteCount = invitations?.length || 0;

    if (inviteCount > 0) {
        return (
            <div>
                <FieldHeaderComponent title={`${inviteCount} PENDING INVITES`} />
                {invitations?.map((invitation, i) => (
                    <div key={i}>
                        <OrgInviteRowComponent invitation={invitation} />
                    </div>
                ))}
            </div>
        );
    }
};
