import React, { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';

const textAreaStyle: CSSProperties = {
    width: '100%',
    border: 'none',
    resize: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    backgroundColor: 'inherit',
    fontWeight: Size.FontWeight.SemiBold,
    height: '100%',
    paddingBottom: '15px',
};

const valueStyle: CSSProperties = {
    paddingTop: '4px',
    border: 'none',
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.SemiBold,
    flexWrap: 'nowrap',
    color: Color.DARK_GRAY,
    flex: '1 1 auto',
    resize: 'none',
    borderWidth: 'initial',
    borderStyle: 'none',
    borderColor: 'initial',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    width: '100%',
    height: '100%',
};

const fieldStyle: CSSProperties = {
    minHeight: '27px',
    flexWrap: 'nowrap',
};

interface TextAreaReadOnlyFormComponentProps extends IFormField {
    rows?: number;
    textStyle?: CSSProperties;
}

/*component to provide standard style used in read-only forms*/
export const TextAreaReadOnlyFormComponent = forwardRef((props: TextAreaReadOnlyFormComponentProps, ref) => {
    const textAreaRef = useRef<HTMLInputElement>();
    const { defaultValue, value, readonly = true, rows = 8, onFocus, onBlur, onChange, focusOnLoad, textStyle } = props;

    const handleInitialFocus = () => {
        if (focusOnLoad && textAreaRef.current) {
            textAreaRef.current.focus();
        }
    };

    useEffect(() => {
        handleInitialFocus();
    }, []);

    const readonlyDisplay = (
        <div style={{ ...valueStyle, cursor: 'default' }} title={defaultValue}>
            <textarea readOnly={true} disabled={true} defaultValue={defaultValue} style={{ ...textAreaStyle, ...textStyle }} rows={rows} />
        </div>
    );

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.TextArea} inputRef={textAreaRef}>
            <div style={fieldStyle}>{readonlyDisplay}</div>
        </BaseInputFieldComponent>
    );
});
