import React, { CSSProperties } from 'react';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { AmrDataRequestTabComponent } from './amr-data-request-tab.component';

const tabsContainerStyle: CSSProperties = {
    minWidth: 250,
    maxWidth: 250,
    display: 'flex',
    flexDirection: 'column',
};

const bottomDivStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    background: 'white',
    position: 'relative',
    zIndex: 14,
    display: 'flex',
    flexGrow: 2,
};

interface AmrDataRequestTabsComponentProps {
    showRequestTab: boolean;
    showPrintTab: boolean;
    showRequestPrintTab: boolean;
    showStatusUpdateTab: boolean;
}

export const AmrDataRequestTabsComponent = ({
    showRequestTab,
    showPrintTab,
    showRequestPrintTab,
    showStatusUpdateTab,
}: AmrDataRequestTabsComponentProps) => {
    const config = useConfigService();

    const getTabs = (): string[] => {
        const amrDataRequestTabs = ['Datasets', 'Request History'];

        const shouldShowChat = config.chatEnabled && config.chatUri && config.chatUri.length > 0;

        if (showPrintTab) {
            amrDataRequestTabs.push('Print View');
        }

        if (showRequestPrintTab) {
            amrDataRequestTabs.push('Request/Print View');
        }

        if (showRequestTab) {
            amrDataRequestTabs.push('Request');
        }

        if (shouldShowChat) {
            amrDataRequestTabs.push('Chat');
        }

        if (showStatusUpdateTab) {
            amrDataRequestTabs.push('Status Update');
        }

        return amrDataRequestTabs;
    };

    return (
        <div style={tabsContainerStyle} className="do-not-print">
            {getTabs().map((title, i) => (
                <AmrDataRequestTabComponent title={title} key={i} />
            ))}

            <div style={bottomDivStyle} />
        </div>
    );
};
