import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useEffect, useRef } from 'react';
import { LoggerService } from '@vivli/shared/infrastructure/service';

interface ISignalrHookProps<T> {
    uri: string;
    events?: string[];
    onStart?: () => void;
    onStop?: () => void;
    onError?: (err) => void;
    onEvent?: (message: T, event: string) => void;
}

export function useSignalR<T>({ uri, onStart, onStop, events, onEvent, onError }: ISignalrHookProps<T>): void {
    const isDestroyedRef = useRef(false);
    const isConnectingRef = useRef(false);
    const connectionRef = useRef<HubConnection>(null);

    const handleOnError = (err: any) => {
        onEvent && onError(err);
        isConnectingRef.current = false;
    };

    const startConnection = () => {
        if (isDestroyedRef.current) {
            return;
        }
        isConnectingRef.current = true;
        connectionRef.current = new HubConnectionBuilder()
            .withUrl(uri)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        events?.forEach((event) => {
            connectionRef.current.on(event, (data) => {
                if (isDestroyedRef.current) {
                    return;
                }

                onEvent && onEvent(data, event);
            });
        });

        connectionRef.current
            .start()
            .then(() => {
                LoggerService.info(`Channel connection is started for uri ${uri}.`);
                !isDestroyedRef.current && onStart && onStart();
                isConnectingRef.current = false;
            })
            .catch(handleOnError);
    };

    useEffect(() => {
        if (!uri) {
            return;
        }

        startConnection();

        return () => {
            if (!isConnectingRef.current) {
                isDestroyedRef.current = true;
                if (connectionRef.current?.state === HubConnectionState.Connected) {
                    connectionRef.current?.stop().then(() => onStop && onStop());
                }
            }
        };
    }, [uri]);
}
