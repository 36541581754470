﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { AvailableIpdContentTypeEnum, BulkUploadContentTypeEnum } from '@vivli/features/studies/infrastructure/enum';
import { CheckboxFormFieldComponent, DropdownFormFieldComponent, FieldHeaderComponent } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { DTICTFormFields, DTIDropdownMenu } from '@vivli/shared/infrastructure/constants';

const fieldStyle: CSSProperties = {
    flex: '1 1 46%',
    maxWidth: '45%',
    marginBottom: Size.FIELD_SEPARATION,
};
const checkBoxStyle: CSSProperties = {
    marginBottom: Size.ROW_BOTTOM_MARGIN,
    maxWidth: '50%',
    flexDirection: 'row-reverse',
};

const alphabeticalSort = (a, b) => {
    return a.title < b.title ? -1 : 1;
};

const getOptions = (list: IOrganization[]) => {
    return list
        ?.map(({ id, name, code }) => ({
            value: { orgId: id, orgName: name, orgCode: code },
            title: name,
        }))
        .sort(alphabeticalSort);
};

const IPDContentTypeLabelsOld = {
    [AvailableIpdContentTypeEnum.Basic]: 'Basic – IPD, Protocol, Statistical Analysis Plan, Data Dictionary',
    [AvailableIpdContentTypeEnum.Full]:
        'Expanded – Basic plus Analysis-ready Dataset, redacted Clinical Study Report, Annotated Case Report Form, or other',
};

const BulkUploadLabels = {
    [BulkUploadContentTypeEnum.IdsOnlySheet]: 'Vivli Metadata Sheet NCT ID (Option 1)', //   'List of Ids Saved as CSV',
    [BulkUploadContentTypeEnum.CSDRMetadataSheet]: 'Vivli Metadata Sheet Non NCT ID (Option 2)', //  'CSDR Datasheet Saved as CSV',
    [BulkUploadContentTypeEnum.VivliMetadataSheet]: 'Vivli Metadata Sheet Non NCT ID (Option 3)', // 'Vivli Datasheet Saved as CSV',
};

export const BulkUploadStudiesSelectorsComponent = () => {
    const vivliConfig = useVivliConfig();
    const { organizations, irpApproverOrgs, qaReviewerOrgs, annotatorOrgs } = useOrganizations();
    const orgIdItems = getOptions(organizations);
    const irpOrgIdItems = getOptions(irpApproverOrgs);
    const annotatorOrgIdItems = getOptions(annotatorOrgs);
    const qaReviewerOrgIdItems = getOptions(qaReviewerOrgs);
    const IPDContentTypeLabels = {
        [AvailableIpdContentTypeEnum.Basic]: `Basic – ${vivliConfig?.ipdFileTypes
            ?.filter((x) => x.requiredCtBasic)
            .map((x) => x.fileType)
            .join(', ')}`,
        [AvailableIpdContentTypeEnum.Full]: `Expanded – Basic plus ${vivliConfig?.ipdFileTypes
            ?.filter((x) => x.includeCt && !x.requiredCtExtended)
            .map((x) => x.displayName)
            .join(', ')}`,
    };
    let packageTypeOptions = Object.keys(AvailableIpdContentTypeEnum)
        .map((o) => ({ value: o, title: IPDContentTypeLabels[o] }))
        .sort(alphabeticalSort);
    const contentTypeOptions = Object.keys(BulkUploadContentTypeEnum)
        .map((o) => ({ value: o, title: BulkUploadLabels[o] }))
        .sort(alphabeticalSort);
    const [typeOptionsChanged, setOptionsChanged] = useState<{ value: string; title: any }[]>(null);
    useEffect(() => {
        if (vivliConfig) {
            packageTypeOptions = Object.keys(AvailableIpdContentTypeEnum)
                .map((o) => ({ value: o, title: IPDContentTypeLabels[o] }))
                .sort(alphabeticalSort);
            setOptionsChanged(packageTypeOptions);
        }
    }, [vivliConfig]);

    return (
        <>
            <FieldHeaderComponent title="ORGANIZATIONAL DETAILS" />
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'dataContributorOrganization'}
                    label={'Data Contributor Organization'}
                    items={orgIdItems}
                    style={fieldStyle}
                    hidePopoverError={true}
                    objectKey={'orgId'}
                    dataId={DTIDropdownMenu.DropdownMenuItem('DataContributorOrganization')}
                />
                <DropdownFormFieldComponent
                    name={'requestsApprovedByOrganization'}
                    label={'Data Requests to be Approved by'}
                    items={irpOrgIdItems}
                    style={fieldStyle}
                    hidePopoverError={true}
                    objectKey={'orgId'}
                    dataId={DTIDropdownMenu.DropdownMenuItem('ApprovedByOrganization')}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'curationOrganization'}
                    label={'Curation Organization'}
                    items={annotatorOrgIdItems}
                    style={fieldStyle}
                    hidePopoverError={true}
                    objectKey={'orgId'}
                    dataId={DTIDropdownMenu.DropdownMenuItem('CurationOrganization')}
                />
                <DropdownFormFieldComponent
                    name={'curationQcOrganization'}
                    label={'Curation QC Reviewer Organization'}
                    items={qaReviewerOrgIdItems}
                    style={fieldStyle}
                    hidePopoverError={true}
                    objectKey={'orgId'}
                    dataId={DTIDropdownMenu.DropdownMenuItem('QcOrganization')}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'dataPackageType'}
                    label={'Data Package To Be Made Available For Studies'}
                    items={typeOptionsChanged}
                    style={fieldStyle}
                    hidePopoverError={true}
                    dataId={DTIDropdownMenu.DropdownMenuItem('DataPackageType')}
                />
                <DropdownFormFieldComponent
                    name={'contentType'}
                    label={'Upload Sheet Type (Must be CSV file)'}
                    items={contentTypeOptions}
                    style={fieldStyle}
                    hidePopoverError={true}
                    dataId={DTIDropdownMenu.DropdownMenuItem('ContentType')}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <CheckboxFormFieldComponent
                    name={'isDownloadable'}
                    label="Study Data Packages are Downloadable"
                    style={checkBoxStyle}
                    dataId={DTICTFormFields.FormCheckBox('IsDownLoadableCheckBox')}
                />
            </div>
        </>
    );
};
