import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IStatusUpdate } from '@vivli/shared/infrastructure/interface';

interface IStatusUpdateContext {
    submitStatusUpdate(): void;

    setFormApi(formApi: UseFormReturn<IStatusUpdate>);

    isSubmittingForm?: boolean;
}

export const StatusUpdateContext = createContext<IStatusUpdateContext>(null);

export const useStatusUpdateContext = () => useContext(StatusUpdateContext);
