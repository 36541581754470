import React, { CSSProperties, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { first } from 'rxjs/operators';
import { useResultRequestService } from '@vivli/features/results-request/infrastructure/context';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { useModalService } from '@vivli/shared/infrastructure/context';

const buttonContainerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
};

interface CreateResultRequestButtonComponentProps {
    onCreatingResultRequest?: () => void;
    onResultRequestCreated?: (resultRequest: IResultRequest) => void;
    requestId: string;
}

export const CreateResultRequestButtonComponent = ({
    onCreatingResultRequest,
    onResultRequestCreated,
    requestId,
}: CreateResultRequestButtonComponentProps) => {
    const resultRequestService = useResultRequestService();
    const modal = useModalService();
    const [isLoading, setIsLoading] = useState(false);

    const createResultRequest = () => {
        onCreatingResultRequest && onCreatingResultRequest();
        setIsLoading(true);
        resultRequestService
            .createResultRequest(requestId)
            .pipe(first())
            .subscribe((result) => {
                onResultRequestCreated && onResultRequestCreated(result);
                setIsLoading(false);
            });
    };

    const createResultRequestConfirm = () => {
        modal.confirm('Create New Result Request?', {
            confirmText: 'Yes',
            cancelText: 'No',
            onConfirm: createResultRequest,
        });
    };

    return (
        <div style={buttonContainerStyle}>
            <ButtonComponent onClick={createResultRequestConfirm} style={{ float: 'right' }} isLoading={isLoading}>
                Create New Results Request
            </ButtonComponent>
        </div>
    );
};
