﻿import React from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useDatasetContext } from '@vivli/features/datasets/infrastructure/context';
import { AmrDatasetAdminDetailsComponent } from './amr-dataset-admin-details.component';
import { DatasetAdminDetailsComponent } from './dataset-admin-details.component';

export const DatasetAdminDetailsContainerComponent = () => {
    const { study } = useDatasetContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    if (isAmr) {
        return <AmrDatasetAdminDetailsComponent study={study} />;
    }

    return <DatasetAdminDetailsComponent study={study} />;
};
