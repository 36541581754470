import React from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { AmrSearchFiltersComponent } from './amr/amr-search-filters.component';
import { CtSearchFiltersComponent } from './ct/ct-search-filters.component';
import { PicoSearchFiltersComponent } from './pico/pico-search-filters.component';
import { SearchFiltersContainerComponent } from './search-filters-container.component';
import { useSearchContext } from '@vivli/features/search/infrastructure/context';
import { SearchResultsComponent } from '../search-results.component';
import { SearchTabEnum } from '@vivli/features/search/infrastructure/enum';

export const SearchFiltersComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const { isShowingResults, activeTab } = useSearchContext();

    const getActiveFilters = () => {
        if (isAmr) {
            return <AmrSearchFiltersComponent />;
        }

        switch (activeTab) {
            case SearchTabEnum.FirstTab:
                return <CtSearchFiltersComponent />;
            case SearchTabEnum.SecondTab:
                return <PicoSearchFiltersComponent />;
        }
    };

    return (
        <SearchFiltersContainerComponent>
            {getActiveFilters()}
            <SearchResultsComponent isOpen={isShowingResults} />
        </SearchFiltersContainerComponent>
    );
};
