import { IApprovalDescriptor, IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { ApprovalStatusEnum, DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { DataRequestPermissionsService } from '@vivli/features/data-requests/infrastructure/service';

export function useDataRequestPermissions() {
    const user = useActiveUser();

    const userIsOwner = (dataRequest: IDataRequest) => {
        if (!dataRequest) {
            return false;
        }

        return dataRequest.userId === user?.userId;
    };

    const userIsAdmin = (dataRequest: IDataRequest) => {
        if (!dataRequest) {
            return false;
        }

        return dataRequest.researchTeam.researchTeamDetails.some((rtd) => rtd.isAdmin && rtd.userId === user?.userId);
    };

    const userIsDataContributor = (dataRequest: IDataRequest, organizations: IOrganization[]) => {
        // get requested study org ids
        const orgIds = dataRequest.requestedStudies?.map((study) => study.organizationId);

        // null check / length check on org ids, if none they can't contribute
        if (!orgIds || orgIds.length <= 0) {
            return false;
        }

        // check if the user has org membership rights
        return user.orgMemberships?.some((om) => organizations?.some((o) => o.id === om.orgId && om.isDataProvider));
    };

    const userIsIRPApprover = (dataRequest: IDataRequest, organizations: IOrganization[]) => {
        // get requested study org ids
        const orgIds = dataRequest.requestedStudies?.map((study) => study.organizationId);

        // null check / length check on org ids, if none they can't contribute
        if (!orgIds || orgIds.length <= 0) {
            return false;
        }

        // check if the user has org membership rights
        return user.orgMemberships?.some((om) => organizations?.some((o) => o.id === om.orgId && om.isIRPApprover));
    };

    const userIsContributorOrIRPApprover = (dataRequest: IDataRequest, organizations: IOrganization[]) => {
        if (userIsDataContributor(dataRequest, organizations) || userIsIRPApprover(dataRequest, organizations)) {
            return true;
        }

        return false;
    };

    const shouldHideReviewers = (dataRequest: IDataRequest, orgs: IOrganization[], forPrint = false) => {
        if (!dataRequest) {
            return false;
        }

        // get org ids where user is a data provider or irp approver
        // don't return the org if the user is an admin, since they are still allowed to see reviewers
        const userOrgIds = user.orgMemberships
            .filter((x) => x.isDataProvider && ((forPrint && x.isOrgAdmin) || !x.isOrgAdmin))
            .map((x) => x.orgId);
        const irpOrgIds = user.orgMemberships
            .filter((x) => x.isIRPApprover && ((forPrint && x.isOrgAdmin) || !x.isOrgAdmin))
            .map((x) => x.orgId);

        // get org ids and approver org ids from request
        const studyOrgIds = dataRequest.requestedStudies.map((x) => x.organizationId);
        const approverOrgIds = dataRequest.requestedStudies.map((x) => x.approvalBodyOrgId);

        // cross reference ids to see if the user has studies in this request
        const dataProviderOrgIds = userOrgIds.filter((x) => studyOrgIds.some((s) => s === x));
        const irpApproverOrgIds = irpOrgIds.filter((x) => approverOrgIds.some((s) => s === x));

        // concat ids for ease of use
        const orgIds = [...dataProviderOrgIds, ...irpApproverOrgIds];

        // filter orgs based the returned org ids, check of any of them have hide reviewers checked
        // if any have it checked then use it (least permission possible)
        return orgs.filter((org) => orgIds.some((orgId) => orgId === org.id)).some((org) => org.blindReviewers);
    };

    const isDuaValidatedRequest = (dataRequest: IDataRequest) => {
        if (!dataRequest) {
            return false;
        }

        return (
            dataRequest.status === DataRequestStatusEnum.DUAValidated ||
            dataRequest.status === DataRequestStatusEnum.Fulfilled ||
            dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled
        );
    };

    const userCanReject = (dataRequest: IDataRequest) => {
        const isVivliAdmin = user.isVivliAdmin;
        //we're discontinuing use of dua return to requestor button, so don't show
        //it for vivli admins. DRs likely won't be in ReturnedToRequestor status
        //but if they are, only approve will be allowed (no reject)
        if (
            isVivliAdmin &&
            dataRequest.status != DataRequestStatusEnum.AwaitingDUAValidation &&
            dataRequest.status != DataRequestStatusEnum.DUAReturnedToRequester
        ) {
            return true;
        }

        switch (dataRequest.status) {
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return (
                    user &&
                    dataRequest.requiredDataProviderApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None &&
                            DataRequestPermissionsService.isOrgMember(user, dpa.orgId, (om) => om.isDataProvider || om.isOrgAdmin)
                    )
                );
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return (
                    user &&
                    dataRequest.requiredIRPApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None &&
                            DataRequestPermissionsService.isOrgMember(user, dpa.orgId, (om) => om.isOrgAdmin)
                    )
                );
        }
        return false;
    };

    const userCanApprove = (dataRequest: IDataRequest) => {
        if (user.isVivliAdmin) {
            switch (dataRequest.status) {
                case DataRequestStatusEnum.SubmittedToVivli:
                case DataRequestStatusEnum.AwaitingDataProviderApproval:
                case DataRequestStatusEnum.AwaitingIRPApproval:
                case DataRequestStatusEnum.AwaitingDUAValidation:
                case DataRequestStatusEnum.DUAReturnedToRequester:
                    return true;
            }
        }

        switch (dataRequest.status) {
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return (
                    user &&
                    dataRequest.requiredDataProviderApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None &&
                            DataRequestPermissionsService.isOrgMember(user, dpa.orgId, (om) => om.isDataProvider || om.isOrgAdmin)
                    )
                );
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return (
                    user &&
                    dataRequest.requiredIRPApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None &&
                            DataRequestPermissionsService.isOrgMember(user, dpa.orgId, (om) => om.isOrgAdmin)
                    )
                );
        }
        return false;
    };

    const canSoftReject = (dataRequest: IDataRequest) => {
        switch (dataRequest.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDUAValidation:
                return false;
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return userCanApprove(dataRequest);
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return userCanApprove(dataRequest);
        }
        return false;
    };

    const getAllowedTransitions = (dataRequest: IDataRequest) => {
        if (!userCanApprove(dataRequest)) {
            return { approve: null, reject: null, softReject: null };
        }

        switch (dataRequest.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
                if (dataRequest.requiredDataProviderApprovals?.length > 0) {
                    return {
                        approve: DataRequestStatusEnum.AwaitingDataProviderApproval,
                        reject: DataRequestStatusEnum.RejectedByVivli,
                        softReject: null,
                    };
                } else if (dataRequest.requiredIRPApprovals?.length > 0) {
                    return {
                        approve: DataRequestStatusEnum.AwaitingIRPApproval,
                        reject: DataRequestStatusEnum.RejectedByVivli,
                        softReject: null,
                    };
                } else {
                    return {
                        approve: DataRequestStatusEnum.Approved,
                        reject: DataRequestStatusEnum.RejectedByVivli,
                        softReject: null,
                    };
                }
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return {
                    approve: null,
                    reject: DataRequestStatusEnum.RejectedByDataProvider,
                    softReject: DataRequestStatusEnum.RevisionsRequestedByDataProvider,
                };

            case DataRequestStatusEnum.AwaitingIRPApproval:
                return {
                    approve: null,
                    reject: DataRequestStatusEnum.RejectedByIRP,
                    softReject: DataRequestStatusEnum.RevisionsRequestedByIRP,
                };
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAReturnedToRequester:
                return {
                    approve: DataRequestStatusEnum.DUAValidated,
                    reject: null,
                    softReject: null,
                };
        }

        return { approve: null, reject: null, softReject: null };
    };

    const isInProcessRequest = (dataRequest: IDataRequest) => {
        switch (dataRequest.status) {
            case DataRequestStatusEnum.Draft:
            case DataRequestStatusEnum.Archived:
            case DataRequestStatusEnum.Cancelled:
                return false;
            default:
                return true;
        }
    };

    return {
        userIsOwner,
        userIsAdmin,
        userIsDataContributor,
        userIsIRPApprover,
        userIsContributorOrIRPApprover,
        isDuaValidatedRequest,
        shouldHideReviewers,
        userCanReject,
        userCanApprove,
        canSoftReject,
        getAllowedTransitions,
        isInProcessRequest,
    };
}
