﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { IMetricsReport } from '@vivli/features/dashboard/infrastructure/interface';
import { MetricsReportHeaderComponent } from './metrics-report-header.component';
import { AveragesWrapperComponent } from './averages-wrapper.component';
import { SimpleMetricsListComponent } from './simple-metrics-list.component';

const reportContainerStyle = (metricsReport): CSSProperties => ({
    height: '100%',
    overflowY: 'scroll',
    padding: `${MetricsConstants.Sizing.SPACING}px ${metricsReport ? `${Size.INSET_SIZE}px` : '10%'} ${
        MetricsConstants.Sizing.DOUBLE_SPACING
    }px`,
});
const widthSpacingStyle: CSSProperties = {
    width: MetricsConstants.Sizing.SPACING,
};

interface MetricsReportComponentProps {
    metricsReport: IMetricsReport;
    isAggregateReport?: boolean;
}

export const MetricsReportComponent = ({ metricsReport, isAggregateReport }: MetricsReportComponentProps) => {
    const [chartWidth, setChartWidth] = useState<number>(null);

    const updateChartWidth = () => {
        const averageContainer = document.getElementById('averageMetrics');
        if (window[MetricsConstants.Text.PRINT_FLAG] || !averageContainer) {
            setChartWidth(MetricsConstants.Sizing.CHART_PRINT_WIDTH);
        } else if (averageContainer) {
            const calculatedWidth = averageContainer?.offsetWidth - 2 * MetricsConstants.Sizing.DOUBLE_SPACING;
            setChartWidth(calculatedWidth);
        }
    };

    const evaluatePrint = () => {
        const previousValue = window[MetricsConstants.Text.PRINT_FLAG];
        window[MetricsConstants.Text.PRINT_FLAG] = window[MetricsConstants.Text.PRINT_DIALOG_EVENT_COUNTER] > 0;
        if (previousValue !== window[MetricsConstants.Text.PRINT_FLAG]) {
            updateChartWidth();
        }
    };

    const prepPrintHandling = () => {
        const beforePrint = function () {
            window[MetricsConstants.Text.PRINT_DIALOG_EVENT_COUNTER]++;
            evaluatePrint();
        };
        const afterPrint = function () {
            window[MetricsConstants.Text.PRINT_DIALOG_EVENT_COUNTER]--;
            evaluatePrint();
        };

        delete window[MetricsConstants.Text.PRINT_DIALOG_EVENT_COUNTER];
        delete window[MetricsConstants.Text.PRINT_FLAG];
        window[MetricsConstants.Text.PRINT_DIALOG_EVENT_COUNTER] = 0;
        window[MetricsConstants.Text.PRINT_FLAG] = false;
        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    };

    useEffect(() => {
        updateChartWidth();
    }, [metricsReport]);
    useEffect(() => {
        prepPrintHandling();
    }, []);

    if (!metricsReport) {
        return null;
    }

    return (
        <div id="reportContainer" className={'reportContainer'} style={reportContainerStyle(metricsReport)}>
            <MetricsReportHeaderComponent details={metricsReport?.details} />
            <div className={'simpleMetrics'} style={{ display: 'flex' }}>
                <SimpleMetricsListComponent
                    title={MetricsConstants.Text.SIMPLE_METRIC_CURRENT_DATA_TITLE}
                    metrics={metricsReport?.currentDataRequestMetrics}
                />
                <div style={widthSpacingStyle} />
                <SimpleMetricsListComponent
                    title={MetricsConstants.Text.SIMPLE_METRIC_TOTAL_DATA_TITLE}
                    metrics={metricsReport?.totalDataRequestMetrics}
                />
            </div>
            <div id={'averageMetrics'}>
                {metricsReport?.singleContributorTimeMetrics && (
                    <AveragesWrapperComponent
                        title={MetricsConstants.Text.SECTION_HEADER_SINGLE_DC(metricsReport?.details?.singleContributorRequestCount)}
                        color={MetricsConstants.Colors.SINGLE_DC_PRIMARY}
                        chartData={metricsReport?.singleContributorTimeMetrics}
                        orgName={metricsReport?.details?.orgName}
                        width={chartWidth}
                        isAggregateReport={isAggregateReport}
                    />
                )}

                <div className={'forcePageBreak'} />

                {metricsReport?.multipleContributorTimeMetrics && (
                    <AveragesWrapperComponent
                        title={MetricsConstants.Text.SECTION_HEADER_MULTIPLE_DC(metricsReport?.details?.multipleContributorRequestCount)}
                        color={MetricsConstants.Colors.MULTIPLE_DC_PRIMARY}
                        chartData={metricsReport?.multipleContributorTimeMetrics}
                        orgName={metricsReport?.details?.orgName}
                        width={chartWidth}
                        isAggregateReport={isAggregateReport}
                    />
                )}
            </div>
        </div>
    );
};
