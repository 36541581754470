import React, { CSSProperties, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { SignDocumentComponent } from './sign-document.component';
import { useUsersService } from '@vivli/features/users/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { finalize, first } from 'rxjs/operators';
import { ISignedDocument } from '@vivli/features/users/infrastructure/interface';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { DTIEsign } from '@vivli/shared/infrastructure/constants';

const buttonStyle: CSSProperties = {
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    minHeight: 50,
    minWidth: 180,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 45,
};

const documentName = 'VivliTermsOfUseAgreement.html';

export const EsignComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const modalService = useModalService();
    const { updateSignedDocuments } = useUsersService();
    const { attachSignatureToRequest } = useDataRequestsService();
    const { dataRequest, updateDataRequest } = useDataRequestContext();
    const user = useActiveUser();
    const vivliConfig = useVivliConfig();

    const handleAttachToDataRequest = (signedDocument: ISignedDocument) => {
        attachSignatureToRequest(dataRequest.id, signedDocument.id)
            .pipe(
                first(),
                finalize(() => modalService.dismiss())
            )
            .subscribe(updateDataRequest);
    };

    const handleError = (error) => {
        modalService.error(`Failed to sign document.
        Please try again or contact support if the problem continues: ${error.message}`);
        modalService.dismiss();
    };

    const handleOnSigned = (signature: string) => {
        updateSignedDocuments(user.userId, documentName, vivliConfig?.termsOfUseAgreement, 'HTML', signature)
            .pipe(first())
            .subscribe(handleAttachToDataRequest, handleError);
    };

    const handleButtonClick = () => {
        setIsLoading(true);
        const modalId = modalService.custom(
            <SignDocumentComponent
                onCancel={() => {
                    modalService.dismiss(modalId);
                    setIsLoading(false);
                }}
                onSigned={handleOnSigned}
            />
        );
    };

    return (
        <div>
            <ButtonComponent
                style={buttonStyle}
                isLoading={isLoading}
                onClick={handleButtonClick}
                className="vmView_signDocument"
                dataId={DTIEsign.ESignButton}
            >
                Sign Now
            </ButtonComponent>
        </div>
    );
};
