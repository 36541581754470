import React from 'react';

export const NarrativeSummaryHelpComponent = () => {
    return (
        <div>
            <div>
                Please provide an English-language summary of the proposed research that is suitable for a general or lay audience,
                clarifies the design, and explains the relevance of the research project to science and public health, covering the
                following topics:
            </div>
            <ul>
                <li>The project background</li>
                <li>Necessity of the research</li>
                <li>How many patients / members of the public are potentially affected</li>
                <li>How the research will add to medical science or patient care</li>
                <li>How the research will be conducted</li>
                <li>What design and methods you have you chosen and why (in brief)</li>
                <li>Provide references to prior work on the topic, if applicable</li>
            </ul>
            <div>
                In the first instance, please spell out and explain all acronyms used. Vivli may request that you revise your request and
                spell out acronyms, before moving your request forward.
            </div>
            <br />
            <div>Note that this summary will be published on the Vivli website if the request is approved.</div>
        </div>
    );
};
