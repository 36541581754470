﻿interface Reason {
    title: string;
    description: string;
}

const reasons: Reason[] = [
    {
        title: 'Study Completion Date criteria is not yet met.',
        description: '',
    },
    {
        title: 'Data Sharing Prohibited by Consent, Legal, Regulatory, or Contractual Constraints.',
        description: '',
    },
    {
        title: 'Indications have not received market authorization.',
        description: '',
    },
    {
        title: 'Likelihood of re-identification of patients given small number of patients and/or involves a rare disease.',
        description: '',
    },
    {
        title: 'Not responsible for Data Sharing –',
        description: 'The data contributor specified in the request is not the one responsible for sharing this data',
    },
    {
        title: 'Other',
        description: '',
    },
];

export const ReasonHint = () => {
    return (
        <>
            When the response is Not Available, the reason field provides more information about why;
            <br />
            <br />
            Choices are the following:
            <ul>
                {reasons.map((response) => (
                    <li key={response.title} style={{ marginTop: '10px' }}>
                        <b>{response.title}</b> {response.description}
                    </li>
                ))}
            </ul>
        </>
    );
};
