import React, { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useSharedDataContext } from '@vivli/features/data-requests/infrastructure/context';
import { BadgeComponent } from '@vivli/shared/components';
import {
    internalMenuButtonContainerStyle,
    internalMenuButtonOuterContainerStyle,
    internalMenuTextStyle,
} from './internal-link-menu-styles';
import { AssetsConstant, DTIMyDataRequestsButton } from '@vivli/shared/infrastructure/constants';
import { useNavigate } from 'react-router-dom';

const badgeStyle = (requestCount: number): CSSProperties => ({
    backgroundColor: Color.VIVLI_YELLOW,
    paddingBottom: requestCount === 0 ? 0 : 2,
    paddingLeft: requestCount === 0 ? 0 : 2,
    paddingRight: requestCount === 0 ? 0 : 2,
    paddingTop: requestCount === 0 ? 0 : 2,
    color: requestCount === 0 ? 'rgba(0,0,0,0)' : 'black',
    fontWeight: Size.FontWeight.Bold,
    fontSize: '10px',
    position: 'absolute',
    top: -3,
    right: -3,
    height: requestCount === 0 ? 0 : 'initial',
    transition: 'padding .4s ease, height .4s ease, color .5s ease-in',
});

const badgeContainerStyle: CSSProperties = {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.RIBBON_MY_REQUESTS})`,
    backgroundPosition: 'top -1px left -1px',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    marginRight: 10,
};

const clickHereTextStyle: CSSProperties = {
    ...Styles.Popout.POPOUT_BODY_STYLE,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    backgroundColor: Color.TEXT_LIGHT_BLUE,
    fontSize: Size.FontSize.Medium,
    color: Color.WHITE,
    maxWidth: 300,
};

const popoverContainerStyle: CSSProperties = {
    filter: Styles.POPOVER_DROP_SHADOW,
    zIndex: 500,
};

export const MyDataRequestsButtonComponent = () => {
    const navigate = useNavigate();
    const {
        myDataRequests: {
            myDataRequests,
            draftCount,
            inProgressCount,
            notApprovedCount,
            archivedCount,
            fulfilledCount,
            showRequestAddedBadge,
        },
    } = useSharedDataContext();

    const handleButtonClick = () => {
        let pushToUrl = '';

        if (draftCount <= 0) {
            pushToUrl = 'Active';
            if (inProgressCount <= 0) {
                pushToUrl = 'Fulfilled';
                if (fulfilledCount <= 0) {
                    pushToUrl = 'NotApproved';
                    if (notApprovedCount <= 0) {
                        pushToUrl = 'Archived';
                        if (archivedCount <= 0) {
                            pushToUrl = 'Draft';
                        }
                    }
                }
            }
        }
        navigate(`/myDataRequests/${pushToUrl}`);
    };

    const arrowContainerStyle: CSSProperties = {
        opacity: showRequestAddedBadge ? 1 : 0,
        transition: 'opacity 1s',
    };

    return (
        <div style={internalMenuButtonOuterContainerStyle}>
            <div
                style={internalMenuButtonContainerStyle}
                onClick={handleButtonClick}
                className="header_myDataRequests"
                data-test-id={DTIMyDataRequestsButton.HeaderMyDataRequest}
            >
                <Popover
                    isOpen={showRequestAddedBadge}
                    positions={['left']}
                    padding={10}
                    containerStyle={popoverContainerStyle as any}
                    content={(props) => (
                        <ArrowContainer {...props} arrowSize={8} arrowColor={Color.TEXT_LIGHT_BLUE} style={arrowContainerStyle}>
                            <div style={clickHereTextStyle}>Click here to view your data requests.</div>
                        </ArrowContainer>
                    )}
                >
                    <div style={badgeContainerStyle}>
                        <BadgeComponent style={badgeStyle(myDataRequests?.length || 0)} value={myDataRequests?.length} />
                    </div>
                </Popover>

                <div style={internalMenuTextStyle}>MY DATA REQUESTS</div>
            </div>
        </div>
    );
};
