import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';

export function useDataRequestFilters() {
    const filterAwaitingUpload = (dataRequests: IDataRequestSummary[]): IDataRequestSummary[] => {
        return dataRequests.filter(
            (r) => r.status === DataRequestStatusEnum.DUAValidated || r.status === DataRequestStatusEnum.PartiallyFulfilled
        );
    };

    return {
        filterAwaitingUpload,
    };
}
