import { Observable } from 'rxjs';
import {
    IStudiesSearchApiService,
    IStudyMetadataSearchResult,
    IStudySearchFilterOption,
    IStudySearchResult,
} from '@vivli/features/studies/infrastructure/interface';
import { RestApi } from '@vivli/core/infrastructure/rest';
import { map } from 'rxjs/operators';
import { AzureSearchService } from './azure-search.service';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

export class StudiesSearchApiService extends RestApi implements IStudiesSearchApiService {
    private _azureSearchService = new AzureSearchService();

    getQuickSearchAdvancedMetadata = (
        assignedAppType: AssignedAppTypeEnum,
        filterString?: string
    ): Observable<IStudySearchFilterOption[]> => {
        const { queryKey, queryUri } = this.config;
        let filter = '';
        if (filterString) {
            filter = `&$filter=${filterString}`;
        }

        const facetsString = this._azureSearchService.getFacetString(assignedAppType);
        const uri = `/indexes/studies/docs?api-version=2016-09-01&api-key=${queryKey}&search=*${filter}${facetsString}&$top=0`;

        return this.handleGet<IStudyMetadataSearchResult>(uri, {
            bypassAuth: true,
            baseUrl: queryUri,
        }).pipe(map((result) => result['@search.facets'].sponsorName));
    };

    getStudiesById = (ids: string[], top?: number, skip?: number): Observable<IStudySearchResult[]> => {
        const isNctId = (id: string): boolean => id && id.toLocaleLowerCase().startsWith('nct');
        const { queryKey, queryUri } = this.config;

        const idFilterString = Array.from(new Set(ids).values()).reduce((resultString, id, index) => {
            const filter = `${isNctId(id) ? 'nctId' : 'sponsorProtocolId'} eq '${id}'`;
            return resultString + (index > 0 && index < ids.length ? ' or ' + filter : filter);
        }, '');
        const searchBaseUrl = `/indexes/studies/docs?api-version=2016-09-01&api-key=${queryKey}`;
        const filterString = `&$filter=(${idFilterString})`;
        const orderByString = `&$orderby=nctId desc, sponsorProtocolId desc`;
        let uri = `${searchBaseUrl}${filterString}${orderByString}`;

        if (top && skip) {
            uri += `&$top=${top}&$skip=${skip}`;
        }

        return this.handleGet<{ value: IStudySearchResult[] }>(uri, {
            bypassAuth: true,
            baseUrl: queryUri,
        }).pipe(map((result) => result.value));
    };
}
