import React from 'react';
import { Styles } from '@vivli/shared/theme';
import {
    LinkFieldComponent,
    oneAcrossStyle,
    TextFieldReadOnlyFormComponent,
    threeSmallSplitAcrossStyle,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { DTIFormFields } from '@vivli/shared/infrastructure/constants';
import { useDoiTransform } from '@vivli/features/studies/infrastructure/hook';

export const AmrDatasetAdminDetailsComponent = ({ study }) => {
    const showLinkDoi = study.studyMetadataDoi.indexOf('DOINotCreated') < 0;
    const { transformDoiUrl } = useDoiTransform();

    return (
        <div style={{ width: '100%' }}>
            <div style={Styles.FORM_ROW}>
                {showLinkDoi ? (
                    <LinkFieldComponent
                        linkTitle={study.studyMetadataDoi}
                        linkContent={transformDoiUrl(study.studyMetadataDoi) /*11301*/}
                        label={'DOI'}
                        style={twoAcrossStyle}
                        dataId={DTIFormFields.FormStudyDoi}
                    />
                ) : (
                    <TextFieldReadOnlyFormComponent
                        label={'DOI'}
                        style={twoAcrossStyle}
                        defaultValue={study.studyMetadataDoi}
                        readonly={true}
                        dataId={DTIFormFields.FormStudyDoi}
                    />
                )}
                <TextFieldReadOnlyFormComponent
                    label={'AMR ID'}
                    style={threeSmallSplitAcrossStyle}
                    defaultValue={`VIV${study.doiStem}`}
                    readonly={true}
                    dataId={DTIFormFields.FormAmrId}
                />
                <TextFieldReadOnlyFormComponent
                    label="Dataset ID"
                    style={threeSmallSplitAcrossStyle}
                    defaultValue={study.sponsorProtocolId}
                    readonly={true}
                    dataId={DTIFormFields.FormSponsorId}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <LinkFieldComponent
                    linkTitle={study.studyIPDDataPackageDoi}
                    linkContent={transformDoiUrl(study.studyIPDDataPackageDoi) /*11301*/}
                    label={'Available Data Package DOIs'}
                    style={oneAcrossStyle}
                    dataId={DTIFormFields.FormStudyIpdDoi}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label={'Data Contributor Organization Name'}
                    style={oneAcrossStyle}
                    defaultValue={study.orgName}
                    readonly={true}
                    dataId={DTIFormFields.FormOrgName}
                />
            </div>
        </div>
    );
};
