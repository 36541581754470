import React, { CSSProperties } from 'react';
import { OtherInformationDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { FieldHeaderComponent, TextAreaFormFieldComponent } from '@vivli/shared/components';
import { Size } from '@vivli/shared/theme';
import { DataRequestHintsConstant } from '@vivli/shared/infrastructure/constants';
import { useFormContext } from 'react-hook-form';

const containerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: Size.INSET_SIZE,
};

export const OtherInformationFormComponent = () => {
    const formApi = useFormContext();

    const handlePackageValidation = (isValid: boolean) => {
        formApi.setValue('metaDataDataPackageIsValid', isValid);
    };

    const metaDataDataPackageId = formApi.getValues('metaDataDataPackageId');

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title="Other Information" />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <TextAreaFormFieldComponent
                    name={'otherInformation'}
                    label={'Other Information'}
                    hint={DataRequestHintsConstant.otherInformation}
                />
            </div>
            <FieldHeaderComponent title="File Attachments" />

            <OtherInformationDataPackageFeature dataPackageId={metaDataDataPackageId} onValidation={handlePackageValidation} />
        </div>
    );
};
