export const reportSpecsIntercept = [
    {
        name: 'Standard Display, 1 Data Request per Row (Vivli Admin)',
        accessType: 'Displayed',
        userType: 'VivliAdmin',
        reportGenerationType: 'RequestPerRow',
        code: 'B25257D5-9A12-49B1-8C28-F7928DD5F73C',
        active: true,
        default: true,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vivliOwner',
                columnTitle: 'Vivli Owner',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.internalNotes',
                columnTitle: 'Internal Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'remainingNoChargeDays',
                columnTitle: 'Remaining No-Charge Days',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionDate',
                columnTitle: 'Next Action Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionNotes',
                columnTitle: 'Next Action Notes',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepStartDate',
                columnTitle: 'Start Date of Current Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.firstPublicationDate',
                columnTitle: 'Date First Published',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Standard Export, 1 Data Request per Row (Vivli Admin)',
        accessType: 'Exported',
        userType: 'VivliAdmin',
        reportGenerationType: 'RequestPerRow',
        code: '451DB49E-166E-4D0F-8648-7C071C5F9962',
        active: true,
        default: false,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'researchTitle',
                columnTitle: 'Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherAffiliation',
                columnTitle: 'Lead Researcher Affiliation',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherTitle',
                columnTitle: 'Lead Researcher Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherEmail',
                columnTitle: 'Lead Researcher Email',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vivliOwner',
                columnTitle: 'Vivli Owner',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.firstPublicationDate',
                columnTitle: 'Date First Published',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.internalNotes',
                columnTitle: 'Internal Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmSizeFromTitle',
                columnTitle: 'VM Size',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.billingRatePerDay',
                columnTitle: 'Billing Rate',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmBillingStartDate',
                columnTitle: 'VM Billing Start Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.sasForVM',
                columnTitle: 'SAS',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'remainingNoChargeDays',
                columnTitle: 'Remaining No-Charge Days',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'billableDaysThisMonth',
                columnTitle: 'Billable Days For Period',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'billableDollarsThisMonth',
                columnTitle: 'Billable $ For Period',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.paymentRemindersStatusHistory',
                columnTitle: 'Payment Reminders, Status, History',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmFreeDaysBooked',
                columnTitle: 'Research Environment No-Charge Days Banked',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionDate',
                columnTitle: 'Next Action Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionNotes',
                columnTitle: 'Next Action Notes',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepStartDate',
                columnTitle: 'Start Date of Current Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'mainPredictor',
                columnTitle: 'Main Predictor/Independent Variable',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'publicationPlan',
                columnTitle: 'Publication Plan',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'briefDescription',
                columnTitle: 'Brief Description',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'aimsObjectivesHypotheses',
                columnTitle: 'Aims, Objectives, Hypotheses',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'purposeOfAnalysisString',
                columnTitle: 'Purpose of Analysis',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'therapeuticArea',
                columnTitle: 'Therapeutic Area',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'requestedStudyIds',
                columnTitle: 'All Studies Included by Sponsor ID/NCT ID',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateSubmittedToDataContributor',
                columnTitle: 'Date Submitted to Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Full Export, 1 Requested Study per Row (Vivli Admin)',
        accessType: 'Exported',
        userType: 'VivliAdmin',
        reportGenerationType: 'RequestOrStudyPerRow',
        code: '493D5404-0328-4D47-8EAA-89C67348E846',
        active: true,
        default: false,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'researchTitle',
                columnTitle: 'Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherAffiliation',
                columnTitle: 'Lead Researcher Affiliation',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherTitle',
                columnTitle: 'Lead Researcher Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherEmail',
                columnTitle: 'Lead Researcher Email',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vivliOwner',
                columnTitle: 'Vivli Owner',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudyContributorName',
                columnTitle: 'Requested Study Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudyNctId',
                columnTitle: 'Requested Study NCT ID',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudySponsorId',
                columnTitle: 'Requested Study Sponsor ID',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.firstPublicationDate',
                columnTitle: 'Date First Published',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.internalNotes',
                columnTitle: 'Internal Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmSizeFromTitle',
                columnTitle: 'VM Size',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.billingRatePerDay',
                columnTitle: 'Billing Rate',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmBillingStartDate',
                columnTitle: 'VM Billing Start Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.sasForVM',
                columnTitle: 'SAS',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'remainingNoChargeDays',
                columnTitle: 'Remaining No-Charge Days',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'billableDaysThisMonth',
                columnTitle: 'Billable Days For Period',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'billableDollarsThisMonth',
                columnTitle: 'Billable $ For Period',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.paymentRemindersStatusHistory',
                columnTitle: 'Payment Reminders, Status, History',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.vmFreeDaysBooked',
                columnTitle: 'Research Environment No-Charge Days Banked',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionDate',
                columnTitle: 'Next Action Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.nextActionNotes',
                columnTitle: 'Next Action Notes',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepStartDate',
                columnTitle: 'Start Date of Current Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'mainPredictor',
                columnTitle: 'Main Predictor/Independent Variable',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'publicationPlan',
                columnTitle: 'Publication Plan',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'briefDescription',
                columnTitle: 'Brief Description',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'aimsObjectivesHypotheses',
                columnTitle: 'Aims, Objectives, Hypotheses',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'purposeOfAnalysisString',
                columnTitle: 'Purpose of Analysis',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'therapeuticArea',
                columnTitle: 'Therapeutic Area',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateSubmittedToDataContributor',
                columnTitle: 'Date Submitted to Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Standard Display, 1 Data Request per Row (Org Admin)',
        accessType: 'Displayed',
        userType: 'OrgAdmin',
        reportGenerationType: 'RequestPerRow',
        code: '4FBAF699-01C9-4AA4-8796-B0AC9093D185',
        active: true,
        default: true,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'numberStudiesMyOrg',
                columnTitle: "Number of My Organization's Studies",
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateSubmittedToDataContributor',
                columnTitle: 'Date Submitted to Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Standard Export, 1 Data Request per Row (Org Admin)',
        accessType: 'Exported',
        userType: 'OrgAdmin',
        reportGenerationType: 'RequestPerRow',
        code: 'DF534BA5-7B99-475F-8F9B-CAB31723693A',
        active: true,
        default: false,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'researchTitle',
                columnTitle: 'Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherAffiliation',
                columnTitle: 'Lead Researcher Affiliation',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherTitle',
                columnTitle: 'Lead Researcher Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherEmail',
                columnTitle: 'Lead Researcher Email',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'numberStudiesMyOrg',
                columnTitle: "Number of My Organization's Studies",
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.firstPublicationDate',
                columnTitle: 'Date First Published',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'mainPredictor',
                columnTitle: 'Main Predictor/Independent Variable',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'publicationPlan',
                columnTitle: 'Publication Plan',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'briefDescription',
                columnTitle: 'Brief Description',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'aimsObjectivesHypotheses',
                columnTitle: 'Aims, Objectives, Hypotheses',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'purposeOfAnalysisString',
                columnTitle: 'Purpose of Analysis',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'therapeuticArea',
                columnTitle: 'Therapeutic Area',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'requestedStudyIds',
                columnTitle: 'All Studies Included by Sponsor ID/NCT ID',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateSubmittedToDataContributor',
                columnTitle: 'Date Submitted to Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Full Export, 1 Requested Study per Row (Org Admin)',
        accessType: 'Exported',
        userType: 'OrgAdmin',
        reportGenerationType: 'RequestOrStudyPerRow',
        code: '18F550D7-7E98-4F7D-9B75-B04E78CA7B38',
        active: true,
        default: false,
        reportColumns: [
            {
                columnPropertyName: 'VivliId',
                columnTitle: 'Vivli Id',
                wrapText: false,
                width: 150,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'researchTitle',
                columnTitle: 'Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcher',
                columnTitle: 'Lead Researcher',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherAffiliation',
                columnTitle: 'Lead Researcher Affiliation',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherTitle',
                columnTitle: 'Lead Researcher Title',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'leadResearcherEmail',
                columnTitle: 'Lead Researcher Email',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dataContributorsList',
                columnTitle: 'Data Contributors',
                wrapText: false,
                width: 320,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Contributors' },
            },
            {
                columnPropertyName: 'numberStudiesMyOrg',
                columnTitle: "Number of My Organization's Studies",
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'requestedStudiesCount',
                columnTitle: 'Total Studies Requested',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudyContributorName',
                columnTitle: 'Requested Study Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudyNctId',
                columnTitle: 'Requested Study NCT ID',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'includedRequestedStudySponsorId',
                columnTitle: 'Requested Study Sponsor ID',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'currentStatus',
                columnTitle: 'Current Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'active',
                columnTitle: 'Active',
                wrapText: false,
                width: 100,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.actionsRequired',
                columnTitle: 'Actions Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'activitiesRequired',
                columnTitle: 'Decisions and Uploads Required',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.highlightedDaysInCurrentWorkflowStep',
                columnTitle: 'Days in Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.currentWorkflowStepTargetDays',
                columnTitle: 'Target Days for Current Workflow Step',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.feedback',
                columnTitle: 'Feedback',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.requestReviewStatusList',
                columnTitle: 'Request Review Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.duaStatusList',
                columnTitle: 'DUA Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: '' },
            },
            {
                columnPropertyName: 'statusUpdate.dataUploadStatusList',
                columnTitle: 'Data Upload Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'resultsRemovalRequestsList',
                columnTitle: 'Results Export Requests',
                wrapText: false,
                width: 400,
                cellRenderer: 'GridCellList',
                cellRendererParams: { emptyMessage: 'No Requests' },
            },
            {
                columnPropertyName: 'statusUpdate.publicationProgress',
                columnTitle: 'Publication Status',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.firstPublicationDate',
                columnTitle: 'Date First Published',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'statusUpdate.additionalNotes',
                columnTitle: 'Additional Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'mainPredictor',
                columnTitle: 'Main Predictor/Independent Variable',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'publicationPlan',
                columnTitle: 'Publication Plan',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'briefDescription',
                columnTitle: 'Brief Description',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'aimsObjectivesHypotheses',
                columnTitle: 'Aims, Objectives, Hypotheses',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'purposeOfAnalysisString',
                columnTitle: 'Purpose of Analysis',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'therapeuticArea',
                columnTitle: 'Therapeutic Area',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateSubmittedToDataContributor',
                columnTitle: 'Date Submitted to Data Contributor',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
            {
                columnPropertyName: 'dateModified',
                columnTitle: 'Date of Last Change',
                wrapText: false,
                width: 0,
                cellRenderer: '',
                cellRendererParams: null,
            },
        ],
    },
    {
        name: 'Single Study Submissions (Vivli Admin)',
        dataType: 'ListingRequest',
        accessType: 'Displayed',
        category: 'Single Study Submissions',
        userType: 'VivliAdmin',
        reportGenerationType: 'RequestPerRow',
        code: '0D2985B9-B93E-42B8-94A8-16552ACCBA7A',
        active: true,
        default: true,
        reportColumns: [
            {
                columnPropertyName: 'nctId',
                columnTitle: 'NCT Id',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'sponsorProtocolId',
                columnTitle: 'Sponsor ID',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'requesterEmail',
                columnTitle: 'Requester email',
                wrapText: false,
                width: 0,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'orgName',
                columnTitle: 'Contributor organization',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
                cellRendererParams: {
                    emptyMessage: 'No Contributors',
                },
            },
            {
                columnPropertyName: 'status',
                columnTitle: 'Status',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'isDataUploaded',
                columnTitle: 'IPD Loaded (Y/N)',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'submittedDate',
                columnTitle: 'Date Study Submitted',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'postedDate',
                columnTitle: 'Date Posted',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'studyTitle',
                columnTitle: 'Study Title',
                wrapText: false,
                width: 500,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'uploadNotes',
                columnTitle: 'Data Upload Notes',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'internalNotes',
                columnTitle: 'Internal Notes',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellLargeText',
            },
            {
                NextActionDate: 'nextActionDate',
                columnTitle: 'Next Action Date',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'nextActionNotes',
                columnTitle: 'Next Action Notes',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'bypassDataContributorReview',
                columnTitle: 'Bypass Data Contributor Review',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'bypassIRPReview',
                columnTitle: 'Bypass IRP Review',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'rorIds',
                columnTitle: 'ROR IDs',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
            },
            {
                columnPropertyName: 'rorNames',
                columnTitle: 'ROR Names',
                wrapText: false,
                width: 300,
                cellRenderer: 'GridCellList',
            },
            {
                columnPropertyName: 'parentRorIds',
                columnTitle: 'Funding Organization(s) ROR IDs',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'parentRorNames',
                columnTitle: 'Funding Organization(s) ROR Names',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellLargeText',
            },
            {
                columnPropertyName: 'grantIds',
                columnTitle: 'Funding Organization Grant IDs',
                wrapText: false,
                width: 150,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'dataRequests',
                columnTitle: 'Requests',
                wrapText: false,
                width: 200,
                cellRenderer: 'GridCellList',
                cellRendererParams: {
                    emptyMessage: 'No Requests',
                },
            },
            {
                columnPropertyName: 'dataRequestsCount',
                columnTitle: '# of requests',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropert: 'isEmbargoed',
                columnTitle: 'Embargo',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
            {
                columnPropertyName: 'downloadableStudyIPDDataPackage',
                columnTitle: 'Downloadable',
                wrapText: false,
                width: 0,
                cellRenderer: '',
            },
        ],
    },
];
