﻿import { UserInfoContext, useUsersService } from '@vivli/features/users/infrastructure/context';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useEffect, useState } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useFormContext } from 'react-hook-form';
import { IEnquiry } from '@vivli/features/enquiry/infastructure/interface';

export const UserInfoContextWrapper = ({ children }: IContextWrapper) => {
    const activeUser = useActiveUser();
    const isVivliAdmin = activeUser.isVivliAdmin;
    const usersService = useUsersService();
    const formApi = useFormContext();
    const toastService = useToastService();
    const [isSavingNotes, setIsSavingNotes] = useState<boolean>(false);
    const [userDataRequests, setUserDataRequests] = useState<IDataRequest[]>();
    const [userEnquiries, setEnquiries] = useState<IEnquiry[]>();
    const user = formApi.getValues();
    const modalService = useModalService();

    const handleUpdateUserInfo = (notes = formApi.getValues().notes) => {
        setIsSavingNotes(true);
        usersService.updateUserNote(user.id, notes).subscribe(
            () => {
                setIsSavingNotes(false);
                toastService.success('Notes saved.');
            },
            () => toastService.error('Notes not saved.')
        );
    };

    function handleErrorRequests(error) {
        modalService.error(`An error happened getting Data Requests. Error received: ${error}`);
    }

    function handleErrorEnquiries(error) {
        modalService.error(`An error happened getting Enquiries. Error received: ${error}`);
    }

    const handleAssociatedDataRequests = (dataRequests) => {
        // filter out dr to only show that are actually associated to user and the user is active.
        const associatedDataRequests = dataRequests.map((dr) => {
            //mapped to add userRoles
            const presentUser = dr.researchTeam.researchTeamDetails.find((tm) => tm.userId === user.id);
            const presentUserRoles = [];
            if (presentUser) {
                if (presentUser.isAdmin) presentUserRoles.push('Admin');
                if (presentUser.isLeadResearcher) presentUserRoles.push('Lead Researcher');
                if (presentUser.isStatistician) presentUserRoles.push('Statistician');
                if (!presentUser.isAdmin && !presentUser.isLeadResearcher && !presentUser.isStatistician)
                    presentUserRoles.push('Additional Researcher');
                if (presentUser.userId === dr.userId) presentUserRoles.push('Requester');
            }
            return {
                ...dr,
                userRoles: presentUserRoles,
                ...(isVivliAdmin && { envType: dr.antimicrobialDetail === null ? 'Clinical Trials' : 'AMR' }),
            };
        });
        setUserDataRequests(associatedDataRequests);
    };

    useEffect(() => {
        const userDataRequestsSub = usersService.getUserDataRequests(user.id).subscribe(handleAssociatedDataRequests, handleErrorRequests);
        return () => {
            userDataRequestsSub.unsubscribe();
        };
    }, []);

    const handleAssociatedEnquiries = (enquiries) => {
        setEnquiries(enquiries);
    };

    useEffect(() => {
        const userEnquiriesSub = usersService.getUserEnquiries(user.id).subscribe((data) => {
            data.forEach((item) => {
                item.dataInfoRequestsCount = item.dataInfoRequests?.length ?? 0;
            });
            setEnquiries(data);
        }, handleErrorEnquiries);

        return () => {
            userEnquiriesSub.unsubscribe();
        };
    }, []);

    const provider = {
        handleUpdateUserInfo,
        isSavingNotes,
        userDataRequests,
        userEnquiries,
    };

    return <UserInfoContext.Provider value={provider}>{children}</UserInfoContext.Provider>;
};
