import { MutableRefObject, useRef, useState } from 'react';

export function useRefState<T>(defaultState?: T): [MutableRefObject<T>, T, (stateObject: T) => void] {
    const localRef = useRef<T>(defaultState);
    const [localState, setLocalState] = useState<T>(defaultState);

    function setRefState(stateObject: T) {
        localRef.current = stateObject;
        setLocalState(stateObject);
    }

    return [localRef, localState, setRefState];
}
