import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { btnStyle, ButtonComponent, GridComponent, TextAreaComponent } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { ColDef, GridApi } from 'ag-grid-community';
import './enquiry.style.scss';
import { IDataInfoRequest } from '@vivli/features/enquiry/infastructure/interface';
import { useEnquiryContext } from '@vivli/features/enquiry/infastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { DTIEnquiryDiscussionGrid } from '@vivli/shared/infrastructure/constants';
import { DirStatusEnum } from '@vivli/features/enquiry/infastructure/enum';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToastService } from '@vivli/shared/infrastructure/context';
import { GridCellRendererEnum } from '@vivli/shared/infrastructure/enum';

const twoColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '2fr 1fr',
    columnGap: '25px',
};
const paddingTop20: CSSProperties = {
    paddingTop: '20px',
};
const discussionGridStytle: CSSProperties = {
    height: '300px',
};
const paragraphStyle: CSSProperties = {
    lineHeight: 1.2,
    wordBreak: 'break-word',
    margin: 0,
    marginBottom: '3px',
};
const iconStyle: CSSProperties = {
    fontSize: '1.5em',
    padding: '0px 0px 0px 0px',
    color: Color.TEXT_LIGHT_BLUE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    cursor: 'pointer',
};

interface EnquiryCommentComponentProps {
    dataInfoRequest: IDataInfoRequest;
    dirIndex: number;
}

export const EnquiryDiscussionGridComponent = ({ dataInfoRequest, dirIndex }: EnquiryCommentComponentProps) => {
    const [discussionComment, setDiscussionComment] = useState('');
    const [discussionRows, setDiscussionRows] = useState(dataInfoRequest?.comments || []);
    const { addComment, enquiry } = useEnquiryContext();
    const gridApiRef = useRef<GridApi>();
    const toastService = useToastService();
    const user = useActiveUser();
    const showAddButton =
        user.orgMemberships.some((x) => x.orgId === dataInfoRequest.orgId && (x.isOrgAdmin || x.isDataProvider)) ||
        user.isVivliAdmin ||
        (user.id === enquiry.requesterId &&
            dataInfoRequest?.status != DirStatusEnum.AvailableListed &&
            dataInfoRequest?.status != DirStatusEnum.AvailableUnlisted &&
            dataInfoRequest?.status != DirStatusEnum.NotAvailable);
    //we "show" transparent text when not showing the add button to preserve the same width for all DIR-s
    const saveTextStyle: CSSProperties = showAddButton ? {} : { color: 'transparent' };

    const columns: ColDef[] = [
        {
            field: 'createdDate',
            headerName: '',
            filter: false,
            sortable: false,
            minWidth: 180,
            cellRenderer: GridCellRendererEnum.GridCellDateTime,
            autoHeight: true,
            tooltipField: 'createdDate',
        },
        {
            field: 'displayName',
            headerName: '',
            filter: false,
            sortable: false,
            minWidth: 150,
            autoHeight: true,
            tooltipField: 'displayName',
        },

        {
            field: 'comment',
            headerName: '',
            filter: false,
            sortable: false,
            minWidth: 300,
            flex: 1,
            suppressSizeToFit: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
            cellRenderer: 'comment',
            tooltipField: 'comment',
            cellStyle: { ...paragraphStyle },
            cellRendererParams: {
                column: 'comment',
            },
        },
    ];

    const handleCopyToClipboard = (value) => {
        navigator.clipboard
            .writeText(value)
            .then(() => toastService.success('Copied!'))
            .catch(() => toastService.error('Failed to copy:'));
    };

    const CustomComponent = ({ data, column }) => {
        // setup to be used for other fields to if needed.
        const displayText = column === 'comment' && data.comment;

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <div>{displayText}</div>
                <div>
                    <FontAwesomeIcon onClick={() => handleCopyToClipboard(displayText)} style={iconStyle} icon={faCopy} />
                </div>
            </div>
        );
    };

    const customComponents = [
        {
            name: 'comment',
            component: CustomComponent,
        },
    ];

    const handleAddComment = (e) => {
        e.stopPropagation();
        if (discussionComment) {
            addComment(dataInfoRequest, dirIndex, discussionComment);
            setDiscussionRows([...dataInfoRequest?.comments]);
            setDiscussionComment('');
            scrollToBottom(gridApiRef.current, true);
        }
    };

    useEffect(() => {
        //will not display 1st added comment without this code
        if (dataInfoRequest?.comments) {
            setDiscussionRows([...dataInfoRequest?.comments]);
        }
    }, [dataInfoRequest?.comments]);

    const handleOnGridReady = (gridApi: GridApi) => {
        gridApiRef.current = gridApi;
        scrollToBottom(gridApi, false);
    };
    const scrollToBottom = (gridApi: GridApi, isAddition: boolean) => {
        setTimeout(() => {
            if (discussionRows?.length > 0) {
                //true - on add comment - discussion rows are not yet set :-(
                var numberOfRows = isAddition ? discussionRows?.length : discussionRows?.length - 1;
                setTimeout(() => gridApiRef.current.ensureIndexVisible(numberOfRows, 'bottom'), 100);
            }
        }, 50);
    };

    // @ts-ignore
    return (
        <div>
            <div style={{ paddingBottom: '15px' }}>Discussion:</div>
            <div className="enquiryDiscussionHeader">
                <GridComponent
                    onGridReady={handleOnGridReady}
                    customComponents={customComponents}
                    onRowCountChange={(count) => scrollToBottom(gridApiRef.current, true)}
                    rowData={discussionRows}
                    columns={columns}
                    rowHeight={300}
                    hideHeader={true}
                    style={discussionGridStytle}
                />
            </div>
            <div style={{ ...twoColumnGrid, ...paddingTop20 }}>
                <TextAreaComponent
                    rows={3}
                    label={'Comment'}
                    value={discussionComment}
                    onChange={(e) => setDiscussionComment(e.target.value)}
                    dataId={DTIEnquiryDiscussionGrid.DiscussionCommentInput}
                />
                <div>
                    {showAddButton && (
                        <div>
                            <ButtonComponent
                                title="AddComment"
                                style={{
                                    ...btnStyle,
                                    marginLeft: Size.PADDING,
                                }}
                                onClick={handleAddComment}
                            >
                                Add Comment
                            </ButtonComponent>
                        </div>
                    )}
                    <div style={{ ...paddingTop20, ...saveTextStyle }}>To save comments please click "Save" or "Save & Notify" button.</div>
                </div>
            </div>
        </div>
    );
};
