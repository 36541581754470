import { useEffect, useState } from 'react';
import { IVivliConfiguration } from '@vivli/shared/infrastructure/interface';
import { useVivliConfigurationService } from '@vivli/core/infrastructure/context';
import { first } from 'rxjs/operators';

export function useVivliConfig() {
    const [vivliConfig, setVivliConfig] = useState<IVivliConfiguration>();

    const vivliConfigService = useVivliConfigurationService();

    useEffect(() => {
        const sub = vivliConfigService
            .getVivliConfig()
            .pipe(first((config) => config !== null))
            .subscribe(setVivliConfig);

        return () => {
            sub.unsubscribe();
        };
    }, []);

    return vivliConfig;
}
