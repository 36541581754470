import { useEffect, useState } from 'react';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { useResultRequestService } from '@vivli/features/results-request/infrastructure/context';
import { first } from 'rxjs/operators';

export function useResultRequests(requestId: string) {
    const [resultRequests, setResultRequests] = useState<IResultRequest[]>();

    const resultRequestService = useResultRequestService();

    useEffect(() => {
        if (!requestId) {
            return;
        }

        resultRequestService.getResultRequests(requestId).pipe(first()).subscribe(setResultRequests);
    }, [requestId]);

    return {
        resultRequests,
        setResultRequests,
    };
}
