import React, { CSSProperties } from 'react';
import { badgeStyle } from './badge.style';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { Color } from '@vivli/shared/theme';
import { ResetToDraftButtonComponent } from '../../../shared/reset-to-draft-button.component';
import { DTIResetToDraftWithTitle } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const textStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_YELLOW,
    color: Color.BLACK,
    marginRight: '10px',
    ...badgeStyle,
};

interface ResetToDraftWithTitleComponentProps {
    title: string;
}

export const ResetToDraftWithTitleComponent = ({ title }: ResetToDraftWithTitleComponentProps) => {
    const { dataRequest } = useDataRequestContext();
    const { userCanReject } = useDataRequestPermissions();
    const dataId = DTIResetToDraftWithTitle.DataRequest + `${title.replace(' ', '_')}_button`;
    return (
        <div style={containerStyle}>
            <div style={textStyle}>
                <span data-test-id={DTIResetToDraftWithTitle.ResetTitle}>{title}</span>
            </div>
            {userCanReject(dataRequest) && <ResetToDraftButtonComponent dataId={dataId} />}
        </div>
    );
};
