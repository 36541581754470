﻿import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';

const DIVIDER_WIDTH = 1;
const DIVIDER_HEIGHT_PERCENTAGE = 90;

const outerContainerStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_BLUE,
    flex: 'auto',
    display: 'flex',
    position: 'relative',
    color: Color.WHITE,
    textShadow: 'rgba(0, 0, 0, 0.1) 10px 10px 20px',
};

const firstContainerStyle: CSSProperties = {
    flex: '0 40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
};
const firstContainerOneStyle: CSSProperties = {
    height: 300,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: Size.INSET_SIZE,
};
const fourZeroFourStyle: CSSProperties = {
    fontSize: 80,
};
const firstContainerTwoStyle: CSSProperties = {
    backgroundColor: Color.TEXT_LIGHT_BLUE,
    position: 'absolute',
    left: '100%',
    height: `${DIVIDER_HEIGHT_PERCENTAGE}%`,
    transform: `translate(-${DIVIDER_WIDTH}px, 0)`,
    width: DIVIDER_WIDTH,
};

const secondContainerStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};
const secondContainerInnerStyle: CSSProperties = {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: Size.INSET_SIZE,
};
const pageNotFoundStyle: CSSProperties = {
    fontSize: 50,
    fontWeight: Size.FontWeight.Light,
};

export const NotFoundComponent = () => {
    return (
        <div style={outerContainerStyle}>
            <div style={firstContainerStyle}>
                <div style={firstContainerOneStyle}>
                    <div style={fourZeroFourStyle}>404</div>
                </div>
                <div style={firstContainerTwoStyle} />
            </div>
            <div style={secondContainerStyle}>
                <div style={secondContainerInnerStyle}>
                    <div style={pageNotFoundStyle}>Page not found</div>
                </div>
            </div>
        </div>
    );
};
