import React, { CSSProperties, ReactNode } from 'react';
import { Color, Size } from '@vivli/shared/theme';

const containerStyle = (style: CSSProperties): CSSProperties => ({
    display: 'flex',
    alignItems: 'center',
    height: Size.HEADER_HEIGHT,
    color: Color.VIVLI_DARK_BLUE,
    ...style,
});

interface FieldHeaderComponentProps {
    title: string;
    style?: React.CSSProperties;
    rightAccessory?: ReactNode;
    accessoryStyle?: React.CSSProperties;
}

export const FieldHeaderComponent = ({ style, accessoryStyle, title, rightAccessory }: FieldHeaderComponentProps) => (
    <div style={containerStyle(style)}>
        <span style={{ ...accessoryStyle }}>{title}</span>
        {rightAccessory}
    </div>
);
