﻿import React from 'react';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { RequestHeaderComponent } from './request-header.component';
import { useDataRequestAdminPath, useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

interface DataRequestHeaderComponentProps {
    onEditTitleClick?: () => void;
    onSaveClick?: () => void;
    onSubmitClick?: () => void;
    formHasErrors?: boolean;
    formIsDirty?: boolean;
    title?: string;
}

export const DataRequestHeaderComponent = ({
    onEditTitleClick,
    onSaveClick,
    onSubmitClick,
    formHasErrors,
    formIsDirty,
    title,
}: DataRequestHeaderComponentProps) => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const isDataRequestsView = useDataRequestAdminPath();
    const { dataRequest, organizations } = useDataRequestContext();
    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft;
    const { userIsContributorOrIRPApprover } = useDataRequestPermissions();

    const isContributorOrIRPApprover = userIsContributorOrIRPApprover(dataRequest, organizations);
    const myDataRequestDraft = !isDataRequestsView && isDraft;
    const myDataRequestNotDraft = !isDataRequestsView && !isDraft;
    const showSaveButtons = myDataRequestDraft || (isDraft && !isContributorOrIRPApprover);
    const showPrintButton = myDataRequestNotDraft || (isContributorOrIRPApprover && isDataRequestsView);
    const showCancelButton = isDraft && !isAmr;

    return (
        <RequestHeaderComponent
            onEditTitleClick={onEditTitleClick}
            onSaveClick={onSaveClick}
            onSubmitClick={onSubmitClick}
            formHasErrors={formHasErrors}
            formIsDirty={formIsDirty}
            title={title}
            showTrackingButton={false} //admin only
            showResetToDraftButton={false} //admin only
            showCancelButton={showCancelButton}
            showEditDataRequestButton={false} //admin only
            showSaveButtons={showSaveButtons}
            showEditTitleButton={showSaveButtons}
            showPrintButton={showPrintButton}
            myDataRequests={true}
        />
    );
};
