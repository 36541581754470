﻿import { ICitation } from '@vivli/shared/features/citations/infrastructure/interface';
import { ColDef, GridApi, RowNode } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { CitationRowRenderer } from './citation-row-renderer.component';
import { GridComponent } from '@vivli/shared/components';

interface CitationsGridComponentProps {
    citations: ICitation[];
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    asyncUpdateKey?: string;
    dataRefreshing?: boolean;
    dataRequest?: IDataRequest;
    dataRequestDoiStem?: string;
    study?: IStudy;
    onChange?: () => void;
}

export const CitationsGridComponent = ({
    citations,
    onGridReady,
    emptyMessage,
    hideFloatingFilter,
    hideHeader,
    autoHeight,
    dataRefreshing,
    onChange,
    dataRequestDoiStem,
}: CitationsGridComponentProps) => {
    const [rowData, setRowData] = useState<{ citation: ICitation }[]>();
    const gridApiRef = useRef<GridApi>();

    const handleOnGridReady = (gridApi: GridApi) => {
        onGridReady && onGridReady(gridApi);
        gridApiRef.current = gridApi;
    };

    const handleRemoveRow = (node: RowNode, citation: ICitation) => {
        gridApiRef.current.applyTransaction({ remove: [node.data] });
        onChange && onChange();
    };

    const columnDefs: ColDef[] = [
        {
            field: 'citation',
            headerName: 'Citation',
            filter: true,
            sortable: true,

            cellRenderer: 'CitationRow',
            cellRendererParams: {
                onRemoveRow: handleRemoveRow,
                dataRequestDoiStem: dataRequestDoiStem,
            },
        },
    ];

    const customComponents = [
        {
            name: 'CitationRow',
            component: CitationRowRenderer,
            params: {
                dataRequestDoiStem: dataRequestDoiStem,
            },
        },
    ];

    useEffect(() => {
        if (!citations) {
            return;
        }

        const newRowData = [];

        citations.forEach((citation) => {
            newRowData.push({ citation });
        });
        setRowData(newRowData);
    }, [citations]);

    return (
        <GridComponent
            rowData={rowData}
            fullWidthColumns={true}
            onGridReady={handleOnGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            hideHeader={hideHeader}
            autoHeight={autoHeight}
            customComponents={customComponents}
            rowHeight={75}
            asyncUpdateKey={'id'}
            dataRefreshing={dataRefreshing}
            flushPrevious={true}
        />
    );
};
