﻿import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationDetailsContext } from '@vivli/features/organizations/infrastructure/context';
import { useRorOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { RorSearchContainerComponent } from '@vivli/features/organizations/components';

interface RorOrgSearchComponentProps {
    organization?: IOrganization;
}

export const RorOrgSearchComponent = ({ organization }: RorOrgSearchComponentProps) => {
    const orgContext = useOrganizationDetailsContext();
    const { getRorInformationFromSelection } = useRorOrganizations();
    const searchText = 'SEARCH TO FIND ROR ORGANIZATION';

    const handleResult = (selections, matches) => {
        const rorInfo: any = getRorInformationFromSelection(selections, matches, organization?.rorInformation);
        const updatedOrg = { ...organization };
        updatedOrg.rorInformation = rorInfo;
        orgContext.handleSave(updatedOrg);
    };

    return <RorSearchContainerComponent searchText={searchText} handleResult={handleResult} />;
};
