import React, { CSSProperties } from 'react';
import { EmptyListPlaceholderComponent, HistoryListComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { AuditTrailContextualComponent } from '@vivli/features/admin-console/components';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const placeholderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
};

const auditButtonStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: '60px',
    paddingBottom: '20px',
};

export const DataRequestHistoryComponent = () => {
    const { dataRequest, organizations } = useDataRequestContext();
    const { shouldHideReviewers } = useDataRequestPermissions();
    const hideReviewers = shouldHideReviewers(dataRequest, organizations);

    if (!dataRequest || !organizations) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <>
            <div style={auditButtonStyle}>
                <AuditTrailContextualComponent
                    type="DataRequest"
                    id={dataRequest.dataRequestDoi}
                    internalId={dataRequest.id}
                ></AuditTrailContextualComponent>
            </div>
            {dataRequest.dataRequestHistoryEntries?.length > 0 ? (
                <HistoryListComponent historyEntries={dataRequest.dataRequestHistoryEntries} hideReviewers={hideReviewers} />
            ) : (
                <EmptyListPlaceholderComponent
                    optionalStyles={placeholderStyle}
                    imageHeight={'100%'}
                    image={AssetsConstant.TRIALS_ICON_INACTIVE}
                    text={'There is no history for this data request because it has not yet been submitted.'}
                />
            )}
        </>
    );
};
