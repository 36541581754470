﻿import React, { useEffect, useState } from 'react';
import { Styles } from '@vivli/shared/theme';
import moment from 'moment/moment';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ButtonComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useVirtualMachineService } from '@vivli/features/virtual-machine/infrastructure/context';
import { first } from 'rxjs/operators';
import {
    cellStyle,
    containerStyle,
    flexColStyle,
    flexRelativeStyle,
    flexRowEvenStyle,
    flexRowMarginStyle,
    flexRowStyle,
    headerDivStyle,
    innerContainerStyle,
    innerWrapperStyle,
    redButtonStyle,
    wrapperStyle,
} from './manage-vm.styles';
import { ManageVmModalButtonsComponent } from './manage-vm-modal-buttons.component';

interface ManageVmModalComponentProps {
    vmId: string;
    onCancel?: () => void;
}

export const ManageVmModalComponent = ({ vmId, onCancel }: ManageVmModalComponentProps) => {
    const user = useActiveUser();
    const virtualMachineService = useVirtualMachineService();

    const [isLoading, setIsLoading] = useState(false);
    const [vm, setVm] = useState(null);

    const dateFormat = 'MM/DD/YYYY';
    const adminAndNotDeProvisioned = (user.isOperationsAdmin || user.isVivliAdmin) && vm?.status !== 'DeProvisioned';
    const provisionedDate =
        vm?.provisionedDateTime !== undefined && vm?.provisionedDateTime ? moment(vm?.provisionedDateTime).format(dateFormat) : 'N/A';
    const deProvisionedDate =
        vm?.deProvisionedDateTime !== undefined && vm?.deProvisionedDateTime ? moment(vm?.deProvisionedDateTime).format(dateFormat) : 'N/A';

    const licenseSAS = vm && vm?.licenseSAS ? 'SAS' : 'None';
    const licenseSTATA = vm && vm?.licenseSTATA ? 'STATA' : licenseSAS;
    const licenses = vm && vm?.licenseSAS && vm?.licenseSTATA ? 'SAS, STATA' : licenseSTATA;

    const handleCloseButton = () => {
        onCancel && onCancel();
    };

    const getVirtualMachine = () => {
        virtualMachineService
            .getVm(vmId)
            .pipe(first())
            .subscribe((vm) => {
                setVm(vm);
            });
    };

    useEffect(() => {
        getVirtualMachine();
    }, [vmId]);

    if (!vmId || !vm) {
        return (
            <div style={wrapperStyle}>
                <div style={headerDivStyle}>Research Environment Details</div>
                <div style={innerWrapperStyle}>
                    <div style={containerStyle} className="scrolly">
                        <LoadIndicatorCenteredComponent />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={wrapperStyle}>
            <div>
                <div style={headerDivStyle}>Research Environment Details - {vm?.dataRequestDoi + ' (' + vm?.dataRequestTitle + ')'}</div>
                <div style={innerWrapperStyle}>
                    <div style={containerStyle} className="scrolly">
                        <div style={innerContainerStyle}>
                            <div>
                                <div style={flexRowMarginStyle}>
                                    <div style={cellStyle}>Requestor Name:</div>
                                    <div style={cellStyle}>{vm && vm.provisionedByDisplayName}</div>
                                </div>
                                <div style={flexRowStyle}>
                                    <div style={cellStyle}>Requestor Email:</div>
                                    <div style={cellStyle}>{vm && vm.provisionedByEmail}</div>
                                </div>
                                <div style={flexRowStyle}>
                                    <div style={cellStyle}>Number Authorized Users:</div>
                                    <div style={cellStyle}>{vm && vm.authorizedUsers.length}</div>
                                </div>
                                <div style={flexRowStyle}>
                                    <div style={cellStyle}>Provisioned Date:</div>
                                    <div style={cellStyle}>{provisionedDate}</div>
                                </div>
                                <div style={flexRowStyle}>
                                    <div style={cellStyle}>Deprovisioned Date:</div>
                                    <div style={cellStyle}>{deProvisionedDate}</div>
                                </div>

                                {adminAndNotDeProvisioned && (
                                    <ManageVmModalButtonsComponent vm={vm} isLoading={isLoading} setIsLoading={setIsLoading} />
                                )}
                            </div>
                            <div style={flexColStyle}>
                                <div style={Styles.COLUMN_ROW_DEF}>
                                    <div style={Styles.COLUMN_ROW}>Machine Size: {vm && vm.size}</div>
                                </div>
                                <div style={Styles.COLUMN_ROW_DEF}>
                                    <div style={Styles.COLUMN_ROW}>Status: {vm && vm.status}</div>
                                </div>
                                <div style={Styles.COLUMN_ROW_DEF}>
                                    <div style={Styles.COLUMN_ROW}>Licenses: {licenses}</div>
                                </div>
                                <div style={Styles.COLUMN_ROW_DEF}>
                                    <div style={Styles.COLUMN_ROW}>Runtime since last restart (days): {vm?.daysSinceLastStart}</div>
                                </div>
                                <div style={Styles.COLUMN_ROW_DEF}>
                                    <div style={Styles.COLUMN_ROW}>Days since last login (days): {vm?.daysSinceLastLogin}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={flexRowEvenStyle}>
                        <div style={flexRelativeStyle}>
                            <ButtonComponent style={redButtonStyle} onClick={handleCloseButton} className="vivliInfoModal_close">
                                Close
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
