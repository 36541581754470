import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { useNavigate } from 'react-router-dom';

const backButtonStyle = (style): CSSProperties => ({
    color: Color.TEXT_LIGHT_BLUE,
    fontSize: Size.FontSize.Medium,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
    ...style,
});

interface GoBackLinkComponentProps {
    destination?: string;
    textToShow?: string;
    style?: CSSProperties;
    dataId?: any;
}

export const GoBackLinkComponent = ({ destination, textToShow, style, dataId }: GoBackLinkComponentProps) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (destination) {
            navigate(destination);
        } else {
            navigate(-1);
        }
    };

    const text = textToShow ? textToShow : '< Go Back';

    return (
        <div style={backButtonStyle(style)} onClick={handleGoBack} data-test-id={dataId}>
            <span>{text}</span>
        </div>
    );
};
