﻿import joi, { object, ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { INotification } from '@vivli/shared/features/notification/infrastructure/interface';

const formSchema: ObjectSchema<INotification> = joi
    .object({
        notificationFrequency: joi.number().optional().integer().min(1).label('Frequency (Per Day)'),
        startDate: joi.date().iso().required().label('Start Date'),
        endDate: joi
            .date()
            .iso()
            .required()
            .label('End Date/ Next Checkin')
            .custom((value, helpers) => {
                if (value < joi.ref('startDate')) {
                    return helpers.message({ custom: 'End date must be greater than or equal to start date' });
                }
                return value;
            }),
    })
    .options(JoiValidationOptionsConstant);

const defaultValues: INotification = {
    notificationText: '',
    active: false,
    startDate: '',
    endDate: '',
    notificationFrequency: null,
};

export function useNotificationForm(
    notification: INotification = defaultValues,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'onChange'
) {
    return useForm({
        mode,
        resolver: joiResolver(formSchema),
        defaultValues: notification,
        reValidateMode: 'onChange',
    });
}
