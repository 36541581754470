﻿import { RestApi } from '@vivli/core/infrastructure/rest';
import { IDateResult, INotification, INotificationApiService } from '@vivli/shared/features/notification/infrastructure/interface';
import { Observable } from 'rxjs';

export class NotificationApiService extends RestApi implements INotificationApiService {
    getNotification = (notificationId: string): Observable<INotification> => {
        return this.handleGet<INotification>(`/notifications/${notificationId}`);
    };

    getNotificationsForTopic = (topicId: string): Observable<INotification[]> => {
        return this.handleGet<INotification[]>(`/notifications/${topicId}/topic`);
    };

    createNotification = (notification: INotification): Observable<INotification> => {
        return this.handlePost<INotification>(`/notifications/`, notification);
    };

    updateNotification = (notification: INotification): Observable<INotification> => {
        return this.handlePut<INotification>(`/notifications/`, notification);
    };

    getLastAndNextDate = (notificationId: number): Observable<IDateResult> => {
        return this.handleGet<IDateResult>(`/notifications/${notificationId}/lastAndNextDates`);
    };
}
