﻿import { CheckboxComponent } from '@vivli/shared/components';
import React, { useEffect, useState } from 'react';
import { IOrganizationRoles, IUser } from '@vivli/shared/infrastructure/interface';
import { useOrganizationDetailsContext } from '@vivli/features/organizations/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';

export const OrgMemberCheckBoxRendererComponent = ({ data, column, orgId }) => {
    const user = useActiveUser();
    const [userToUpdate, setUserToUpdate] = useState<IUser>(data.userRole);
    const fieldName = column.getColDef().field;
    const [isLoading, setIsLoading] = useState<boolean>();
    const [currentOrgRoles, setCurrentOrgRoles] = useState<IOrganizationRoles>();
    const [currentRoleValue, setCurrentRoleValue] = useState<boolean>();
    const { updatedUser, handleRoleUpdate } = useOrganizationDetailsContext();

    const onClick = () => {
        setIsLoading(true);
        handleRoleUpdate(orgId, fieldName, userToUpdate, setIsLoading);
    };

    useEffect(() => {
        if (!orgId || !userToUpdate) {
            return;
        }

        const _currentOrgRoles = userToUpdate.orgMemberships.find((m) => m.orgId === orgId);
        if (_currentOrgRoles) {
            setCurrentOrgRoles(_currentOrgRoles);
            setCurrentRoleValue(_currentOrgRoles[fieldName]);
        }
    }, [orgId, userToUpdate]);

    useEffect(() => {
        if (updatedUser?.id === userToUpdate.id) {
            setUserToUpdate(updatedUser.userRole);
        }
    }, [updatedUser]);

    return (
        <CheckboxComponent
            onChange={onClick}
            disabled={!user.isVivliAdmin || isLoading || !currentOrgRoles?.isOrgAdmin}
            checked={currentRoleValue}
        />
    );
};
