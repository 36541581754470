import { IRestApiOptions } from '@vivli/core/infrastructure/interface';
import { useDefaultApiOptions } from './use-default-api-options.hook';
import { useConfigService } from '@vivli/core/infrastructure/context';

export const useChatApiOptions = (): IRestApiOptions => {
    const config = useConfigService();
    const defaultOptions = useDefaultApiOptions();

    return {
        ...defaultOptions,
        baseUrl: `${config.chatUri}api`,
        retryCalls: '1',
        errorHandler: null,
    };
};
