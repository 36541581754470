﻿import { DropdownFieldComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { BulkUpdateContentTypeEnum } from '@vivli/features/studies/infrastructure/enum';
import { Size } from '@vivli/shared/theme';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const dropdownStyle: CSSProperties = {
    flex: '1 1 46%',
    maxWidth: '45%',
    marginBottom: Size.FIELD_SEPARATION,
};

const BulkUpdateLabels = {
    [BulkUpdateContentTypeEnum.AwaitingCurationStudies]: 'Update studies to Awaiting Curation Status',
    [BulkUpdateContentTypeEnum.PostStudies]: 'Update studies to Posted to Vivli Status',
    [BulkUpdateContentTypeEnum.PropertyUpdate]: 'Update properties of one or more studies',
};

export const BulkUpdateStudiesSelectorsComponent = ({ onDropdownChange }) => {
    const dropdownOptions = Object.keys(BulkUpdateContentTypeEnum).map((o) => ({ value: o, title: BulkUpdateLabels[o] }));

    return (
        <div>
            <div style={{ paddingBottom: '15px', lineHeight: '21px' }}>
                To update a set of studies, please create an update CSV file with the required fields. Then choose the type of update you
                wish to do and press Continue.
            </div>
            <DropdownFieldComponent
                label={'Update Type (Must be CSV file)'}
                style={dropdownStyle}
                items={dropdownOptions}
                onChange={onDropdownChange}
                dataId={DTICommonConst.DropdownValue}
            />
        </div>
    );
};
