import React, { CSSProperties } from 'react';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import {
    LinkFieldComponent,
    oneAcrossStyle,
    TextFieldReadOnlyFormComponent,
    TextFormFieldComponent,
    threeAcrossStyle,
} from '@vivli/shared/components';
import { useDoiTransform } from '@vivli/features/studies/infrastructure/hook';

const tightRow: CSSProperties = {
    ...Styles.FORM_ROW,
    marginBottom: 0,
};

//component for shared use by both org admins and vivli admins
//AMR only
interface AdminAmrAdministrativeSharedComponentProps {
    study?: IStudy;
    isVivliAdmin?: boolean;
}

export const AdminAmrAdministrativeSharedComponent = ({ study, isVivliAdmin }: AdminAmrAdministrativeSharedComponentProps) => {
    const showLinkDoi = study.studyMetadataDoi?.indexOf('DOINotCreated') < 0;
    const { transformDoiUrl } = useDoiTransform();

    return (
        <div style={{ width: '100%' }}>
            <div style={Styles.FORM_ROW}>
                {showLinkDoi ? (
                    <LinkFieldComponent
                        label="DOI"
                        linkContent={transformDoiUrl(study.studyMetadataDoi) /*11301*/}
                        linkTitle={study.studyMetadataDoi}
                        style={threeAcrossStyle}
                    />
                ) : (
                    <TextFormFieldComponent name={`studyMetadataDoi`} label="DOI" style={threeAcrossStyle} readonly={true} />
                )}
                <TextFieldReadOnlyFormComponent
                    label={'AMR ID'}
                    style={threeAcrossStyle}
                    defaultValue={`VIV${study.doiStem}`}
                    readonly={true}
                />
                <TextFormFieldComponent name={`sponsorProtocolId`} label="Dataset ID" style={threeAcrossStyle} readonly={!isVivliAdmin} />
            </div>
            {study.studyIPDDataPackageDoi && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.studyIPDDataPackageDoi}
                        linkContent={transformDoiUrl(study.studyIPDDataPackageDoi)}
                        label={'Data Package DOI(s) Available for this Dataset'}
                        style={oneAcrossStyle}
                    />
                </div>
            )}
            <TextFormFieldComponent name="orgName" label={'Data Contributor Organization Name'} style={oneAcrossStyle} readonly={false} />
        </div>
    );
};
