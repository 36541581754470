import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import i18next from 'i18next';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IUserMenuLink } from '../interface/user-menu-link.interface';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';


const order = [
    'Dashboard',
    'Data Requests',
    'Enquiries',
    'Studies',
    'Awaiting Upload',
    'Report',
    'Research Environments',
    'My Organizations',
    'My Organization',
    'Users',
    'Submissions',
    'Admin Console',
];


export function useUserMenuRoutes() {
    const assignedAppType = useAssignedAppType();
    const user = useActiveUser();
    const { isDatasetOwner, isOrgAdmin, isDataProvider, isAnnotator, isQaReviewer, isIRPApprover } = useUserPermissions();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    if (!user) {
        return [];
    }

    const isVivliAdmin = user.isVivliAdmin;
    const isOpsAdmin = user.isOperationsAdmin;

    const orgs = user.orgMemberships?.filter((om) => om.isOrgAdmin);
    let orgId = null;
    if (orgs && orgs.length !== 0) {
        [{ orgId }] = orgs;
    }

    let links: Array<IUserMenuLink> = [
        {
            title: 'Dashboard',
            icon: {
                active: AssetsConstant.DASHBOARD_ICON_ACTIVE,
                inactive: AssetsConstant.DASHBOARD_ICON_INACTIVE,
            },
            url: 'dashboard',
            defaultTab: '',
            dataId: 'menuitem_dashboard',
        },
        {}, // empty placeholder key for admin portal side nav panels
    ];

    if (user?.orgMemberships) {
        if ((isVivliAdmin || (isOrgAdmin && isDataProvider)) && !isAmr) {
            links = [
                ...links,
                {
                    title: 'Research Environments',
                    icon: {
                        active: AssetsConstant.VM_ICON_ACTIVE,
                        inactive: AssetsConstant.VM_ICON_INACTIVE,
                    },
                    url: `vms`,
                    defaultTab: '',
                    dataId: 'menuitem_research_environments',
                },
            ];
        }
        if (isVivliAdmin) {
            links = [
                ...links,
                {
                    title: 'Users',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `users`,
                    defaultTab: '',
                    dataId: 'menuitem_users',
                },
            ];
        }
        if (isOrgAdmin || isVivliAdmin) {
            links = [
                ...links,
                {
                    title: 'Report',
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: `report`,
                    defaultTab: '',
                    dataId: 'menuitem_report',
                },
            ];
            links = [
                ...links,
                {
                    title:
                        !isVivliAdmin && !(user?.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1)
                            ? 'My Organization'
                            : 'My Organizations',
                    icon: {
                        active: AssetsConstant.OFFICEBUILDING_ICON_ACTIVE,
                        inactive: AssetsConstant.OFFICEBUILDING_ICON_INACTIVE,
                    },
                    url: !isVivliAdmin && !(user?.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1) ? `orgs/${orgId}` : 'orgs',
                    defaultTab: '',
                    dataId: 'menuitem_my_organizations',
                },
            ];
        }
        if (isAnnotator || isQaReviewer || isDatasetOwner) {
            links = [
                ...links,
                {
                    title: i18next.t('studies'),
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: 'studies',
                    defaultTab: '',
                    dataId: 'menuitem_studies',
                },
            ];
        }
        if (isDataProvider || isIRPApprover) {
            links = [
                ...links,
                {
                    title: 'Data Requests',
                    icon: {
                        active: AssetsConstant.REQUESTS_ICON_ACTIVE,
                        inactive: AssetsConstant.REQUESTS_ICON_INACTIVE,
                    },
                    url: 'requests',
                    defaultTab: 'AwaitingMyAction',
                    dataId: 'menuitem_data_requests',
                },
            ];
        }
        if (!isAmr) {
            links = [
                ...links,
                {
                    title: 'Submissions',
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: `study-submissions`,
                    defaultTab: 'draft',
                    dataId: 'menuitem_academic_listings',
                },
            ];
        }
        if ((isDataProvider || isIRPApprover) && !isAmr) {
            links = [
                ...links,
                {
                    title: 'Awaiting Upload',
                    icon: {
                        active: AssetsConstant.DATA_REQUESTS_ICON_ACTIVE,
                        inactive: AssetsConstant.DATA_REQUESTS_ICON_INACTIVE,
                    },
                    url: 'awaitingUpload',
                    defaultTab: '',
                    dataId: 'menuitem_awaiting_upload',
                },
            ];
        }

        if (!isAmr) {
            links = [
                ...links,
                {
                    title: 'Enquiries',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `enquiries`,
                    defaultTab: '',
                    dataId: 'menuitem_enquiries',
                },
            ];
        }

        if (isOpsAdmin) {
            links = [
                ...links,
                {
                    title: 'Admin Console',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `adminConsole/templateStudy`,
                    defaultTab: '',
                    dataId: 'menuitem_admin_console',
                },
            ];
        }
    } else {
        if (isVivliAdmin || isOpsAdmin) {
            links = [
                ...links,
                {
                    title:
                        !isVivliAdmin && !(user?.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1)
                            ? 'My Organization'
                            : 'My Organizations',
                    icon: {
                        active: AssetsConstant.OFFICEBUILDING_ICON_ACTIVE,
                        inactive: AssetsConstant.OFFICEBUILDING_ICON_INACTIVE,
                    },
                    url: !isVivliAdmin && !(user?.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1) ? `orgs/${orgId}` : 'orgs',
                    defaultTab: '',
                    dataId: 'menuitem_my_organizations',
                },
            ];
            links = [
                ...links,
                {
                    title: 'Users',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `users`,
                    defaultTab: '',
                    dataId: 'menuitem_users',
                },
            ];
            if (!isAmr) {
                links = [
                    ...links,
                    {
                        title: 'Research Environments',
                        icon: {
                            active: AssetsConstant.NAV_VM_ICON,
                            inactive: AssetsConstant.NAV_VM_ICON,
                        },
                        url: `vms`,
                        defaultTab: '',
                        dataId: 'menuitem_research_environments',
                    },
                ];
            }
        }
    }
    
    links.sort((a, b) => {
        if (!a.title) return 1;
        if (!b.title) return -1;

        const indexA = order.indexOf(a.title);
        const indexB = order.indexOf(b.title);
        return indexA - indexB;
    });

    return links;
}
