import React from 'react';
import { filterGroupVerticalContainerStyle, innerScrollVerticalStyle, searchFiltersVerticalContainerStyle } from '../search-filters.styles';
import { StudyDesignFilterGroupComponent } from './ct-filter-groups/study-design-filter-group.component';
import { StudyPhaseFilterGroupComponent } from './ct-filter-groups/study-phase-filter-group.component';
import { SponsorInfoFilterGroupComponent } from './ct-filter-groups/sponsor-info-filter-group.component';
import { LocationFilterGroupComponent } from './ct-filter-groups/location-filter-group.component';
import { DatesFilterGroupComponent } from './ct-filter-groups/dates-filter-group.component';
import { SampleSizeFilterGroupComponent } from './ct-filter-groups/sample-size-filter-group.component';

export const CtSearchFiltersVerticalComponent = () => {
    return (
        <div style={searchFiltersVerticalContainerStyle}>
            <div style={innerScrollVerticalStyle}>
                <div style={filterGroupVerticalContainerStyle}>
                    <StudyDesignFilterGroupComponent />
                    <StudyPhaseFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    <SponsorInfoFilterGroupComponent />
                    <SampleSizeFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    <LocationFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    <DatesFilterGroupComponent />
                </div>
            </div>
        </div>
    );
};
