﻿import React, { ReactNode, useEffect, useState } from 'react';
import { AmrSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';
import { IAmrSearchOptionsContext, ISelectOption } from '@vivli/features/search/infrastructure/interface';
import { useBooleanFilterHook, useDropdownFilterHook, useYearRangeFilterHook } from '@vivli/features/search/infrastructure/hook';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

interface AmrSearchOptionsContextWrapperProps {
    children?: ReactNode;
}

export const AmrSearchOptionsContextWrapper = ({ children }: AmrSearchOptionsContextWrapperProps) => {
    const { handleYearRangeFilter, handleDropdownFilter, handleBooleanFilter, updateFilterString } = useSearchContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const earliestDatasetYearDefault = 2004; //default based on current data

    const [antimicrobialSelectedOptions, setAntimicrobialSelectedOptions] = useState<ISelectOption[]>();
    const [organismSelectedOptions, setOrganismSelectedOptions] = useState<ISelectOption[]>();
    const [sampleSelectedOptions, setSampleSelectedOptions] = useState<ISelectOption[]>();
    const [dataContributorSelectedOptions, setDataContributorSelectedOptions] = useState<ISelectOption[]>();
    const [resistanceSelectedOptions, setResistanceSelectedOptions] = useState<ISelectOption[]>();
    const [countrySelectedOptions, setCountrySelectedOptions] = useState<ISelectOption[]>();
    const [regionSelectedOptions, setRegionSelectedOptions] = useState<ISelectOption[]>();
    const [sampleSourceSelectedOptions, setSampleSourceSelectedOptions] = useState<ISelectOption[]>();
    const [yearsDataCollectedFrom, setYearsDataCollectedFrom] = useState<ISelectOption[]>();
    const [yearsDataCollectedTo, setYearsDataCollectedTo] = useState<ISelectOption[]>();
    const [earliestYear, setEarliestYear] = useState<number>(earliestDatasetYearDefault);
    const [includeGenotypeInfo, setIncludeGenotypeInfo] = useState(false);
    const [includePediatricsInfo, setIncludePediatricsInfo] = useState(false);
    const [searchString, setSearchString] = useState<string>('');

    useDropdownFilterHook({
        key: 'antimicrobials',
        options: antimicrobialSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'organisms',
        options: organismSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'sampleTimes',
        options: sampleSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'sponsorName',
        options: dataContributorSelectedOptions,
        useAnySyntax: false,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'groupingsOfResistancePatterns',
        options: resistanceSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'countries',
        options: countrySelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'regions',
        options: regionSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useDropdownFilterHook({
        key: 'specimenSources',
        options: sampleSourceSelectedOptions,
        useAnySyntax: true,
        onChange: handleDropdownFilter,
    });

    useYearRangeFilterHook({
        key: 'yearsDataCollected',
        from: yearsDataCollectedFrom,
        to: yearsDataCollectedTo,
        onChange: handleYearRangeFilter,
        earliestYear: earliestYear,
    });

    useBooleanFilterHook({
        key: 'containsGenotype',
        selected: includeGenotypeInfo,
        onChange: handleBooleanFilter,
    });

    useBooleanFilterHook({
        key: 'containsPediatrics',
        selected: includePediatricsInfo,
        onChange: handleBooleanFilter,
    });

    useEffect(() => {
        if (!isAmr) {
            return;
        }
        updateFilterString(searchString);
    }, [searchString]);

    const resetOptions = () => {
        setAntimicrobialSelectedOptions([]);
        setOrganismSelectedOptions([]);
        setSampleSelectedOptions([]);
        setDataContributorSelectedOptions([]);
        setResistanceSelectedOptions([]);
        setCountrySelectedOptions([]);
        setRegionSelectedOptions([]);
        setSampleSourceSelectedOptions([]);
        setIncludePediatricsInfo(undefined);
        setIncludeGenotypeInfo(undefined);
        setYearsDataCollectedTo([]);
        setYearsDataCollectedFrom([]);
    };

    const provider: IAmrSearchOptionsContext = {
        antimicrobialSelectedOptions,
        setAntimicrobialSelectedOptions,
        organismSelectedOptions,
        setOrganismSelectedOptions,
        sampleSelectedOptions,
        setSampleSelectedOptions,
        dataContributorSelectedOptions,
        setDataContributorSelectedOptions,
        resistanceSelectedOptions,
        setResistanceSelectedOptions,
        countrySelectedOptions,
        setCountrySelectedOptions,
        regionSelectedOptions,
        setRegionSelectedOptions,
        sampleSourceSelectedOptions,
        setSampleSourceSelectedOptions,
        includePediatricsInfo,
        setIncludePediatricsInfo,
        includeGenotypeInfo,
        setIncludeGenotypeInfo,
        yearsDataCollectedTo,
        setYearsDataCollectedTo,
        yearsDataCollectedFrom,
        setYearsDataCollectedFrom,
        earliestYear,
        setEarliestYear,
        earliestDatasetYearDefault,
        resetOptions,
        searchString,
        setSearchString,
    };

    return <AmrSearchOptionsContext.Provider value={provider}>{children}</AmrSearchOptionsContext.Provider>;
};
