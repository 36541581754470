﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { CheckboxComponent, CheckboxFormFieldComponent, FieldHeaderComponent } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { useFormContext } from 'react-hook-form';
import { DTICTFormFields } from '@vivli/shared/infrastructure/constants';

const checkboxStyle: CSSProperties = {
    marginBottom: Size.ROW_BOTTOM_MARGIN,
    flexDirection: 'row-reverse',
    justifyContent: 'start',
};
const inputStyle: CSSProperties = {
    marginRight: 0,
};

export const AnnotateStudyBlindingComponent = () => {
    const formApi = useFormContext();
    const [blindingNotAvailable, setBlindingNotAvailable] = useState(false);

    const subjectMaskedOrBlinded = formApi.watch('studyDesign.subjectMaskedOrBlinded');
    const investigatorMaskedOrBlinded = formApi.watch('studyDesign.investigatorMaskedOrBlinded');
    const careProviderMaskedOrBlinded = formApi.watch('studyDesign.careProviderMaskedOrBlinded');
    const outcomeAssessorMaskedOrBlinded = formApi.watch('studyDesign.outcomeAssessorMaskedOrBlinded');

    const nullOtherCheckboxes = (e) => {
        if (e.target.checked) {
            //set the other 4 boxes to a value of false
            formApi.setValue('studyDesign.subjectMaskedOrBlinded', false, { shouldDirty: true });
            formApi.setValue('studyDesign.investigatorMaskedOrBlinded', false, { shouldDirty: true });
            formApi.setValue('studyDesign.careProviderMaskedOrBlinded', false, { shouldDirty: true });
            formApi.setValue('studyDesign.outcomeAssessorMaskedOrBlinded', false, { shouldDirty: true });
            setBlindingNotAvailable(true);
        } else {
            setBlindingNotAvailable(false);
        }
    };

    useEffect(() => {
        if (subjectMaskedOrBlinded || investigatorMaskedOrBlinded || careProviderMaskedOrBlinded || outcomeAssessorMaskedOrBlinded) {
            setBlindingNotAvailable(false);
        }
    }, [subjectMaskedOrBlinded, investigatorMaskedOrBlinded, careProviderMaskedOrBlinded, outcomeAssessorMaskedOrBlinded]);

    return (
        <>
            <FieldHeaderComponent title="BLINDING - IF BLINDING INFO AVAILABLE CHECK BLINDED ROLES - IF NOT AVAILABLE CHECK NOT AVAILABLE BOX" />
            <div style={Styles.FORM_ROW}>
                <CheckboxFormFieldComponent
                    name={'studyDesign.subjectMaskedOrBlinded'}
                    label={'Subject'}
                    truncateLabel={false}
                    style={checkboxStyle}
                    inputStyle={inputStyle}
                    dataId={DTICTFormFields.FormCheckBox('SubjectMasked')}
                />
                <CheckboxFormFieldComponent
                    name={'studyDesign.investigatorMaskedOrBlinded'}
                    label={'Investigator'}
                    truncateLabel={false}
                    style={checkboxStyle}
                    inputStyle={inputStyle}
                    dataId={DTICTFormFields.FormCheckBox('InvestigationMasked')}
                />
                <CheckboxFormFieldComponent
                    name={'studyDesign.careProviderMaskedOrBlinded'}
                    label={'Care Provider'}
                    truncateLabel={false}
                    style={checkboxStyle}
                    inputStyle={inputStyle}
                    dataId={DTICTFormFields.FormCheckBox('CareProviderMasked')}
                />
                <CheckboxFormFieldComponent
                    name={'studyDesign.outcomeAssessorMaskedOrBlinded'}
                    label={'Outcome Assessor'}
                    truncateLabel={false}
                    style={checkboxStyle}
                    inputStyle={inputStyle}
                    dataId={DTICTFormFields.FormCheckBox('AssessorMasked')}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <CheckboxComponent
                    label={'Blinding information is not available'}
                    truncateLabel={false}
                    onChange={nullOtherCheckboxes}
                    checked={blindingNotAvailable}
                    style={checkboxStyle}
                    inputStyle={inputStyle}
                    dataId={DTICTFormFields.FormCheckBox('InformationNotAvailable')}
                />
            </div>
        </>
    );
};
