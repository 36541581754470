import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

interface ShowApprovalButtonsComponentProps {
    message: string;
    title: string;
}

export const ShowApprovalButtonsComponent = ({ message, title }: ShowApprovalButtonsComponentProps) => {
    const { setShowApprovalButtons, isUpdatingStatus } = useDataRequestContext();
    const modalService = useModalService();

    const handleButtonClick = () => {
        modalService.confirm(message, {
            title: 'Confirm Override?',
            onConfirm: () => setShowApprovalButtons(true),
        });
    };

    const dataId = DTICommonConst.HeaderApprovalButton(`${title.toLowerCase().replace(/ /g, '_')}`);

    return (
        <ButtonComponent style={{ marginLeft: 10 }} onClick={handleButtonClick} disabled={isUpdatingStatus} dataId={dataId}>
            {title}
        </ButtonComponent>
    );
};
