﻿import { IUploadStudiesDetails } from '@vivli/features/studies/infrastructure/interface';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import joi, { ObjectSchema } from 'joi';

const formSchema: ObjectSchema<IUploadStudiesDetails> = joi
    .object({
        dataContributorOrganization: joi.object().required().label('Data Contributor Organization'),
        requestsApprovedByOrganization: joi.object().required().label('Data Requests to be Approved by'),
        curationOrganization: joi.object().required().label('Curation Organization'),
        curationQcOrganization: joi.object().required().label('Curation QC Reviewer Organization'),
        dataPackageType: joi.string().required().label('Data Package To Be Made Available For Studies'),
        contentType: joi.string().required().label('Upload Sheet Type'),
    })
    .options(JoiValidationOptionsConstant);

const defaultValues: IUploadStudiesDetails = {
    dataContributorOrganization: null,
    requestsApprovedByOrganization: null,
    curationOrganization: null,
    curationQcOrganization: null,
    dataPackageType: null,
    contentType: null,
    isDownloadable: false,
};

export function useBulkUploadFormHook(
    details: IUploadStudiesDetails = defaultValues,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all'
) {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: details,
        reValidateMode: 'onChange',
    });
}
