import { FieldHeaderComponent, TextFormFieldComponent } from '@vivli/shared/components';
import React from 'react';
import { Size } from '@vivli/shared/theme';
import { useActiveUser } from '@vivli/core/infrastructure/context';

const detailsDivStyle = {
    display: 'flex',
    justifyContent: 'space-around',
};

const bottomMarginStyle = {
    marginBottom: Size.PADDING,
    marginRight: Size.PADDING,
};

export const OrgDetailsBasicInformation = () => {
    const { isVivliAdmin } = useActiveUser();

    return (
        <div>
            <FieldHeaderComponent title={'BASIC INFORMATION'} />
            <TextFormFieldComponent name={'name'} label={'Organization Name'} style={bottomMarginStyle} readonly={!isVivliAdmin} />
            <div style={detailsDivStyle}>
                <TextFormFieldComponent name={'billingCountry'} label={'Country'} style={bottomMarginStyle} readonly={!isVivliAdmin} />
            </div>
        </div>
    );
};
