﻿import { useNotificationContext } from '@vivli/shared/features/notification/infrastructure/context';
import React, { CSSProperties, useEffect } from 'react';
import { NotificationFormComponent } from '@vivli/shared/features/notification/components';
import { LoadIndicatorComponent } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';

const loadingContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

const loadingStyle: CSSProperties = {
    ...Styles.CENTERED_IN_BLOCK,
    zIndex: 100,
    minWidth: 'initial',
};
export const NotificationFormWrapper = ({ topicId }) => {
    const { notifications, getNotifications, showForm } = useNotificationContext();

    useEffect(() => {
        getNotifications(topicId);
    }, []);

    return (
        <>
            {showForm ? (
                <NotificationFormComponent notification={notifications[0]} />
            ) : (
                <div style={loadingContainerStyle}>
                    <div style={loadingStyle}>
                        <LoadIndicatorComponent />
                    </div>
                </div>
            )}
        </>
    );
};
