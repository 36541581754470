import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormField } from '@vivli/shared/infrastructure/interface';

interface BaseControlledFormFieldComponentProps extends IFormField {
    children: ReactElement;
    name: string;
}

export const BaseControlledFormFieldComponent = ({ name, children, ...props }: BaseControlledFormFieldComponentProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState }) => {
                const { invalid, error } = fieldState;

                //this is where value, onChange, etc. are passed in
                return React.cloneElement(children, {
                    ...field,
                    ...props,
                    invalid,
                    checked: field.value,
                    error: props.error || error?.message,
                });
            }}
        />
    );
};
