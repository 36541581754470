import React, { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import { Size } from '@vivli/shared/theme';
import { ICheckboxMenuItem, IFormField } from '@vivli/shared/infrastructure/interface';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useRefState } from '@vivli/shared/infrastructure/hook';
import { BaseInputFieldComponent } from './base-input-field.component';
import { CheckboxComponent } from './checkbox.component';
import mergeRefs from 'react-merge-refs';

const checkboxFieldStyle: CSSProperties = { flexDirection: 'row-reverse', justifyContent: 'flex-end' };

interface CheckboxListComponentProps extends IFormField {
    checkedListItems?: ICheckboxMenuItem[];
    checkboxGroupNames?: any;
    defaultCheckedItems?: string[];
    allowMultiple?: boolean;
    groupStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    innerStyle?: CSSProperties;
    onChange?: (checkedValue: string[]) => void;
    isInherited?: boolean;
    fieldStyle?: CSSProperties;
    listHeader?: string;
}

export const CheckboxListComponent = forwardRef((props: CheckboxListComponentProps, ref) => {
    const {
        checkedListItems,
        checkboxGroupNames,
        allowMultiple = true,
        style,
        groupStyle,
        labelStyle,
        onChange,
        onBlur,
        defaultCheckedItems,
        isInherited = false,
        fieldStyle,
    } = props;
    const inputRef = useRef<HTMLDivElement>();
    const [checkedItemsRef, checkedItems, setCheckedItems] = useRefState<ICheckboxMenuItem[]>(checkedListItems);

    const containerStyle: CSSProperties = {
        padding: Size.PADDING,
        ...style,
        margin: 0,
    };

    const updateCheckedValues = (checkboxValue: string, checked: boolean) => {
        if (allowMultiple) {
            setCheckedItems([
                ...checkedItemsRef.current.map((i) => {
                    if (i.value === checkboxValue) {
                        return {
                            ...i,
                            checked,
                        };
                    }

                    return i;
                }),
            ]);
        } else {
            setCheckedItems([
                ...checkedItemsRef.current.map((i) => ({
                    ...i,
                    checked: i.value === checkboxValue,
                })),
            ]);
        }
    };

    const handleCheckboxChanged = (updatedValue: string, checked: boolean) => {
        updateCheckedValues(updatedValue, checked);

        onChange && onChange(checkedItemsRef.current.filter((i) => i.checked).map((i) => i.value));
    };

    useEffect(() => {
        if (!defaultCheckedItems) {
            return;
        }

        const checkedItemsUpdate = checkedItems.map((ci) => ({
            ...ci,
            checked: defaultCheckedItems.some((dci) => dci === ci.value),
        }));
        setCheckedItems(checkedItemsUpdate);
    }, [defaultCheckedItems]);

    const buildInput = () => (
        <div style={containerStyle} className="scrolly" ref={mergeRefs([inputRef, ref])}>
            {checkedItems?.map(({ title, value, checked }) => (
                <CheckboxComponent
                    onBlur={onBlur}
                    key={value}
                    checked={checked}
                    onChange={(e) => handleCheckboxChanged(value, e.target.checked)}
                    label={title}
                    style={{ ...checkboxFieldStyle, ...fieldStyle }}
                    labelStyle={labelStyle}
                    dataId={title.replace(/\s/g, '') + '_checkbox'}
                />
            ))}
        </div>
    );

    const buildGroupInput = () => (
        <div style={containerStyle} className="scrolly" ref={mergeRefs([inputRef, ref])}>
            {Object?.values(checkboxGroupNames)?.map((groupName) => (
                <div style={groupStyle} key={groupName.toString()}>
                    {groupName && <span>{groupName}</span>}
                    {checkedItems
                        ?.filter((item) => item.group == groupName)
                        ?.map(({ title, value, checked }) => (
                            <CheckboxComponent
                                onBlur={onBlur}
                                key={value}
                                checked={checked}
                                onChange={(e) => handleCheckboxChanged(value, e.target.checked)}
                                label={title}
                                style={{ ...checkboxFieldStyle, ...fieldStyle }}
                                labelStyle={labelStyle}
                                dataId={title.replace(/\s/g, '') + '_checkbox'}
                            />
                        ))}
                </div>
            ))}
        </div>
    );

    if (isInherited) {
        return buildInput();
    }

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.CheckboxList} inputRef={inputRef}>
            {!checkboxGroupNames ? buildInput() : buildGroupInput()}
        </BaseInputFieldComponent>
    );
});
