﻿import { CheckboxFormFieldComponent } from './form-field/checkbox-form-field.component';
import { TextAreaComponent } from './input/text-area.component';
import React, { CSSProperties } from 'react';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { useWatch } from 'react-hook-form';

const containerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    marginTop: 16,
};

const textAreaStyle: CSSProperties = {
    cursor: 'default',
};

export const TermsOfUseComponent = () => {
    const config = useVivliConfig();
    const amrTermsOfUse = config?.amrTermsOfUseAgreement;
    const termsOfUseCheckbox = useWatch({ name: 'antimicrobialDetail.agreesToTermsOfUse' });
    const error = !termsOfUseCheckbox && 'Terms of Use must be [true]';

    return (
        <div style={containerStyle}>
            <div style={{ width: '5%' }}>
                <CheckboxFormFieldComponent
                    name={'antimicrobialDetail.agreesToTermsOfUse'}
                    label={''}
                    reverseOrientation={true}
                    truncateLabel={false}
                />
            </div>
            <div style={{ width: '90%' }}>
                <TextAreaComponent
                    label={'Terms of Use:'}
                    rows={6}
                    readonly={true}
                    value={amrTermsOfUse}
                    style={textAreaStyle}
                    error={error}
                />
            </div>
        </div>
    );
};
