import { IResearchTeamMemberDetail } from '@vivli/features/data-requests/infrastructure/interface';

export function useResearcherSubtitle() {
    const getSubTitle = (teamMember: IResearchTeamMemberDetail) => {
        if (!teamMember) {
            return '';
        }

        if (teamMember.isLeadResearcher) {
            let title = 'LEAD RESEARCHER';

            if (teamMember.isStatistician) {
                title += ' / STATISTICIAN';
            }

            if (teamMember.isAdmin) {
                title += ' / DATA REQUEST ADMINISTRATOR';
            }

            return title;
        } else if (teamMember.isStatistician) {
            return teamMember.isAdmin ? 'STATISTICIAN RESEARCHER / DATA REQUEST ADMINISTRATOR' : 'STATISTICIAN RESEARCHER';
        }

        return teamMember.isAdmin ? 'DATA REQUEST ADMINISTRATOR' : 'ADDITIONAL RESEARCHER';
    };

    return getSubTitle;
}
