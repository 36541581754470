import React, { CSSProperties, useEffect, useState } from 'react';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { AdminHeaderComponent, AdminHeaderTitleComponent, FieldHeaderComponent } from '@vivli/shared/components';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { Color, Size } from '@vivli/shared/theme';
import { IOrganizationRoles } from '@vivli/shared/infrastructure/interface';
import { AssetsConstant, DTIOrganizationMembershipList } from '@vivli/shared/infrastructure/constants';
import { useNavigate } from 'react-router-dom';

const orgMembershipStyle = (role: IOrganizationRoles): CSSProperties =>
    ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginLeft: Size.INSET_SIZE,
        marginRight: Size.INSET_SIZE,
        paddingBottom: Size.PADDING,
        paddingTop: Size.PADDING,
        boxShadow: Size.BOX_SHADOW_LIGHT,
        paddingLeft: 25,
        paddingRight: 60,
        fontSize: '1em',
        backgroundColor: 'white',
        backgroundImage: role.isOrgAdmin ? `url(${AssetsConstant.BLACK_RIGHT_ARROW})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right 35px',
        ':hover': {
            opacity: 0.6,
        },
        ':active': {
            opacity: 0.8,
        },
        transition: `scale 0.35s, opacity ${Size.HOVER_TRANSITION_TIME}s`,
        cursor: role.isOrgAdmin ? 'pointer' : 'default',
    } as React.CSSProperties);

const orgTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
    color: Color.LIGHTER_GRAY,
    marginBottom: 15,
};

const orgNameStyle: CSSProperties = {
    fontSize: Size.FontSize.XLarge,
    color: Color.LIGHTER_GRAY,
};

const orgRoleNameStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
    color: Color.LIGHTER_GRAY,
};

const scrollContainerStyle: CSSProperties = {
    height: '100%',
    maxHeight: '400px',
    overflowX: 'auto',
    marginBottom: Size.INSET_SIZE,
    maxWidth: '90%',
};

export const OrganizationMembershipListComponent = () => {
    const [organizations, setOrganizations] = useState<IOrganization[]>();
    const user = useActiveUser();
    const organizationsService = useOrganizationsService();
    const navigate = useNavigate();

    const handleOrgClick = (role: IOrganizationRoles) => {
        if (!role.isOrgAdmin) {
            return;
        }

        navigate(`/admin/orgs/${role.orgId}`);
    };

    useEffect(() => {
        const sub = organizationsService.getOrganizations().subscribe(setOrganizations);
        return () => {
            sub.unsubscribe();
        };
    }, []);

    return (
        <div style={{ width: '50%', display: 'flex', flexFlow: 'column' }}>
            <AdminHeaderComponent>
                <AdminHeaderTitleComponent title={`Organization Memberships`} />
            </AdminHeaderComponent>
            <div style={scrollContainerStyle} className="scrolly">
                {organizations &&
                    user.orgMemberships
                        ?.filter((om) => !om.isDisabled)
                        .map((om) => {
                            const org = organizations.find((org) => org.id === om.orgId);
                            return (
                                <div
                                    key={om.orgId}
                                    style={orgMembershipStyle(om)}
                                    onClick={() => handleOrgClick(om)}
                                    data-test-id={DTIOrganizationMembershipList.OrgMemberRoles + `${org.name.replace(' ', '_')}`}
                                >
                                    <div>
                                        <div style={orgTextStyle}>Organization</div>
                                        <div style={orgNameStyle}>{org && org.name}</div>
                                    </div>
                                    <div style={{ width: 200 }}>
                                        <div style={orgRoleNameStyle}>Roles</div>
                                        <ul data-test-id={DTIOrganizationMembershipList.OrgMemberRolesList}>
                                            {om.isOrgAdmin && <li data-test-id={DTIOrganizationMembershipList.OrgAdminRole}>Org Admin</li>}
                                            {om.isIRPApprover && (
                                                <li data-test-id={DTIOrganizationMembershipList.IrpReviewerRole}>IRP/Reviewer</li>
                                            )}
                                            {om.isDataProvider && (
                                                <li data-test-id={DTIOrganizationMembershipList.DataContributorRole}>Data Contributor</li>
                                            )}
                                            {om.isAnnotator && <li data-test-id={DTIOrganizationMembershipList.CuratorRole}>Curator</li>}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                {user.orgMemberships?.length <= 0 && (
                    <div style={{ marginLeft: Size.INSET_SIZE }}>
                        <FieldHeaderComponent title={'You are currently not a member of any organizations'} />
                    </div>
                )}
            </div>
        </div>
    );
};
