﻿import React, { CSSProperties } from 'react';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { AdminHeaderTitleComponent } from '@vivli/shared/components';

const containerStyle: CSSProperties = {
    marginTop: 35,
};

export const PortalTitleComponent = () => {
    const config = useConfigService();

    return (
        <div style={containerStyle}>
            <AdminHeaderTitleComponent title={config?.portalTitle} />
        </div>
    );
};
