import React, { CSSProperties } from 'react';
import {
    DatePickerFormFieldComponent,
    FieldHeaderComponent,
    InfoPopoverComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { NarrativeSummaryHelpComponent } from './narrative-summary-help.component';
import { DataRequestHintsConstant, DTIResearchProposalForm } from '@vivli/shared/infrastructure/constants';
import { PurposeOfAnalysisComponent } from '../purpose-of-analysis.component';
import { OutcomesComponent } from '../outcomes.component';

const containerStyle: CSSProperties = {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: Size.INSET_SIZE,
};

const spacedRowStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const publicationDivStyle: CSSProperties = {
    maxWidth: '40%',
};

const citationDivStyle: CSSProperties = {
    maxWidth: '59%',
};

const formFieldStyle: CSSProperties = {
    marginBottom: '20px',
};

const infoPopupStyle: CSSProperties = {
    minHeight: '30px',
    justifyContent: 'flex-end',
};

const dateFieldStyle: CSSProperties = {
    maxWidth: '400px',
    marginRight: '20px',
};

const flexContainerStyle: CSSProperties = {
    display: 'flex',
};

const projectTimelineHint = (
    <div>
        <div>
            Provide an estimation of key milestone dates for the proposed research, including anticipated project start date and analysis
            completion date. This refers to the timeline of your work on the Vivli platform only, so make sure the dates you enter reflect
            this expectation.
        </div>
        <br />
        <div>
            The data request review and data use agreement process can take between 2-6 months. The length of the review process depends on
            the number of factors such as the number of Data Contributors involved in a data request, the number of studies involved, and
            how long it takes the requester to respond to comments.
        </div>
        <br />
        <div>
            Please note: if your data request is approved, the Terms of Use Agreement allows for data access for a 12-month period, with the
            possibility of an extension.
        </div>
    </div>
);

const briefDescriptionHint = (
    <div>
        <div>Please provide a brief description of the study design, for example:</div>
        <ul>
            <li>case-control</li>
            <li>cohort</li>
            <li>cross-sectional</li>
            <li>historical controlled</li>
            <li>hybrid designs</li>
            <li>meta-analysis</li>
            <li>pooled analysis</li>
        </ul>
        <div>Please also provide a description of the study population or populations for the proposed research, for example:</div>
        <ul>
            <li>the study arms from the requested clinical studies</li>
            <li>intent-to-treat or per-protocol populations</li>
            <li>the inclusion and exclusion criteria for any cohort or subgroup analysis</li>
        </ul>
        <div>
            Please clarify how the data you have requested will help you answer the hypothesis you have proposed. This includes outlining
            the methods you will use and how you will handle any missing data. Consider providing well defined baselines and SAP details
            that consider as many variables as possible.
        </div>
    </div>
);

const specificOutcomesHint = (
    <div>
        <div>Please describe the specific outcomes elements and how they will be categorized/defined for your study, including:</div>
        <ul>
            <li>Domain (e.g., anxiety)</li>
            <li>Specific measurement (e.g., Hamilton Anxiety Rating Scale)</li>
            <li>Specific Metric (e.g., change from baseline)</li>
            <li>Method of Aggregation (e.g., mean)</li>
            <li>Time-point(s) (e.g., 3 and 6 months)</li>
        </ul>
        <div>Please describe the endpoints of the requested studies that will be analyzed.</div>
        <br />
        <div>
            Note that some data contributors require that research proposals must relate to the intervention or disease that was the subject
            of the original clinical studies to ensure the use of the data aligns with the informed consent provided by clinical study
            participants. For information about the requirements of members, please review their Members Page.
        </div>
    </div>
);

export const ResearchProposalFormComponent = () => {
    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title="GENERAL" />

            <TextFormFieldComponent
                name={'researchProposal.title'}
                label={
                    'Title of the Proposed Research (Overall brief description, including the condition, about the proposed research question that is understandable by a general audience)'
                }
                readonly={false}
                style={formFieldStyle}
                dataId={DTIResearchProposalForm.ResearchProposalTitleTextBox}
            />

            <InfoPopoverComponent content={<NarrativeSummaryHelpComponent />} size={20} style={infoPopupStyle} />
            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.laySummary'}
                label={'Lay Summary of the Research Request'}
                dataId={DTIResearchProposalForm.ResearchProposalSummaryTextBox}
            />

            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.specificAimsObjectives'}
                label={'What are the specific aims/objectives of the proposed research, including the specific hypotheses to be evaluated?'}
            />

            <div style={Styles.FORM_ROW}>
                <PurposeOfAnalysisComponent />
                <OutcomesComponent />
            </div>

            <FieldHeaderComponent title="STUDY DESIGN" />

            <InfoPopoverComponent content={briefDescriptionHint} size={20} style={infoPopupStyle} />
            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.researchProposalStudyDesign.briefDescription'}
                label={'Brief Description'}
                dataId={DTIResearchProposalForm.BriefDescriptionTextBox}
            />

            <InfoPopoverComponent content={specificOutcomesHint} size={20} style={infoPopupStyle} />
            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.researchProposalStudyDesign.elementsforOutcomes'}
                label={'Please describe the specific outcomes elements and how they will be categorized/defined for your proposed research'}
                dataId={DTIResearchProposalForm.ResearchProposalStudyDesignTextBox}
                showCount={true}
                maxCount={1000}
            />

            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.researchProposalStudyDesign.mainPredictorIndependentVariable'}
                label={'Main Predictor/Independent Variable and how it will be categorized/defined for your proposed research'}
                dataId={DTIResearchProposalForm.ResearchProposalMainPredictorTextBox}
            />

            <TextAreaFormFieldComponent
                style={formFieldStyle}
                name={'researchProposal.researchProposalStudyDesign.otherVariablesOfInterest'}
                label={
                    'Other variables of interest that will be used in your analysis and how they will be categorized/defined in your proposed research (i.e. genders, age groups, ethnic groups)'
                }
                dataId={DTIResearchProposalForm.ResearchProposalOtherVariablesTextBox}
            />

            <FieldHeaderComponent
                title="PROJECT TIMELINE"
                rightAccessory={<InfoPopoverComponent content={projectTimelineHint} size={20} />}
            />

            <div style={flexContainerStyle}>
                <DatePickerFormFieldComponent
                    name={'researchProposal.projectTimeline.projectStartDate'}
                    label={'Target Analysis Start Date'}
                    style={dateFieldStyle}
                />

                <DatePickerFormFieldComponent
                    name={'researchProposal.projectTimeline.analysisCompletionDate'}
                    label={'Estimated Analysis Completion Date'}
                    style={dateFieldStyle}
                />
            </div>

            <FieldHeaderComponent title="DISSEMINATION AND PUBLICATION PLAN" />

            <div style={spacedRowStyle}>
                <TextAreaFormFieldComponent
                    style={publicationDivStyle}
                    name={'researchProposal.disseminationAndPublicationPlan'}
                    label={'Dissemination & Publication Plan'}
                    hint={DataRequestHintsConstant.researchProposal.disseminationAndPublicationPlan}
                    dataId={DTIResearchProposalForm.ResearchProposalDisseminationPlanTextBox}
                />

                <TextAreaFormFieldComponent
                    style={citationDivStyle}
                    name={'researchProposal.citations'}
                    label={'Provide references for all cited material (following APA guidelines)'}
                />
            </div>
        </div>
    );
};
