import { useEffect } from 'react';
import { ISampleSize } from '@vivli/features/search/infrastructure/interface';

export function useSampleSizeFilterHook({
    key,
    sampleRange,
    onChange,
}: {
    key: string;
    sampleRange: ISampleSize;
    onChange: (key: string, sampleRange: ISampleSize) => void;
}) {
    useEffect(() => {
        onChange(key, sampleRange);
    }, [sampleRange, sampleRange?.min, sampleRange?.max]);
}
