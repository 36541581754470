﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { OrganizationsApiService } from '@vivli/features/organizations/infrastructure/service';
import { IOrganizationsService } from '@vivli/features/organizations/infrastructure/interface';
import React from 'react';
import { OrganizationsServiceContext } from '@vivli/features/organizations/infrastructure/context';

export const OrganizationsServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const organizationsApi = new OrganizationsApiService(apiOptions);

    const provider: IOrganizationsService = {
        ...organizationsApi,
    };

    return <OrganizationsServiceContext.Provider value={provider}>{children}</OrganizationsServiceContext.Provider>;
};
