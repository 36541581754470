﻿import { IDataRequest, IRequestedStudy } from '@vivli/features/data-requests/infrastructure/interface';
import { RequestedStudyRowComponent } from './requested-study-row/requested-study-row.component';
import React from 'react';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

interface DataRequestRequestedStudyRowComponentProps {
    study: IRequestedStudy;
    dataRequest: IDataRequest;
    onHeightChange?: (height: number) => void;
    onRemoveRow?: (dataRequest: IDataRequest) => void;
}

export const DataRequestRequestedStudyRowComponent = ({
    study,
    dataRequest,
    onHeightChange,
    onRemoveRow,
}: DataRequestRequestedStudyRowComponentProps) => {
    const updateDataRequest = useDataRequestContext()?.updateDataRequest;

    return (
        <RequestedStudyRowComponent
            study={study}
            dataRequest={dataRequest}
            onHeightChange={onHeightChange}
            onRemoveRow={onRemoveRow}
            updateDataRequest={updateDataRequest}
        />
    );
};
