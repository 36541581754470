﻿import {
    AdminHeaderComponent,
    FieldHeaderComponent,
    StudyResearchTeamMemberRowsComponent,
    TabComponent,
    TabsComponent,
} from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { Color, Size } from '@vivli/shared/theme';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { StudyDatasetAdminCtAmrTabEnum, StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { AmrDatasetDetailsComponent } from '@vivli/features/datasets/components';
import { useAdminStudyPermissions } from '@vivli/features/studies/infrastructure/hook';
import { DataTransformationService } from '@vivli/shared/infrastructure/service';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { AdminStudyDatasetAttachedDocumentsComponent } from './admin-study-dataset-attached-documents-component';
import { AdminAmrAdministrativeDetailsComponent } from './admin-amr-administrative-details.component';
import { AdminCtAdministrativeDetailsComponent } from './admin-ct-administrative-details.component';
import { AdminCtStudyDetailComponent } from './admin-ct-study-detail.component';
import { StudyDatasetChatComponent } from '../study-dataset-chat.component';
import { AdminStudyDatasetDatapackageComponent } from './admin-study-dataset-datapackage.component';
import { StudyDatasetHistoryComponent } from '../study-dataset-history.component';
import { StudyDatasetJsonComponent } from '../study-dataset-json.component';
import { AdminStudyDatasetUsedInComponent } from './admin-study-dataset-usedin.component';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { UsageCitationsContainerComponent } from './usage-citations-container.component';
import { AdminStudyDatasetStatusDisplayComponent } from './admin-study-dataset-status-display.component';

const headerContainerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: '0 40px',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
};
const headerTitleContainerStyle: CSSProperties = {
    marginLeft: '-40px',
    padding: '0px',
    color: Color.DARK_GRAY,
    fontWeight: Size.FontWeight.Bold,
    width: '70%',
};

const headerTitleStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Regular,
    color: Color.DARK_GRAY,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-word',
};

const tabContainerHolderStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    paddingRight: '10px',
    overflowY: 'auto',
};

export const AdminStudyDatasetTabsComponent = () => {
    const { study, usedIn, studyIpdPackageSubmitted } = useStudyContext();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const activeUser = useActiveUser();
    const { isOrgAdmin } = useUserPermissions();

    const studyPermissions = useAdminStudyPermissions(study, studyIpdPackageSubmitted);
    const showDataPackagesTab = studyPermissions.userCanSeeDataPackage();
    const userCanDownloadIpd = studyPermissions.userCanDownloadIpd();
    const userCanUploadIpd = studyPermissions.userCanUploadIpd();
    const showDataCount = studyPermissions.canSeeDataCount();

    const statusMessage = DataTransformationService.mapStatusToMessage(study.status);
    const postedStudy = study.status === StudyStatusEnum.Posted;
    const jsonString = JSON.stringify(study, null, '\t');
    const amrTitleToDisplay = study?.amrDataset?.program;
    const acronymString = study?.acronym ? ' (' + study?.acronym + ')' : '';
    const studyTitleToDisplay = study?.studyTitle + acronymString;
    const titleToDisplay = isAmr ? amrTitleToDisplay : studyTitleToDisplay;

    const isResearchTeamReadOnly = !studyPermissions.userCanSave();
    const arrayName = 'researchTeam';
    const isVivliAdmin = activeUser.isVivliAdmin;
    const orgVivliAdmin = isVivliAdmin || isOrgAdmin;
    const isThisDatasetOwner = study?.ownerIds?.includes(activeUser?.id);
    const detailsComponent = isAmr ? (
        <AmrDatasetDetailsComponent dataset={study.amrDataset} />
    ) : (
        <AdminCtStudyDetailComponent study={study} user={activeUser} />
    );
    const administrativeComponent = isAmr ? (
        <AdminAmrAdministrativeDetailsComponent study={study} user={activeUser} />
    ) : (
        <AdminCtAdministrativeDetailsComponent user={activeUser} />
    );
    const detailsTabTitle = isAmr ? StudyDatasetAdminCtAmrTabEnum.DatasetDetails : StudyDatasetAdminCtAmrTabEnum.StudyDetails;
    const documentsTabTitle = isAmr ? StudyDatasetAdminCtAmrTabEnum.AmrDocuments : StudyDatasetAdminCtAmrTabEnum.StudyDocuments;
    const datasetIdForSendingResearchTeamInvites = isVivliAdmin || isOrgAdmin || isThisDatasetOwner ? study?.id : null;
    const hasRequests = usedIn && usedIn !== '';

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div style={headerContainerStyle}>
                <AdminHeaderComponent>
                    <div style={headerTitleContainerStyle}>
                        <FieldHeaderComponent title={titleToDisplay} style={headerTitleStyle} />
                    </div>
                    <AdminStudyDatasetUsedInComponent
                        statusMessage={statusMessage}
                        dataUploaded={studyIpdPackageSubmitted}
                        usedInList={usedIn}
                    />
                </AdminHeaderComponent>
                <div style={tabContainerHolderStyle}>
                    <TabsComponent defaultTab={0} commandOnClick={() => {}}>
                        <TabComponent title={detailsTabTitle} onTabClick={() => {}}>
                            <div>{detailsComponent}</div>
                        </TabComponent>
                        <TabComponent title={documentsTabTitle} onTabClick={() => {}}>
                            <div>
                                <AdminStudyDatasetAttachedDocumentsComponent />
                            </div>
                        </TabComponent>
                        <TabComponent title={StudyDatasetAdminCtAmrTabEnum.AdministrativeDetails} onTabClick={() => {}}>
                            <div>{administrativeComponent}</div>
                        </TabComponent>
                        {!isAmr && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.ResearchTeam} onTabClick={() => {}}>
                                <div>
                                    <StudyResearchTeamMemberRowsComponent
                                        arrayName={arrayName}
                                        isReadOnly={isResearchTeamReadOnly}
                                        datasetId={datasetIdForSendingResearchTeamInvites}
                                    />
                                </div>
                            </TabComponent>
                        )}
                        {orgVivliAdmin && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.History} onTabClick={() => {}}>
                                <div>
                                    <StudyDatasetHistoryComponent studyId={study.id} />
                                </div>
                            </TabComponent>
                        )}
                        {isVivliAdmin && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.Json} onTabClick={() => {}}>
                                <div>
                                    <StudyDatasetJsonComponent json={jsonString} showTitle={true} />
                                </div>
                            </TabComponent>
                        )}
                        {showDataPackagesTab && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.DataPackage} onTabClick={() => {}}>
                                <div>
                                    <AdminStudyDatasetDatapackageComponent
                                        ipdDataPackageId={study.ipdDataPackageId}
                                        userCanDownload={userCanDownloadIpd}
                                        userCanUpload={userCanUploadIpd}
                                        studyId={study.id}
                                        studyIpdContentType={study.ipdContentType}
                                        studyOrgId={study.orgId}
                                    />
                                </div>
                            </TabComponent>
                        )}
                        {orgVivliAdmin && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.Chat} onTabClick={() => {}}>
                                <div>
                                    <StudyDatasetChatComponent chatChannelId={study.chatChannelId} />
                                </div>
                            </TabComponent>
                        )}
                        {showDataCount && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.Usage} onTabClick={() => {}}>
                                <div>
                                    <UsageCitationsContainerComponent
                                        studyId={study.id}
                                        studyMetadataDoi={study.studyMetadataDoi}
                                        studyPostedDate={study.postedDate}
                                        studyApprovedDrs={study.approvedDrs}
                                    />
                                </div>
                            </TabComponent>
                        )}
                        {hasRequests && (
                            <TabComponent title={StudyDatasetAdminCtAmrTabEnum.Requests}>
                                <div>
                                    <AdminStudyDatasetStatusDisplayComponent usedInList={usedIn} />
                                </div>
                            </TabComponent>
                        )}
                    </TabsComponent>
                </div>
            </div>
        </div>
    );
};
