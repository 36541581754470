import { IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { useEffect, useState } from 'react';
import { useEnquiryServiceContext } from '@vivli/features/enquiry/infastructure/context';
import { finalize, first, map } from 'rxjs/operators';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DataTransformationService } from '@vivli/shared/infrastructure/service';

export function useEnquiryListHook() {
    const [enquires, setEnquires] = useState<IEnquiry[]>();
    const [isLoadingEnquires, setIsLoadingEnquires] = useState(false);

    const enquiryService = useEnquiryServiceContext();
    const modalService = useModalService();

    const handleError = (error) => {
        modalService.error(error);
    };

    useEffect(() => {
        setIsLoadingEnquires(true);
        enquiryService
            .getEnquiryList()
            .pipe(
                first(),
                map(DataTransformationService.formatEnquiries),
                finalize(() => setIsLoadingEnquires(false))
            )
            .subscribe((data) => {
                data.forEach((item) => {
                    item.dataInfoRequestsCount = item.dataInfoRequests?.length ?? 0;
                });
                setEnquires(data);
            }, handleError);
    }, []);

    return {
        enquires,
        isLoadingEnquires,
    };
}
