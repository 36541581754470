﻿import React, { CSSProperties } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { Color, Size } from '@vivli/shared/theme';
import {
    CheckboxFormFieldComponent,
    FieldHeaderComponent,
    TermsOfUseComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import { getPublicationText } from './amr-publication-text.component';
import { AmrPurposeOfAnalysisComponent } from './amr-data-request-fields/amr-purpose-of-analysis.component';
import { AmrResearcherLocationComponent } from './amr-data-request-fields/amr-researcher-location.component';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'left',
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
};
const twoColContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};
const halfWidth: CSSProperties = {
    maxWidth: '48%',
    minWidth: '48%',
};
const rowStyle: CSSProperties = {
    display: 'flex',
    flex: 'auto',
    flexFlow: 'row wrap',
    marginBottom: 16,
    justifyContent: 'space-between',
};
const summaryTextStyle: CSSProperties = {
    marginTop: '2em',
    color: Color.VIVLI_DARK_BLUE,
};

const orcIdHint = (
    <div>
        ORCID iD is a persistent digital identifier that distinguishes you from every other researcher and, through integration in key
        research workflows such as manuscript and grant submission, supports automated linkages between you and your professional activities
        ensuring that your work is recognized.
    </div>
);

interface AmrRequestFormComponentProps {
    dataRequest: IDataRequest;
}

export const AmrRequestFormComponent = ({ dataRequest }: AmrRequestFormComponentProps) => {
    return (
        <div style={containerStyle}>
            <div style={rowStyle} />
            <div style={{ ...rowStyle, maxHeight: '60px' }}>
                <TextFormFieldComponent
                    name={`antimicrobialDetail.amrResearcherDetails.researcherName`}
                    label={"Lead Researcher's Name"}
                    style={halfWidth}
                />
                <TextFormFieldComponent
                    name={`antimicrobialDetail.amrResearcherDetails.researcherEmail`}
                    label={"Lead Researcher's Email Address"}
                    style={halfWidth}
                />
            </div>
            <div style={{ ...rowStyle, maxHeight: '60px' }}>
                <TextFormFieldComponent
                    name={`antimicrobialDetail.amrResearcherDetails.amrResearcherAffiliation`}
                    label={"Lead Researcher's Affiliation"}
                    style={halfWidth}
                />
                <TextFormFieldComponent
                    name={`antimicrobialDetail.amrResearcherDetails.orcId`}
                    label={"Lead Researcher's ORCID iD"}
                    style={halfWidth}
                    hint={orcIdHint}
                />
            </div>
            <div style={{ ...rowStyle, maxHeight: '60px' }}>
                <AmrResearcherLocationComponent style={halfWidth} />
            </div>
            <div style={twoColContainerStyle}>
                <div style={halfWidth}>
                    <FieldHeaderComponent title="" />
                    <CheckboxFormFieldComponent
                        name={'antimicrobialDetail.agreesToPublicationAcknowledgment'}
                        label={getPublicationText(dataRequest)}
                        reverseOrientation={true}
                        truncateLabel={false}
                    />
                    <TermsOfUseComponent />
                    <div style={summaryTextStyle}>
                        Please provide a background and summary of the proposed research that is suitable for a general audience. Describe
                        how your proposed research will:
                        <ul>
                            <li>Help improve patient outcomes</li>
                            <li>Strengthen stewardship</li>
                            <li>Inform public health practice</li>
                            <li>Strengthen health systems</li>
                        </ul>
                    </div>
                    <TextAreaFormFieldComponent
                        name={'antimicrobialDetail.amrAnalysisDetails.summary'}
                        label={'Research Summary'}
                        rows={9}
                    />
                </div>
                <div style={halfWidth}>
                    <AmrPurposeOfAnalysisComponent />
                </div>
            </div>
        </div>
    );
};
