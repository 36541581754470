import React, { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import tinycolor from 'tinycolor2';

export const containerStyle: CSSProperties = {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgb(233, 234, 239)',
    zIndex: 1,
};

export const bodyContainerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    maxHeight: 'calc(100% - 70px)',
};

export const bodyStyle: CSSProperties = {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    marginTop: 20,
    paddingRight: '5px',
    display: 'flex',
    flexGrow: 1,
};

export const navButtonContainerStyle: CSSProperties = {
    minWidth: '300px',
    maxWidth: '300px',
    backgroundColor: 'white',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
};

export const navButtonStyle = (key: string): CSSProperties =>
    ({
        ...Styles.NO_SELECT,
        maxHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT,
        minHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT - 40,
        marginBottom: 0,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        paddingLeft: Size.PADDING,
        fontSize: Size.FontSize.Large,
        backgroundColor: window.location.href.includes(key) ? '#ebeaed' : Color.WHITE,
        cursor: 'pointer',
        pointerEvents: 'inherit',
        boxShadow: window.location.href.includes(key) ? 'none' : 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
        position: 'relative',
        zIndex: window.location.href.includes(key) ? 0 : 12,
    } as React.CSSProperties);

export const navButtonTextStyle = (key: string): CSSProperties => ({
    color: window.location.href.includes(key) ? Color.TEXT_DARK_BLUE : tinycolor(Color.TEXT_DARK_BLUE).clone().lighten(20).toRgbString(),
    fontWeight: window.location.href.includes(key) ? Size.FontWeight.Bold : Size.FontWeight.Regular,
});

export const navButtonTextContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

export const fillerStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    flexGrow: 2,
    background: 'white',
    position: 'relative',
    zIndex: 14,
};

export const subContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
};
