import React, { CSSProperties } from 'react';
import Tippy from '@tippyjs/react';
import { ILinkMenuItem } from '@vivli/shared/infrastructure/interface';

const titleStyle: CSSProperties = {
    textDecoration: 'none',
    color: '#03395a',
};

const titleContainerStyle: CSSProperties = { margin: '0 15px' };

interface LinkMenuComponentProps {
    title: string;
    titleHref: string;
    links: ILinkMenuItem[];
    singleton: any;
}

export const LinkMenuComponent = ({ title, titleHref, links, singleton }: LinkMenuComponentProps) => {
    const content = (
        <div>
            {links?.length > 0 && (
                <ul className="sub-menu">
                    {links.map(({ href, title, target }, i) => (
                        <li key={i}>
                            <a href={href} target={target || '_blank'}>
                                {title}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    return (
        <Tippy content={content} singleton={singleton}>
            <div style={titleContainerStyle}>
                <a href={titleHref} target="_blank" style={titleStyle}>
                    {title}
                </a>
            </div>
        </Tippy>
    );
};
