﻿import { useEffect, useState } from 'react';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

export const useDuaDataHook = () => {
    const { organizations } = useOrganizations();
    const vivliConfig = useVivliConfig();
    const { planningTableData, setPlanningTableData } = useDataRequestContext();
    const [organizationsOptions, setOrganizationsOptions] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([]);

    const getDefaultTemplateCode = (orgId) => {
        const organization = organizations?.find((org) => org.id === orgId);
        return organization ? organization.defaultDuaTemplateCode : null;
    };
    const handleAddRow = () => {
        setPlanningTableData([
            ...planningTableData,
            {
                contributorName: '',
                selectedTemplateCode: '',
                contributorTemplateNotes: '',
            },
        ]);
    };
    const handleDeleteRow = (index) => {
        const newData = [...planningTableData];
        newData.splice(index, 1);
        setPlanningTableData(newData);
    };
    const handleUpdateRow = (index, field, value) => {
        const newData = [...planningTableData];
        newData[index][field] = value;
        setPlanningTableData(newData);
    };

    useEffect(() => {
        if (organizations) {
            const orgOptions = organizations
                .map((org) => ({
                    title: org.name,
                    value: org.name,
                    code: org.code,
                }))
                .sort((a, b) => (a.title < b.title ? -1 : 1));
            setOrganizationsOptions(orgOptions);
        }
        if (vivliConfig?.duaTemplateItems) {
            const tempOptions = vivliConfig.duaTemplateItems
                .map((item) => ({
                    title: item.displayName,
                    value: item.code,
                }))
                .sort((a, b) => (a.title < b.title ? -1 : 1));
            setTemplateOptions(tempOptions);
        }
    }, [organizations, vivliConfig]);

    return {
        organizationsOptions,
        templateOptions,
        getDefaultTemplateCode,
        handleAddRow,
        handleDeleteRow,
        handleUpdateRow,
        organizations,
        planningTableData,
    };
};
