import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { ButtonComponent } from '@vivli/shared/components';
import { useDisplayBannerHook } from '@vivli/features/search/infrastructure/hook';

const Sizing = {
    TITLE_FONT_SIZE: Size.FontSize.Larger * 1.2,
    DESCRIPTION_FONT_SIZE: Size.FontSize.Large * 1.2,
    BUTTON_FONT_SIZE: Size.FontSize.Large,
    BANNER_BASE_PADDING: 15,
    BANNER_BUTTON_PADDING: 10,
};

const Colors = {
    DEFAULT_TEXT_COLOR: Color.VIVLI_DARK_BLUE,
};

const BannerSize = {
    Sizing,
    Colors,
};

const wrapperStyle: CSSProperties = {
    textAlign: 'center',
    color: Colors.DEFAULT_TEXT_COLOR.toString(),
    padding: Sizing.BANNER_BASE_PADDING,
    paddingBottom: 0,
};

const titleStyle: CSSProperties = {
    fontSize: Sizing.TITLE_FONT_SIZE,
    lineHeight: `${Sizing.TITLE_FONT_SIZE}px`,
    fontWeight: Size.FontWeight.Bold,
    paddingBottom: Sizing.BANNER_BASE_PADDING,
    height: Sizing.TITLE_FONT_SIZE + Sizing.BANNER_BASE_PADDING,
};

const descriptionStyle: CSSProperties = {
    fontSize: Sizing.DESCRIPTION_FONT_SIZE,
    lineHeight: `${Sizing.DESCRIPTION_FONT_SIZE}px`,
    paddingBottom: Sizing.BANNER_BASE_PADDING,
    height: Sizing.DESCRIPTION_FONT_SIZE + Sizing.BANNER_BASE_PADDING,
};

const buttonWrapperStyle: CSSProperties = {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: Sizing.BANNER_BASE_PADDING,
    height: Sizing.BUTTON_FONT_SIZE + Sizing.BANNER_BASE_PADDING + 2 * Sizing.BANNER_BUTTON_PADDING,
};

const buttonStyle: CSSProperties = {
    fontSize: Sizing.BUTTON_FONT_SIZE,
    lineHeight: `${Sizing.BUTTON_FONT_SIZE}px`,
    fontWeight: Size.FontWeight.Bold,
    height: Sizing.BUTTON_FONT_SIZE + 2 * Sizing.BANNER_BUTTON_PADDING,
    zIndex: 2,
    marginLeft: 40,
    marginRight: 40,
};

export const SearchBannerComponent = () => {
    const { bannerConfig } = useConfigService();

    const {
        shouldDisplayBanner,
        bannerTitleShouldDisplay,
        bannerDescriptionShouldDisplay,
        getNumberOfBannerButtonsPresent,
        bannerButtonShouldDisplay,
    } = useDisplayBannerHook();

    const getBannerHeight = (): number => {
        let bannerHeight = 0;
        let itemsPresent = 0;
        if (bannerConfig) {
            if (bannerTitleShouldDisplay()) {
                bannerHeight += BannerSize.Sizing.TITLE_FONT_SIZE; // Size of title
                itemsPresent++;
            }
            if (bannerDescriptionShouldDisplay()) {
                bannerHeight += BannerSize.Sizing.DESCRIPTION_FONT_SIZE; // Size of description
                itemsPresent++;
            }
            if (getNumberOfBannerButtonsPresent() > 0) {
                bannerHeight += BannerSize.Sizing.BUTTON_FONT_SIZE; // Size of button text
                bannerHeight += 2 * BannerSize.Sizing.BANNER_BUTTON_PADDING; // Size of vertical button padding
                itemsPresent++;
            }
            if (bannerHeight > 0) {
                const bannerContainerPadding = 2 * BannerSize.Sizing.BANNER_BASE_PADDING; // Padding around banner
                const spaceBetweenSiblings = (itemsPresent - 1) * BannerSize.Sizing.BANNER_BASE_PADDING; // Buffer between banner items
                bannerHeight += bannerContainerPadding + spaceBetweenSiblings;
            }
        }
        return bannerHeight;
    };

    const getBannerFontColor = () => {
        let fontColor = '';
        if (bannerConfig.fontColor) {
            fontColor = bannerConfig.fontColor.toString();
        } else {
            fontColor = BannerSize.Colors.DEFAULT_TEXT_COLOR.toString();
        }

        return fontColor;
    };

    const bannerContainerStyle: CSSProperties = {
        ...wrapperStyle,
        height: getBannerHeight(),
        backgroundImage: `url(${bannerConfig.backgroundUrl})`,
        color: getBannerFontColor(),
    };

    const ctButtonOneStyle: CSSProperties = {
        backgroundColor: bannerConfig.ctaButtonOne.ctaButtonColor,
        color: bannerConfig.ctaButtonOne.ctaButtonFontColor,
        ...buttonStyle,
    };

    const ctButtonTwoStyle: CSSProperties = {
        backgroundColor: bannerConfig.ctaButtonTwo.ctaButtonColor,
        color: bannerConfig.ctaButtonTwo.ctaButtonFontColor,
        marginRight: bannerButtonShouldDisplay(bannerConfig.ctaButtonOne) ? 2 * Sizing.BANNER_BASE_PADDING : 0,
        ...buttonStyle,
    };

    const openButtonLink = (link: string) => {
        return () => window.open(link, '_self');
    };

    return (
        shouldDisplayBanner() && (
            <div className={'bannerHolder'} style={bannerContainerStyle}>
                <div className={'bannerBody'}>
                    {bannerTitleShouldDisplay() && (
                        <div className={'bannerTitle'} style={titleStyle}>
                            {bannerConfig.bannerTitle}
                        </div>
                    )}

                    {bannerDescriptionShouldDisplay() && (
                        <div className={'bannerDescription'} style={descriptionStyle}>
                            {bannerConfig.bannerDescription}
                        </div>
                    )}

                    {getNumberOfBannerButtonsPresent() > 0 && (
                        <div className={'bannerButtons'} style={buttonWrapperStyle}>
                            {bannerButtonShouldDisplay(bannerConfig.ctaButtonOne) && (
                                <ButtonComponent style={ctButtonOneStyle} onClick={openButtonLink(bannerConfig.ctaButtonOne.ctaButtonUrl)}>
                                    {bannerConfig.ctaButtonOne.ctaButtonText}
                                </ButtonComponent>
                            )}

                            {bannerButtonShouldDisplay(bannerConfig.ctaButtonTwo) && (
                                <ButtonComponent style={ctButtonTwoStyle} onClick={openButtonLink(bannerConfig.ctaButtonTwo.ctaButtonUrl)}>
                                    {bannerConfig.ctaButtonTwo.ctaButtonText}
                                </ButtonComponent>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};
