import React from 'react';
import { AdvancedSelectComponent, AdvancedSubHeaderComponent } from '@vivli/shared/components';
import { amrFieldTitleStyle, inputGroupBreakStyle } from '../../search-filters.styles';
import { FilterGroupComponent } from '../../filter-group.component';
import { useAmrSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';

export const SecondFilterGroupComponent = () => {
    const { amrFilterValues } = useSearchContext();
    const { dataContributorSelectedOptions, setDataContributorSelectedOptions, resistanceSelectedOptions, setResistanceSelectedOptions } =
        useAmrSearchOptionsContext();

    const resistanceOptions = amrFilterValues?.groupingsOfResistancePatterns.map((l) => ({
        value: l.value,
        label: l.value,
    }));
    const sponsorOptions = amrFilterValues?.sponsorName.map((l) => ({
        value: l.value,
        label: l.value,
    }));

    return (
        <FilterGroupComponent>
            <div style={inputGroupBreakStyle}>
                <AdvancedSubHeaderComponent title={'Data Contributor'} style={amrFieldTitleStyle} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setDataContributorSelectedOptions}
                    options={sponsorOptions}
                    selectedOptions={dataContributorSelectedOptions}
                />
            </div>

            <div>
                <AdvancedSubHeaderComponent title={'Resistance Grouping'} style={amrFieldTitleStyle} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setResistanceSelectedOptions}
                    options={resistanceOptions}
                    selectedOptions={resistanceSelectedOptions}
                />
            </div>
        </FilterGroupComponent>
    );
};
