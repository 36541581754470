import React, { CSSProperties } from 'react';
import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { ICellRendererParams } from 'ag-grid-community';
import { DataRequestFormatService } from '@vivli/features/data-requests/infrastructure/service';
import { useToastService } from '@vivli/shared/infrastructure/context';
import { useTranslation } from 'react-i18next';
import { AssignedAppTypeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { CancelButtonComponent } from '../shared/cancel-button.component';
import { DTIDataRequestStatusRenderer } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    color: 'rgb(176, 176, 176)',
    fontSize: '15px',
    flexDirection: 'column',
    minWidth: '440px',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
};

const titleStyle: CSSProperties = {
    color: 'rgb(0, 0, 0)',
    textAlign: 'center',
};

const bodyContainerStyle = (alignRight?: boolean): CSSProperties => ({
    fontSize: '15px',
    display: 'flex',
    justifyContent: alignRight ? 'flex-end' : 'initial',
});

const bodyStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

const removeBtnStyle: CSSProperties = {
    ...Styles.Button.BUTTON_RED_X,
    marginRight: 50,
    justifyContent: 'flex-start',
    paddingLeft: '12px',
    paddingBottom: '3px',
};

interface DataRequestStatusRendererComponentProps extends ICellRendererParams {
    data: IDataRequestSummary;
    hideStatus?: boolean;
    alignRight?: boolean;
    showRemoveButton?: () => void;
}

export const DataRequestStatusRenderer = ({
    data,
    hideStatus,
    alignRight,
    showRemoveButton,
    node,
    api,
}: DataRequestStatusRendererComponentProps) => {
    const toast = useToastService();
    const { t } = useTranslation();
    const duaTranslation = t(TranslationKeyEnum.duaValidatedStatusString);
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const statusMessage = DataRequestFormatService.mapStatusToMessage(DataRequestStatusEnum[data.status], isAmr, duaTranslation);

    const handleRemoveRow = () => {
        toast.success(`${data.requestTitle} successfully withdrawn.`);
        api.applyTransaction({ remove: [data] });
    };

    return (
        <div style={containerStyle} data-test-id={DTIDataRequestStatusRenderer.DataRequestStatus + data.requestTitle}>
            {!hideStatus && <div style={titleStyle}>{statusMessage}</div>}
            <div style={bodyContainerStyle(alignRight)}>
                <div style={bodyStyle}>
                    {showRemoveButton && data.status == DataRequestStatusEnum.Draft && (
                        <div>
                            <CancelButtonComponent dataRequest={data} style={removeBtnStyle} updateDataRequest={handleRemoveRow} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
