﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { StudyDatasetJsonComponent } from '../study-dataset-json.component';
import { Annotator } from './annotator';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useFormContext } from 'react-hook-form';
import { useConfigService } from '@vivli/core/infrastructure/context';

const containerStyle: CSSProperties = {
    flex: 'auto',
};
const annotationContainerStyle: CSSProperties = {
    paddingRight: 350,
    position: 'relative',
    marginBottom: Size.PADDING,
    flex: 'auto',
    height: '30em',
};
const annotationInnerContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    minHeight: 400,
    width: 340,
    alignContent: 'center',
};
const jsonStyle: CSSProperties = {
    width: '100%',
    marginBottom: Size.PADDING,
    padding: 0,
};
const emptyDivStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: Size.PADDING,
    marginTop: Size.PADDING,
};

export const AnnotateStudyAnnotationComponent = () => {
    const { study } = useStudyContext();
    const formApi = useFormContext();
    const modalService = useModalService();
    const configService = useConfigService();
    const showAnnotation = !(
        study.status === StudyStatusEnum.AwaitingCuration || study.status === StudyStatusEnum.AwaitingCurationQaReview
    );
    const [uploadedStudyJSON, setUploadedStudyJSON] = useState<string>('');

    const updatePicoOutput = (json) => {
        const cleanJson = JSON.stringify(json, null, '\t');
        setUploadedStudyJSON(cleanJson);
        formApi.setValue('picoAndStudyDesign', json, { shouldDirty: true });
    };

    useEffect(() => {
        const cleanedStudy: IStudy = JSON.parse(JSON.stringify(study, null, '\t'));
        delete cleanedStudy.studyHistoryEntries;
        const cleanedStudyJson = JSON.stringify(cleanedStudy.picoAndStudyDesign, null, 2);
        setUploadedStudyJSON(cleanedStudyJson);
    }, [study]);

    return (
        <>
            <FieldHeaderComponent title="ANNOTATION" />
            {showAnnotation && (
                <div style={containerStyle}>
                    <div style={annotationContainerStyle}>
                        <div style={{ height: '100%' }}>
                            <iframe
                                src={`https://clinicaltrials.gov/show/${study.nctId}`}
                                width="100%"
                                height="100%"
                                style={{ display: 'block' }}
                            />
                        </div>
                        <div style={annotationInnerContainerStyle}>
                            Click to annotate this study:
                            <Annotator
                                annotation={study.picoAndStudyDesign}
                                updatePicoOutput={updatePicoOutput}
                                modalService={modalService}
                                annotatorWidgetOptions={configService?.annotationWidgetConfig}
                            />
                            <div style={emptyDivStyle}></div>
                        </div>
                    </div>
                    <StudyDatasetJsonComponent json={uploadedStudyJSON} containerStyle={jsonStyle} />
                </div>
            )}
        </>
    );
};
