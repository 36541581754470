import React, { CSSProperties, ReactNode, useState } from 'react';
import { ColDef, GridApi } from 'ag-grid-community';
import { AdminHeaderComponent } from '../header/admin-header.component';
import { AdminHeaderTitleComponent } from '../header/admin-header-title.component';
import { ExportCsvButtonComponent } from '../export-csv-button.component';
import { GridComponent } from './grid.component';

const containerStyle: CSSProperties = {
    padding: '0px 40px 20px 40px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
};

interface GridPageComponentProps {
    title: string;
    exportTitle: string;
    columnDefs: ColDef[];
    rowData: any[];
    onRowClick?: (data: any) => void;
    fullWidthColumns?: boolean;
    asyncUpdateKey?: any;
    dataRefreshing?: boolean;
    onGridReady?: (gridApi: GridApi) => void;
    customHeader?: ReactNode;
    customComponent?: ReactNode;
    flushPrevious?: boolean;
    exportColumnKeys?: string[];
}

export const GridPageComponent = ({
    columnDefs,
    rowData,
    onRowClick,
    title,
    exportTitle,
    fullWidthColumns,
    dataRefreshing,
    onGridReady,
    customHeader,
    customComponent,
    flushPrevious,
    exportColumnKeys,
}: GridPageComponentProps) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);

    const exportToCsv = () => {
        const dateStr = new Date().toLocaleDateString().replace('/', '_');
        const fileName = `${exportTitle}_${dateStr}`;
        gridApi.exportDataAsCsv({ fileName, columnKeys: exportColumnKeys });
    };
    const handleOnGridReady = (gridApi: GridApi) => {
        setGridApi(gridApi);
        onGridReady && onGridReady(gridApi);
    };
    return (
        <div style={containerStyle}>
            <AdminHeaderComponent style={{ margin: '20px 0 0 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AdminHeaderTitleComponent title={title} />
                </div>
                {customHeader && <div style={{ boxShadow: 'none', marginRight: '50px', marginLeft: '50px' }}>{customHeader}</div>}

                <ExportCsvButtonComponent onClick={exportToCsv} style={{ right: '10px', height: 'initial' }} />
            </AdminHeaderComponent>

            {customComponent && <div>{customComponent}</div>}
            <GridComponent
                rowData={rowData}
                columns={columnDefs}
                onRowClick={onRowClick}
                onGridReady={handleOnGridReady}
                fullWidthColumns={fullWidthColumns}
                style={{ height: '90%', marginTop: '35px' }}
                dataRefreshing={dataRefreshing}
                flushPrevious={flushPrevious}
            />
        </div>
    );
};
