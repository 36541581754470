import React from 'react';
import { VmFailureComponent } from '../vm-failure.component';
import { BlockMessageComponent } from '../block-message.component';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';

export const VmProvisioningFailedViewComponent = () => {
    const { retryProvisionVm } = useResearchEnvironmentContext();

    return (
        <BlockMessageComponent message={'Failed to Provision Research Environment'}>
            <VmFailureComponent title={'Retry Provision of Research Environment'} onClick={retryProvisionVm} />
        </BlockMessageComponent>
    );
};
