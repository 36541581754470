﻿import React from 'react';
import { IStudy, IStudyDesign } from '@vivli/features/studies/infrastructure/interface';
import { AssignmentToInterventionEnum, InterventionModelEnum } from '@vivli/features/studies/infrastructure/enum';
import { Styles } from '@vivli/shared/theme';

const getBlindedList = (studyDesign: IStudyDesign) => {
    let blinded = '';
    if (studyDesign.careProviderMaskedOrBlinded) {
        blinded += 'Care Provider, ';
    }
    if (studyDesign.investigatorMaskedOrBlinded) {
        blinded += 'Investigator, ';
    }
    if (studyDesign.outcomeAssessorMaskedOrBlinded) {
        blinded += 'Outcome Assessor, ';
    }
    if (studyDesign.subjectMaskedOrBlinded) {
        blinded += 'Subject, ';
    }
    return blinded.slice(0, -2);
};

interface StudyDesignGridComponentProps {
    study: IStudy;
    dataId: any;
}

export const StudyDesignGridComponent = ({ study, dataId }: StudyDesignGridComponentProps) => {
    const interventionText = study?.studyDesign?.interventionModel ? InterventionModelEnum[study.studyDesign.interventionModel] : '';
    const assignmentToInterventionTest = study?.studyDesign?.assignmentToIntervention
        ? AssignmentToInterventionEnum[study.studyDesign.assignmentToIntervention]
        : '';

    return (
        <div data-test-id={dataId}>
            <table style={Styles.FULL_WIDTH_TABLE}>
                <tbody>
                    <tr>
                        <th style={Styles.TABLE_HEADER}>{'Study Type'}</th>
                        <th style={Styles.TABLE_HEADER}>{'Intervention Model'}</th>
                        <th style={Styles.TABLE_HEADER}>{'Assignment to Intervention'}</th>
                        <th style={Styles.TABLE_HEADER}>{'Blinding'}</th>
                    </tr>
                    <tr>
                        <td>{study.studyType ?? ''}</td>
                        <td>{interventionText}</td>
                        <td>{assignmentToInterventionTest}</td>
                        <td>{getBlindedList(study.studyDesign)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
