﻿import { IUser } from '@vivli/shared/infrastructure/interface';
import { getAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import i18next from 'i18next';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export function useUserRouteMapperHook() {
    const { isDatasetOwner, isOrgAdmin, isDataProvider, isAnnotator, isQaReviewer, isIRPApprover } = useUserPermissions();

    const order = [
        'Dashboard',
        'Data Requests',
        'Enquiries',
        'Studies',
        'Awaiting Upload',
        'Report',
        'Research Environments',
        'My Organizations',
        'My Organization',
        'Users',
        'Submissions',
        'Admin Console',
    ];
    const getUserRoutes = (
        user: IUser
    ): Array<{
        name?: string;
        title?: string;
        icon?: { active: string; inactive: string };
        url?: string;
        defaultTab?: string;
    }> => {
        if (!user) {
            return [];
        }
        const orgs = user?.orgMemberships?.filter((om) => om.isOrgAdmin);

        let orgId = null;
        if (orgs && orgs.length !== 0) {
            [{ orgId }] = orgs;
        }

        const links: Array<{
            name?: string;
            title?: string;
            icon?: { active: string; inactive: string };
            url?: string;
            defaultTab?: string;
        }> = [
            {
                title: 'Dashboard',
                icon: {
                    active: AssetsConstant.DASHBOARD_ICON_ACTIVE,
                    inactive: AssetsConstant.DASHBOARD_ICON_INACTIVE,
                },
                url: 'dashboard',
                defaultTab: '',
            },
            {}, // empty placeholder key for admin portal side nav panels
        ];

        const isVivliAdmin = user?.isVivliAdmin;
        const isOpsAdmin = user?.isOperationsAdmin;
        const assignedAppType = getAssignedAppType();
        const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

        if (user.orgMemberships) {
            if (!isAmr) {
                links.splice(1, 0, {
                    title: 'Submissions',
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: `study-submissions`,
                    defaultTab: 'draft',
                });
            }

            if (isDataProvider || isIRPApprover) {
                links.splice(1, 0, {
                    title: 'Data Requests',
                    icon: {
                        active: AssetsConstant.REQUESTS_ICON_ACTIVE,
                        inactive: AssetsConstant.REQUESTS_ICON_INACTIVE,
                    },
                    url: 'requests',
                    defaultTab: 'AwaitingMyAction',
                });
                if (!isAmr) {
                    links.splice(links.length - 1, 0, {
                        title: 'Awaiting Upload',
                        icon: {
                            active: AssetsConstant.DATA_REQUESTS_ICON_ACTIVE,
                            inactive: AssetsConstant.DATA_REQUESTS_ICON_INACTIVE,
                        },
                        url: 'awaitingUpload',
                        defaultTab: '',
                    });
                }
            }
            if (isAnnotator || isQaReviewer || isDatasetOwner) {
                links.splice(1, 0, {
                    title: i18next.t('studies'),
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: 'studies',
                    defaultTab: '',
                });
            }
            if (isOrgAdmin || isVivliAdmin) {
                links.splice(1, 0, {
                    title:
                        !isVivliAdmin && !(user.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1)
                            ? 'My Organization'
                            : 'My Organizations',
                    icon: {
                        active: AssetsConstant.OFFICEBUILDING_ICON_ACTIVE,
                        inactive: AssetsConstant.OFFICEBUILDING_ICON_INACTIVE,
                    },
                    url: !isVivliAdmin && !(user.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1) ? `orgs/${orgId}` : 'orgs',
                    defaultTab: '',
                });
                links.splice(1, 0, {
                    title: 'Report',
                    icon: {
                        active: AssetsConstant.TRIALS_ICON_ACTIVE,
                        inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                    },
                    url: `report`,
                    defaultTab: '',
                });
            }
            if (isVivliAdmin || isOrgAdmin) {
                links.splice(1, 0, {
                    title: 'Users',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `users`,
                    defaultTab: '',
                });
            }

            if ((isVivliAdmin || (isOrgAdmin && isDataProvider)) && !isAmr) {
                links.splice(1, 0, {
                    title: 'Research Environments',
                    icon: {
                        active: AssetsConstant.VM_ICON_ACTIVE,
                        inactive: AssetsConstant.VM_ICON_INACTIVE,
                    },
                    url: `vms`,
                    defaultTab: '',
                });
            }
        } else {
            if (isVivliAdmin || isOpsAdmin) {
                links.splice(1, 0, {
                    title:
                        !isVivliAdmin && !(user.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1)
                            ? 'My Organization'
                            : 'My Organizations',
                    icon: {
                        active: AssetsConstant.OFFICEBUILDING_ICON_ACTIVE,
                        inactive: AssetsConstant.OFFICEBUILDING_ICON_INACTIVE,
                    },
                    url: !isVivliAdmin && !(user.orgMemberships?.filter((om) => om.isOrgAdmin).length > 1) ? `orgs/${orgId}` : 'orgs',
                    defaultTab: '',
                });
                links.splice(1, 0, {
                    title: 'Users',
                    icon: {
                        active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                        inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                    },
                    url: `users`,
                    defaultTab: '',
                });
                if (!isAmr) {
                    links.splice(1, 0, {
                        title: 'Research Environments',
                        icon: {
                            active: AssetsConstant.NAV_VM_ICON,
                            inactive: AssetsConstant.NAV_VM_ICON,
                        },
                        url: `vms`,
                        defaultTab: '',
                    });
                }
            }
        }

        if (!isAmr) {
            links.splice(links.length - 1, 0, {
                title: 'Enquiries',
                icon: {
                    active: AssetsConstant.TRIALS_ICON_ACTIVE,
                    inactive: AssetsConstant.TRIALS_ICON_INACTIVE,
                },
                url: 'enquiries',
                defaultTab: '',
            });
        }

        if (isOpsAdmin) {
            links.splice(links.length - 1, 0, {
                title: 'Admin Console',
                icon: {
                    active: AssetsConstant.ORGANIZATIONS_ICON_ACTIVE,
                    inactive: AssetsConstant.ORGANIZATIONS_ICON_INACTIVE,
                },
                url: `adminConsole/templateStudy`,
                defaultTab: '',
            });
        }

        links.sort((a, b) => {
            if (!a.title) return 1;
            if (!b.title) return -1;

            const indexA = order.indexOf(a.title);
            const indexB = order.indexOf(b.title);
            return indexA - indexB;
        });

        return links;
    };

    return {
        getUserRoutes,
    };
}
