export enum FormFieldTypeEnum {
    Text,
    TextArea,
    DropDown,
    Checkbox,
    CheckboxList,
    Date,
    RadioGroup,
    YesNoRadio,
    Radio,
}
