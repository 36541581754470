import { IMsal2AuthService, Msal2EventTypeEnum } from '../interface';
import { useEffect } from 'react';
import { StorageService } from '@vivli/shared/infrastructure/service';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';

export const useLogoutOnClose = (authService: IMsal2AuthService, enabled = true) => {
    const lastActiveBrowserKey = 'session_keep_alive';

    const updateLastActive = () => {
        StorageService.setItem(lastActiveBrowserKey, moment().toISOString(true));
    };
    const checkIfLogoutNeeded = () => {
        const account = authService.getAccount();
        if (!account) {
            return;
        }

        // aka if all tabs were closed and this is a new tab, see if we need to start a new session / log the current user out
        const lastActiveBrowserDate = StorageService.getItem<string>(lastActiveBrowserKey);
        if (!lastActiveBrowserDate) {
            authService.logout();
        } else {
            const currentDate = moment();
            const lastActivityDate = moment(lastActiveBrowserDate);
            const timeSinceLastActivity = currentDate.diff(lastActivityDate, 'seconds');

            if (timeSinceLastActivity > 30) {
                authService.logout();
            }
        }
    };

    const initializeBrowserActivityTimer = (): Subscription => {
        updateLastActive();
        return interval(5000).subscribe(updateLastActive);
    };

    useEffect(() => {
        if (!authService || !enabled) {
            return;
        }

        checkIfLogoutNeeded();
        let activityTimerSub = null;

        const eventsSub = authService.authEvents.subscribe((event) => {
            switch (event.type) {
                case Msal2EventTypeEnum.LoginEvent:
                case Msal2EventTypeEnum.SilentLoginEvent:
                    activityTimerSub?.unsubscribe();
                    activityTimerSub = initializeBrowserActivityTimer();
                    break;
                case Msal2EventTypeEnum.LogoutEvent:
                    activityTimerSub?.unsubscribe();
                    break;
            }
        });

        if (authService.getAccount()) {
            activityTimerSub?.unsubscribe();
            activityTimerSub = initializeBrowserActivityTimer();
        }

        return () => {
            eventsSub.unsubscribe();
            activityTimerSub?.unsubscribe();
        };
    }, [authService]);
};
