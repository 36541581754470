﻿import React from 'react';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { AmrDataRequestAdminTabsComponent } from './amr-data-request-admin-tabs.component';
import { AmrDataRequestRequesterTabsComponent } from './amr-data-request-requester-tabs.component';
import { AmrDataRequestDcTabsComponent } from './amr-data-request-dc-tabs.component';

export const AmrDataRequestTabsContainerComponent = () => {
    const activeUser = useActiveUser();
    const { dataRequest, organizations } = useDataRequestContext();
    const { userIsContributorOrIRPApprover } = useDataRequestPermissions();
    const isContributorOrIRPApprover = userIsContributorOrIRPApprover(dataRequest, organizations);

    if (activeUser.isVivliAdmin) {
        return <AmrDataRequestAdminTabsComponent />;
    }

    if (isContributorOrIRPApprover) {
        return <AmrDataRequestDcTabsComponent />;
    }

    return <AmrDataRequestRequesterTabsComponent />;
};
