import React, { CSSProperties, useState } from 'react';
import { AdminHeaderButtonComponent } from '@vivli/shared/components';
import { Color } from '@vivli/shared/theme';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { finalize, first } from 'rxjs/operators';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { badgeStyle } from './badge.style';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DTIDuaValidationButton } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 40,
};

const approvedTextStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_GREEN,
    color: Color.WHITE,
    ...badgeStyle,
};

export const DuaValidationButtonComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { dataRequest, updateDataRequest, setIsUpdatingStatus, isUpdatingStatus } = useDataRequestContext();

    const { setDataRequestStatus } = useDataRequestsService();
    const { isVivliAdmin } = useActiveUser();
    const modalService = useModalService();

    const updateLoadingStatus = (status: boolean) => {
        setIsUpdatingStatus(status);
        setIsLoading(status);
    };

    const handleDuaValidationClick = () => {
        updateLoadingStatus(true);
        setDataRequestStatus(dataRequest.id, DataRequestStatusEnum.AwaitingDUAValidation, ' Begin DUA Validation')
            .pipe(
                first(),
                finalize(() => updateLoadingStatus(false))
            )
            .subscribe(updateDataRequest, modalService.error);
    };

    return (
        <div style={containerStyle}>
            <span style={approvedTextStyle}> Approved</span>
            {isVivliAdmin && (
                <AdminHeaderButtonComponent
                    style={{ marginLeft: 10 }}
                    title={'Begin DUA Validation'}
                    isLoading={isLoading}
                    isDisabled={isUpdatingStatus}
                    onClick={handleDuaValidationClick}
                    dataId={DTIDuaValidationButton.DataRequestBeginDuaValidationButton}
                />
            )}
        </div>
    );
};
