﻿import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ICurationStatusDetails, IStudy, IStudyResearchTeamMember } from '@vivli/features/studies/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { StudyContext, useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useAdminStudyStatusChange } from '@vivli/features/studies/infrastructure/hook';
import { useTranslation } from 'react-i18next';
import { first } from 'rxjs/operators';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { UseFormReturn } from 'react-hook-form';
import { Subject } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { checkResearchTeamForDuplicates } from '@vivli/shared/infrastructure/hook';

interface StudyContextWrapperProps {
    children: ReactNode;
    study: IStudy;
    setStudy: React.Dispatch<React.SetStateAction<IStudy>>;
    usedIn: string;
    studyIpdPackageSubmitted: boolean;
}

export const StudyContextWrapper = ({ children, study, setStudy, usedIn, studyIpdPackageSubmitted }: StudyContextWrapperProps) => {
    const user = useActiveUser();
    const assignedAppType = useAssignedAppType();
    const navigate = useNavigate();
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [isSavingStudy, setIsSavingStudy] = useState(false);
    const [activeStudy, setActiveStudy] = useState<IStudy>(study);
    //next 2 lines - state variables for refreshing siblings data package components on the admin study page
    const [documentsRefresh, setDocumentsRefresh] = useState<number>(0);
    const [ipdRefresh, setIpdRefresh] = useState<number>(0);

    const studiesService = useStudiesService();
    const toastService = useToastService();
    const modalService = useModalService();
    const studyStatusChange = useAdminStudyStatusChange(activeStudy);

    const isSavingRef = useRef(false);
    const { t } = useTranslation();

    const toCapitalize = (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    };

    const studyTranslation = () => {
        return toCapitalize(t('study'));
    };

    const confirmNewIpdTitle = `Prepare for new stored data version for this ${studyTranslation()}?`;
    const confirmNewIpdMessage = `The original IPD Data Package version will no longer be available for automatic copy to data requests; the Data Contributor for the ${studyTranslation()} will have to upload a new active Data Package version for this ${studyTranslation()} if requested. Click OK to proceed when you are ready, or Cancel if you decide not to proceed.`;

    const confirmDeleteIpdTitle = `Remove Data Package stored for ${studyTranslation()}?`;
    const confirmDeleteIpdMessage = `The Data Package version will no longer be available for automatic copy to data requests; the Data Contributor for the ${studyTranslation()} will have to upload a new active Data Package version for this ${studyTranslation()} if requested. Click OK to proceed when you are ready, or Cancel if you decide not to proceed.`;

    const removePostingTitle = `Withdraw Posted ${studyTranslation()}?`;
    const removePostingMessage = `The withdrawn ${studyTranslation()} will no longer be available for search; however, you will be able to see it in the Cancelled tab. Click OK to proceed when you are ready, or Cancel if you decide not to proceed.`;

    const returnForRecurationTitle = 'Confirm Re-Curation?';
    const returnForRecurationMessage = `This study is used in the following data requests: ${usedIn}. If you want to notify the requestors, please copy the information. Click OK to proceed when you are ready, or Cancel if you decide not to proceed.`;

    const rejectedNewStudyTitle = 'Reason for Rejection';
    const rejectedNewStudyMessage = 'Please explain your reason for rejecting this study';

    const approveForCurationTitle = 'Process Study Comment';
    const approveForCurationMessage = 'Please provide comments if you like, then click OK';

    const handleSuccess = (study: IStudy) => {
        setActiveStudy(study);
        updateIsSaving(false);
        toastService.success('Edits saved successfully');
        setIsSubmittingForm(false);
    };
    const handleFailure = (err: string) => {
        updateIsSaving(false);
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support. Message: ${err}`);
        setIsSubmittingForm(false);
    };
    const handleAwaitingCurationSuccess = (study: IStudy) => {
        setActiveStudy(study);
        updateIsSaving(false);
        toastService.success('Submitted For Curation');
    };
    const handlePostedSuccess = (study: IStudy) => {
        setActiveStudy(study);
        updateIsSaving(false);
        toastService.success('Posted to Vivli');
    };
    const handlePrepareNewIpdSuccess = () => {
        updateIsSaving(false);

        studiesService
            .getStudy(activeStudy.id)
            .pipe(first())
            .subscribe((value) => {
                setStudy(value);
            });

        toastService.success('Successfully prepared for new data package version');
    };
    const handlePrepareNewIpdFailure = (err: string) => {
        updateIsSaving(false);
        modalService.error(`An error preparing for new data package version. Please try again or contact Vivli support. Message: ${err}`);
    };
    const handleDeleteIpdSuccess = () => {
        updateIsSaving(false);
        toastService.success(`The stored Data Package for this ${studyTranslation()} was removed.`);
    };
    const handleDeleteIpdFailure = (err: string) => {
        updateIsSaving(false);
        modalService.error(`An error occurred deleting the data package. Please try again or contact Vivli support. Message: ${err}`);
    };
    const handleRemovePostingSuccess = (study: IStudy) => {
        setActiveStudy(study);
        updateIsSaving(false);
        toastService.success(`The posted ${studyTranslation()} was withdrawn and now appears in the Cancelled tab.`);
    };
    const handleCreateDoiSuccess = () => {
        updateIsSaving(false);
        toastService.success(`The DOI for this data package has been created.`);
    };

    // for study/dataset owner or Vivli Admin to get ready to replace current Ipd version,
    // this is a "soft delete" - the original version itself remains in blob storage
    const handleSetupForNewIpdVersion = () => {
        updateIsSaving(true);
        modalService.confirm(confirmNewIpdMessage, {
            title: confirmNewIpdTitle,
            showTextAreaPrompt: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: () => {
                studiesService
                    .softDeleteStudyIPD(activeStudy.id, activeStudy.ipdDataPackageId)
                    .pipe(first())
                    .subscribe(handlePrepareNewIpdSuccess, handlePrepareNewIpdFailure);
            },
            onCancel: () => {
                updateIsSaving(false);
            },
        });
    };

    // for study/dataset owner to create DOI for new Ipd version
    // (for Vivli admin save does this automatically)
    const handleCreateDoiForIpdPackage = () => {
        updateIsSaving(true);
        studiesService
            .getDoiForIpdDataPackage(activeStudy.id, activeStudy.ipdDataPackageId)
            .pipe(first())
            .subscribe(handleCreateDoiSuccess, handleFailure);
    };

    // for Vivli admin to completely delete IPD and associated
    // blob storage
    const handleDeleteIpdPackage = () => {
        updateIsSaving(true);
        modalService.confirm(confirmDeleteIpdMessage, {
            title: confirmDeleteIpdTitle,
            showTextAreaPrompt: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: () => {
                studiesService
                    .deleteStudyIPD(activeStudy.id, activeStudy.ipdDataPackageId)
                    .pipe(first())
                    .subscribe(handleDeleteIpdSuccess, handleDeleteIpdFailure);
            },
        });
    };

    const handleDeleteStudy = () => {
        setIsSavingStudy(true);
        studiesService
            .deleteStudy(activeStudy.id)
            .pipe(first())
            .subscribe(() => {
                setIsSavingStudy(false);
                toastService.success('Study Withdrawn Successfully');
                modalService.message('The submission has been withdrawn. You will now be brought to the list of draft studies.', {
                    showBackground: false,
                    showLogo: true,
                    showContinueButton: true,
                });
                navigate('/admin/studies/Draft');
            }, handleFailure);
    };

    // for vivli admin to change status from Posted to cancelled
    const handleRemovePosting = () => {
        updateIsSaving(true);
        modalService.confirm(removePostingMessage, {
            title: removePostingTitle,
            showTextAreaPrompt: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: () => {
                studiesService
                    .setStudyStatus(activeStudy, StudyStatusEnum.Cancelled, 'Cancelling Study')
                    .pipe(first())
                    .subscribe(handleRemovePostingSuccess, handleFailure);
            },
        });
    };

    // approve means "move forward to a new status"
    const handleApproveStudy = () => {
        const statusToChangeTo = studyStatusChange.statusChangeForApprovals();
        updateIsSaving(true);
        if (statusToChangeTo === StudyStatusEnum.AwaitingCuration) {
            modalService.confirm(approveForCurationMessage, {
                title: approveForCurationTitle,
                showTextAreaPrompt: true,
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: () => {
                    studiesService
                        .setStudyStatus(activeStudy, StudyStatusEnum.AwaitingCuration, 'Returning Study to Awaiting Curation Status')
                        .pipe(first())
                        .subscribe(handleAwaitingCurationSuccess, handleFailure);
                },
            });
        } else {
            //posted is only other case
            studiesService
                .setStudyStatus(activeStudy, statusToChangeTo, 'Approving Status Change')
                .pipe(first())
                .subscribe(handlePostedSuccess, handleFailure);
        }
    };

    //reject means "go back to a previous status"
    const handleRejectStudy = () => {
        const statusToChangeTo = studyStatusChange.statusChangeForRejections();
        updateIsSaving(true);
        if (statusToChangeTo === StudyStatusEnum.SubmittedToVivliForPreCheck) {
            modalService.confirm(returnForRecurationMessage, {
                title: returnForRecurationTitle,
                showTextAreaPrompt: true,
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: () => {
                    studiesService
                        .setStudyStatus(
                            activeStudy,
                            StudyStatusEnum.SubmittedToVivliForPreCheck,
                            'Returning Study to Awaiting Curation Status'
                        )
                        .pipe(first())
                        .subscribe(handleSuccess, handleFailure);
                },
            });
        } else if (statusToChangeTo === StudyStatusEnum.Cancelled) {
            modalService.confirm(rejectedNewStudyMessage, {
                title: rejectedNewStudyTitle,
                showTextAreaPrompt: true,
                confirmText: 'Ok',
                cancelText: 'Cancel',
                onConfirm: () => {
                    studiesService
                        .setStudyStatus(activeStudy, StudyStatusEnum.Cancelled, 'Cancelling study')
                        .pipe(first())
                        .subscribe(handleSuccess, handleFailure);
                },
            });
        } else {
            studiesService
                .setStudyStatus(activeStudy, statusToChangeTo, 'Rejecting Status Change')
                .pipe(first())
                .subscribe(handleSuccess, handleFailure);
        }
    };

    const handleUpdateAndStatusChange = (
        formApi: UseFormReturn<IStudy, object>,
        statusDetails: ICurationStatusDetails,
        finalStep?: () => void
    ) => {
        handleFormSave(formApi)
            .pipe(first())
            .subscribe(() => {
                updateIsSaving(true);
                handleStatusChange(statusDetails, finalStep).pipe(first()).subscribe(handleSuccess, handleFailure);
            }, handleFailure);
    };

    const handleStatusChange = (statusDetails: ICurationStatusDetails, finalStep?: () => void) => {
        const subject = new Subject<IStudy>();
        if (statusDetails.hasPrompt) {
            modalService.confirm(statusDetails.promptText, {
                title: statusDetails.promptTitle,
                showTextAreaPrompt: statusDetails.hasPrompt,
                requireComment: statusDetails.requiresComment,
                confirmText: 'Ok',
                cancelText: 'Cancel',
                style: { minWidth: '45em', textAlign: 'left' },
                messageStyle: { textAlign: 'left' },
                onConfirm: ({ comment }) => {
                    if (statusDetails.nextStatus) {
                        updateIsSaving(true);
                        studiesService
                            .setStudyStatus(activeStudy, statusDetails.nextStatus, comment)
                            .pipe(first())
                            .subscribe((study) => {
                                subject.next(study);
                                handleSuccess(study);
                                if (finalStep) {
                                    finalStep();
                                }
                            }, handleFailure);
                    }
                },
            });
        } else {
            if (statusDetails.nextStatus) {
                updateIsSaving(true);
                studiesService
                    .setStudyStatus(activeStudy, statusDetails.nextStatus, statusDetails.comment)
                    .pipe(first())
                    .subscribe((study) => {
                        subject.next(study);
                        handleSuccess(study);
                        if (finalStep) {
                            finalStep();
                        }
                    }, handleFailure);
            }
        }
        return subject;
    };

    const handleApproveCurationSubmit = () => {
        modalService.confirm('Please provide any context to save with the history of this study.', {
            title: 'Submission Comment',
            showTextAreaPrompt: true,
            requireComment: true,
            confirmText: 'Ok',
            cancelText: 'Cancel',
            style: { minWidth: '45em', textAlign: 'left' },
            messageStyle: { textAlign: 'left' },
            onConfirm: ({ comment }) => {
                updateIsSaving(true);
                studiesService
                    .acceptJSONFromCochrane(activeStudy, comment)
                    .pipe(first())
                    .subscribe(() => {
                        setActiveStudy(study);
                        updateIsSaving(false);
                        setIsSubmittingForm(false);

                        modalService.message('Submission Complete. You will now be taken back to view active study submissions.', {
                            showBackground: false,
                            showLogo: true,
                            showContinueButton: true,
                        });
                        navigate('/admin/studies/InProgress');
                    }, handleFailure);
            },
        });
    };

    const handleFormSave = (formApi: UseFormReturn<IStudy, object>, submitStudy = false) => {
        // already in the process of a form save, don't do any additional saves (yet)
        // also ignore if no fields have changed
        if (isSavingRef.current) {
            return;
        }
        const subject = new Subject<IStudy>();
        formApi.handleSubmit(
            (formValues) => handleFormApiSave(formApi, formValues, submitStudy, subject),
            () => {
                const formValues = formApi.getValues();
                handleFormApiSave(formApi, formValues, submitStudy, subject);
            }
        )();
        return subject;
    };

    const updateIsSaving = (status: boolean) => {
        isSavingRef.current = status;
        setIsSavingStudy(status);
    };

    function checkResearchTeamForDuplicatesLocal(teamMembers: IStudyResearchTeamMember[]) {
        var message = checkResearchTeamForDuplicates(teamMembers);
        if (message != '') {
            modalService.error(message);
            return false;
        }
        return true;
    }

    const handleFormApiSave = (
        formApi: UseFormReturn<IStudy, object>,
        formValues: IStudy,
        submitStudy: boolean,
        subject: Subject<IStudy>
    ) => {
        const updatedStudy: IStudy = { ...formValues };
        const isDirty: boolean = formApi.formState.isDirty;
        //this special case below is to deal with problems in isDirty when removing the last funding org
        //TODO when updating to react-hook-form 7.48.2 or latest, try to remove this special case.
        const isFundingOrgsLastItem = updatedStudy.fundingOrganizations?.length == 0 && !isDirty;
        if (isDirty || isFundingOrgsLastItem) {
            // only do another study update if needed (data has changed)
            updateIsSaving(true);
            if (updatedStudy.researchTeam && !checkResearchTeamForDuplicatesLocal(updatedStudy.researchTeam)) {
                updateIsSaving(false);
                return;
            }
            studiesService
                .updateStudy(updatedStudy)
                .pipe(first())
                .subscribe((study) => {
                    subject.next(study);
                    handleSuccess(study);
                    formApi.reset(study);
                    formApi.trigger();
                }, handleFailure);
        } else {
            subject.next(activeStudy);
        }
    };

    useEffect(() => {
        setActiveStudy(study);
    }, [study]);

    const provider = {
        study: activeStudy,
        user: user,
        usedIn: usedIn,
        studyIpdPackageSubmitted: studyIpdPackageSubmitted,
        assignedAppType: assignedAppType,
        isSavingStudy,
        setActiveStudy,
        handleFormSave,
        handleSetupForNewIpdVersion,
        handleCreateDoiForIpdPackage,
        handleDeleteIpdPackage,
        handleApproveStudy,
        handleRejectStudy,
        handleApproveCurationSubmit,
        handleRemovePosting,
        handleUpdateAndStatusChange,
        handleDeleteStudy,
        documentsRefresh,
        setDocumentsRefresh,
        ipdRefresh,
        setIpdRefresh,
    };

    return <StudyContext.Provider value={provider}>{children}</StudyContext.Provider>;
};
