﻿import React, { CSSProperties, useEffect } from 'react';
import { ResearchMemberRowComponent } from './research-member-row.component';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ButtonComponent } from '../buttons/button.component';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { OrcidApiService } from '@vivli/features/studies/infrastructure/service';
import { first } from 'rxjs/operators';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';

const teamGridRowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '20px',
    marginTop: 10,
};
const rowStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 10,
    marginTop: 30,
};
const nextButtonStyle: CSSProperties = {
    width: '25%',
    marginRight: 20,
};
const addButtonStyle: CSSProperties = {
    ...nextButtonStyle,
    marginRight: 20,
};

interface StudyResearchTeamMemberRowsComponentProps {
    arrayName: string;
    isReadOnly?: boolean;
    showNextButton?: boolean;
    handleNext?: () => void;
    datasetId?: string;
}

export const StudyResearchTeamMemberRowsComponent = ({
    arrayName,
    isReadOnly,
    showNextButton,
    handleNext,
    datasetId,
}: StudyResearchTeamMemberRowsComponentProps) => {
    const formApi = useFormContext();
    const { setValue } = useFormContext();
    const api = new OrcidApiService(useDefaultApiOptions());
    const modalService = useModalService();
    const toastService = useToastService();
    const { fields, append, remove } = useFieldArray({
        name: arrayName,
    });

    //see if the team member accepted their ORCID credits
    const isOrcidUpdated = (rowIndex): boolean => {
        return formApi.getValues(`${arrayName}[${rowIndex}].orcidAuthCode`) && formApi.getValues(`${arrayName}[${rowIndex}].orcidPutCode`);
    };

    const handleRemoveRow = (rowIndex) => {
        if (!isReadOnly) {
            remove(rowIndex);
        }
    };
    const handleAddRow = () => {
        const defaultNewTeamMember = {
            emailAddress: '',
            givenName: '',
            familyName: '',
            orcidId: '',
            creditRoles: [],
        };

        append(defaultNewTeamMember);
    };

    const handleSend = () => {
        api.sendOrcidInvites(datasetId)
            .pipe(first())
            .subscribe((data) => {
                if (!data) {
                    toastService.success('Invitations sent for all team members who have not yet accepted their ORCID credits.');
                } else {
                    toastService.success(
                        'Invitations will be sent when the dataset is posted to Vivli; for all team members who have not yet accepted their ORCID credits.'
                    );
                }
            }, handleError);
    };

    const handleError = (error) => {
        modalService.error(error);
    };

    useEffect(() => {
        //This is here to re-validate form on research member row removal/addition
        formApi.trigger();
    }, [fields]);

    return (
        <>
            <div style={teamGridRowStyle}>
                {fields.map((field, index) => (
                    <ResearchMemberRowComponent
                        key={field.id}
                        index={index}
                        isReadOnly={isReadOnly}
                        isOrcidUpdated={isOrcidUpdated(index)}
                        arrayName={arrayName}
                        handleRemoveRow={handleRemoveRow}
                    />
                ))}
            </div>

            <div style={rowStyle}>
                <ButtonComponent style={addButtonStyle} onClick={handleAddRow} disabled={isReadOnly}>
                    Add Team Member
                </ButtonComponent>
                {showNextButton && (
                    <ButtonComponent style={nextButtonStyle} onClick={handleNext}>
                        Next Page
                    </ButtonComponent>
                )}
                {datasetId && (
                    <ButtonComponent style={nextButtonStyle} onClick={handleSend}>
                        Send Invitations to Team Members
                    </ButtonComponent>
                )}
            </div>

            <div style={{ marginTop: '20px' }}>
                Please note: This submission is for SHARING data on Vivli. If you are interested in requesting data on Vivli please submit a
                Data Request, see this <a href="https://vivli.org/resources/requestdata/">link</a> for guidance.
            </div>
        </>
    );
};
