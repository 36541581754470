import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { FundersRorContainerComponent } from './funders-ror-container.component';

interface LrFundersRorContainerComponentProps {
    study: IStudy;
    onFunderChange?: (study: IStudy) => void;
    handleRemoveRow?: (fundingOrganization: IFundingOrganization) => void;
    canEdit?: boolean;
}

export const LrFundersRorContainerComponent = ({ study, onFunderChange, canEdit }: LrFundersRorContainerComponentProps) => {
    const handleFundingOrgDelete = (study) => {
        handleFunderChange(study);
    };

    const handleFunderChange = (fundingOrganizations: IFundingOrganization[]) => {
        onFunderChange({ ...study, fundingOrganizations });
    };

    return (
        <div style={{ width: '100%' }}>
            {
                <FundersRorContainerComponent
                    fundingOrganizations={study?.fundingOrganizations}
                    handleFunderChange={handleFunderChange}
                    isListingRequest={true}
                    canEdit={canEdit}
                    handleFundingOrgDelete={handleFundingOrgDelete}
                />
            }
        </div>
    );
};
