import React from 'react';
import { VmFailureComponent } from '../vm-failure.component';
import { BlockMessageComponent } from '../block-message.component';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';

export const VmDeprovisioningFailedViewComponent = () => {
    const { deProvisionVm } = useResearchEnvironmentContext();

    return (
        <BlockMessageComponent message={'Failed to Deprovision Research Environment'}>
            <VmFailureComponent title={'Retry Deprovision of Research Environment'} onClick={deProvisionVm} isRedButton={true} />
        </BlockMessageComponent>
    );
};
