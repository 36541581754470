﻿import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import { IDropdownMenuItem, IFormField } from '@vivli/shared/infrastructure/interface';
import { EllipsisTextComponent } from '../ellipsis-text.component';
import { Color, Size } from '@vivli/shared/theme';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import mergeRefs from 'react-merge-refs';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { HierarchicalDropdownMenuComponent } from '../hierarchical-dropdown-menu/hierarchical-dropdown-menu.component';

const valueStyle = (readOnly?: boolean): CSSProperties => ({
    cursor: readOnly ? 'default' : 'pointer',
    display: 'flex',
    padding: '4px',
    paddingLeft: '15px',
    paddingRight: '60px',
    justifyContent: 'space-between',
    position: 'relative',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    fontWeight: 'bold',
});

const downArrowStyle: CSSProperties = {
    position: 'absolute',
    right: '25px',
    bottom: '50%',
};

interface HierarchicalDropdownFieldComponentProps extends IFormField {
    items: IDropdownMenuItem[];
    selectText?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onChange?: (value: unknown) => void;
    onReady?: () => void;
    sort?: 'asc' | 'desc';
    objectKey?: string;
}

export const HierarchicalDropdownFieldComponent = forwardRef((props: HierarchicalDropdownFieldComponentProps, ref) => {
    const dropdownRef = useRef<HTMLDivElement>();
    const [activeItems, setActiveItems] = useState<IDropdownMenuItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<IDropdownMenuItem>(null);
    const {
        items,
        defaultValue,
        value,
        onChange,
        readonly,
        onBlur,
        disabled = false,
        sort,
        selectText = '- Select an Option -',
        label,
        objectKey,
        dataId,
        name,
    } = props;

    const disabledStyle: any = {
        cursor: disabled ? 'default' : 'pointer',
        pointerEvents: disabled ? 'none' : null,
        opacity: disabled ? Size.HOVER_OPACITY : 1,
    };
    const isObject = typeof value === 'object' || typeof defaultValue === 'object';
    const isControlled = value !== undefined;

    const setActiveItem = (dropdownMenuItem: IDropdownMenuItem) => {
        const resultItem = getItem(dropdownMenuItem.value);

        if (resultItem) {
            setSelectedItem(resultItem);
        }
    };

    const handleOnChange = (dropdownMenuItem: IDropdownMenuItem) => {
        onChange && onChange(dropdownMenuItem.value);
        setActiveItem(dropdownMenuItem);
    };

    const getItem = (comparisonValue) => {
        if (isObject && comparisonValue) {
            return activeItems?.find((i) => i.value[objectKey] === comparisonValue[objectKey]);
        }
        return activeItems?.find((i) => i.value === comparisonValue);
    };

    useEffect(() => {
        if (!value && defaultValue) {
            const item = getItem(defaultValue);
            setSelectedItem(item);
        }
    }, []);

    /* ActiveItems can change at any time (before/after value/defaultValue has loaded)
     *  and will need to keep selected item */
    useEffect(() => {
        const item = getItem(isControlled ? value : defaultValue);
        setSelectedItem(item);
    }, [value, activeItems]);

    /* Needs to check if the length of the two lists has changed to prevent
     *  redundant re-rendering when in use with areas like BulkUploadSelectors */
    useEffect(() => {
        if (!items || items.length === activeItems?.length) {
            return;
        }
        setActiveItems(items);
    }, [items]);

    const readonlyDisplay = <div style={valueStyle(readonly)}>{selectedItem?.title || ''}</div>;

    const buildInput = (_props) => (
        <div style={disabledStyle}>
            <HierarchicalDropdownMenuComponent
                placement="bottom-start"
                offset={[0, 0]}
                items={activeItems}
                onChange={handleOnChange}
                onBlur={onBlur}
                sort={sort}
                {..._props}
                dataId={dataId || name}
            >
                <div style={valueStyle(readonly)}>
                    <EllipsisTextComponent text={selectedItem?.title || selectText} />
                    <div style={downArrowStyle}>
                        <img src={AssetsConstant.DOWN_CHEVRON_BLACK} />
                    </div>
                </div>
            </HierarchicalDropdownMenuComponent>
        </div>
    );

    // prefer controlled value if it exists, then default
    const inputDisplay = value !== undefined ? buildInput({ value }) : buildInput({ defaultValue });

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.DropDown} inputRef={dropdownRef} label={label || ''}>
            <div ref={mergeRefs([dropdownRef, ref])}>{!readonly ? inputDisplay : readonlyDisplay}</div>
        </BaseInputFieldComponent>
    );
});
