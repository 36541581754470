import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';

const textStyle: CSSProperties = {
    cursor: 'pointer',
    display: 'inline-block',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: Color.TEXT_LIGHT_BLUE,
    fontSize: Size.FontSize.Medium,
    transition: `opacity ${Size.HOVER_TRANSITION_TIME}s`,
    ':hover': {
        opacity: Size.HOVER_OPACITY,
    },
} as React.CSSProperties;

interface GoBackHeaderButtonComponentProps {
    style?: CSSProperties;
    text?: string;
    onClick?: () => void;
}

export const GoBackHeaderButtonComponent = ({ style, text, onClick }: GoBackHeaderButtonComponentProps) => {
    const containerStyle: CSSProperties = {
        display: 'flex',
        paddingBottom: '10px',
        alignItems: 'center',
        ...style,
    };

    return (
        <div style={containerStyle} onClick={onClick}>
            <div style={textStyle}>{text || `< Go Back`}</div>
        </div>
    );
};
