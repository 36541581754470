﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { DataRequestsApiService } from '@vivli/features/data-requests/infrastructure/service';
import { IDataRequestsService } from '@vivli/features/data-requests/infrastructure/interface';
import React from 'react';
import { DataRequestsServiceContext } from '@vivli/features/data-requests/infrastructure/context';

export const DataRequestsServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new DataRequestsApiService(apiOptions);

    const provider: IDataRequestsService = {
        ...api,
    };

    return <DataRequestsServiceContext.Provider value={provider}>{children}</DataRequestsServiceContext.Provider>;
};
