import { useSignalR } from './use-signalr.hook';
import { IChannelApi, IChatApiMessage, IChatMessage } from '@vivli/features/chat/infrastructure/interface';
import { useChatService } from '@vivli/features/chat/infrastructure/context';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { useAuthService } from '@vivli/core/authentication';

interface UseChannelHookProps {
    channelId: string;
    onStart?: (channel: IChannelApi) => void;
    onStop?: () => void;
    onMessage?: (message: IChatApiMessage | IChatMessage) => void;
    onError?: (err) => void;
}

export const useChannel = ({ channelId, onStart, onStop, onMessage, onError }: UseChannelHookProps): void => {
    const auth = useAuthService();
    const config = useConfigService();
    const chatService = useChatService();

    const send = (message: IChatMessage, sendToLocal?: boolean) => {
        if (sendToLocal) {
            onMessage && onMessage(message);
        }

        return chatService.postChatMessage(channelId, message.text);
    };

    const apiToken = auth.getApiToken();
    const channelUri = channelId ? `${config.chatUri}chat?channelId=${encodeURI(channelId)}&jwt=${encodeURI(apiToken)}` : null;

    const handleMessageReceived = (message: IChatApiMessage) => {
        if (!message || message.channelId !== channelId) {
            return;
        }
        auth.getTokenPopup().then(() => {
            // calling get token popup to refresh app token, aka keep the chat page alive if a message is received
        });
        onMessage && onMessage(message);
    };

    useSignalR<IChatApiMessage>({
        uri: channelUri,
        events: ['receiveChatMessage'],
        onStart: () => onStart && onStart({ send }),
        onStop: () => onStop && onStop(),
        onEvent: handleMessageReceived,
        onError,
    });
};
