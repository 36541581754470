﻿import { ButtonComponent, TextFieldComponent, twoAcrossStyle } from '@vivli/shared/components';
import React, { CSSProperties, useState } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { useRorSearchHook } from '@vivli/features/organizations/infrastructure/hook';
import { useModalService } from '@vivli/shared/infrastructure/context';

const buttonStyle: CSSProperties = {
    marginLeft: Size.PADDING,
    backgroundColor: Color.TRANSPARENT,
    color: Color.VIVLI_BLUE,
    flex: '1 1 30%',
    maxWidth: '30%',
    marginTop: Size.FIELD_SEPARATION * 2,
};

const twoAcrossSTwoThirdsStyle: CSSProperties = {
    ...twoAcrossStyle,
    flex: '1 1 66%',
    maxWidth: '66%',
};

const containerStyle: CSSProperties = {
    paddingBottom: '20px',
    paddingTop: '20px',
    display: 'grid',
    gridTemplateColumns: 'auto',
    rowGap: '5px',
    minWidth: '500px',
};

interface RorSearchContainerComponentProps {
    searchText?: string;
    handleResult?: (selections, matches) => void;
}

export const RorSearchContainerComponent = ({ searchText, handleResult }: RorSearchContainerComponentProps) => {
    const rorSearch = useRorSearchHook();
    const modalService = useModalService();
    const [suggestedOrganization, setSuggestedOrganization] = useState<string>('');

    const handleError = (e, toast?: string) => {
        const message = toast ? toast + e : e;
        modalService.error(message);
    };

    const handleRequestedRorSearch = () => {
        rorSearch({
            searchTerm: suggestedOrganization,
            onError: handleError,
            onConfirm: handleResult,
            setIsLoading: () => {},
        });
    };

    return (
        <div>
            <div style={containerStyle}>
                <div style={{ marginTop: '40px' }}>{searchText}</div>
                <div style={Styles.FORM_ROW}>
                    <TextFieldComponent
                        value={suggestedOrganization}
                        style={twoAcrossSTwoThirdsStyle}
                        label={'Suggested Organization Name'}
                        onChange={(e) => setSuggestedOrganization(e.target.value)}
                    />
                    <div style={buttonStyle}>
                        <ButtonComponent onClick={handleRequestedRorSearch} disabled={suggestedOrganization.length === 0}>
                            Search ROR
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};
