import React, { CSSProperties, useRef } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const checkboxStyle: CSSProperties = {
    cursor: 'pointer',
    padding: 5,
};

const containerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

interface GridCheckboxComponentProps extends ICellRendererParams {
    onChange?: (checked: boolean, data: any) => void;
}

export const GridCheckboxComponent = ({ value, onChange, data }: GridCheckboxComponentProps) => {
    const inputRef = useRef<HTMLInputElement>();

    const handleOnChange = (e) => {
        onChange && onChange(e.target.checked, data);
        e.stopPropagation();
        e.originalEvent?.stopPropagation();
    };

    return (
        <div style={containerStyle}>
            <input style={checkboxStyle} ref={inputRef} type="checkbox" onChange={handleOnChange} defaultChecked={value} />
        </div>
    );
};
