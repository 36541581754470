import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { fundingFormSchema } from './use-funding-form.hook';
import { researchProposalFormSchema } from './use-research-proposal-form.hook';
import { statisticalAnalysisFormSchema } from './use-statistical-analysis-form.hook';
import { researchTeamFormSchema } from './use-research-team-form.hook';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';

const formSchema: ObjectSchema<IDataRequest> = joi
    .object({
        requestTitle: joi.string().required().label('Request Title'),
        status: joi.string().allow(DataRequestStatusEnum.Draft),
        otherInformation: joi.optional().label('Other Information'),
        researchTeam: researchTeamFormSchema,
        researchProposal: researchProposalFormSchema,
        funding: fundingFormSchema,
        statisticalAnalysisPlan: statisticalAnalysisFormSchema,
        requirementsAndAttestations: joi.object({
            hasCertifiedCompleteAndAccurate: joi
                .boolean()
                .label('Certification Acknowledgment')
                .valid(true)
                .messages({ 'any.only': '{{#label}} must be checked.' }),
        }),
    })
    .options(JoiValidationOptionsConstant);

export function useDataRequestForm(dataRequest: IDataRequest, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: dataRequest,
        reValidateMode: 'onChange',
    });
}
