﻿import { ICitation } from '@vivli/shared/features/citations/infrastructure/interface';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import React from 'react';
import { CitationContainerComponent } from './citation-container.component';

interface CitationRowRendererComponentProps extends ICellRendererParams {
    data: { citation: ICitation; study: IStudy; dataRequest: IDataRequest; dataRequestDoiStem: string };
    onRemoveRow?: (node: RowNode, citation: ICitation, study: IStudy, dataRequest: IDataRequest) => void;
    dataRequestDoiStem?: string;
    objectId?: string; // this is the id of the study or data request that owns the citation
}

export const CitationRowRenderer = ({ data, node, api, onRemoveRow, dataRequestDoiStem, objectId }: CitationRowRendererComponentProps) => {
    return <CitationContainerComponent citation={data.citation} dataRequestDoiStem={dataRequestDoiStem} />;
};
