﻿import React, { CSSProperties, useEffect } from 'react';
import {
    CheckboxListFormFieldComponent,
    FieldHeaderComponent,
    InfoPopoverComponent,
    TextFormFieldComponent,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { AnalysisPurposeEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { ICheckboxMenuItem } from '@vivli/shared/infrastructure/interface';
import { useAnalysisPurposeTextMap } from '@vivli/features/data-requests/infrastructure/hook';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRefState } from '@vivli/shared/infrastructure/hook';

const twoAcrossNoShadowStyle: CSSProperties = {
    ...twoAcrossStyle,
    boxShadow: 'none',
};
const formFieldStyle: CSSProperties = {
    marginBottom: '20px',
};

export const PurposeOfAnalysisComponent = () => {
    const analysisPurposeMap = useAnalysisPurposeTextMap();
    const formApi = useFormContext();
    const purposeOfAnalysis = useWatch({ name: 'researchProposal.purposeOfAnalysis' });
    const [showOtherDescFieldRef, showOtherDescriptionField, setShowOtherDescriptionField] = useRefState(false);

    const createCheckboxMenuItem = (purpose: AnalysisPurposeEnum): ICheckboxMenuItem => {
        const currentValues = formApi.getValues('researchProposal.purposeOfAnalysis') as string[];
        return {
            title: analysisPurposeMap(purpose),
            value: purpose,
            checked: currentValues?.some((x) => x === purpose),
        };
    };

    const checkboxMenuItems = Object.values(AnalysisPurposeEnum).map(createCheckboxMenuItem);

    const purposeOfAnalysisHint = (
        <div>
            <div>Choose from the available categories – select all that apply:</div>
            <ul>
                {checkboxMenuItems
                    .filter((i) => i.value !== AnalysisPurposeEnum.Other)
                    .map((item) => (
                        <li key={item.value}>{item.title}</li>
                    ))}
            </ul>
            <div>If you only need summary-level data or documents related to the study, please specify that here.</div>
        </div>
    );

    useEffect(() => {
        if (!purposeOfAnalysis) {
            return;
        }

        const fieldName = 'researchProposal.purposeOfAnalysisOtherDescription';
        const showDescField = purposeOfAnalysis.some((p) => p === AnalysisPurposeEnum.Other);

        if (showDescField !== showOtherDescFieldRef.current) {
            setShowOtherDescriptionField(showDescField);

            // clear the form value if other is no longer checked
            if (!showDescField) {
                formApi.setValue(fieldName, '', { shouldValidate: true });
            } else {
                formApi.trigger(fieldName, { shouldFocus: true });
            }
        }
    }, [purposeOfAnalysis]);

    return (
        <div style={twoAcrossNoShadowStyle}>
            <FieldHeaderComponent
                title="PURPOSE OF ANALYSIS"
                rightAccessory={<InfoPopoverComponent content={purposeOfAnalysisHint} size={20} />}
            />
            <CheckboxListFormFieldComponent
                name={'researchProposal.purposeOfAnalysis'}
                checkedListItems={checkboxMenuItems}
                truncateLabel={false}
            />
            {showOtherDescriptionField && (
                <TextFormFieldComponent
                    style={formFieldStyle}
                    name={'researchProposal.purposeOfAnalysisOtherDescription'}
                    label={'Please provide further details'}
                />
            )}
        </div>
    );
};
