/* eslint-disable-next-line */
import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { EnquiryContext, useEnquiryServiceContext } from '@vivli/features/enquiry/infastructure/context';
import { IDataComment, IDataInfoRequest, IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { UseFieldArrayAppend, UseFormReturn } from 'react-hook-form';
import { Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { TranslationComponent } from '@vivli/shared/components';
import { useEnquiryFormHook } from '@vivli/features/enquiry/infastructure/hook';
import { useStudyPrimaryRegistryUrlHook } from '@vivli/features/studies/infrastructure/hook';
import { useNavigate, useParams } from 'react-router';
import { EnquiryStatusEnum } from '@vivli/features/enquiry/infastructure/enum';
import { useSharedDataContext } from '@vivli/features/data-requests/infrastructure/context';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';

export interface EnquiryContextWrappersProps {
    children?: ReactNode;
}

const defaultValues = (user: IUser): IEnquiry => ({
    id: 0,
    requesterId: user.id,
    requesterEmail: user.email,
    requesterName: user.displayName,
    requesterOrganization: '',
    requesterCountry: '',
    enquiryPurpose: '',
    dataInfoRequests: [{ ...defaultDataInfoRequest }],
    status: 1,
    submittedDate: null,
    createdBy: '',
    updatedBy: '',
    createdDate: new Date().toDateString(),
    updatedDate: null,
    environment: '',
});

export const defaultDataInfoRequest: IDataInfoRequest = {
    id: 0,
    nctId: '',
    sponsorId: '',
    studyTitle: '',
    studyCompletionDate: null,
    orgId: '',
    isVivliPosted: false,
    isOnClinicalTrials: false,
    dataType: [],
    status: 1,
    notAvailableReason: 0,
    finalResponseDate: null,
    internalNotes: '',
    dataRequestsCreated: [],
    comments: [],
    registryUrl: '',
    createdBy: '',
    updatedBy: '',
    createdDate: new Date().toDateString(),
    updatedDate: '',
    environment: '',
    notify: false, //this field is for marked notify and will not be persisted
};

export const EnquiryContextWrapper = ({ children }: EnquiryContextWrappersProps) => {
    const user = useActiveUser();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [enquiryState, setEnquiryState] = useState<IEnquiry>();
    const [nctError, setNctError] = useState<boolean>(false);
    const [sponsorIdError, setSponsorIdError] = useState<boolean>(false);
    const [study, setStudy] = useState();
    let enquiryForm: IEnquiry;
    let dirIndex: number;
    const isSavingRef = useRef(false);
    const dirAppendRef = useRef<UseFieldArrayAppend<IEnquiry>>();

    const enquiryService = useEnquiryServiceContext();
    const modalService = useModalService();
    const toastService = useToastService();
    const formApi = useEnquiryFormHook();
    const { registryUrl } = useStudyPrimaryRegistryUrlHook();
    const { addDataRequest } = useSharedDataContext().myDataRequests;
    const modalMaxWidth = '40em';
    const navigate = useNavigate();
    const pageEndRef = useRef(null);
    const [openDirs, setOpenDirs] = useState<number[]>([]);
    const dirRefs = useRef<{ ref: MutableRefObject<HTMLDivElement> | null; dirIndex: number }[]>([]);

    const toastSuccessResponse = 'Item Successfully Added to My Requests';

    const modalDirStudyText = (
        <div>
            Enter a descriptive name for your research project.
            <p>
                If this is an additional <TranslationComponent>study</TranslationComponent> you want to add to the same project, then
                instead of entering a new project name here, click cancel and choose your previous project name from the drop-down on the
                "Request <TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent>" button.
            </p>
        </div>
    );
    const updateIsSaving = (status: boolean) => {
        isSavingRef.current = status;
        setIsLoading(status);
    };
    const handleSavingError = (err: string) => {
        updateIsSaving(false);
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support: ${err}`);
    };

    const handleFormSave = (formApi: UseFormReturn<IEnquiry, object>, isSubmit = false, notify = false) => {
        const subject = new Subject<IEnquiry>();

        formApi.handleSubmit(
            (formValues) => handleFormApiSave(formApi, formValues, isSubmit, subject, notify),
            () => {
                const formValues = formApi.getValues();
                handleFormApiSave(formApi, formValues, isSubmit, subject, notify);
            }
        )();

        return subject;
    };

    const handleFormApiSave = (
        formApi: UseFormReturn<IEnquiry, object>,
        formValues: IEnquiry,
        isSubmit: boolean,
        subject: Subject<IEnquiry>,
        notify = false
    ) => {
        setIsLoading(true);
        enquiryService
            .updateEnquiry({ ...formValues } as IEnquiry, notify)
            .pipe(first())
            .subscribe((data) => {
                updateIsSaving(false);
                resetAfterApiCall(data);
                enquiryState.id == 0 && navigate(`/admin/enquiry/${data.id}`, { replace: true });
                setIsLoading(false);
                toastService.success('Edits saved successfully');
            }, handleSavingError);
    };

    const updateStatus = (status: EnquiryStatusEnum, formApi: UseFormReturn<IEnquiry, object>) => {
        formApi.handleSubmit(
            (formValues) => updateStatusApi(status, formApi, formValues),
            () => {
                const formValues = formApi.getValues();
                updateStatusApi(status, formApi, formValues);
            }
        )();
    };
    const updateStatusApi = (status: EnquiryStatusEnum, formApi: UseFormReturn<IEnquiry, object>, formValues: IEnquiry) => {
        setIsLoading(true);
        enquiryService
            .updateStatus({ ...formValues } as IEnquiry, status)
            .pipe(
                first(),
                finalize(() => setIsLoading(false))
            )
            .subscribe(
                (enq) => {
                    updateIsSaving(false);
                    resetAfterApiCall(enq);
                },
                (e) => {
                    let enq = { ...formValues } as IEnquiry;
                    resetAfterApiCall(enq);
                    handleError(e);
                }
            );
    };

    const resetAfterApiCall = (enq: IEnquiry) => {
        setEnquiryState(enq);
        formApi.reset(enq, {
            keepErrors: true,
            keepIsValid: false,
            keepDirty: false,
        });
    };
    const updateDirAfterApiCall = (dir: IDataInfoRequest, index: number) => {
        enquiryForm.dataInfoRequests[index] = dir;
        formApi.setValue('dataInfoRequests', enquiryState.dataInfoRequests);
        setEnquiryState(enquiryForm);
        setIsLoading(false);
    };
    const handleFailure = (input: string) => {
        modalService.message(
            `The Study for "${input}" was not found in the ClincialTrials.gov database.
        Please provide a descriptive "Study Title" for the study and any other information that will help the contributor to identify the desired study.`,
            { showLogo: true, showContinueButton: true, showBackground: false }
        );

        //this is needed to preserve newly added dataInfoRequest from disappearing
        resetAfterApiCall(enquiryForm);
    };

    const handleGetStudy = (nctIdorsponsorId: string, index: number, formApi: UseFormReturn<IEnquiry>) => {
        enquiryForm = formApi.getValues();
        dirIndex = index;
        enquiryService
            .getStudyInfo(nctIdorsponsorId)
            .pipe(first())
            .subscribe(
                (data) => {
                    const nctString = registryUrl(data);

                    const updatedDir: IDataInfoRequest = {
                        ...enquiryState.dataInfoRequests[index],
                        nctId: data.nctId,
                        sponsorId: data.sponsorProtocolId,
                        studyTitle: data.studyTitle,
                        sponsor: data.leadSponsor.agency,
                        studyCompletionDate: data.actualStudyCompletionDate,
                        finalResponseDate: data.finalResponseDate,
                        registryUrl: nctString,
                        isVivliPosted: !!data.orgId,
                    };
                    updateDirAfterApiCall(updatedDir, index);
                    setStudy(data);
                },
                () => handleFailure(nctIdorsponsorId)
            );
    };

    const initialGetStudy = (nctIdorsponsorId: string, index: number, formApi: UseFormReturn<IEnquiry>) => {
        enquiryForm = formApi.getValues();
        dirIndex = index;
        enquiryService
            .getStudyInfo(nctIdorsponsorId)
            .pipe(first())
            .subscribe(
                (data) => {
                    setStudy(data);
                },
                () => handleFailure(nctIdorsponsorId)
            );
    };
    const handleError = (e) => {
        modalService.error(e);
    };
    const getEnquiry = (id: string) => {
        if (id) {
            setIsLoading(true);
            enquiryService
                .getEnquiry(id)
                .pipe(
                    first(),
                    finalize(() => setIsLoading(false))
                )
                .subscribe((enq) => {
                    setEnquiryState(enq);
                }, handleError);
        }
    };

    const addComment = (dir: IDataInfoRequest, dirIndex: number, comment: string) => {
        const commentData: IDataComment = {
            id: 0,
            userId: user.userId,
            displayName: user.displayName,
            enquiryId: 0,
            dataInformationRequestId: 0,
            comment: comment,
            createdBy: user.userId,
            updatedBy: '',
            createdDate: new Date().toISOString(),
            updatedDate: null,
            environment: '',
        };

        dir.comments?.push(commentData);
        setNotify(dir, dirIndex);
    };

    const setNotify = (dir: IDataInfoRequest, dirIndex: number) => {
        dir.notify = true;
        enquiryState.dataInfoRequests[dirIndex] = dir;
        setEnquiryState({ ...enquiryState });
        formApi.setValue(`dataInfoRequests.${dirIndex}.notify`, true);
    };

    const formatStatus = (status: EnquiryStatusEnum): string => {
        switch (status) {
            case EnquiryStatusEnum.Draft:
                return 'Draft';
            case EnquiryStatusEnum.EnquiryValidation:
                return 'Enquiry Validation';
            case EnquiryStatusEnum.Review:
                return 'Review';
            case EnquiryStatusEnum.Archived:
                return 'Archived';
            case EnquiryStatusEnum.Withdrawn:
                return 'Withdrawn';
        }
    };

    const getAddDirStudy = (enquiryId, dirId, receivedTitle, isNewRequest) => {
        setIsLoading(true);

        if (!isNewRequest) {
            handleAddStudy(enquiryId, dirId, receivedTitle);
            return;
        }
        // Modal for New Dir Study
        modalService.prompt(modalDirStudyText, '', {
            title: receivedTitle,
            promptLabel: 'Research Project Name',
            style: { maxWidth: modalMaxWidth, textAlign: 'left' },
            messageStyle: { textAlign: 'left' },
            confirmText: 'OK',
            cancelText: 'Cancel',
            onConfirm: ({ comment }) => {
                handleAddStudy(enquiryId, dirId, comment);
            },
            onCancel: () => setIsLoading(false),
        });
    };
    const handleAddStudy = (enquiryId: number, dirId: number, receivedTitle: string) => {
        enquiryService
            .addDirStudy(enquiryId, dirId, receivedTitle)
            .pipe(first())
            .subscribe(
                (data) => {
                    handleStudyAdded(data.item2);
                    setIsLoading(false);
                },
                (error) => {
                    modalService.error(error);
                    setIsLoading(false);
                }
            );
    };
    const handleStudyAdded = (dataRequest: IDataRequest) => {
        addDataRequest(dataRequest);
        toastService.success(toastSuccessResponse);
    };

    const displaySuccessToastAndScrollToBottom = (message: string, dirIndex: number) => {
        toastService.success(message);
        setTimeout(() => {
            const dirRef = dirRefs.current.find((ref) => ref.dirIndex === dirIndex);
            dirRef.ref?.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    const isDirOpen = (dirIndex: number) => {
        return openDirs.some((od) => od === dirIndex);
    };

    const setDirAsOpen = (dirIndex: number) => {
        setOpenDirs([...openDirs, dirIndex]);
    };

    const setDirAsClosed = (dirIndex: number) => {
        const filteredDirs = [...openDirs.filter((od) => od !== dirIndex)];
        setOpenDirs(filteredDirs);
    };

    const toggleDir = (dirIndex: number) => {
        if (isDirOpen(dirIndex)) {
            setDirAsClosed(dirIndex);
        } else {
            setDirAsOpen(dirIndex);
        }
    };

    const closeAllDirs = () => {
        setOpenDirs([]);
    };

    const addDirRef = (dirRef: MutableRefObject<HTMLDivElement>, dirIndex: number) => {
        dirRefs.current.push({ ref: dirRef, dirIndex });
    };

    const removeDirRef = (dirIndex: number) => {
        dirRefs.current = dirRefs.current.filter((od) => od.dirIndex !== dirIndex);
    };

    const handleDirArrayInit = (onAppend: UseFieldArrayAppend<IEnquiry>) => {
        dirAppendRef.current = onAppend;
    };

    const addNewDir = (dir: IDataInfoRequest) => {
        dirAppendRef.current(dir);
    };

    const provider = {
        enquiry: enquiryState,
        setEnquiryState,
        handleFormSave,
        handleGetStudy,
        isLoading,
        getEnquiry,
        handleError,
        updateStatus,
        addComment,
        formatStatus,
        setNotify,
        nctError,
        sponsorIdError,
        getAddDirStudy,
        displaySuccessToastAndScrollToBottom,
        pageEndRef,
        setDirAsOpen,
        setDirAsClosed,
        toggleDir,
        openDirs,
        isDirOpen,
        closeAllDirs,
        addDirRef,
        removeDirRef,
        handleDirArrayInit,
        addNewDir,
        study,
        initialGetStudy,
    };

    useEffect(() => {
        if (user && id) {
            getEnquiry(id);
        } else if (!enquiryState && user) {
            setEnquiryState(defaultValues(user));
        }
    }, [id, user]);

    useEffect(() => {
        if (enquiryState?.dataInfoRequests?.length < 2) {
            setDirAsOpen(0);
        }
    }, [enquiryState]);

    return <EnquiryContext.Provider value={provider}>{children}</EnquiryContext.Provider>;
};
