﻿import { IStudyResearchTeamMember } from '@vivli/features/studies/infrastructure/interface';

export function validateEmail(inputValue: string) {
    return /\S+@\S+\.\S+/.test(inputValue);
}

export function validateOrcidChecksum(orcidId: string): string {
    const baseDigits = orcidId.replace(/-/g, '').substring(0, 15);
    const checksum = generateCheckDigit(baseDigits);
    if (checksum === 'N/A') {
        return 'Not all the characters are digits.';
    } else if (checksum !== orcidId[orcidId.length - 1]) {
        return 'Checksum is incorrect - ORCID Id is invalid.';
    }
    return '';
}

export function generateCheckDigit(baseDigits: string) {
    let total = 0;
    for (let i = 0; i < baseDigits.length; i++) {
        if (/^\d+$/.test(baseDigits[i])) {
            const digit = parseInt(baseDigits[i]);
            total = (total + digit) * 2;
        } else {
            return 'N/A';
        }
    }

    const remainder = total % 11;
    const result = (12 - remainder) % 11;
    return result === 10 ? 'X' : result.toString();
}

export function findDuplicates(arr: string[]) {
    return arr.filter((currentValue, currentIndex) => arr.indexOf(currentValue) !== currentIndex);
}

export function checkResearchTeamForDuplicates(teamMembers: IStudyResearchTeamMember[]) {
    const teamMemberEmails = teamMembers.map((tm) => tm.emailAddress.toLowerCase());
    const dupEmails = findDuplicates(teamMemberEmails);
    const teamMemberOrcidIds = teamMembers.filter((tm) => tm.orcidId).map((tm) => tm.orcidId.toLowerCase());
    const dupOrcidIds = findDuplicates(teamMemberOrcidIds);
    if (dupEmails.length > 0 || dupOrcidIds.length > 0) {
        return (
            'Each user email address or ORCID iD can appear in the research team only once - please delete any duplicate users.' +
            `The following team members have duplicate emails or ORCID iDs: ${dupEmails.join(',')} ${dupOrcidIds.join(',')}`
        );
    }
    return '';
}

export function useInputValidation() {
    return {
        validateEmail,
        validateOrcidChecksum,
        findDuplicates,
        checkResearchTeamForDuplicates,
    };
}
