﻿import { LinkFieldComponent, TextAreaReadOnlyFormComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { IRequestedStudy } from '@vivli/features/data-requests/infrastructure/interface';
import { Size } from '@vivli/shared/theme';

const textField70WidthStyle: CSSProperties = {
    flex: '0 1 70%',
    maxWidth: '69%',
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'default',
};
const textField30WidthStyle: CSSProperties = {
    flex: '0 1 30%',
    maxWidth: '29%',
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'default',
};

interface ViewSecondaryAnalysisRowComponentProps {
    study: IRequestedStudy;
}

export const ViewSecondaryAnalysisRowComponent = ({ study }: ViewSecondaryAnalysisRowComponentProps) => {
    return (
        <>
            <TextAreaReadOnlyFormComponent
                label={'Title of Study In Analysis'}
                defaultValue={study.studyTitle}
                style={textField70WidthStyle}
                rows={3}
            />
            <LinkFieldComponent
                label={'DOI of Study In Analysis'}
                linkContent={study.requestedStudyChildDoi}
                linkTitle={study.requestedStudyChildDoi}
                style={textField30WidthStyle}
            />
        </>
    );
};
