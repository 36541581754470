﻿import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { antimicrobialFormSchema } from './use-antimicrobial-detail-form.hook';

const formSchema: ObjectSchema<IDataRequest> = joi
    .object({
        requestTitle: joi.string().required().label('Request Title'),
        status: joi.string().allow(DataRequestStatusEnum.Draft),
        otherInformation: joi.optional().label('Other Information'),
        antimicrobialDetail: antimicrobialFormSchema,
    })
    .options(JoiValidationOptionsConstant);

export function useAmrDataRequestForm(dataRequest: IDataRequest, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: dataRequest,
        reValidateMode: 'onChange',
    });
}
