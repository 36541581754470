export function useDoiTransform() {
    const transformDoiUrl = (doi: string): string => {
        if (!doi) {
            return '';
        }
        return doi.replace('.stage.', '.test.');
    };

    return {
        transformDoiUrl,
    };
}
