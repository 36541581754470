﻿import { Color, Size } from '@vivli/shared/theme';
import React, { CSSProperties } from 'react';
import { ISearchStudy } from '@vivli/features/dashboard/infrastructure/interface';

const SEARCH_DETAIL_STYLE: React.CSSProperties = {
    marginTop: Size.TEXTFIELD_ROWS,
    fontSize: Size.FontSize.Large,
    color: Color.TEXT_GRAY,
};
const flexColumnCenterStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};
const flexRowStartStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
};

interface SearchResultRowInfoComponentProps {
    searchResult: ISearchStudy;
    isAmr: boolean;
}

export const SearchResultRowInfoComponent = ({ searchResult, isAmr }: SearchResultRowInfoComponentProps) => {
    const yearsDataCollected = () => {
        if (!searchResult.yearsDataCollected) return null;

        const first = searchResult.yearsDataCollected[0];
        const last = searchResult.yearsDataCollected[searchResult.yearsDataCollected.length - 1];
        return first + ' - ' + last;
    };

    const buildNctRow = () => (
        <>
            IDs: <span>&nbsp;</span>
            <span style={{ color: Color.BLACK }}>{' ' + searchResult.nctId}</span>
            <span style={{ color: Color.TEXT_GRAY }}>&nbsp; |&nbsp; </span>
            <span style={{ color: Color.BLACK }}>{searchResult.sponsorProtocolId}</span>
        </>
    );

    const buildNctIsNullRow = () => (
        <>
            IDs: <span>&nbsp;</span>
            <span style={{ color: Color.BLACK }}>{searchResult.sponsorProtocolId}</span>
        </>
    );

    const buildConditionIntervention = () => (
        <>
            <span style={SEARCH_DETAIL_STYLE}>
                <div style={flexRowStartStyle}>
                    Condition or Disease: <span>&nbsp;</span>{' '}
                    <span style={{ color: Color.BLACK }}>
                        {' ' + searchResult.extractedConditions && searchResult.extractedConditions.join(', ')}
                    </span>{' '}
                </div>
            </span>
            <span style={SEARCH_DETAIL_STYLE}>
                <div style={flexRowStartStyle}>
                    Intervention/treatment:<span>&nbsp;</span>{' '}
                    <span style={{ color: Color.BLACK }}>
                        {' ' + searchResult.extractedInterventions && searchResult.extractedInterventions.join(', ')}
                    </span>{' '}
                </div>
            </span>
        </>
    );

    const buildSponsorRow = () => (
        <>
            Sponsor: <span>&nbsp;</span>
            <span style={{ color: Color.BLACK }}>{searchResult.sponsorName}</span>
        </>
    );

    const buildYearsDateUpdated = () => (
        <>
            <span style={SEARCH_DETAIL_STYLE}>
                <div style={flexRowStartStyle}>
                    Years Data Collected: <span>&nbsp;</span> <span style={{ color: Color.BLACK }}>{' ' + yearsDataCollected()}</span>{' '}
                </div>
            </span>
            <span style={SEARCH_DETAIL_STYLE}>
                <div style={flexRowStartStyle}>
                    Date of Last Update:<span>&nbsp;</span> <span style={{ color: Color.BLACK }}>{' ' + searchResult.lastUpdatedDate}</span>{' '}
                </div>
            </span>
        </>
    );

    const firstRowDisplay = isAmr ? buildSponsorRow() : searchResult.nctId ? buildNctRow() : buildNctIsNullRow();
    const secondThirdRowDisplay = isAmr ? buildYearsDateUpdated() : buildConditionIntervention();

    return (
        <div style={flexColumnCenterStyle}>
            <span style={SEARCH_DETAIL_STYLE}>
                <div style={flexRowStartStyle}>{firstRowDisplay}</div>
            </span>
            {secondThirdRowDisplay}
        </div>
    );
};
