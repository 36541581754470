import React, { CSSProperties } from 'react';
import { AdminHeaderTitleComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { RequestedStudiesGridComponent } from '@vivli/features/studies/components';
import { useStudiesAwaitingUpload } from '@vivli/features/studies/infrastructure/hook';
import { Size } from '@vivli/shared/theme';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

const headerStyle: CSSProperties = {
    margin: `${Size.INSET_SIZE}px 0`,
    display: 'block',
};

const bodyStyle: CSSProperties = {
    height: '100%',
    overflow: 'auto',
    marginBottom: '20px',
};

export const AwaitingUploadStudiesFeature = () => {
    const { requestedStudies, dataRequestSummaries } = useStudiesAwaitingUpload();

    if (!requestedStudies) {
        return <LoadIndicatorComponent style={{ width: '100%', minHeight: '100%' }} size={LoadIndicatorSizeEnum.Medium} />;
    }

    return (
        <div style={containerStyle}>
            <AdminHeaderTitleComponent title="Awaiting Upload" style={headerStyle} />

            <div className="scrolly" style={bodyStyle}>
                <RequestedStudiesGridComponent
                    studies={requestedStudies}
                    dataRequests={dataRequestSummaries}
                    emptyMessage="No Matching Studies Awaiting Upload"
                    hideFloatingFilter={true}
                    hideHeader={true}
                    autoHeight={true}
                />
            </div>
        </div>
    );
};
