﻿import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { AdvancedSubHeaderComponent, ButtonComponent, TextFieldComponent } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';

const outerContainerStyle: CSSProperties = {
    maxHeight: window.innerHeight * 0.8,
    overflowX: 'hidden',
    overflowY: 'auto',
};
const titleStyle: CSSProperties = {
    padding: Size.PADDING,
    color: Color.WHITE,
    fontSize: 20,
};
const innerContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-around',
};
const divOneStyle: CSSProperties = {
    width: 200,
    margin: '10px 10px 5px 15px',
};
const buttonStyle: CSSProperties = {
    backgroundColor: '#005475',
    height: '48px',
    margin: '31px 14px 10px 10px',
};
const textStyle: CSSProperties = {
    backgroundColor: 'rgb(246, 247, 251)',
    padding: '7px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    color: 'rgb(96, 104, 111)',
    cursor: 'pointer',
    borderRadius: '6px',
    width: '200px',
};
const textValueStyle: CSSProperties = {
    borderBottom: '3px solid rgb(81, 180, 213)',
};

interface QuickSearchComponentProps {
    executeSearch(id: string, sponsors?: string[]);
}

export const QuickSearchComponent = ({ executeSearch }: QuickSearchComponentProps) => {
    const [textValue, setTextValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>();

    const handleClick = () => {
        const id = textValue;
        executeSearch(id);
    };

    const handleKeyDown = (e) => {
        if (e.key === Size.Key.Enter) {
            handleClick();
        }
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <div style={outerContainerStyle}>
            <div style={titleStyle}>Find a single study by ID</div>
            <div style={innerContainerStyle}>
                <div style={divOneStyle}>
                    <AdvancedSubHeaderComponent title={'NCT ID / SPONSOR PROTOCOL ID'} style={{ color: Color.WHITE }} />
                    <TextFieldComponent
                        onChange={(v) => setTextValue(v.target.value)}
                        value={textValue}
                        style={textStyle}
                        ref={inputRef}
                        innerStyle={textValueStyle}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <ButtonComponent
                        onClick={handleClick}
                        style={buttonStyle}
                        disabled={textValue.length === 0}
                        className="quickSearch_lookup"
                    >
                        Lookup
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
