﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { UtilityApiService } from './utility-api.service';
import { IUtilityApiService } from './utility-api-service.interface';
import { UtilityApiServiceContext } from './utility-api-service.context';

export const UtilityApiServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new UtilityApiService(apiOptions);

    const provider: IUtilityApiService = {
        ...api,
    };

    return <UtilityApiServiceContext.Provider value={provider}>{children}</UtilityApiServiceContext.Provider>;
};
