import React, { ChangeEvent, CSSProperties, forwardRef, useRef } from 'react';
import { Size } from '@vivli/shared/theme';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { BaseInputFieldComponent } from './base-input-field.component';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import mergeRefs from 'react-merge-refs';

interface CheckboxComponentProps extends IFormField {
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    inputStyle?: CSSProperties;
    className?: string;
    disabled?: boolean;
}

export const CheckboxComponent = forwardRef((props: CheckboxComponentProps, ref) => {
    const textInputRef = useRef<HTMLInputElement>();
    const { checked = false, defaultChecked, onChange, style, inputStyle, className, disabled, label, dataId } = props;

    const checkboxStyle: CSSProperties = {
        transform: 'scale(1.5)',
        marginRight: Size.PADDING,
        ...inputStyle,
    };

    const containerStyle: CSSProperties = {
        padding: 12,
        marginTop: 'auto',
        marginBottom: 'auto',
        ...style,
    };

    const buildInput = (props) => (
        <input
            {...props}
            ref={mergeRefs([textInputRef, ref])}
            style={checkboxStyle}
            type="checkbox"
            disabled={disabled}
            onChange={onChange}
            data-test-id={dataId}
        />
    );

    // prefer controlled value if it exists, then default
    const inputDisplay = checked !== undefined ? buildInput({ checked }) : buildInput({ defaultChecked });

    const fieldDisplay = (
        <div style={containerStyle} className={className}>
            {inputDisplay}
        </div>
    );

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.Checkbox} inputRef={textInputRef} label={label}>
            {fieldDisplay}
        </BaseInputFieldComponent>
    );
});
