﻿import { GridComponent } from '@vivli/shared/components';
import React from 'react';
import { IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { ColDef } from 'ag-grid-community';
import { GridCellRendererEnum } from '@vivli/shared/infrastructure/enum';

interface AssociatedEnquiriesGridComponentProps {
    userEnquiries?: IEnquiry[];
    isVivliAdmin?: boolean;
}

export const AssociatedEnquiriesGridComponent = ({ userEnquiries, isVivliAdmin }: AssociatedEnquiriesGridComponentProps) => {
    const columnDefs: ColDef[] = [
        {
            field: 'id',
            headerName: 'Enquiry ID',
            sortable: true,
            filter: false,
            tooltipField: 'Enquiry Id',
            minWidth: 80,
            maxWidth: 120,
        },
        {
            field: 'requesterOrganization',
            headerName: 'Institution',
            minWidth: 280,
            sortable: false,
            filter: false,
        },
        {
            field: 'statusString',
            headerName: 'Status',
            sortable: false,
            filter: false,
        },
        {
            field: 'submittedDate',
            headerName: 'Date Submitted',
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: GridCellRendererEnum.GridCellDateTime,
        },
        {
            field: 'createdDate',
            headerName: 'Drafted',
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: GridCellRendererEnum.GridCellDateTime,
        },
        // dataInfoRequestsCount
        {
            field: 'dataInfoRequestsCount',
            headerName: '# of Studies',
            filter: false,
            sortable: false,
            minWidth: 100,
            cellStyle: { textAlign: 'center' },
        },
    ];

    const handleRowClick = (data) => {
        let urlToOpen = `/admin/enquiry/${data.id}`;
        window.open(urlToOpen);
    };

    return (
        <GridComponent
            rowData={userEnquiries}
            columns={columnDefs}
            fullWidthColumns={true}
            onRowClick={handleRowClick}
            emptyMessage="No Associated Enquiries"
            autoHeight={true}
            hideFloatingFilter={true}
        />
    );
};
