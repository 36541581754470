﻿import { useActiveUser, useConfigService, useVivliConfigurationService } from '@vivli/core/infrastructure/context';
import { IntegrationContext, useModalService } from '@vivli/shared/infrastructure/context';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import React from 'react';
import { useAuthService } from '@vivli/core/authentication';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';

export let IntegrationInstance = null;

export const IntegrationContextWrapper = ({ children }: IContextWrapper) => {
    const user = useActiveUser();
    const auth = useAuthService();
    const config = useConfigService();
    const modal = useModalService();
    const vivliConfigService = useVivliConfigurationService();
    const studyService = useStudiesService();

    const logOutUser = () => {
        if (auth.isSsoUser()) {
            const confirmMsg = `You are logged in with credentials from another application and logging out may also log you out of that application. Are you sure you want to log out?`;
            modal.confirm(confirmMsg, {
                onConfirm: () => {
                    auth.logout();
                },
            });
        } else {
            auth.logout();
        }
    };

    IntegrationInstance = {
        user,
        auth,
        config,
        logOutUser,
        vivliConfigService,
        studyService,
    };

    return <IntegrationContext.Provider value={IntegrationInstance}>{children}</IntegrationContext.Provider>;
};
