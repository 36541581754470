﻿import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { nextButtonStyle, outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import {
    ButtonComponent,
    CheckboxComponent,
    CheckboxFormFieldComponent,
    FieldHeaderComponent,
    fourAcrossStyle,
    oneAcrossStyle,
    TextAreaFormFieldComponent,
    TextFieldReadOnlyFormComponent,
    TextFormFieldComponent,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import { first, timeout } from 'rxjs/operators';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useFormContext, useWatch } from 'react-hook-form';
import { ListingRequestStudySampleComponent } from '../listing-request-study-sample.component';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';
import { additionalInfoHint, funderEmailHint } from '@vivli/features/listing-request/infrastructure/constants';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useNavigate } from 'react-router-dom';
import { LrFundersRorContainerComponent } from '@vivli/features/organizations/components';
import { ListingRequestStudyNonNctComponent } from '../listing-request-study-nonNct.component';

const nctRowStyle: CSSProperties = {
    ...Styles.FORM_ROW,
    justifyContent: 'start',
};
const studyButtonStyle: CSSProperties = {
    ...fourAcrossStyle,
    marginLeft: 10,
    height: '4em',
};
const studyCheckBoxStyle: CSSProperties = {
    backgroundColor: 'white',
    borderBottom: '2px solid white',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 20px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    maxWidth: '100%',
    marginBottom: '20px',
    padding: '15px 0',
};

export const YourStudyComponent = () => {
    const formApi = useFormContext<IListingRequest>();
    const navigate = useNavigate();
    const { isReadOnly, handleNext, listingRequest, setListingRequest } = useListingRequestContext();
    const studiesService = useStudiesService();
    const modalService = useModalService();
    const toastService = useToastService();
    const user = useActiveUser();
    const [sampleStudy, setSampleStudy] = useState(null);
    const [hasNonNctValue, sethasNonNctValue] = useState<boolean>(false);
    const [isLoadingSample, setIsLoadingSample] = useState(false);
    const status = listingRequest?.status ? ListingRequestStatusEnum[listingRequest?.status] : null;
    const isAccepted = status ? status === ListingRequestStatusEnum.StudyInCuration : false;
    const isApproved = status ? status === ListingRequestStatusEnum.Approved : false;
    const isWithdrawn = status ? status === ListingRequestStatusEnum.Withdrawn : false;
    const isDraft = status ? status === ListingRequestStatusEnum.Draft : false;
    const isCheckboxEnabled = !isReadOnly;
    const emptyStudy: IStudy = { id: '' };
    const nctValue = useWatch({ name: 'datasets[0].nctId' });
    const study: IStudy = listingRequest?.datasets.length > 0 ? listingRequest?.datasets[0] : null;
    const hasFunders = study?.fundingOrganizations?.length > 0;
    const [showFunderEmail, setShowFunderEmail] = useState(hasFunders);

    const canEditRor = !isReadOnly && !isWithdrawn;
    const isVivliAdmin = user.isVivliAdmin;

    const handleError = (e) => {
        let msg = '';
        if (e?.toString()?.toLowerCase()?.indexOf('already exists') >= 0) {
            msg = e.toString();
        } else {
            msg = `Error looking up study by NCT ID; either the ID does not exist or clinicaltrials.gov did not respond - ${e}`;
        }
        modalService.error(msg);
        //mark the nctId
        if (nctValue?.indexOf('error') == -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formApi.setValue('datasets[0].nctId', nctValue + '-error');
        }
        setSampleStudy(null);
        setIsLoadingSample(false);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleViewStudy = () => {
        const studyUrl = `/admin/studies/${listingRequest?.datasetIds[0]}/admin-study-feature`;
        const confirmMessage = 'You are about to leave the form and will lose unsaved changes. Do you want to continue?';
        if (isReadOnly) {
            navigate(studyUrl);
            return;
        }

        modalService.confirm(confirmMessage, {
            title: 'Continue?',
            cancelText: 'Cancel',
            confirmText: 'OK',
            onConfirm: () => navigate(studyUrl),
        });
    };

    const timerRef = useRef(null);

    const toastAttemptingMessage = () => {
        toastService.info('Requesting NCT from clinicaltrials.gov');
    };

    const playAttemptingMessage = () => {
        timerRef.current = setTimeout(toastAttemptingMessage, 50);
    };

    const handleSearch = (updateNeeded) => {
        if (!/^NCT\d{8}$/gi.test(nctValue)) {
            setSampleStudy(null);
            return;
        }
        if (!updateNeeded) {
            setSampleStudy(listingRequest?.datasets[0]);
            return;
        }
        setIsLoadingSample(true);
        playAttemptingMessage();
        const studyId = listingRequest?.datasets[0]?.id;
        studiesService
            .getSampleStudyForListingRequest(nctValue, studyId)
            .pipe(timeout(15000), first())
            .subscribe((sample) => {
                toastService.dismiss();
                clearTimeout(timerRef.current);
                setSampleStudy(sample);
                if (updateNeeded) {
                    mergeSampleWithCurrent(sample);
                }
                setIsLoadingSample(false);
            }, handleError);
    };

    const mergeSampleWithCurrent = (sampleStudy: IStudy) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentStudy = formApi.getValues('datasets[0]');
        const mergedStudy = {
            ...sampleStudy,
            id: currentStudy.id,
            researchTeam: currentStudy.researchTeam,
            orgId: currentStudy.orgId,
            orgName: currentStudy.orgName,
            orgCode: currentStudy.orgCode,
            nctId: currentStudy.nctId,
            additionalInformation: currentStudy.additionalInformation,
            funder: currentStudy.funder,
            funderEmail: currentStudy.funderEmail,
            grant: currentStudy.grant,
            embargoDate: currentStudy.embargoDate,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formApi.setValue('datasets[0]', mergedStudy, { shouldValidate: true });
    };

    const handleFundingOrgChange = (updatedStudy: IStudy) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formApi.setValue('datasets[0]', updatedStudy, {
            shouldDirty: true,
        });
        formApi.trigger();
        setShowFunderEmail(updatedStudy.fundingOrganizations?.length > 0);

        //update the listing request context now
        const updated: IListingRequest = {
            ...listingRequest,
            datasets: [updatedStudy],
        };
        setListingRequest(updated);
    };

    useEffect(() => {
        const updateNeeded = listingRequest?.datasets[0]?.nctId !== nctValue;
        if (!nctValue) {
            setSampleStudy(null);
            return;
        }
        handleSearch(updateNeeded);
    }, [nctValue]);

    useEffect(() => {
        if (listingRequest?.datasets[0]?.sponsorProtocolId && !listingRequest?.datasets[0]?.nctId) {
            sethasNonNctValue(true);
        }
    }, []);

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'TELL US ABOUT YOUR STUDY'} />
                <div>
                    Enter the registration ID from clinicaltrials.gov. This will bring in information about your study from
                    clinicaltrials.gov.
                    <p>
                        If you want to submit a study that has not been registered on clinicaltrials.gov, check the box that says “Study is
                        not Listed on ClinicalTrials.gov”.
                    </p>
                </div>

                {!isWithdrawn ? (
                    <div style={nctRowStyle}>
                        <CheckboxComponent
                            checked={hasNonNctValue}
                            onChange={() => sethasNonNctValue(!hasNonNctValue)}
                            label={'Study is not Listed on ClinicalTrials.gov'}
                        />

                        {hasNonNctValue ? (
                            <TextFormFieldComponent
                                name="datasets[0].sponsorProtocolId"
                                label={'Sponsor Protocol ID'}
                                key={'sponsorProtocolId'}
                                style={fourAcrossStyle}
                                readonly={isReadOnly || !isDraft}
                            />
                        ) : (
                            <TextFormFieldComponent
                                name={'datasets[0].nctId'}
                                label={'NCT ID (of the form NCT12345678)'}
                                key={'nctId'}
                                style={fourAcrossStyle}
                                readonly={isReadOnly || !isDraft}
                            />
                        )}

                        {(isAccepted || isApproved) && (
                            <ButtonComponent style={studyButtonStyle} onClick={handleViewStudy} isLoading={isLoadingSample}>
                                View Study Page
                            </ButtonComponent>
                        )}
                    </div>
                ) : (
                    <div style={Styles.FORM_ROW}>
                        {hasNonNctValue ? (
                            <TextFieldReadOnlyFormComponent
                                name="datasets[0].sponsorProtocolId"
                                label={'Sponsor Protocol ID'}
                                key={'sponsorProtocolId'}
                                style={fourAcrossStyle}
                                readonly={true}
                            />
                        ) : (
                            <TextFieldReadOnlyFormComponent
                                name={'datasets[0].nctId'}
                                label={'NCT ID (of the form NCT12345678)'}
                                key={'nctId'}
                                style={fourAcrossStyle}
                                readonly={true}
                            />
                        )}
                        <TextFieldReadOnlyFormComponent
                            label="Title"
                            style={twoAcrossStyle}
                            defaultValue={listingRequest?.withdrawnStudyTitle}
                            readonly={true}
                        />
                    </div>
                )}

                {!hasNonNctValue ? (
                    sampleStudy ? (
                        <ListingRequestStudySampleComponent sampleStudy={sampleStudy} />
                    ) : (
                        <ListingRequestStudySampleComponent sampleStudy={emptyStudy} />
                    )
                ) : (
                    <ListingRequestStudyNonNctComponent
                        isReadOnly={isReadOnly}
                        hasNonNctValue={hasNonNctValue}
                        sampleStudy={listingRequest?.datasets[0]}
                    />
                )}

                {isVivliAdmin && (
                    <div style={studyCheckBoxStyle}>
                        <CheckboxFormFieldComponent
                            name={'datasets[0].downloadableStudyIPDDataPackage'}
                            label={'Downloadable Study Data'}
                            reverseOrientation={true}
                            truncateLabel={false}
                            disabled={!isCheckboxEnabled}
                        />
                    </div>
                )}

                <div style={Styles.FORM_ROW}>
                    <TextAreaFormFieldComponent
                        name={'datasets[0].additionalInformation'}
                        label={
                            'Please include citations of any primary manuscripts and ' +
                            'include any additional information that may be helpful to a researcher when requesting this data.'
                        }
                        style={oneAcrossStyle}
                        hint={additionalInfoHint}
                        readonly={isReadOnly}
                        rows={3}
                    />

                    {showFunderEmail && (
                        <TextFormFieldComponent
                            name={'datasets[0].funderEmail'}
                            label={'Please provide a contact email at your organization for invoicing'}
                            style={oneAcrossStyle}
                            hint={funderEmailHint}
                            readonly={isReadOnly}
                        />
                    )}
                    {study && <LrFundersRorContainerComponent study={study} onFunderChange={handleFundingOrgChange} canEdit={canEditRor} />}
                </div>

                <div style={Styles.FORM_ROW}>
                    <ButtonComponent
                        style={nextButtonStyle}
                        onClick={() => handleNext(formApi, 'DataSharingSettings')}
                        isLoading={isLoadingSample}
                    >
                        Next Page
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
