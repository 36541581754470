﻿import { Size } from '@vivli/shared/theme';
import React from 'react';
import { IResearchTeamMemberDetail } from '@vivli/features/data-requests/infrastructure/interface';

interface TeamMemberComponentProps {
    member: IResearchTeamMemberDetail;
    hideReviewers: boolean;
    memberName?: any;
}

export const TeamMemberComponent = ({ member, hideReviewers, memberName }: TeamMemberComponentProps) => {
    const memberFirstName = member?.firstName;
    const memberLastName = member?.lastName;

    if (hideReviewers) {
        return (
            <div style={{ paddingLeft: Size.PADDING, borderLeft: '1px solid grey' }}>
                <h3>{memberName}</h3>
                <h4>Conflicts of Interest and Plan for Management</h4>
                <div>
                    {member?.conflictsOfInterest.split('\n').map((line, index) => (
                        <div key={`${line}-${index}`}>{line}</div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div style={{ paddingLeft: Size.PADDING, borderLeft: '1px solid grey' }}>
            <h3>
                {memberFirstName} {memberLastName}
            </h3>
            {member?.email} <br />
            {member?.position} <br />
            {member?.employerOrAffiliation} <br />
            {member?.orcidId?.length > 0 && `ORCID iD: ${member.orcidId}`}
            {member?.country?.name && `Country: ${member.country.name}`}
            <h4>Education or Qualifications</h4>
            <div>
                {member?.educationOrQualifications &&
                    member?.educationOrQualifications.split('\n').map((line, index) => <div key={`${line}-${index}`}>{line}</div>)}
            </div>
            <h4>Conflicts of Interest and Plan for Management</h4>
            <div>
                {member?.conflictsOfInterest &&
                    member?.conflictsOfInterest.split('\n').map((line, index) => <div key={`${line}-${index}`}>{line}</div>)}
            </div>
        </div>
    );
};
