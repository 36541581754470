﻿import joi, { ObjectSchema } from 'joi';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

const formSchema: ObjectSchema<IStudy> = joi
    .object({
        studyTitle: joi.string().required().label('Study Title'),
        sponsorProtocolId: joi.string().required().label('Sponsor Protocol Id'),
    })
    .options(JoiValidationOptionsConstant);

const defaultValues = (study) => ({
    ...study,
    studyTitle: study?.studyTitle || '',
    sponsorProtocolId: study?.sponsorProtocolId || '',
    nctId: study?.nctId || '',
    principalInvestigator: {
        firstName: study?.principalInvestigator?.firstName || '',
        lastName: study?.principalInvestigator?.lastName || '',
        email: study?.principalInvestigator?.emailAddress || '',
        orcidId: study?.principalInvestigator?.orcidId || '',
    },
    orgId: study?.orgId || '',
    irpOrgId: study?.irpOrgId || '',
    ipdContentType: study?.ipdContentType || '',
    studyIPDDataPackagesSuppliedOnSubmission: study?.studyIPDDataPackagesSuppliedOnSubmission || false,
    downloadableStudyIPDDataPackage: study?.downloadableStudyIPDDataPackage || false,
    showExtendedStudyMetadata: study?.showExtendedStudyMetadata || false,
    studyRequestBehavior: study?.studyRequestBehavior || '',
    externalStudyUri: study?.externalStudyUri || '',
    alternativeMetadataDocumentUri: study?.alternativeMetadataDocumentUri || '',
    overrideDisplayDefaults: study?.overrideDisplayDefaults || false,
});

export function useSubmitStudyForm(study?: IStudy, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: defaultValues(study),
        reValidateMode: 'onChange',
    });
}
