﻿import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import moment from 'moment/moment';

export function useStatusUpdate() {
    // provide the proper value or invalid value string to be used in
    // the Days In Current Workflow Step box. No asterisk required.
    const getCurrentDays = (dataRequest?: IDataRequest, currentWorkflowStepStartDate?: any) => {
        const invalidString = 'N/A';
        const dateToUse = currentWorkflowStepStartDate
            ? currentWorkflowStepStartDate
            : dataRequest?.statusUpdate?.currentWorkflowStepStartDate;
        if (!dateToUse) {
            return invalidString;
        } else {
            const origDate = moment(dateToUse).format('L');
            const nowDate = moment(Date.now()).format('L');
            if (nowDate === origDate) {
                return 1; //if it's today, count it as 1 day
            } else {
                if (dataRequest) {
                    const daysToReturn = dataRequest.daysInCurrentWorkflowStep;
                    if (daysToReturn <= 1) {
                        return invalidString;
                    }
                    return daysToReturn;
                } else {
                    const origStartDay = moment(dateToUse).startOf('day').days();
                    const nowStartDay = moment(Date.now()).startOf('day').days();

                    const stepDays = nowStartDay > origStartDay ? 1 + nowStartDay - origStartDay : invalidString;
                    return stepDays;
                }
            }
        }
    };

    return {
        getCurrentDays,
    };
}
