﻿import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import {
    awaitingCurationReviewStatus,
    awaitingCurationStatus,
    curationBlockedStatus,
    curationInProgressStatus,
    curationInternalReviewStatus,
    curationQaReviewInProgressStatus,
    curationRevisionsRequiredStatus,
    draftStatus,
} from '@vivli/features/studies/infrastructure/constants';

export function useAdminStudyStatusChange(study: IStudy) {
    const activeUser = useActiveUser();
    const userIsVivliAdmin = () => {
        return activeUser.isVivliAdmin;
    };
    const isVivAdmin = userIsVivliAdmin();

    const approveForCuration = 'Approve For Curation';
    const approvePost = 'Post to Vivli';
    const reject = 'Reject';
    const rejectRecuration = 'Return for Re-Curation';

    //if status and user allow status change based on approval
    //return message for button, otherwise return null
    const getApprovalMessage = () => {
        if (isVivAdmin && study) {
            switch (study.status) {
                case StudyStatusEnum.SubmittedToVivliForPreCheck:
                    return approveForCuration;
                    break;
                case StudyStatusEnum.SubmittedToVivliForPostCheck:
                    return approvePost;
                    break;
                default:
                    //no status change allowed
                    return null;
            }
        } else {
            return null; //data contributor can't approve anything
        }
    };

    //if status and user allow status change based on "rejection"
    //return message for button, otherwise return null
    const getRejectionMessage = () => {
        if (isVivAdmin && study) {
            switch (study.status) {
                case StudyStatusEnum.SubmittedToVivliForPreCheck:
                    return reject;
                    break;
                case StudyStatusEnum.SubmittedToVivliForPostCheck:
                case StudyStatusEnum.Posted:
                    return rejectRecuration;
                    break;
                default:
                    //no status change allowed
                    return null;
            }
        } else {
            return null; //data contributor can't approve anything
        }
    };

    const statusChangeForApprovals = () => {
        if (isVivAdmin && study) {
            switch (study.status) {
                case StudyStatusEnum.SubmittedToVivliForPreCheck:
                    return StudyStatusEnum.AwaitingCuration;
                    break;
                case StudyStatusEnum.SubmittedToVivliForPostCheck:
                    return StudyStatusEnum.Posted;
                    break;
                default:
                    //no status change allowed
                    return study.status;
            }
        } else {
            return null;
        }
    };

    const statusChangeForRejections = () => {
        if (isVivAdmin && study) {
            switch (study.status) {
                case StudyStatusEnum.SubmittedToVivliForPreCheck:
                    return StudyStatusEnum.Cancelled;
                    break;
                case StudyStatusEnum.SubmittedToVivliForPostCheck:
                case StudyStatusEnum.Posted:
                    return StudyStatusEnum.SubmittedToVivliForPreCheck;
                    break;
                default:
                    //no status change allowed
                    return study.status;
            }
        } else {
            return null;
        }
    };

    const getCurationStatusObject = () => {
        switch (study?.status) {
            case StudyStatusEnum.Draft:
                return draftStatus;
            case StudyStatusEnum.AwaitingCuration:
                return awaitingCurationStatus;
            case StudyStatusEnum.CurationInProgress:
                return curationInProgressStatus;
            case StudyStatusEnum.CurationInternalReview:
                return curationInternalReviewStatus;
            case StudyStatusEnum.AwaitingCurationQaReview:
                return awaitingCurationReviewStatus;
            case StudyStatusEnum.CurationQaReviewInProgress:
                return curationQaReviewInProgressStatus;
            case StudyStatusEnum.CurationRevisionsRequired:
                return curationRevisionsRequiredStatus;
            case StudyStatusEnum.CurationBlocked:
                return curationBlockedStatus;
            default:
                return null;
        }
    };

    return {
        statusChangeForApprovals,
        statusChangeForRejections,
        getApprovalMessage,
        getRejectionMessage,
        getCurationStatusObject,
    };
}
