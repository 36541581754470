﻿import React, { CSSProperties } from 'react';
import {
    ButtonComponent,
    CheckboxComponent,
    DatePickerComponent,
    DropdownFieldComponent,
    TextAreaComponent,
    TextFieldComponent,
} from '@vivli/shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { DuaDocumentStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { EnterInstitutionComponent } from '@vivli/features/data-requests/components';
import { useDuaDocumentTableDataHook } from '@vivli/shared/infrastructure/hook';

const gridContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '0.2fr 1.5fr 2fr 0.3fr 0.3fr 1fr 0.2fr 1fr 0.2fr',
    gap: '1px',
    backgroundColor: 'rgb(255, 246, 217)',
    height: 'fit-content',
    width: '100%',
    margin: '20px 0',
};

const gridItemStyle: CSSProperties = {
    padding: '8px',
    backgroundColor: 'lightgrey',
};

const vivliPrivateFieldStyle: CSSProperties = {
    backgroundColor: 'rgb(255, 246, 217)',
};

const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const modalStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
};
const modalHeadingStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const StatusValue = [
    {
        value: DuaDocumentStatusEnum.No_Status_Yet,
        title: 'No Status Yet',
    },
    {
        value: DuaDocumentStatusEnum.DUA_Ready_Waiting_For_Request_Approval,
        title: 'DUA Ready Waiting For Request Approval',
    },
    {
        value: DuaDocumentStatusEnum.Waiting_For_IO_Contact,
        title: 'Waiting For IO Contact',
    },
    {
        value: DuaDocumentStatusEnum.Waiting_For_Researcher_Acknowledgement,
        title: 'Waiting For Researcher Acknowledgement',
    },
    {
        value: DuaDocumentStatusEnum.Waiver_In_Negotiation,
        title: 'Waiver In Negotiation',
    },
    {
        value: DuaDocumentStatusEnum.Waiting_For_IO_Signature,
        title: 'Waiting For IO Signature',
    },
    {
        value: DuaDocumentStatusEnum.Executed,
        title: 'Executed',
    },
];

export const IndividualDuaDocumentsTableComponent = () => {
    const modalService = useModalService();

    const { templateOptions, handleUpdateRow, handleAddRow, handleDeleteRow, handleDuaDateChange, duaDocuments } =
        useDuaDocumentTableDataHook();

    const handleInstitutionNameChange = (index, field, value) => {
        const modalId = modalService.custom(
            <div style={{ ...vivliPrivateFieldStyle, padding: '20px', width: '800px' }}>
                <div style={modalStyle}>
                    <div style={modalHeadingStyle}>
                        <h3>Enter Institution</h3>
                        <FontAwesomeIcon
                            style={{ color: 'red', cursor: 'pointer' }}
                            icon={faXmark}
                            onClick={() => modalService.dismiss(modalId)}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'start', width: '100%', alignItems: 'start' }}>
                        <EnterInstitutionComponent
                            onCancel={() => modalService.dismiss(modalId)}
                            index={index}
                            field={field}
                            handleUpdateRow={handleUpdateRow}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={gridContainerStyle}>
                <div style={gridItemStyle}>PI</div>
                <div style={gridItemStyle}>Institution</div>
                <div style={gridItemStyle}>DUA type</div>
                <div style={gridItemStyle}>Status</div>
                <div style={gridItemStyle}>Execution</div>
                <div style={gridItemStyle}>Expiration</div>
                <div style={gridItemStyle}>Master</div>
                <div style={gridItemStyle}>Notes</div>
                <div style={gridItemStyle}></div>
                {duaDocuments?.map((row, index) => (
                    <React.Fragment key={index}>
                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle, width: 50 }}>
                            <CheckboxComponent
                                checked={row.forPrincipalInvestigatorOrLeadResearcher}
                                onChange={(event) =>
                                    handleUpdateRow(index, 'forPrincipalInvestigatorOrLeadResearcher', event.target.checked)
                                }
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle }} title={row.institutionName}>
                            <TextFieldComponent
                                style={{ ...vivliPrivateFieldStyle }}
                                innerStyle={{ width: '100%' }}
                                tooltip={row?.institutionName}
                                value={row?.institutionName}
                                onFocus={(event) => handleInstitutionNameChange(index, 'institutionName', event.target.value)}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle }}>
                            <DropdownFieldComponent
                                style={{ ...vivliPrivateFieldStyle, paddingBottom: '7px' }}
                                truncateLabel={false}
                                value={row.duaDocumentType?.code}
                                onChange={(value) => handleUpdateRow(index, 'duaDocumentType', value)}
                                items={templateOptions}
                                widthPerCharacter={4}
                            />
                        </div>
                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle, minWidth: 200 }}>
                            <DropdownFieldComponent
                                style={{ ...vivliPrivateFieldStyle, width: '100%', paddingBottom: '7px' }}
                                truncateLabel={false}
                                onChange={(value) => handleUpdateRow(index, 'status', value)}
                                value={row?.status}
                                items={StatusValue}
                                widthPerCharacter={4}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle, width: 100 }}>
                            <DatePickerComponent
                                style={{ ...vivliPrivateFieldStyle }}
                                optClassName="vivliPrivateDateFieldStyle"
                                onChange={(event) => {
                                    handleDuaDateChange(index, 'executionDate', event);
                                }}
                                value={row?.executionDate}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle, width: 100 }}>
                            <DatePickerComponent
                                style={{ ...vivliPrivateFieldStyle }}
                                optClassName="vivliPrivateDateFieldStyle"
                                onChange={(event) => {
                                    handleDuaDateChange(index, 'expirationDate', event);
                                }}
                                value={row?.expirationDate}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle }}>
                            <CheckboxComponent
                                checked={row.master}
                                onChange={(event) => handleUpdateRow(index, 'master', event.target.checked)}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle, minWidth: 200 }} title={row?.duaDocumentNotes}>
                            <TextAreaComponent
                                style={{ ...vivliPrivateFieldStyle }}
                                rows={3}
                                tooltip={row?.duaDocumentNotes}
                                value={row?.duaDocumentNotes}
                                onChange={(event) => handleUpdateRow(index, 'duaDocumentNotes', event.target.value)}
                            />
                        </div>

                        <div style={{ ...gridItemStyle, ...vivliPrivateFieldStyle }}>
                            <div style={buttonStyle(true)} onClick={() => handleDeleteRow(index)}>
                                <FontAwesomeIcon style={{ color: 'RED' }} icon={faTrash} />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonComponent onClick={() => handleAddRow()} style={{ margin: '20px 0', width: '5%' }}>
                    Add Row
                </ButtonComponent>
            </div>
        </div>
    );
};
