﻿import { FieldHeaderComponent, fieldHeaderStyle, leftHeaderContainerStyle } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import React from 'react';
import { CitationsFeature } from '@vivli/shared/features/citations/feature';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

const FORM_ROW_SHARED: React.CSSProperties = {
    display: 'flex',
    flex: '1 1 50%',
    maxWidth: '49%',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
};

const FORM_ROW_UNSHARED: React.CSSProperties = {
    display: 'flex',
    flex: '1 1 100%',
    maxWidth: '100%',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
};

export const DisclosuresContainerComponent = () => {
    const { dataRequest } = useDataRequestContext();

    return (
        <div style={leftHeaderContainerStyle}>
            {
                <>
                    {/*//this is reserved for the references component*/}
                    {/*//coming in a future release*/}
                    {/*<div style={FORM_ROW_SHARED}>*/}
                    {/*    <div style={{width: '95%'}}>*/}
                    {/*        <FieldHeaderComponent title={'Usage'} style={fieldHeaderStyle} />*/}
                    {/*        <div style={Styles.CONTENT_BORDER}>*/}
                    {/*            <DatasetUsageComponent studyId={studyId} studyMetadataDoi={studyMetadataDoi} studyPostedDate={studyPostedDate}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div style={FORM_ROW_UNSHARED}>
                        <div style={{ width: '100%', marginLeft: Size.INSET_SIZE }}>
                            <FieldHeaderComponent title={'Public Disclosures'} style={fieldHeaderStyle} />
                            <div style={Styles.CONTENT_BORDER}>
                                <CitationsFeature objectId={dataRequest.id} isStudy={false} doUpdate={true} doiStem={dataRequest.doiStem} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
