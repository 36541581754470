﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import {
    ResearchEnvironmentContext,
    useDataRequestContext,
    useDataRequestsService,
} from '@vivli/features/data-requests/infrastructure/context';
import React, { useEffect, useRef, useState } from 'react';
import { IVirtualMachine, IVirtualMachineUser } from '@vivli/features/virtual-machine/infrastructure/interface';
import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { IProvisionOptions, IResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/interface';
import { Subject } from 'rxjs';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { useVmPubSubHook } from '@vivli/shared/infrastructure/hook';

export const ResearchEnvironmentContextWrapper = ({ children }: IContextWrapper) => {
    const { dataRequest } = useDataRequestContext();
    const [virtualMachine, setVirtualMachine] = useState<IVirtualMachine>();
    const [isLoadingVirtualMachine, setIsLoadingVirtualMachine] = useState(false);
    const [isAttaching, setIsAttaching] = useState(false);
    const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
    const [authorizedUser, setAuthorizedUser] = useState<IVirtualMachineUser>();
    const [isWorking, setIsWorking] = useState(false);
    const [provisionStatus, setProvisionStatus] = useState<VmStatusEnum>();
    const pollRef = useRef<number>(null);
    const dataRequestsService = useDataRequestsService();
    const user = useActiveUser();
    const modalService = useModalService();

    const _getAuthorizedUser = (vm: IVirtualMachine) => {
        return vm.authorizedUsers.find((x) => x.id === user.userId);
    };

    const _updateIsAuthorizedUser = () => {
        const authorizedUser = _getAuthorizedUser(virtualMachine);
        const isAuthorized = authorizedUser !== null && authorizedUser !== undefined;
        setIsAuthorizedUser(isAuthorized);
        setAuthorizedUser(authorizedUser);
        return isAuthorized;
    };

    const _handleOnVmStarted = () => {
        setProvisionStatus(VmStatusEnum.Starting);
        vmPubSub.subscribeToVmStatusChanged();
    };

    const _handleOnVmStopped = () => {
        setProvisionStatus(VmStatusEnum.Stopping);
        vmPubSub.subscribeToVmStatusChanged();
    };

    const _handleOnProvisionStarted = () => {
        setProvisionStatus(VmStatusEnum.Provisioning);
        vmPubSub.startVmPolling(); //no vm id yet - start polling
    };

    const _handleOnDeProvisionStarted = () => {
        setProvisionStatus(VmStatusEnum.DeProvisioning);
        vmPubSub.subscribeToVmStatusChanged();
    };

    const _handleOnAddDataPackages = () => {
        setProvisionStatus(VmStatusEnum.AddingDataPackages);
        vmPubSub.subscribeToVmStatusChanged();
    };
    const _updateVmStatus = (message: string) => {
        LoggerService.info(`VMStatusChanged: ${message}`);
        dataRequestsService
            .getVirtualMachine(dataRequest.id)
            .pipe(first())
            .subscribe(
                (vm) => {
                    setVirtualMachine(vm);
                    setProvisionStatus(vm.status);
                    if (vm.status == VmStatusEnum.Provisioned) {
                        vmPubSub.subscribeToVmStatusChanged();
                    }
                },
                (error) => {
                    _handleModalError('Unable to update VM status: ' + error);
                }
            );
    };
    const vmPubSub = useVmPubSubHook(virtualMachine, _updateVmStatus);

    const subscribeToVmMessages = (callback: (message: string) => void) => {
        vmPubSub.subscribeToVmMessages(callback);
    };

    const _cleanProvisionOptions = ({ size, licenseSAS, licenseSTATA }: IProvisionOptions) => {
        return {
            size,
            licenseSAS: !!licenseSAS, // ensure we are sending true / false
            licenseSTATA: !!licenseSTATA, // ensure we are sending true / false
        };
    };

    const _activeVmProvisionOptions = () => {
        return _cleanProvisionOptions({
            size: virtualMachine.size,
            licenseSAS: virtualMachine.licenseSAS,
            licenseSTATA: virtualMachine.licenseSTATA,
        });
    };

    const _handleFinalize = () => {
        return finalize(() => setIsWorking(false));
    };
    const _handleFinalizeWithDelay = (delaySec: number) => {
        return finalize(() => setTimeout(() => setIsWorking(false), delaySec * 1000));
    };

    const _handleModalError = (error: string) => {
        modalService.error(`${error}. Please try again or contact your administrator.`);
    };

    const provisionVm = (provisionOptions: IProvisionOptions) => {
        setIsWorking(true);
        const options = _cleanProvisionOptions(provisionOptions);
        dataRequestsService
            .provisionVirtualMachine(dataRequest.id, options)
            .pipe(first(), _handleFinalizeWithDelay(60))
            .subscribe(_handleOnProvisionStarted, () => {
                setIsWorking(false);
                _handleModalError('Unable to provision your environment');
            });
    };

    const retryProvisionVm = () => {
        setIsWorking(true);
        const options = _activeVmProvisionOptions();
        dataRequestsService
            .retryProvisionVirtualMachine(dataRequest.id, options)
            .pipe(first(), _handleFinalize())
            .subscribe(_handleOnProvisionStarted, () => {
                _handleModalError('Unable to retry provisioning your environment');
            });
    };

    const deProvisionVm = (onCancel?: () => void) => {
        const title = 'Deprovision Research Environment?';
        const message = `Deprovisioning your research environment will destroy all data that is not stored in the
        Results folder of the data drive. This action cannot be undone. Are you SURE you want to proceed?`;

        modalService.confirm(message, {
            title,
            onConfirm: () => {
                setIsWorking(true);
                const options = _activeVmProvisionOptions();
                dataRequestsService
                    .deProvisionVirtualMachine(dataRequest.id, options)
                    .pipe(first(), _handleFinalize())
                    .subscribe(_handleOnDeProvisionStarted, () => {
                        _handleModalError('Unable to deprovision your environment');
                    });
            },
            onCancel: () => {
                setIsWorking(false);
                onCancel && onCancel();
            },
        });
    };

    const startVm = () => {
        setIsWorking(true);
        const options = _activeVmProvisionOptions();
        dataRequestsService
            .startVirtualMachine(dataRequest.id, options)
            .pipe(first(), _handleFinalize())
            .subscribe(_handleOnVmStarted, () => {
                _handleModalError('Unable to start your environment');
            });
    };

    const stopVm = () => {
        setIsWorking(true);
        const options = _activeVmProvisionOptions();
        dataRequestsService
            .stopVirtualMachine(dataRequest.id, options)
            .pipe(first(), _handleFinalize())
            .subscribe(_handleOnVmStopped, () => {
                _handleModalError('Unable to stop your environment');
            });
    };

    const addDataPackagesToVm = (onCancel?: () => void) => {
        const title = 'Add data packages to Research Environment?';

        const message = `Adding data packages will add any study data provided by any data
        contributor since Research Environment provisioning or since the last time "Add Data Packages" was used.
        BEFORE YOU PRESS YES, if the Add is to REPLACE data already in the Research Environment,
        log in to the Research Environment and rename the folder containing the older data version.
        The new or updated data will be placed on the Research Environment network drive.
        You can use "Add Data Packages" while the Research Environment is running.`;

        modalService.confirm(message, {
            title,
            onConfirm: () => {
                setIsWorking(true);
                const options = _activeVmProvisionOptions();
                dataRequestsService
                    .addDataPackagesToVirtualMachine(dataRequest.id, options)
                    .pipe(first(), _handleFinalize())
                    .subscribe(_handleOnAddDataPackages, () => {
                        _handleModalError('Unable to add data packages to your environment');
                    });
            },
            onCancel: () => {
                setIsWorking(false);
                onCancel && onCancel();
            },
        });
    };

    const getPasswordForUser = () => {
        const subject = new Subject<string>();

        dataRequestsService
            .getVmPasswordForActiveUser(dataRequest.id)
            .pipe(first())
            .subscribe((vm) => {
                setVirtualMachine(vm);
                const authorizedUser = _getAuthorizedUser(vm);
                subject.next(authorizedUser.password);
            });

        return subject.asObservable();
    };

    useEffect(() => {
        if (virtualMachine) {
            const authorizedUserExists = _updateIsAuthorizedUser();
            vmPubSub.initiatePubSubIfNeeded(authorizedUserExists);
            setProvisionStatus(virtualMachine.status);
        }
    }, [virtualMachine]);

    useEffect(() => {
        setIsLoadingVirtualMachine(true);
        const sub = dataRequestsService
            .getVirtualMachine(dataRequest.id)
            .pipe(
                first(),
                finalize(() => setIsLoadingVirtualMachine(false))
            )
            .subscribe(setVirtualMachine);

        return () => {
            sub.unsubscribe();
            vmPubSub.closeConnections();
        };
    }, []);

    const provider: IResearchEnvironmentContext = {
        virtualMachine,
        isLoadingVirtualMachine,
        provisionStatus,
        setProvisionStatus,
        isAttaching,
        setIsAttaching,
        isAuthorizedUser,
        setIsAuthorizedUser,
        isWorking,
        setIsWorking,
        provisionVm,
        retryProvisionVm,
        deProvisionVm,
        startVm,
        stopVm,
        addDataPackagesToVm,
        authorizedUser,
        getPasswordForUser,
        subscribeToVmMessages,
    };

    return <ResearchEnvironmentContext.Provider value={provider}>{children}</ResearchEnvironmentContext.Provider>;
};
