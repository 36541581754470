import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IResearchProposal, IResearchProposalStudyDesign, ITimeline } from '@vivli/features/data-requests/infrastructure/interface';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';

const studyDesignSchema: ObjectSchema<IResearchProposalStudyDesign> = joi
    .object({
        briefDescription: joi.string().max(9000).required().label('Brief Description'),
        elementsforOutcomes: joi.string().max(9000).required().label('Outcomes Elements Description'),
        mainPredictorIndependentVariable: joi.string().max(5000).required().label('Main Predictor/Independent Variable Description'),
        otherVariablesOfInterest: joi.string().max(9000).label('Other Variables Description').allow('').optional(),
    })
    .options(JoiValidationOptionsConstant);

const timelineSchema: ObjectSchema<ITimeline> = joi
    .object({
        projectStartDate: joi.date().required().label('Project Start Date'),
        analysisCompletionDate: joi.date().required().label('Analysis Completion Date'),
    })
    .options(JoiValidationOptionsConstant);

export const researchProposalFormSchema: ObjectSchema<IResearchProposal> = joi
    .object({
        title: joi.string().required().min(1).label('Title'),
        laySummary: joi.string().required().label('Lay Summary'),
        specificAimsObjectives: joi.string().required().label('Objective'),
        purposeOfAnalysis: joi.array().min(1).required().label('Purpose of Analysis').default([]),
        outcomes: joi.array().min(1).required().label('Outcome(s)').default([]),
        researchProposalStudyDesign: studyDesignSchema,
        projectTimeline: timelineSchema,
        disseminationAndPublicationPlan: joi.string().max(9000).label('Publication Plan'),
        purposeOfAnalysisOtherDescription: joi.when('purposeOfAnalysis', {
            is: joi.array().items(joi.string().valid('Other').required(), joi.any()).required(),
            then: joi.string().required().label('Other Purpose of Analysis'),
            otherwise: joi.optional(),
        }),
        outcomesOtherDescription: joi.when('outcomes', {
            is: joi.array().items(joi.string().valid('Other').required(), joi.any()).required(),
            then: joi.string().required().label('Other Outcome'),
            otherwise: joi.optional(),
        }),
    })
    .options(JoiValidationOptionsConstant);

export function useResearchProposalFormHook(
    researchProposal: IResearchProposal,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all'
) {
    return useForm({
        mode,
        resolver: joiResolver(researchProposalFormSchema.options(JoiValidationOptionsConstant)),
        defaultValues: researchProposal,
        reValidateMode: 'onChange',
    });
}
