﻿import { ButtonComponent, CheckboxComponent } from '@vivli/shared/components';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';
import { Color } from '@vivli/shared/theme';
import React, { CSSProperties, useState } from 'react';

const containerStyle: CSSProperties = {
    padding: 15,
};
const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    paddingTop: 10,
};
const btnContainerStyle: CSSProperties = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
};

const textStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
};

interface FileUploadWarningChatComponentProps {
    handleCancel: () => void;
    handleConfirm: () => void;
}

export const FileUploadWarningChatComponent = ({ handleCancel, handleConfirm }: FileUploadWarningChatComponentProps) => {
    const [IPDCheck, setIPDCheck] = useState(false);

    return (
        <div style={containerStyle}>
            <div style={textStyle}>
                <div>
                    The files section of Chat is not to be used for loading Individual Participant Data or for screenshots of the research
                    environment. If you need to upload data containing Individual Participant Data, please contact Vivli at{' '}
                    <a href="mailto:support@vivli.org">support@vivli.org</a> and we can help you with other ways to load the data.
                </div>
                <div>
                    If the file you just asked to load contains Individual Participant Data, please click the Cancel button below. If the
                    file does not contain Individual Participant Data, check "This is not IPD, load this data" and click on OK.
                </div>
            </div>
            <div>
                <CheckboxComponent
                    label={'This is not IPD, load this data'}
                    style={checkboxStyle}
                    checked={IPDCheck}
                    onChange={(e) => setIPDCheck(e.target.checked)}
                />
            </div>
            <div style={btnContainerStyle}>
                <ButtonComponent onClick={handleConfirm} disabled={!IPDCheck} dataId={DTICommonConst.OkButton}>
                    Ok
                </ButtonComponent>

                <ButtonComponent style={{ backgroundColor: Color.VIVLI_RED }} onClick={handleCancel} dataId={DTICommonConst.CancelButton}>
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    );
};
