import React, { CSSProperties, useEffect, useState } from 'react';
import {
    CheckboxFormFieldComponent,
    DatePickerFormFieldComponent,
    DropdownFormFieldComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { useVivliAdmins } from '@vivli/features/users/infrastructure/hook';
import './status-update-form.css';
import { SharedStatusUpdateFieldsComponent } from './shared-status-update-fields.component';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { useStatusUpdate } from '@vivli/features/data-requests/infrastructure/hook';

// this style is for items that Vivli Admins don't share
// with any users; color indicates to the Vivli Admin
// they don't have to be so careful in how they phrase things
const vivliPrivateFieldStyle: CSSProperties = {
    backgroundColor: 'rgb(255, 246, 217)',
};

const fullWidthFieldStyle: CSSProperties = {
    gridColumn: 'span 2',
};

export const StatusUpdateFormComponent = ({ daysInCurrentStep, isOrgAdmin, origWorkflowStepStartDate }) => {
    const [vivliOwners, setVivliOwners] = useState<IDropdownMenuItem[]>();
    const [therapeuticAreaItems, setTherapeuticAreaItems] = useState<IDropdownMenuItem[]>();
    const [highlightedDays, setHighlightedDays] = useState<any>(daysInCurrentStep);
    const update = useStatusUpdate();

    const { vivliAdmins } = useVivliAdmins();
    const vivliConfig = useVivliConfig();

    useEffect(() => {
        if (vivliAdmins?.length > 0) {
            const menuItems = vivliAdmins.map(({ displayName }) => ({ value: displayName, title: displayName }));
            setVivliOwners(menuItems);
        }
    }, [vivliAdmins]);

    useEffect(() => {
        if (vivliConfig?.therapeuticAreaItems?.length > 0) {
            const menuItems = vivliConfig.therapeuticAreaItems.map(({ description }) => ({
                value: description,
                title: description,
            }));
            setTherapeuticAreaItems(menuItems);
        }
    }, [vivliConfig]);

    useEffect(() => {
        const days = update.getCurrentDays(null, origWorkflowStepStartDate);
        setHighlightedDays(days);
    }, [origWorkflowStepStartDate]);

    // Org Admins see a smaller version of this form where only Feedback is editable
    if (isOrgAdmin) {
        return <SharedStatusUpdateFieldsComponent daysInCurrentStep={highlightedDays} isOrgAdmin={isOrgAdmin} />;
    }

    return (
        <>
            <DropdownFormFieldComponent
                name={'therapeuticArea'}
                label={'Therapeutic Area'}
                items={therapeuticAreaItems}
                style={fullWidthFieldStyle}
                readonly={isOrgAdmin}
            />

            <SharedStatusUpdateFieldsComponent daysInCurrentStep={daysInCurrentStep} isOrgAdmin={isOrgAdmin} />

            <TextAreaFormFieldComponent name={'internalNotes'} label={'Internal Notes'} style={vivliPrivateFieldStyle} />

            <TextFormFieldComponent name={'vmSizeFromTitle'} label={'Research Environment Size'} style={vivliPrivateFieldStyle} />
            <CheckboxFormFieldComponent
                name={'sasForVM'}
                label={'SAS'}
                reverseOrientation={true}
                truncateLabel={false}
                style={vivliPrivateFieldStyle}
            />

            <DatePickerFormFieldComponent
                name={'vmBillingStartDate'}
                label={'Start Date for Billing'}
                optClassName="vivliPrivateDateFieldStyle"
                style={vivliPrivateFieldStyle}
            />
            <TextFormFieldComponent name={'billingRatePerDay'} label={'Billing Rate (Per Day)'} style={vivliPrivateFieldStyle} />

            <TextAreaFormFieldComponent
                name={'paymentRemindersStatusHistory'}
                label={'Payment Reminders, Status, and History'}
                style={vivliPrivateFieldStyle}
            />
            {/*//NOTE DISCREPANCY BETWEEN LABEL AND NAME - property should have been named
            Banked not Booked but misnamed property has been in use for some time so we don't
            want to change it. Added this field now so whoever is working with this form understands
            discrepancy between name and label. However, note that the field will likely
            need to be moved to the correct place.*/}
            <TextAreaFormFieldComponent
                name={'vmFreeDaysBooked'}
                label={'Research Environment No-Charge Days Banked'}
                style={vivliPrivateFieldStyle}
            />

            <DropdownFormFieldComponent items={vivliOwners} name={'vivliOwner'} label={'Vivli Owners'} style={vivliPrivateFieldStyle} />
            <CheckboxFormFieldComponent
                name={'flagRequest'}
                label={'Flag Request'}
                reverseOrientation={true}
                truncateLabel={false}
                style={vivliPrivateFieldStyle}
            />

            <DatePickerFormFieldComponent
                name={'nextActionDate'}
                label={'Next Action Date'}
                optClassName="vivliPrivateDateFieldStyle"
                style={vivliPrivateFieldStyle}
            />
            <DatePickerFormFieldComponent
                name={'firstPublicationDate'}
                label={'First Publication Date'}
                optClassName="vivliPrivateDateFieldStyle"
                style={vivliPrivateFieldStyle}
            />

            <TextAreaFormFieldComponent name={'nextActionNotes'} label={'Next Action Notes'} style={vivliPrivateFieldStyle} />
            <DatePickerFormFieldComponent
                name={'currentWorkflowStepStartDate'}
                label={'Start Date of Current Step'}
                optClassName="vivliPrivateDateFieldStyle"
                style={vivliPrivateFieldStyle}
            />
        </>
    );
};
