﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { DataPackageFileApiService } from '@vivli/shared/features/data-package/infrastructure/service';
import { IDataPackageFileService } from '@vivli/shared/features/data-package/infrastructure/interface';
import React from 'react';
import {
    DataPackageFileServiceContext,
    DataPackageFileServiceSecureContext,
} from '@vivli/shared/features/data-package/infrastructure/context';

export const DataPackageFileServiceContextWrapper = ({ children }: IContextWrapper) => {
    const secureApiOptions = useDefaultApiOptions(true);
    const apiOptions = useDefaultApiOptions(false);

    const secureApi = new DataPackageFileApiService({
        ...secureApiOptions,
        errorHandler: null,
    });

    const api = new DataPackageFileApiService({
        ...apiOptions,
        errorHandler: null,
    });

    const provider: IDataPackageFileService = {
        ...api,
    };

    const secureProvider: IDataPackageFileService = {
        ...secureApi,
    };

    return (
        <DataPackageFileServiceContext.Provider value={provider}>
            <DataPackageFileServiceSecureContext.Provider value={secureProvider}>{children}</DataPackageFileServiceSecureContext.Provider>
        </DataPackageFileServiceContext.Provider>
    );
};
