﻿import React, { CSSProperties, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { useAdminCtStudyForm } from '@vivli/features/studies/infrastructure/hook';
import { GoBackLinkComponent, leftHeaderContainerStyle, rightHeaderContainerStyle } from '@vivli/shared/components';
import { Size } from '@vivli/shared/theme';
import { AdminStudyDatasetTabsComponent } from './admin-study-dataset-tabs.component';
import { AdminStudyHeaderContainerComponent } from './admin-study-header-container.component';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto',
};

const studyButtonsContainerStyle: CSSProperties = {
    ...rightHeaderContainerStyle,
    alignItems: 'right',
    marginTop: Size.PADDING,
};

const topHeaderContainerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    marginLeft: '40px',
    paddingRight: '60px',
};

export const AdminStudyFormComponent = () => {
    const { study } = useStudyContext();
    const formApi = useAdminCtStudyForm(study);

    useEffect(() => {
        // force initial validation
        formApi.trigger();
    }, []);

    return (
        <FormProvider {...formApi}>
            <div style={topHeaderContainerStyle}>
                <div style={leftHeaderContainerStyle}>
                    <GoBackLinkComponent dataId={DTICommonConst.HeaderApprovalButton('GoBack')} />
                </div>
                <div style={studyButtonsContainerStyle}>
                    <AdminStudyHeaderContainerComponent />
                </div>
            </div>
            <div style={bodyContainerStyle}>
                <AdminStudyDatasetTabsComponent />
            </div>
        </FormProvider>
    );
};
