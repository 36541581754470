﻿import React, { CSSProperties } from 'react';
import { WhiteBackgroundLogoComponent } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { useAuthService } from '@vivli/core/authentication';
import { useExternalMenuItemsHook } from '@vivli/core/infrastructure/hook';

const outerContainerStyle: CSSProperties = {
    overflowY: 'scroll',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
};
const containerStyle: CSSProperties = {
    maxWidth: '80%',
    minWidth: 400,
    marginTop: '4%',
};
const headingStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
    fontSize: Size.FontSize.XLarge,
    paddingTop: Size.PADDING,
    paddingBottom: Size.PADDING,
};

const logoPaddingStyle: CSSProperties = {
    textAlign: 'center',
    paddingBottom: Size.PADDING,
};
const adsTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
};
const accountStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: 10,
    marginTop: '5%',
};

export const loginSignUpLandingButtonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_YELLOW,
    color: Color.VIVLI_BLUE,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Color.VIVLI_BLUE,
    cursor: 'pointer',
    padding: '1%',
    marginRight: '5%',
    fontWeight: Size.FontWeight.SemiBold,
};

export const EnquiryLandingViewComponent = () => {
    const { signUp, login } = useAuthService();
    const externalMenuItems = useExternalMenuItemsHook();
    const ResourcesLink = externalMenuItems?.find((item) => item.name === 'Resources').url;
    const OurMembersLink = externalMenuItems
        ?.find((item) => item.name === 'Members')
        .menuItems?.find((item) => (item.name = 'Our Members')).url;

    return (
        <div style={outerContainerStyle} className={'scrolly'}>
            <div style={containerStyle}>
                <div style={logoPaddingStyle}>
                    <WhiteBackgroundLogoComponent />
                </div>

                <div style={headingStyle}>Enquiries for Studies</div>

                <div style={adsTextStyle}>
                    <p>
                        To enquire about a study not listed on Vivli or for additional study information not included in a study listing,
                        please login with an existing account or create an account.
                    </p>
                    <p>
                        If you are requesting multiple studies that will be part of a single research project, please request all studies as
                        part of a single enquiry, even if the studies you are looking for are from more than one contributor.
                    </p>
                    <p>
                        For more information on Vivli Members and their individual sharing policies, please visit the &nbsp;
                        <a target="_blank" rel="noopener noreferrer" href={OurMembersLink}>
                            Member Page
                        </a>
                        . &nbsp;Some Members may require that enquiries be submitted via their own portals. Enquiries will be answered at
                        the discretion of the Member. Please note that most members do not share studies where the primary completion date
                        has not yet been reached.
                    </p>
                    <p>
                        If a Member responds that a study is available, a data request will need to be submitted via the Vivli platform –
                        you will be able to create a new request or add to an existing draft request from this enquiry. Additional
                        information on how to get started is available on the Vivli site in our &nbsp;
                        <a target="_blank" rel="noopener noreferrer" href={ResourcesLink}>
                            resources section
                        </a>
                        .
                    </p>
                </div>

                <div style={accountStyle}>
                    <div onClick={() => signUp()} style={loginSignUpLandingButtonStyle}>
                        Sign Up
                    </div>

                    <div onClick={() => login()} style={loginSignUpLandingButtonStyle}>
                        Login
                    </div>
                </div>
            </div>
        </div>
    );
};
