﻿import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import { IToastService } from '@vivli/shared/infrastructure/interface';
import React from 'react';
import { ToastContext } from '@vivli/shared/infrastructure/context';
import { Color } from '@vivli/shared/theme';

const toastStyle: any = {
    backgroundColor: 'rgb(80, 80, 80, 0.8)',
    color: Color.WHITE,
    pointerEvents: 'all',
    cursor: 'pointer',
    fontSize: 12,
    lineHeight: 1,
    '@media print': {
        display: 'none',
    },
};

export let ToastInstance: IToastService = null;

export const ToastContextWrapper = ({ children }) => {
    ToastInstance = toast;

    const info = (msg: string, options?: ToastOptions) =>
        toast.info(msg, {
            toastId: 'info-toast', // prevent dups by assigning a non-unique id
            ...options,
        });

    const error = (msg: string, options?: ToastOptions) =>
        toast.info(msg, {
            toastId: 'error-toast', // prevent dups by assigning a non-unique id
            ...options,
        });

    const success = (msg: string, options?: ToastOptions) =>
        toast.info(msg, {
            toastId: 'success-toast', // prevent dups by assigning a non-unique id
            ...options,
        });

    const toastProvider: IToastService = {
        info,
        error,
        success,
        dismiss: toast.dismiss,
    };

    return (
        <ToastContext.Provider value={toastProvider}>
            {children}
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick={true}
                toastStyle={toastStyle}
                style={{ zIndex: 20000 }}
                closeButton={true}
                limit={1}
            />
        </ToastContext.Provider>
    );
};
