import React from 'react';
import { AdvancedHeaderComponent, DateRangeComponent } from '@vivli/shared/components';
import { inputGroupBreakStyle } from '../../search-filters.styles';
import { FilterGroupComponent } from '../../filter-group.component';
import { useCtSearchOptionsContext } from '@vivli/features/search/infrastructure/context';

export const DatesFilterGroupComponent = () => {
    const { fromDateRange, setFromDateRange, toDateRange, setToDateRange } = useCtSearchOptionsContext();

    return (
        <FilterGroupComponent title={'START DATE'} innerStyle={{ height: '100%' }}>
            <DateRangeComponent onChange={setFromDateRange} value={fromDateRange} />

            <div style={inputGroupBreakStyle}></div>
            <AdvancedHeaderComponent title={'END DATE'} />
            <DateRangeComponent onChange={setToDateRange} value={toDateRange} />
        </FilterGroupComponent>
    );
};
