import React, { ChangeEvent, CSSProperties } from 'react';
import { WelcomePopupComponent } from '../welcome-popup.component';
import { TranslationComponent } from '../translation.component';
import { Color, Size } from '@vivli/shared/theme';
import { ButtonComponent } from '../buttons/button.component';
import { StorageService } from '@vivli/shared/infrastructure/service';
import { LoadIndicatorColorEnum, LocalStorageKeyEnum } from '@vivli/shared/infrastructure/enum';
import { DTIWelcomeModal } from '@vivli/shared/infrastructure/constants';

const infoTextStyle = {
    fontSize: Size.FontSize.Large,
    lineHeight: '1.4',
    fontFamily: 'Verdana, sans-serif',
};

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: Color.WHITE,
    minWidth: 300,
    borderRadius: 15,
    textAlign: 'left',
};

const headerStyle: CSSProperties = {
    paddingBottom: Size.PADDING * 2,
    paddingLeft: Size.PADDING * 2,
    paddingRight: Size.PADDING * 2,
    paddingTop: Size.PADDING * 2,
    borderBottom: '1px solid white',
    fontSize: Size.FontSize.Larger,
    alignContent: 'center',
};

const bodyContainerStyle: CSSProperties = {
    paddingBottom: Size.PADDING * 2,
    paddingLeft: Size.PADDING * 2,
    paddingRight: Size.PADDING * 2,
    paddingTop: Size.PADDING * 2,
    fontSize: Size.FontSize.Medium,
};

const bodyStyle: CSSProperties = {
    display: 'flex',
    backgroundColor: 'white',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    color: 'black',
    overflowY: 'scroll',
    height: 310,
    width: 850,
    flexDirection: 'column',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    position: 'relative',
    paddingTop: '20px',
    color: 'white',
};

const checkboxStyle: CSSProperties = {
    padding: '15px',
    fontFamily: infoTextStyle.fontFamily,
    flexDirection: 'row-reverse',
    color: 'white',
    transform: 'scale(1.5)',
};

const checkboxContainerStyle: CSSProperties = {
    display: 'flex',
    position: 'absolute',
    right: '191px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    width: '150px',
};

const buttonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_RED,
};

export const WelcomeModalComponent = ({ onClose }) => {
    const handleClose = () => {
        onClose && onClose();
    };

    const handleNeverShow = (e: ChangeEvent<HTMLInputElement>) => {
        StorageService.setItem(LocalStorageKeyEnum.ShowWelcomePopup, !e.target.value);
    };

    const showNeverShowCheckbox = StorageService.getItem(LocalStorageKeyEnum.ShowWelcomePopup) !== false;

    return (
        <div style={containerStyle}>
            <div style={headerStyle} data-test-id="welcome_modal_header">
                Welcome to the <TranslationComponent>Vivli</TranslationComponent> platform
            </div>
            <div style={bodyContainerStyle}>
                <div style={bodyStyle} className="scrolly">
                    <span style={infoTextStyle} data-test-id="welcome_modal_text">
                        <WelcomePopupComponent />
                    </span>
                </div>

                <div style={buttonContainerStyle}>
                    <ButtonComponent
                        style={buttonStyle}
                        onClick={handleClose}
                        dataId={DTIWelcomeModal.WelcomeModalButtonClose}
                        loaderColor={LoadIndicatorColorEnum.White}
                    >
                        Close
                    </ButtonComponent>

                    {showNeverShowCheckbox && (
                        <div style={checkboxContainerStyle}>
                            <div>
                                <input type="checkbox" style={checkboxStyle} onChange={handleNeverShow} />
                            </div>
                            <div>
                                <label>Never show this again</label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
