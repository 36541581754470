import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { RorSearchComponent } from './ror-search.component';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { FundingOrgsContainerComponent } from './funding-orgs-container.component';
import { useModalService } from '@vivli/shared/infrastructure/context';

interface FundersRorContainerComponentProps {
    fundingOrganizations: IFundingOrganization[];
    handleFunderChange?: (fundingOrganizations: IFundingOrganization[]) => void;
    canEdit?: boolean;
    handleFundingOrgDelete?: (study: IStudy) => void;
    isListingRequest?: boolean;
}

export const FundersRorContainerComponent = ({
    fundingOrganizations,
    handleFunderChange,
    canEdit,
    handleFundingOrgDelete,
    isListingRequest,
}: FundersRorContainerComponentProps) => {
    const modal = useModalService();

    const handleRemoveFunder = (fundingOrganization: IFundingOrganization) => {
        const updatedFundingOrgs = fundingOrganizations.filter((x) => x.rorId !== fundingOrganization.rorId);
        const message = `Are you sure you want to remove the funding organization ${fundingOrganization?.rorName} from the study?`;
        modal.confirm(message, {
            onConfirm: () => {
                handleFunderChange(updatedFundingOrgs);
            },
        });
    };

    return (
        <div style={{ width: '100%' }}>
            {canEdit && <RorSearchComponent fundingOrganizations={fundingOrganizations} handleFunderChange={handleFunderChange} />}
            <FundingOrgsContainerComponent
                handleRemoveRow={handleRemoveFunder}
                fundingOrgs={fundingOrganizations}
                handleFunderChange={handleFunderChange}
                canEdit={canEdit}
                isListingRequest={isListingRequest}
            />
        </div>
    );
};
