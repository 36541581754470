import React, { CSSProperties } from 'react';
import { Styles } from '@vivli/shared/theme';
import {
    fourAcrossStyle,
    fourBigSplitAcrossStyle,
    fourSmallSplitAcrossStyle,
    LinkFieldComponent,
    oneAcrossStyle,
    TextFieldReadOnlyFormComponent,
    twoAcrossStyle,
    twoBigSplitAcrossStyle,
    twoSmallSplitAcrossStyle,
} from '@vivli/shared/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useParams } from 'react-router';
import { DownloadableStatusChangeComponent } from './downloadable-status-change.component';
import { EmbeddedQuickSearchResultsComponent } from '@vivli/features/search/components';
import { DTIFormFields } from '@vivli/shared/infrastructure/constants';
import { useDoiTransform } from '@vivli/features/studies/infrastructure/hook';

const tightRow: CSSProperties = {
    ...Styles.FORM_ROW,
    marginBottom: 0,
};

export const DatasetAdminDetailsComponent = ({ study }) => {
    const user = useActiveUser();
    const isLanding = window.location.href.includes('doiLanding');
    const { dataRequestId, reqStudyDl } = useParams();
    const reqStudyDownloadable = reqStudyDl && reqStudyDl.toLowerCase() === 'true' ? true : false;
    const showLinkDoi = study.studyMetadataDoi?.indexOf('DOINotCreated') < 0;
    const AltDoi = study.alternateDoi;
    const dataAvailabilityText = study.hasSubmittedIpd
        ? 'Data ready for sharing'
        : 'Data to be prepared and loaded to platform after request approval';
    const dataAccessibilityText = study.downloadableStudyIPDDataPackage
        ? 'Data available via secure download'
        : 'Data available only in secure research environment';
    const { transformDoiUrl } = useDoiTransform();

    const PrimaryDOIField = () => {
        if (AltDoi) {
            return (
                <LinkFieldComponent
                    label="Primary DOI"
                    linkContent={study.alternateDoi}
                    linkTitle={study.alternateDoi}
                    style={fourAcrossStyle}
                    readonly={true}
                    dataId={DTIFormFields.FormStudyDoi}
                />
            );
        }
        if (showLinkDoi) {
            return (
                <LinkFieldComponent
                    linkTitle={study.studyMetadataDoi}
                    linkContent={transformDoiUrl(study.studyMetadataDoi) /*11301*/}
                    label={'Primary DOI'}
                    style={study.sponsorProtocolId ? fourBigSplitAcrossStyle : twoAcrossStyle}
                    dataId={DTIFormFields.FormStudyDoi}
                />
            );
        }
        return (
            <TextFieldReadOnlyFormComponent
                label={'Primary DOI'}
                style={study.sponsorProtocolId ? fourBigSplitAcrossStyle : twoAcrossStyle}
                defaultValue={study.studyMetadataDoi}
                readonly={true}
                dataId={DTIFormFields.FormStudyDoi}
            />
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={tightRow}>
                <PrimaryDOIField />
                <TextFieldReadOnlyFormComponent
                    label={'Vivli ID'}
                    style={study.sponsorProtocolId ? fourSmallSplitAcrossStyle : twoAcrossStyle}
                    defaultValue={`VIV${study.doiStem}`}
                    readonly={true}
                    dataId={DTIFormFields.FormVivliId}
                />
                {study.sponsorProtocolId && (
                    <>
                        <TextFieldReadOnlyFormComponent
                            label={'Sponsor Protocol ID'}
                            style={fourAcrossStyle}
                            defaultValue={study.sponsorProtocolId}
                            readonly={true}
                            dataId={DTIFormFields.FormSponsorId}
                        />
                        <TextFieldReadOnlyFormComponent
                            label={'Acronym'}
                            style={fourAcrossStyle}
                            defaultValue={study.acronym}
                            readonly={true}
                            dataId={DTIFormFields.FormAcronym}
                        />
                    </>
                )}
            </div>

            {study.secondaryIds && study.secondaryIds.length > 0 && (
                <div style={tightRow}>
                    <TextFieldReadOnlyFormComponent
                        label={'Secondary IDs'}
                        style={oneAcrossStyle}
                        defaultValue={study.secondaryIds.join(', ')}
                        readonly={true}
                        dataId={DTIFormFields.FormSecondaryId}
                    />
                </div>
            )}

            {study.studyIPDDataPackageDoi && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.studyIPDDataPackageDoi}
                        linkContent={transformDoiUrl(study.studyIPDDataPackageDoi) /*11301*/}
                        label={'Data Package DOI(s) Available for this Study'}
                        style={oneAcrossStyle}
                        dataId={DTIFormFields.FormStudyIpdDoi}
                    />
                </div>
            )}

            {study.alternateDoi && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.studyIPDDataPackageDoi}
                        linkContent={transformDoiUrl(study.studyIPDDataPackageDoi) /*11301*/}
                        label={'Data Package DOI(s) Available for this Study'}
                        style={oneAcrossStyle}
                        dataId={DTIFormFields.FormStudyIpdDoi}
                    />
                </div>
            )}

            <div style={tightRow}>
                <TextFieldReadOnlyFormComponent
                    label={'Lead Sponsor Agency'}
                    style={twoBigSplitAcrossStyle}
                    defaultValue={study.leadSponsor.agency}
                    readonly={true}
                    dataId={DTIFormFields.FormLeadAgency}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Lead Sponsor Agency Class'}
                    style={twoSmallSplitAcrossStyle}
                    defaultValue={study.leadSponsor.agencyClass}
                    readonly={true}
                    dataId={DTIFormFields.FormAgencyClass}
                />
            </div>

            {/* Todo : this block is yuck. We'll want to come back and clean this up */}
            {study.collaborators &&
                study.collaborators.length > 0 &&
                study.collaborators.map((collaborator, index) => (
                    <div key={index} style={tightRow}>
                        {[
                            { link: collaborator.agency, title: 'Collaborator Agency' },
                            { link: collaborator.agencyClass, title: 'Collaborator Agency Class' },
                        ].map(({ link, title }, i) => (
                            <TextFieldReadOnlyFormComponent
                                key={title + '_' + index}
                                label={title}
                                style={title === 'Collaborator Agency' ? twoBigSplitAcrossStyle : twoSmallSplitAcrossStyle}
                                defaultValue={link}
                                readonly={true}
                                dataId={DTIFormFields.CollaboratorTitle(title, index)}
                            />
                        ))}
                    </div>
                ))}

            <div style={tightRow}>
                <TextFieldReadOnlyFormComponent
                    label={'Data Contributor Organization Name'}
                    style={oneAcrossStyle}
                    defaultValue={study.orgName}
                    readonly={true}
                    dataId={DTIFormFields.FormOrgName}
                />
            </div>

            {study.alternativeMetadataDocumentUri && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.alternativeMetadataDocumentUri}
                        linkContent={study.alternativeMetadataDocumentUri}
                        label="Other Resources for Study"
                        style={oneAcrossStyle}
                        dataId={DTIFormFields.FormAlternateDocumentUri}
                    />
                </div>
            )}

            {study.externalStudyUri && (
                <div style={tightRow}>
                    <LinkFieldComponent
                        linkTitle={study.externalStudyUri}
                        linkContent={study.externalStudyUri}
                        label="URL to Request Study from Sponsor"
                        style={oneAcrossStyle}
                        dataId={DTIFormFields.FormExternalStudyUri}
                    />
                </div>
            )}

            <div style={tightRow}>
                <TextFieldReadOnlyFormComponent
                    label={'Data Availability'}
                    style={twoAcrossStyle}
                    defaultValue={dataAvailabilityText}
                    readonly={true}
                    dataId={DTIFormFields.FormDataAvailability}
                />
                <TextFieldReadOnlyFormComponent
                    label={'Data Accessibility'}
                    style={twoAcrossStyle}
                    defaultValue={dataAccessibilityText}
                    readonly={true}
                    dataId={DTIFormFields.FormDataAccessibility}
                />
            </div>

            {user?.isVivliAdmin && (
                <div style={tightRow}>
                    {dataRequestId && (
                        <DownloadableStatusChangeComponent
                            dataRequestId={dataRequestId}
                            targetStudyId={study.id}
                            targetDownloadable={reqStudyDownloadable}
                        />
                    )}
                </div>
            )}

            <EmbeddedQuickSearchResultsComponent isLanding={isLanding} study={study} />
        </div>
    );
};
