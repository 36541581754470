import React, { useEffect, useRef, useState } from 'react';
import nouislider, { API } from 'nouislider';

import 'nouislider/dist/nouislider.css';
import './range-slider.styles.css';
import { ISliderRange } from '@vivli/shared/infrastructure/interface';

interface RangeSliderComponent {
    rangeValues: string[];
    value: ISliderRange;
    disabled?: boolean;
    onChange?: (range: ISliderRange) => void;
}

export const RangeSliderComponent = ({ rangeValues, value, disabled, onChange }: RangeSliderComponent) => {
    const [activeValue, setActiveValue] = useState<ISliderRange>(value);
    const sliderElementRef = useRef<HTMLDivElement>();
    const sliderRef = useRef<API>();
    const isEnabledRef = useRef(true);

    const format = {
        to: function (value) {
            // plugin returns decimal values to indicate distance between slider values
            const index = Math.floor(value);
            return rangeValues[index].toString();
        },
        from: function (value) {
            return rangeValues.indexOf(value);
        },
    };

    const updateSliderValues = () => {
        const startValues = getValues();

        if (!sliderRef.current) {
            return;
        }

        const currentValues: string | string[] = sliderRef.current.get() as string | string[];

        if (typeof currentValues == 'string') {
            return; // not using this type of value
        }

        if (currentValues?.length > 1) {
            if (startValues[0] !== currentValues[0] || startValues[1] !== currentValues[1]) {
                sliderRef.current.set(startValues);
            }
        }
    };

    const getValues = () => {
        if (!activeValue) {
            return [0, 1];
        }

        if (!activeValue && rangeValues?.length > 0) {
            return [rangeValues[0].toString(), rangeValues[rangeValues.length - 1].toString()];
        }

        return [activeValue.min, activeValue.max];
    };

    const disableSlider = () => {
        if (isEnabledRef.current) {
            isEnabledRef.current = false;

            sliderElementRef.current.setAttribute('disabled', 'true');

            sliderRef.current.updateOptions(
                {
                    tooltips: false,
                },
                false
            );

            onChange(null);
        }
    };

    const enableSlider = () => {
        if (!isEnabledRef.current) {
            isEnabledRef.current = true;

            sliderRef.current.updateOptions(
                {
                    tooltips: true,
                },
                false
            );

            sliderElementRef.current.removeAttribute('disabled');

            updateSliderValues();

            handleOnChange();
        }
    };

    const initSliderValues = () => {
        if (!sliderRef.current) {
            return;
        }

        const startValues = getValues();

        sliderRef.current.updateOptions(
            {
                start: startValues,
                range: {
                    min: [0],
                    max: [rangeValues.length - 1],
                },
                format,
                step: 1,
                tooltips: !disabled,
                margin: 1,
            },
            false
        );

        if (disabled) {
            disableSlider();
        } else {
            enableSlider();
        }
    };

    const createSlider = () => {
        const slider = nouislider.create(sliderElementRef.current, {
            start: [0, 1],
            range: {
                min: [0],
                max: [1],
            },
            connect: true,
        });

        sliderRef.current = slider;

        disableSlider();
    };

    const handleOnChange = () => {
        const currentValues = sliderRef.current.get();

        const min = currentValues[0];
        const max = currentValues[1];

        onChange && onChange({ min, max });
    };

    const setupEvents = () => {
        sliderRef.current.on('change', handleOnChange);
    };

    useEffect(() => {
        if (!sliderRef.current) {
            return;
        }

        updateSliderValues();
    }, [activeValue]);

    useEffect(() => {
        if (!activeValue || (value && (value.max !== activeValue.max || value.min !== activeValue.min))) {
            setActiveValue(value);
        } else if (!value) {
            setActiveValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (rangeValues?.length > 0 && sliderRef.current) {
            initSliderValues();
        }
    }, [rangeValues]);

    useEffect(() => {
        if (!sliderRef.current) {
            return;
        }

        if (disabled) {
            disableSlider();
        } else {
            enableSlider();
        }
    }, [disabled]);

    useEffect(() => {
        createSlider();
        setupEvents();

        return () => {
            sliderRef.current.destroy();
        };
    }, []);

    return <div ref={sliderElementRef}></div>;
};
