import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';

export function useVmStatusMapHook() {
    const getStatus = (status: VmStatusEnum) => {
        switch (status) {
            case VmStatusEnum.AddingDataPackages:
                return 'Adding Data Packages';
            case VmStatusEnum.AddingDataPackagesFailed:
                return 'Adding Data Packages Failed';
            case VmStatusEnum.DeProvisioned:
                return 'Deprovisioned';
            case VmStatusEnum.DeProvisioning:
                return 'Deprovisioning';
            case VmStatusEnum.DeProvisioningFailed:
                return 'Deprovisioning Failed';
            case VmStatusEnum.Provisioning:
                return 'Provisioning';
            case VmStatusEnum.ProvisioningFailed:
                return 'Provisioning Failed';
            case VmStatusEnum.Starting:
                return 'Starting';
            case VmStatusEnum.StartingFailed:
                return 'Starting Failed';
            case VmStatusEnum.Running:
                return 'Running';
            case VmStatusEnum.Stopping:
                return 'Stopping';
            case VmStatusEnum.StoppingFailed:
                return 'Stopping Failed';
            case VmStatusEnum.Stopped:
                return 'Stopped';
        }

        return status;
    };

    return getStatus;
}
