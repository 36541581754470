import React, { CSSProperties } from 'react';
import { useActiveUser, useConfigService } from '@vivli/core/infrastructure/context';
import { ActiveUserMenuComponent } from './active-user-menu.component';
import { SignupLoginButtonComponent } from './signup-login-button.component';
import { QuickStudyLookupButtonComponent } from './quick-study-lookup-button.component';
import { MyDataRequestsButtonComponent } from './my-data-requests-button.component';
import { EnquiryLinkComponent } from './enquiry-link.component';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

const flexStyle: CSSProperties = { display: 'flex' };

export const SubHeaderComponent = () => {
    const activeUser = useActiveUser();
    const { myDataRequestsEnabled } = useConfigService();
    const assignedAppType = useAssignedAppType();

    return (
        <div style={flexStyle}>
            <div style={flexStyle}>
                {assignedAppType !== AssignedAppTypeEnum.Amr && (
                    <>
                        <EnquiryLinkComponent />
                        <QuickStudyLookupButtonComponent />
                    </>
                )}
            </div>

            {activeUser && (myDataRequestsEnabled || activeUser.isVivliAdmin) && <MyDataRequestsButtonComponent />}

            {activeUser ? <ActiveUserMenuComponent /> : <SignupLoginButtonComponent />}
        </div>
    );
};
