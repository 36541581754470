import React, { CSSProperties, useEffect, useState } from 'react';
import { TabComponent, TabsComponent } from '@vivli/shared/components';
import { AdminConsoleTabsComponent } from './admin-console-tabs.component';
import { IAdminConsoleTab } from '@vivli/features/admin-console/infrastructure/interface';

const containerStyle: CSSProperties = {
    padding: '0 40px',
};

export interface AdminConsoleComponentProps {
    title?: string;
    tabs?: IAdminConsoleTab[];
    defaultTab?: string;
    onTabClick?: (tab: IAdminConsoleTab) => void;
    dataRefreshing?: boolean;
}

export const AdminConsoleComponent = ({ title, tabs, onTabClick }: AdminConsoleComponentProps) => {
    const [activeTab, setActiveTab] = useState('');

    const handleTabClick = (tab: IAdminConsoleTab) => {
        onTabClick && onTabClick(tab);
        setActiveTab(tab.name);
    };
    useEffect(() => {
        if (tabs !== undefined) setActiveTab(tabs[0].name);
    }, [tabs]);

    return (
        <div style={containerStyle}>
            <TabsComponent title={title} defaultTab={0}>
                {tabs?.map((tab, i) => {
                    const { title } = tab;
                    return (
                        <TabComponent key={i} title={title} onTabClick={() => handleTabClick(tab)}>
                            <AdminConsoleTabsComponent activeTab={activeTab} />
                        </TabComponent>
                    );
                })}
            </TabsComponent>
        </div>
    );
};
