export enum AnalysisPurposeEnum {
    NewResearchTreatmentEffectiveness = 'NewResearchTreatmentEffectiveness',
    NewResearchTreatmentSafety = 'NewResearchTreatmentSafety',
    ConfirmingResearchTreatmentEffectiveness = 'ConfirmingResearchTreatmentEffectiveness',
    ConfirmingResearchTreatmentSafety = 'ConfirmingResearchTreatmentSafety',
    PreliminaryResearch = 'PreliminaryResearch',
    SummaryLevelDataMetaAnalysis = 'SummaryLevelDataMetaAnalysis',
    ParticipantLevelDataMetanalysis = 'ParticipantLevelDataMetanalysis',
    SupportClinicalTrialDesign = 'SupportClinicalTrialDesign',
    StatisticalMethods = 'StatisticalMethods',
    TrainingTesting = 'TrainingTesting',
    Other = 'Other',
}
