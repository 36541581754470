import React from 'react';
import { RequestedStudiesFeature } from '@vivli/features/studies/feature';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

export const DataRequestStudiesComponent = () => {
    const { dataRequest, updateDataRequest } = useDataRequestContext();

    return (
        <RequestedStudiesFeature
            dataRequest={dataRequest}
            requestedStudies={dataRequest.requestedStudies}
            editable={dataRequest.status === DataRequestStatusEnum.Draft}
            onChange={updateDataRequest}
        />
    );
};
