import React, { useRef } from 'react';
import { IMsal2Wrapper } from './interface';
import { Msal2AuthContext } from './msal-2.context';
import { Msal2AuthFactory } from './msal-2-auth.factory';

export const Msal2Wrapper = ({ children, silentLoginOnLoad, redirectLoginOnLoad, options, externalLoginOnLoad }: IMsal2Wrapper) => {
    const authInitializedRef = useRef(false);

    const authService = Msal2AuthFactory.Create(options);

    if (!authInitializedRef.current) {
        authInitializedRef.current = true;

        authService.loadAuthModule().then((resp) => {
            if (!resp) {
                if (externalLoginOnLoad) {
                    authService.login({ signInType: 'loginRedirect', authorityType: 'sso' });
                } else if (redirectLoginOnLoad) {
                    authService.login({ signInType: 'loginRedirect' });
                } else if (silentLoginOnLoad) {
                    authService.silentLogin();
                }
            }
        });
    }

    return authService && <Msal2AuthContext.Provider value={authService}>{children}</Msal2AuthContext.Provider>;
};
