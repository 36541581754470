﻿import React, { CSSProperties } from 'react';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { Size, Styles } from '@vivli/shared/theme';
import { oneAcrossStyle, TextFormFieldComponent } from '@vivli/shared/components';
import { AdminCommonSubmissionDetailsComponent } from './admin-common-submission-details.component';
import { PiDetailsComponent } from './pi-details.component';
import { StudyDatasetIpdOptionsComponent } from './study-dataset-ipd-options-component';
import { StudyDatasetRequestTypeComponent } from '../study-dataset-request-type.component';
import { AdminCtAdministrativeSharedComponent } from './admin-ct-administrative-shared.component';
import { StudyFundersRorContainerComponent } from '@vivli/features/organizations/components';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { useFormContext } from 'react-hook-form';
import { DTICTFormFields } from '@vivli/shared/infrastructure/constants';

interface AdminCtAdministrativeDetailsComponentProps {
    user?: IUser;
}

const topStyle: CSSProperties = {
    width: '97%',
    marginLeft: Size.PADDING,
    marginRight: Size.PADDING,
};

export const AdminCtAdministrativeDetailsComponent = ({ user }: AdminCtAdministrativeDetailsComponentProps) => {
    const isVivliAdmin = user.isVivliAdmin;
    const alternativeResourcesLabel = 'Other Resources for Study';
    const alternativeResourcesHint =
        'To supply a URL for a publicly accessible document to be used during the curation process, copy the URL here. Publicly visible as Other Resources for Study';
    const formApi = useFormContext<IStudy>();
    const editable = true;
    const { handleFormSave, setActiveStudy, study } = useStudyContext();

    const handleFundingOrgChange = (updatedStudy: IStudy) => {
        formApi.setValue('fundingOrganizations', updatedStudy.fundingOrganizations, {
            shouldValidate: true,
            shouldDirty: true,
        });
        //Forcing a save here due to misbehavior in isDirty above in this case
        //This is a workaround for a suspected bug in react-hook-form to be
        //dealt with when updating to latest version.
        if (updatedStudy.fundingOrganizations?.length === 0) {
            handleFormSave(formApi);
        }

        formApi.trigger().then((r) => {
            setActiveStudy(updatedStudy);
        });
    };

    return (
        <div style={topStyle}>
            <AdminCtAdministrativeSharedComponent
                isVivliAdmin={isVivliAdmin}
                study={study}
                otherResourcesLabel={alternativeResourcesLabel}
            />

            {isVivliAdmin && (
                <div>
                    <div style={Styles.FORM_ROW}>
                        <StudyFundersRorContainerComponent study={study} canEdit={editable} onFunderChange={handleFundingOrgChange} />
                    </div>
                    <PiDetailsComponent userIsReadOnly={false} />
                    <div>
                        <AdminCommonSubmissionDetailsComponent user={user} study={study} />
                    </div>
                    <StudyDatasetRequestTypeComponent study={study} />
                    <div style={Styles.FORM_ROW}>
                        <TextFormFieldComponent
                            name="alternativeMetadataDocumentUri"
                            label={alternativeResourcesLabel}
                            style={oneAcrossStyle}
                            readonly={false}
                            hint={alternativeResourcesHint}
                            dataId={DTICTFormFields.AlternateDoi}
                        />
                    </div>
                    <StudyDatasetIpdOptionsComponent extendedMetadataOption={true} />
                </div>
            )}
        </div>
    );
};
