﻿import { FormProvider } from 'react-hook-form';
import React, { CSSProperties, useEffect } from 'react';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { useAnnotateStudyFormHook } from '@vivli/features/studies/infrastructure/hook';
import { AnnotateStudyHeaderComponent } from './annotate-study-header.component';
import { AnnotateStudyTabsComponent } from './annotate-study-tabs.component';

const containerStyle: CSSProperties = {
    height: '100%',
};
const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: 'calc(100% - 70px)',
    overflow: 'auto',
};

export const AnnotateStudyFormComponent = () => {
    const { study } = useStudyContext();
    const formApi = useAnnotateStudyFormHook(study);

    useEffect(() => {
        // force initial validation
        formApi.trigger();
    }, []);

    useEffect(() => {
        formApi.reset(study, { keepErrors: true, keepIsValid: false, keepDirty: false });
    }, [study]);

    return (
        <FormProvider {...formApi}>
            <div style={containerStyle}>
                <div>
                    <AnnotateStudyHeaderComponent />
                </div>
                <div style={bodyContainerStyle} className="scrolly">
                    <AnnotateStudyTabsComponent />
                </div>
            </div>
        </FormProvider>
    );
};
