﻿import React, { CSSProperties } from 'react';
import { Styles } from '@vivli/shared/theme';
import { ButtonComponent, CopyrightComponent } from '@vivli/shared/components';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useNavigate } from 'react-router';

const acceptedStyle: CSSProperties = {
    display: 'inline-block',
    paddingBottom: '25px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '25px',
};

const getTitle = () => {
    return decodeURIComponent(window.location.search.split('&')[1].split('=')[1]);
};

export const AcceptedDataRequestInviteComponent = () => {
    const modalService = useModalService();
    const navigate = useNavigate();
    const title = getTitle();

    return (
        <div style={{ textAlign: 'center', width: 500 }}>
            <div style={acceptedStyle}>
                <h2>Welcome!</h2>
                <br />
                You now have access to data request {title} <br />
                <br />
                <ButtonComponent
                    className="adr_continue"
                    style={{ ...Styles.Button.BUTTON_VIVLI_BLUE }}
                    onClick={() => {
                        modalService.hide();
                        navigate('/myDataRequests/draft');
                    }}
                >
                    Continue
                </ButtonComponent>
                <br />
                <br />
                <br />
                <CopyrightComponent />
            </div>
        </div>
    );
};
