﻿import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import React, { CSSProperties } from 'react';

interface MetricsHeaderComponentProps {
    title: string;
    color: string;
}

export const MetricsHeaderComponent = ({ title, color }: MetricsHeaderComponentProps) => {
    const headerStyle: CSSProperties = {
        color: 'white',
        backgroundColor: color,
        fontSize: MetricsConstants.Sizing.METRICS_HEADER_FONT_SIZE,
        padding: MetricsConstants.Sizing.METRICS_HEADER_PADDING,
    };

    return title && <div style={headerStyle}>{title}</div>;
};
