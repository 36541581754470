import { VmAdminApprovalStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';

const VmAdminApprovalLabels = {
    [VmAdminApprovalStatusEnum.NeedsApproval]: 'Needs DUA Approval',
    [VmAdminApprovalStatusEnum.NotApplicable]: 'DUA Approval Not Applicable',
    [VmAdminApprovalStatusEnum.Approved]: 'Has DUA Approval',
    [VmAdminApprovalStatusEnum.Disapproved]: 'Denied DUA Approval',
    [VmAdminApprovalStatusEnum.Automatic]: 'Automatic DUA Approval',
};

const menuVMAdminApprovalLabels = {
    [VmAdminApprovalStatusEnum.NeedsApproval]: 'DUA Approval Required',
    [VmAdminApprovalStatusEnum.Approved]: 'Has DUA Approval',
    [VmAdminApprovalStatusEnum.Automatic]: 'Has DUA Approval',
    [VmAdminApprovalStatusEnum.Disapproved]: 'DUA Approval Denied',
    [VmAdminApprovalStatusEnum.NotApplicable]: null,
};

const phaseLabels = {
    phaseTable: [
        { value: '', label: '' },
        { value: 'NA', label: 'N/A' },
        { value: 'EarlyPhase1', label: 'Early Phase 1' },
        { value: 'Phase1', label: 'Phase 1' },
        { value: 'Phase1Phase2', label: 'Phase 1/Phase 2' },
        { value: 'Phase2', label: 'Phase 2' },
        { value: 'Phase2a', label: 'Phase 2a' },
        { value: 'Phase2b', label: 'Phase 2b' },
        { value: 'Phase2Phase3', label: 'Phase 2/Phase 3' },
        { value: 'Phase3', label: 'Phase 3' },
        { value: 'Phase3a', label: 'Phase 3a' },
        { value: 'Phase3b', label: 'Phase 3b' },
        { value: 'Phase4', label: 'Phase 4' },
    ],
};

export const AdminApprovalConstant = {
    VmAdminApprovalLabels,
    menuVMAdminApprovalLabels,
    phaseLabels,
};
