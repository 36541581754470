import React from 'react';
import { filterGroupVerticalContainerStyle, innerScrollVerticalStyle, searchFiltersVerticalContainerStyle } from '../search-filters.styles';
import { FirstFilterGroupComponent } from './amr-filter-groups/first-filter-group.component';
import { SecondFilterGroupComponent } from './amr-filter-groups/second-filter-group.component';
import { ThirdFilterGroupComponent } from './amr-filter-groups/third-filter-group.component';
import { FourthFilterGroupComponent } from './amr-filter-groups/fourth-filter-group.component';
import { FifthFilterGroupComponent } from './amr-filter-groups/fifth-filter-group.component';

export const AmrSearchFiltersVerticalComponent = () => {
    return (
        <div style={searchFiltersVerticalContainerStyle}>
            <div style={innerScrollVerticalStyle}>
                <div style={filterGroupVerticalContainerStyle}>
                    <FirstFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    <SecondFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    <ThirdFilterGroupComponent />
                </div>

                <div style={filterGroupVerticalContainerStyle}>
                    {/*dropdown position fixed to top in below component*/}
                    <FourthFilterGroupComponent vertical={true} menuPlacement={'top'} />
                    <FifthFilterGroupComponent />
                </div>
            </div>
        </div>
    );
};
