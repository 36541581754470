import React, { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import mergeRefs from 'react-merge-refs';
import { Color, Size } from '@vivli/shared/theme';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { EllipsisTextComponent } from '../ellipsis-text.component';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { IFormField } from '@vivli/shared/infrastructure/interface';

const valueStyle = (innerStyle): CSSProperties => ({
    paddingTop: '10px',
    border: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    flex: '1 1 auto',
    resize: 'none',
    borderWidth: 'initial',
    borderStyle: 'none',
    borderColor: 'initial',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    width: '100%',
    ...innerStyle,
});

const fieldStyle: CSSProperties = {
    minHeight: '27px',
};

interface TextFieldComponentProps extends IFormField {
    onKeyDown?: (e) => void;
    divStyle?: CSSProperties;
    innerStyle?: CSSProperties;
    widthPerCharacter?: number;
}

export const TextFieldComponent = forwardRef((props: TextFieldComponentProps, ref) => {
    const textInputRef = useRef<HTMLInputElement>();
    const {
        defaultValue,
        value,
        readonly,
        tooltip,
        focusOnLoad,
        onChange,
        onBlur,
        onFocus,
        dataId,
        onKeyDown,
        divStyle,
        innerStyle,
        name,
        truncateLabel = true,
        widthPerCharacter = 10,
    } = props;

    const handleInitialFocus = () => {
        if (focusOnLoad && textInputRef.current) {
            textInputRef.current.focus();
        }
    };

    useEffect(() => {
        handleInitialFocus();
    }, []);

    const buildReadonlyDisplay = (props, useDefaultValue) => (
        <div style={{ ...valueStyle(innerStyle), cursor: 'default' }} title={defaultValue}>
            <Tippy content={defaultValue} disabled={!tooltip} appendTo="parent" followCursor={true} plugins={[followCursor]}>
                <div>
                    {useDefaultValue && (
                        <EllipsisTextComponent
                            {...props}
                            bypassTruncate={!truncateLabel}
                            text={props.defaultValue}
                            widthPerCharacter={widthPerCharacter}
                        />
                    )}
                    {!useDefaultValue && (
                        <EllipsisTextComponent
                            {...props}
                            text={props.value}
                            bypassTruncate={!truncateLabel}
                            widthPerCharacter={widthPerCharacter}
                        />
                    )}
                </div>
            </Tippy>
        </div>
    );

    const buildInput = (props) => (
        <input
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            type={'text'}
            onFocus={onFocus}
            style={valueStyle(innerStyle)}
            ref={mergeRefs([textInputRef, ref])}
            data-test-id={dataId || name}
            onKeyDown={onKeyDown}
        />
    );

    // prefer controlled value if it exists, then default
    const inputDisplay = value !== undefined ? buildInput({ value }) : buildInput({ defaultValue });

    const readOnlyDisplay = value !== undefined ? buildReadonlyDisplay({ value }, false) : buildReadonlyDisplay({ defaultValue }, true);

    return (
        <BaseInputFieldComponent {...props} divStyle={divStyle} type={FormFieldTypeEnum.Text} inputRef={textInputRef}>
            <div style={fieldStyle}>{!readonly ? inputDisplay : readOnlyDisplay}</div>
        </BaseInputFieldComponent>
    );
});
