﻿import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IStudy, IStudyResearchTeamMember } from '@vivli/features/studies/infrastructure/interface';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { studyPiFormSchema } from './use-study-pi-form.hook';
import { orcidInputSchema } from '@vivli/shared/infrastructure/schema';

const studyResearchTeamMembersSchema: ObjectSchema<IStudyResearchTeamMember> = joi
    .object({
        emailAddress: joi
            .string()
            .email({ tlds: { allow: false } })
            .required()
            .label('Email Address'),
        givenName: joi.string().required().label('Given Name'),
        familyName: joi.string().required().label('Family Name'),
        orcidId: orcidInputSchema('ORCID iD'),
        creditRoles: joi.array().min(1).required().label('CRediT Role(s)'),
    })
    .options(JoiValidationOptionsConstant);

const formSchema: ObjectSchema<IStudy> = joi
    .object({
        studyTitle: joi.string().required().label('Study Title'),
        sponsorProtocolId: joi.string().required().label('Sponsor Protocol Id'),
        annotatorOrgId: joi.string().required().label('Curator Organization Id'),
        annotatorOrgName: joi.string().required().label('Curator Organization Name'),
        qaReviewerOrgId: joi.string().required().label('Curation QA Reviewer Organization Id'),
        qaReviewerOrgName: joi.string().required().label('Curation QA Reviewer Organization Id'),
        principalInvestigator: studyPiFormSchema,
        researchTeam: joi.array().items(studyResearchTeamMembersSchema),
    })
    .options(JoiValidationOptionsConstant);

export function useAdminCtStudyForm(study: IStudy, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formSchema.options(JoiValidationOptionsConstant)),
        defaultValues: study,
        reValidateMode: 'onChange',
    });
}
