import { createContext, useContext } from 'react';
import { ICitationsService } from '@vivli/shared/features/citations/infrastructure/interface';

/* eslint-disable-next-line */
export const CitationsServiceSecureContext = createContext<ICitationsService>(null);

export const CitationsServiceContext = createContext<ICitationsService>(null);

export const useCitationsService = (useSecure = true) => {
    const currentContext = useSecure ? CitationsServiceSecureContext : CitationsServiceContext;
    return useContext(currentContext);
};
