﻿import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { DTIAMRCommonConst } from '@vivli/shared/infrastructure/constants';

interface DataPackageValidateButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
    title?: string;
}

export const DataPackageValidateButtonComponent = (props: DataPackageValidateButtonProps) => {
    const { onClick, style, title } = props;

    return (
        <div style={style}>
            <ButtonComponent
                onClick={onClick}
                dataId={DTIAMRCommonConst.VerifyUpload}
                title={
                    title ||
                    '"Verify Upload" will confirm that for each of the files listed below, the file itself has been fully uploaded and is stored in the system.'
                }
            >
                Verify Upload
            </ButtonComponent>
        </div>
    );
};
