import { IFacet } from '@vivli/shared/infrastructure/interface';

export const ClinicalTrialsFacetsConstant: IFacet[] = [
    { name: 'studyDesign' },
    {
        name: 'locationsOfStudySites',
        arguments: {
            count: 300,
            sort: 'value',
        },
    },
    { name: 'sponsorType' },
    { name: 'contributorType' },
    {
        name: 'sponsorName',
        arguments: {
            count: 500,
            sort: 'value',
        },
    },
    { name: 'studyType' },
    {
        name: 'actualEnrollment',
        arguments: {
            interval: 100,
        },
    },
];
