﻿import React, { createContext, useContext } from 'react';
import { ICitation } from '@vivli/shared/features/citations/infrastructure/interface';

interface ICitationsContext {
    isStudy?: boolean;
    citation?: ICitation;
    isLoading: boolean;
    citations: ICitation[];
    setCitation: React.Dispatch<React.SetStateAction<ICitation>>;

    canDeleteOrEdit(): boolean;

    removeCitation(id: string, dataRequestDoiStem: string): void;

    /*object id  is the document id of the data request or study that "owns" the citation*/
    addCitation(citationDoi: string, objectId: string, isStudy: boolean, dataRequestDoiStem: string, citation: ICitation): void;

    updateCitation(citation: ICitation, dataRequestDoiStem: string): void;

    defaultCitation: ICitation;
}

export const CitationsContext = createContext<ICitationsContext>(null);

export const useCitationsContext = () => useContext(CitationsContext);
