import React, { CSSProperties } from 'react';
import { IVirtualMachine, IVirtualMachineUser } from '@vivli/features/virtual-machine/infrastructure/interface';

interface VmUserDetailsProps {
    vmUserDetails: IVirtualMachineUser[];
}

const UserTemplateStyle: CSSProperties = {
    backgroundColor: '#ffffff',
    boxShadow: '1px 5px 9px 0px #979191',
    width: '83%',
    marginBottom: '60px',
};

const VmContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '20% 25% 15% 35%',
    gap: '15px',
    padding: '15px',
    marginLeft: '20px',
};

const VmColumStyle: CSSProperties = {
    wordWrap: 'break-word',
};

const vmUserHeader: CSSProperties = {
    marginTop: '50px',
    marginBottom: '30px',
    fontWeight: 700,
};
const noUserFoundStyle: CSSProperties = {
    padding: '25px',
    textAlign: 'center',
    fontSize: '20px',
};
export const VmUserDetailComponent = ({ vmUserDetails }: VmUserDetailsProps) => {
    return (
        <div>
            <div style={vmUserHeader}>User accounts in the VM</div>
            <div style={UserTemplateStyle}>
                {vmUserDetails.length ? (
                    <div>
                        <div style={VmContainerStyle}>
                            <div style={VmColumStyle}>Name:</div>
                            <div style={VmColumStyle}>Email:</div>
                            <div style={VmColumStyle}>UserName:</div>
                            <div style={VmColumStyle}>UserID:</div>
                        </div>
                        {vmUserDetails?.map((vmDetails, index) => (
                            <div style={VmContainerStyle} key={index}>
                                <div style={VmColumStyle}>{vmDetails.name}</div>
                                <div style={VmColumStyle}>{vmDetails.email}</div>
                                <div style={VmColumStyle}>{vmDetails.username}</div>
                                <div style={VmColumStyle}>{vmDetails.id}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div style={noUserFoundStyle}> User accounts not found</div>
                )}
            </div>
        </div>
    );
};
