import { useEffect } from 'react';
import { useToastService } from '@vivli/shared/infrastructure/context';

export function useCleanupHook() {
    const toastService = useToastService();

    useEffect(() => {
        return () => {
            toastService.dismiss();
        };
    }, []);
}
