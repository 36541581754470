import React, { CSSProperties, useEffect, useState } from 'react';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { Styles } from '@vivli/shared/theme';
import {
    LoadIndicatorCenteredComponent,
    TextFieldReadOnlyFormComponent,
    TranslationComponent,
    twoAcrossStyle,
} from '@vivli/shared/components';
import moment from 'moment';
import { useDataSetUsage } from '@vivli/features/datasets/infrastructure/hook';
import { useTranslation } from 'react-i18next';
import { TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { DTIFormFields } from '@vivli/shared/infrastructure/constants';

const helpHeaderStyle: CSSProperties = {
    fontWeight: 'bold',
    padding: '20px  40px  20px  40px',
};
const helpBodyStyle: CSSProperties = {
    padding: '0px  20px  0px  60px',
};

interface DatasetUsageComponentProps {
    studyMetadataDoi?: string;
    studyId?: string;
    studyPostedDate?: Date;
    studyApprovedDrs?: string[];
}

export const DatasetUsageComponent = ({ studyMetadataDoi, studyId, studyPostedDate, studyApprovedDrs }: DatasetUsageComponentProps) => {
    const config = useConfigService();

    const { usageData } = useDataSetUsage(studyMetadataDoi, studyId, studyPostedDate);
    const { t } = useTranslation();
    const [endDateText, setEndDateText] = useState<string>('');

    const MomentDateFormat = 'MM/DD/YYYY';
    const usageDate = usageData
        ? `from ${moment(usageData.startDate).format(`${MomentDateFormat}`)} to ${moment(usageData.endDate).format(`${MomentDateFormat}`)}`
        : '';
    const studyWord = t(TranslationKeyEnum.study);
    const downloadLabel = `Download of ${studyWord[0].toUpperCase() + studyWord.substring(1)} Documents`;

    useEffect(() => {
        if (!config) {
            return;
        }
        setEndDateText(
            config.dataCountDisplayMode == 'D'
                ? 'The end date is always the end date of the month before the current month, as usage information is tallied on a monthly basis.'
                : 'The end date is always 3 days before the current date, since it takes the system 3 days to process and tally the raw usage data.'
        );
    }, [config]);

    if (config.irusCountOn && !usageData) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label="Views"
                    style={twoAcrossStyle}
                    defaultValue={usageData?.views}
                    readonly={true}
                    dataId={DTIFormFields.FormViews}
                />

                <TextFieldReadOnlyFormComponent
                    label={downloadLabel}
                    style={twoAcrossStyle}
                    defaultValue={usageData?.datasetFilesDownloads}
                    readonly={true}
                    dataId={DTIFormFields.FormFileDownloads}
                />
            </div>

            <div style={Styles.FORM_ROW}>
                <TextFieldReadOnlyFormComponent
                    label="Access of Data Package"
                    style={twoAcrossStyle}
                    defaultValue={usageData?.downloads}
                    readonly={true}
                    dataId={DTIFormFields.FormPackageDownloads}
                />

                <TextFieldReadOnlyFormComponent
                    label="All usage metrics"
                    style={twoAcrossStyle}
                    defaultValue={usageDate}
                    readonly={true}
                    dataId={DTIFormFields.FormUsageData}
                />
            </div>
            <div style={{ width: '100%' }}>
                <TextFieldReadOnlyFormComponent
                    label="Study data package included in an approved research proposal"
                    defaultValue={studyApprovedDrs?.length}
                    readonly={true}
                />
                
            </div>
            <div>
                <div style={helpHeaderStyle}>Views:</div>
                <div style={helpBodyStyle}>
                    Vivli counts a view every time a user clicks on{' '}
                    <TranslationComponent >study</TranslationComponent> Details for this{' '}
                    <TranslationComponent>study</TranslationComponent> in a search, or displays the DOI page for this{' '}
                    <TranslationComponent>study</TranslationComponent>. In effect this counts views of the{' '}
                    <TranslationComponent>study</TranslationComponent> metadata.
                </div>
                <div style={helpHeaderStyle}>
                    Download of <TranslationComponent>study</TranslationComponent> documents:
                </div>
                <div style={helpBodyStyle}>
                    <TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent> documents are documents made
                    available to a researcher prior to requesting the <TranslationComponent>study</TranslationComponent> data to help them
                    determine whether the <TranslationComponent>study</TranslationComponent> contains the kind of data necessary to support
                    their research topic; this may include the data dictionary and/or a redacted protocol document. This metric counts the
                    number of times a <TranslationComponent>study</TranslationComponent> document is downloaded.
                </div>
                <div style={helpHeaderStyle}>Total Access of Data Packages:</div>
                <div style={helpBodyStyle}>
                    The data package includes the data that is provided in response to the request, and includes{' '}
                    {t(TranslationKeyEnum.accessDataPackagesIPD)} and supporting documents. "Access" includes{' '}
                    {t(TranslationKeyEnum.accessDPResearchEnvironment)} downloading the data.
                    Every time a data package is accessed by download or re-uploaded into a research environment, 
                    including if the data package is accessed multiple times in the same research proposal, this is counted.
                </div>
                <div style={helpHeaderStyle}><TranslationComponent style={{ textTransform: 'capitalize' }}>study</TranslationComponent> data package included in an approved research proposal:</div>
                <div style={helpBodyStyle}>
                    This metric counts the number of times a data package is included  in an approved research proposal.
                </div>
                <div style={helpHeaderStyle}>All Usage Metrics</div>
                <div style={helpBodyStyle}>
                    The data range here represents the range of dates during which the metrics above were collected. The start date is
                    either the date the data collection feature was turned on, or the date the{' '}
                    <TranslationComponent>study</TranslationComponent> was posted (whichever is later). {endDateText}
                </div>
            </div>
        </div>
    );
};
