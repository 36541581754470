﻿import React, { CSSProperties } from 'react';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { DropdownMenuItemComponent } from '../dropdown-menu/dropdown-menu-item.component';

const iconItemsContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxHeight: '300px',
    overflow: 'auto',
};
const categoryHeadingStyle: CSSProperties = {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '6px',
    borderTop: '1px #F2F3F4 solid',
};

interface HierarchicalDropdownMenuContentComponentProps {
    items: any;
    onItemClick?: (item: IDropdownMenuItem) => void;
    sort?: 'asc' | 'desc';
    optionStyle?: CSSProperties;
}

export const HierarchicalDropdownMenuContentComponent = ({
    items,
    onItemClick,
    sort,
    optionStyle,
}: HierarchicalDropdownMenuContentComponentProps) => {
    const handleContainerClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const sortFunc = (a: IDropdownMenuItem, b: IDropdownMenuItem): number => {
        return a.title.localeCompare(b.title);
    };
    const arrange = (Items) => {
        if (Items) {
            const list = Object?.keys(Items);
            sort ? (sort === 'asc' ? list.sort() : list.sort().reverse()) : list;
            list.push(list.splice(list.indexOf('Others'), 1)[0]);
            return list;
        }
    };

    // items arranged according to sort property,category-less items(Others) are always kept at last.
    const menuCategories = arrange(items);

    return (
        <div style={iconItemsContainerStyle} onClick={handleContainerClick} className="scrolly" data-test-id="dropdown_menu">
            {/* mapping through categories  */}
            {menuCategories?.map((key, i) => {
                let baseItems = items[key];
                const sortedItems = sort ? (sort === 'asc' ? baseItems?.sort(sortFunc) : baseItems?.sort(sortFunc).reverse()) : baseItems;
                return (
                    <div key={i}>
                        <div style={categoryHeadingStyle}> {key} </div>
                        <div style={{ padding: '6px' }}>
                            {/* mapping through items within the category */}
                            {sortedItems?.map((item, i) => (
                                <DropdownMenuItemComponent
                                    key={i}
                                    item={item}
                                    hierarchical={true}
                                    onItemClick={onItemClick}
                                    optionStyle={optionStyle}
                                    data-test-id={`${item.dataIdPrefix || 'default'}-menu-item`}
                                />
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
