﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { ListingRequestApiService } from '@vivli/features/listing-request/infrastructure/service';
import { IListingRequestService } from '@vivli/features/listing-request/infrastructure/interface';
import React from 'react';
import { ListingRequestServiceContext } from '@vivli/features/listing-request/infrastructure/context';

export const ListingRequestServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new ListingRequestApiService(apiOptions);

    const provider: IListingRequestService = {
        ...api,
    };

    return <ListingRequestServiceContext.Provider value={provider}>{children}</ListingRequestServiceContext.Provider>;
};
