import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';

export const DefaultBasicDataPackageListComponent = () => {
    const { t } = useTranslation();
    const vivliConfig = useVivliConfig();
    return (
        <ul>
            {vivliConfig?.ipdFileTypes
                ?.filter((x) => x.requiredCtBasic)
                .map((x) => (
                    <li>{t(x.dropDownDisplayName)}</li>
                ))}
        </ul>
    );
};
