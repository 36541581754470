﻿import React, { CSSProperties } from 'react';
import { useStudyWithUserCheck } from '@vivli/features/studies/infrastructure/hook';
import { useParams } from 'react-router';
import { SubmitNewStudyComponent, SubmitStudyFormComponent } from '@vivli/features/studies/components';
import { StudyContextWrapper } from '@vivli/features/studies/infrastructure/wrappers';

const containerStyle: CSSProperties = {
    backgroundColor: 'rgb(233, 234, 239)',
    overflow: 'hidden',
    transform: 'translate3d(0,0,0)',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    flexGrow: 2,
    height: '100%',
};

export const SubmitStudyFeature = () => {
    const { studyId } = useParams();
    const { study, studyUsedIn, studyIpdDataPackageSubmitted } = useStudyWithUserCheck(studyId);

    /* If there is no studyId (user is creating a new study), return path 1. Else return Draft path */
    if (!studyId) {
        return <SubmitNewStudyComponent />;
    }

    return (
        <div style={containerStyle}>
            <StudyContextWrapper study={study} usedIn={studyUsedIn} studyIpdPackageSubmitted={studyIpdDataPackageSubmitted}>
                <SubmitStudyFormComponent />
            </StudyContextWrapper>
        </div>
    );
};
