﻿import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import joi, { ObjectSchema } from 'joi';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { IMetricsReportDetails } from '@vivli/features/dashboard/infrastructure/interface';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';

const formSchema: ObjectSchema<IMetricsReportDetails> = joi
    .object({
        startDate: joi.date().optional().allow(null, '').label('Start Date'),
        endDate: joi.date().optional().allow(null, '').max(new Date('12/31/2099')).label('End Date'),
    })
    .options(JoiValidationOptionsConstant);

const defaultValues = (defaultOption): IMetricsReportDetails => ({
    isAggregateReport: false,
    orgId: defaultOption.value || '',
    orgName: defaultOption.title || '',
    startDate: null,
    endDate: null,
    creationDate: null,
    singleContributorRequestCount: null,
    multipleContributorRequestCount: null,
});

export function useMetricsReportFormHook(
    defaultOption?: IDropdownMenuItem,
    metrics?: IMetricsReportDetails,
    mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all'
) {
    if (!metrics) {
        metrics = defaultValues(defaultOption);
    }
    return useForm({
        mode,
        resolver: joiResolver(formSchema),
        defaultValues: metrics,
        reValidateMode: 'onChange',
    });
}
