﻿import React, { useState } from 'react';
import { Styles } from '@vivli/shared/theme';
import { ButtonComponent, CheckboxComponent } from '@vivli/shared/components';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { DTIAMRCommonConst, DTIFormFields } from '@vivli/shared/infrastructure/constants';

interface DownloadableStatusChangeComponentProps {
    dataRequestId?: string;
    targetStudyId?: string; // study id of the requested study in the data request
    targetDownloadable?: boolean;
}

export const DownloadableStatusChangeComponent = ({
    dataRequestId,
    targetStudyId,
    targetDownloadable,
}: DownloadableStatusChangeComponentProps) => {
    const toastService = useToastService();
    const modalService = useModalService();
    const dataRequestsService = useDataRequestsService();
    const [targetIsDownloadable, setTargetIsDownloadable] = useState(targetDownloadable);
    const handleButtonClick = () => {
        dataRequestsService
            .updateStudyDownloadable(dataRequestId, targetStudyId, targetIsDownloadable)
            .pipe(
                first(),
                finalize(() => {})
            )
            .subscribe(
                () => {
                    toastService.success(
                        `Successfully changed Data Package downloadable setting to ${targetIsDownloadable} for this requested study in this data request`
                    );
                },
                (error) => {
                    modalService.error(
                        'An error occured changing the downloadable setting. Please try again or contact Vivli support. Message: ' +
                            error.message
                    );
                }
            );
    };

    return (
        <div style={Styles.FORM_ROW}>
            <CheckboxComponent
                label="Allow Data Package download for this study in this request"
                checked={targetIsDownloadable}
                onChange={(e) => setTargetIsDownloadable(e.target.checked)}
                dataId={DTIFormFields.DownloadCheckBox}
            />
            <ButtonComponent dataId={DTIAMRCommonConst.SaveButton} onClick={handleButtonClick}>
                Save Data Package download changes (for this study in this data request only)
            </ButtonComponent>
        </div>
    );
};
