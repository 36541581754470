import { useEffect, useState } from 'react';
import { filter, finalize } from 'rxjs/operators';
import { useUsersService } from '@vivli/features/users/infrastructure/context';
import { IUserDetails } from '@vivli/features/users/infrastructure/interface';
import { Subscription } from 'rxjs';

export function useUsers() {
    const [users, setUsers] = useState<IUserDetails[]>();
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [isRequestsFilled, setIsRequestsFilled] = useState(false);
    const usersService = useUsersService();

    const getUsers = (fillRequests: boolean, callback: () => void): Subscription => {
        if (!isRequestsFilled) {
            //if requests were not previously retrieved with user data, get them now
            setIsLoadingUsers(true);
            return usersService
                .getUsers(fillRequests)
                .pipe(
                    filter((users) => users !== null),
                    finalize(() => setIsLoadingUsers(false))
                )
                .subscribe((users) => {
                    setUsers(users);
                    if (fillRequests) setIsRequestsFilled(true);
                    //offset callback execution to let new "users" set in.
                    callback && setTimeout(() => callback(), 500);
                });
        } else {
            //requests already have been retrieved
            callback && callback();
            return null;
        }
    };

    useEffect(() => {
        const usersSub = getUsers(false, null);

        return () => {
            usersSub.unsubscribe();
        };
    }, []);

    return {
        users,
        isLoadingUsers,
        getUsers,
    };
}
