import React from 'react';

export const StatisticalAnalysisPlanHelpComponent = () => (
    <div>
        <div>Describe how you will analyze the requested clinical study data, including:</div>
        <ul>
            <li>The reasoning behind/criteria used for selecting a specific study (ie. search criteria)</li>
            <li>
                If your proposed research involves studies from other sources, provide a full list of studies requested from other sources
                so the Data Contributors can see the full scope of the proposed research. Also please propose a brief plan on how to combine
                the results.
            </li>
            <li>Include discussion of descriptive, bivariate and multivariable analyses</li>
            <li>
                Any other planned advanced analyses (such as propensity score methods, Kaplan-Meier or Cox modeling approaches,
                non-parametric testing).{' '}
            </li>
            <li>
                Effect measure of interest (e.g. for inferential studies: risk or rate ratio, risk or rate difference, absolute difference;
                for descriptive studies: rate with confidence intervals)
            </li>
            <li>Methods to control for bias (e.g. restriction, matching, stratification, covariate adjustment)</li>
            <li>Assumptions and any planned adjustments for covariates or meta-regression or modelling of covariates</li>
            <li>The statistical approach (e.g. Bayesian or frequentist (classical), fixed or random effects)</li>
            <li>Meta-analysis approach where applicable (e.g. random effects meta-analysis, stratified meta-analysis)</li>
            <li>
                Statistical tests and methods (e.g. Fisher’s exact test, Kaplan-Meier curves, log-rank test to compare groups, multiplicity
                adjustments)
            </li>
            <li>Power to detect an effect, or the precision of the effect estimate given the sample size available</li>
            <li>Statistical power calculations and levels of significance</li>
            <li>Model fit tests, sensitivity or heterogeneity analyses (e.g. Chi-Squared Test, I squared statistic)</li>
            <li>
                Analysis of subgroups (e.g. by age, disease status, ethnicity, socioeconomic status, presence or absence or co-morbidities)
            </li>
            <li>Different types of intervention (e.g. drug dose)</li>
            <li>Handling of missing data</li>
            <li>Make sure you list all additional tools and scripts you are planning to use in your analysis</li>
        </ul>
        <div>
            Special note regarding outcome assessments in the Statistical Analysis Plan (SAP) – your SAP should cover the following
            questions:{' '}
        </div>
        <ol>
            <li>What is the approach for handling missing values? Will missing data be either excluded or imputed?</li>
            <li>What outcome elements would be assessed?</li>
            <li>How will differences in outcome measures used across studies be handled?</li>
            <li>
                If you are requesting more than one study, how will the independence of the studies will be handled (e.g. not just combing
                them all together and analysing as a single data set)?
            </li>
            <li>How will the differences in study designs be handled?</li>
            <li>Will the analysis distinguish between different phases?</li>
            <li>How will differences in patient enrollment criteria be addressed?</li>
            <li>In what country or countries will the analysis be conducted? (Optional)</li>
        </ol>
        <div>
            Please clarify how the data you have requested will help you answer the hypothesis you have proposed. This includes outlining
            the methods you will use and how you will handle any missing data. Consider providing well defined baselines and SAP details
            that consider as many variables as possible.{' '}
        </div>
    </div>
);
