import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { IFunding } from '@vivli/features/data-requests/infrastructure/interface';

const conditionalSchema = {
    is: joi.boolean().valid(true),
    then: joi.string().required(),
    otherwise: joi.optional(),
};

export const fundingFormSchema: ObjectSchema<IFunding> = joi.object({
    researchGrantsFromGovernments: joi.boolean(),
    researchGrantsFromGovernmentsDetail: joi
        .when('researchGrantsFromGovernments', conditionalSchema)
        .label('Further details for Research Grants'),
    fundingFromEmployers: joi.boolean(),
    fundingFromEmployersDetail: joi.when('fundingFromEmployers', conditionalSchema).label('Further details for Funding from Employers'),
    additionalContractsAndConsultancies: joi.boolean(),
    additionalContractsAndConsultanciesDetail: joi
        .when('additionalContractsAndConsultancies', conditionalSchema)
        .label('Further details for Additional Contracts and Consultancies'),
    fundingFromCommercialOrganizations: joi.boolean(),
    fundingFromCommercialOrganizationsDetail: joi
        .when('fundingFromCommercialOrganizations', conditionalSchema)
        .label('Further details for Funding from Commercial Organizations'),
});

export function useFundingForm(funding: IFunding, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(fundingFormSchema),
        defaultValues: funding,
        reValidateMode: 'onChange',
    });
}
