import React, { CSSProperties } from 'react';
import { Size } from '@vivli/shared/theme';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    padding: Size.INSET_SIZE,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    textAlign: 'left',
};

interface PartnerPlatformModalComponentProps {
    externalUri: string;
}

export const PartnerPlatformModalComponent = ({ externalUri }: PartnerPlatformModalComponentProps) => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    if (isAmr) {
        return (
            <div style={containerStyle}>
                <p>Please use the link below to request this dataset from the AMR partner platform.</p>

                <a target="_blank" href={externalUri}>
                    To request the datasets on the partner platform click here.
                </a>
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <p>
                Please use the link below to request the data package for this study from the Vivli partner platform. Once that partner
                platform request is approved, please contact Vivli.
            </p>

            <a target="_blank" href={externalUri}>
                To request the study on the Vivli partner platform click here.
            </a>

            <p>
                After requesting the study on the partner platform, return to the Vivli screen and complete the request in the Vivli
                platform.
            </p>
        </div>
    );
};
