import React, { useEffect, useRef, useState } from 'react';
import Gallery from 'react-fine-uploader';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import { useConfigService, useVivliConfigurationService } from '@vivli/core/infrastructure/context';
import { first } from 'rxjs/operators';
import { IVivliConfiguration } from '@vivli/shared/infrastructure/interface';
import { IDataPackageFileDescriptor } from '@vivli/shared/features/data-package/infrastructure/interface';
import { useAuthService } from '@vivli/core/authentication';

interface UploaderFeatureProps {
    dataPackageId: string;
    useSecureStorage: boolean;
    onFileUploadCompleted?: (id: number, name: string) => void;
    onFileUploadError?: (id: number, name: string, error: string) => void;
    onFileUploadStart?: (id: number, fileName: string, fileSizeKb: number, preExistingFile?: IDataPackageFileDescriptor) => void;
    onFileUploadProgress?: (id: number, name: string, percentUploaded: number) => void;
    onAllFilesCompleted?: (succeededFileIds: number[], failedFileIds: number[]) => void;
    onFileValidation?: (id: number, name: string) => boolean | Promise<boolean>;
}

export const UploaderComponent = ({
    dataPackageId,
    useSecureStorage,
    onFileUploadCompleted,
    onFileUploadError,
    onFileUploadStart,
    onFileUploadProgress,
    onAllFilesCompleted,
    onFileValidation,
}: UploaderFeatureProps) => {
    const uploaderRef = useRef(null);
    const timerRef = useRef(null);
    const [uploaderApi, setUploaderApi] = useState(null);
    const [error, setError] = useState(null);
    const [vivliConfig, setVivliConfig] = useState<IVivliConfiguration>();

    const vivliConfigService = useVivliConfigurationService();
    const config = useConfigService();
    const auth = useAuthService();

    const uploaderEndpoint = `${config.secureApiUri}api/files/${dataPackageId}/${useSecureStorage}`;

    const refreshApiToken = () => {
        auth.getTokenPopup().then((apiToken) => {
            uploaderRef.current.methods.setCustomHeaders({
                Authorization: `Bearer ${apiToken}`,
            });
        });
    };

    const startTimer = () => {
        if (!timerRef.current) {
            const timer = setInterval(() => {
                refreshApiToken();
            }, config.uploadTimeoutExtensionMinutes * 60000);
            timerRef.current = timer;
        }
    };

    const handleOnSubmitted = (id: number, name: string) => {
        startTimer();
        const fileSize = uploaderRef.current.methods.getSize(id);
        onFileUploadStart && onFileUploadStart(id, name, fileSize / 1024);
    };

    const handleOnSubmit = (id: number, name: string) => {
        // if a validation function is passed in, run it to get valid status, otherwise just let it through
        // a result of true will submit the file for upload, false will ignore it
        return onFileValidation ? onFileValidation(id, name) : true;
    };

    const handleOnProgress = (id: number, name: string, uploadedBytes: number, totalBytes: number) => {
        const percentUploaded = (uploadedBytes / totalBytes) * 100;
        onFileUploadProgress && onFileUploadProgress(id, name, percentUploaded);
    };

    const handleOnComplete = (id: number, name: string) => {
        onFileUploadCompleted && onFileUploadCompleted(id, name);
    };

    const handleOnAllComplete = (succeededFiles: number[], failedFiles: number[]) => {
        clearInterval(timerRef.current);
        timerRef.current = null;
        onAllFilesCompleted && onAllFilesCompleted(succeededFiles, failedFiles);
    };

    const handleOnError = (id: number, name: string, errorReason: string, request: any) => {
        if (onFileUploadError) {
            const invalidFileExtensionErrorReason = errorReason.includes('Valid extension(s):');
            if (invalidFileExtensionErrorReason) {
                errorReason = `The file ${name} cannot be uploaded to the Vivli Platform because the type of file is not permitted. If you believe that this file type should be permitted, please contact support@vivli.org.`;
            }
            setError(errorReason);
            onFileUploadError(id, name, errorReason);
        }
    };

    const initializeUploader = (apiToken: string) => {
        const allowedExtensions = vivliConfig.fileExtensions?.map((element: any) => (element = element.extension.replace('.', '')));

        const uploader = new FineUploaderTraditional({
            options: {
                chunking: {
                    enabled: true,
                    partSize: 2 * 1024 * 1024,
                },
                deleteFile: {
                    enabled: false,
                },
                request: {
                    endpoint: uploaderEndpoint,
                    customHeaders: {
                        Authorization: `Bearer ${apiToken}`,
                    },
                },
                retry: {
                    enableAuto: true,
                },
                callbacks: {
                    onComplete: (id, name) => handleOnComplete(id, name),
                    onProgress: (id, name, uploadedBytes, totalBytes) => handleOnProgress(id, name, uploadedBytes, totalBytes),
                    onError: (id, name, errorReason, request) => handleOnError(id, name, errorReason, request),
                    onAllComplete: (succeededFiles, failedFiles) => handleOnAllComplete(succeededFiles, failedFiles),
                    onSubmit: (id, name) => handleOnSubmit(id, name),
                    onSubmitted: (id, name) => handleOnSubmitted(id, name),
                },
                core: {
                    maxConnections: 4,
                },
                validation: {
                    allowedExtensions,
                },
            },
        });

        uploaderRef.current = uploader;
        setUploaderApi(uploader);
    };

    useEffect(() => {
        const sub = vivliConfigService
            .getVivliConfig()
            .pipe(first((vc) => vc !== null))
            .subscribe((vivliConfig) => {
                setVivliConfig(vivliConfig);
            });

        return () => {
            sub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        let isSubscribed = true;

        if (!vivliConfig) {
            return;
        }

        auth.getTokenPopup().then((token) => isSubscribed && initializeUploader(token));

        return () => {
            isSubscribed = false;
        };
    }, [vivliConfig]);

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '10px' }}>{uploaderApi && <Gallery uploader={uploaderApi} />}</div>

            {error && (
                <div
                    style={{
                        paddingBottom: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                        color: 'red',
                    }}
                >
                    Error: {error}
                </div>
            )}
        </div>
    );
};
