export function usePrioritySort(priorityOrder: string[], defaultSortBy: string) {
    function sort<T>(arrayToSort: T[]): T[] {
        const defaultPriority = priorityOrder.length + 1;

        // give every item a default priority
        const localArray = arrayToSort.map((item) => ({
            arrayItem: item,
            priority: defaultPriority,
        }));

        // give every item a priority based on the passed in priority order array
        const priorityArray = localArray.map((item) => {
            let itemPriority = item.priority;

            priorityOrder.every((o, i) => {
                // if we have a higher priority, break out of every loop
                if (itemPriority <= i) {
                    return false;
                }

                // assign the new item priority
                if (item.arrayItem[o]) {
                    itemPriority = i;
                }

                return true;
            });

            return {
                ...item,
                priority: itemPriority,
            };
        });

        // get the default priority order items (aka top of the list items)
        const defaultPriorityArr = priorityArray.filter((p) => p.priority < defaultPriority).sort((a, b) => a.priority - b.priority);

        // order everything else by the default sort string
        const defaultSortArr = priorityArray
            .filter((p) => p.priority === defaultPriority)
            .sort((a, b) => a.arrayItem[defaultSortBy].localeCompare(b.arrayItem[defaultSortBy]));

        // recombine the arrays so the priority items stay on top, and everything else below gets sorted by the default string
        return [...defaultPriorityArr, ...defaultSortArr].map(({ arrayItem }) => arrayItem);
    }

    return sort;
}
