﻿import React, { CSSProperties } from 'react';
import { Styles, Size } from '@vivli/shared/theme';
import { FieldHeaderComponent, fieldHeaderStyle, verticalTopStyle } from '@vivli/shared/components';

const bottomMarginStyle: CSSProperties = {
    marginBottom: Size.FIELD_SEPARATION,
};

export const PicoSearchTermsComponent = ({ study }) => {
    return (
        <>
            <FieldHeaderComponent title={'SEARCH TERMS (BETA)'} style={fieldHeaderStyle} />
            <div>
                <table style={Styles.FULL_WIDTH_TABLE}>
                    <tbody>
                        <tr>
                            <th style={Styles.TABLE_HEADER}>{'Population Terms'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Intervention Terms'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Outcome Terms'}</th>
                        </tr>
                        <tr style={verticalTopStyle}>
                            <td>
                                <ul style={bottomMarginStyle}>
                                    {study?.populationVocabularyItems?.map((p, i) => (
                                        <li key={i}>{p.term}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul style={bottomMarginStyle}>
                                    {study?.interventionVocabularyItems?.map((iv, i) => (
                                        <li key={i}>{iv.term}</li>
                                    ))}
                                </ul>
                            </td>
                            <td>
                                <ul style={bottomMarginStyle}>
                                    {study?.outcomeVocabularyItems?.map((o, i) => (
                                        <li key={i}>{o.term}</li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
