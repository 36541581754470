﻿import React from 'react';
import {
    AdminHeaderTitleComponent,
    ButtonComponent,
    GoBackLinkComponent,
    editRequestBtnStyle,
    editRequestTitleContainerStyle,
    leftHeaderContainerStyle,
} from '@vivli/shared/components';
import { RequestTitleComponent } from './request-title.component';

interface LeftHeaderComponentProps {
    title?: string;
    onEditTitleClick?: () => void;
    showEditTitleButton: boolean;
    myDataRequests?: boolean;
}

export const LeftHeaderComponent = ({ title, onEditTitleClick, showEditTitleButton, myDataRequests }: LeftHeaderComponentProps) => {
    return (
        <>
            {showEditTitleButton ? (
                <div style={leftHeaderContainerStyle}>
                    <GoBackLinkComponent />

                    <div style={editRequestTitleContainerStyle}>
                        <AdminHeaderTitleComponent title={title} style={{ padding: 0 }} truncateText={true} />

                        <ButtonComponent onClick={onEditTitleClick} style={editRequestBtnStyle}>
                            Edit Request Title
                        </ButtonComponent>
                    </div>
                </div>
            ) : (
                <div style={leftHeaderContainerStyle} className="do-not-print">
                    <GoBackLinkComponent />
                    <RequestTitleComponent myDataRequests={myDataRequests} />
                </div>
            )}
        </>
    );
};
