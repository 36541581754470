import React, { CSSProperties } from 'react';
import { SearchTabComponent } from './search-tab.component';
import { useCtSearchOptionsContext, usePicoSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { SearchTabEnum } from '@vivli/features/search/infrastructure/enum';

const searchTabsStyle: CSSProperties = {
    textAlign: 'center',
    color: 'white',
    fontSize: '32px',
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    height: '15px',
};

export const SearchTabsComponent = () => {
    const { isShowingResults, setActiveTab, activeTab } = useSearchContext();
    const picoSearchOptionsContext = usePicoSearchOptionsContext();
    const ctSearchOptionsContext = useCtSearchOptionsContext();

    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const handleTabClicked = (tabIndex: SearchTabEnum) => {
        if (tabIndex === activeTab) {
            return;
        }

        if (isAmr && tabIndex === SearchTabEnum.SecondTab) {
            window.open('https://amr.vivli.org/members/research-programs/');
            return;
        }

        switch (tabIndex) {
            case SearchTabEnum.SecondTab:
                ctSearchOptionsContext.resetOptions();
                picoSearchOptionsContext.initializedRef.current = true;
                break;
            case SearchTabEnum.FirstTab:
                ctSearchOptionsContext.resetOptions();
                picoSearchOptionsContext.resetOptions();
                picoSearchOptionsContext.initializedRef.current = false;
                break;
        }

        setActiveTab(tabIndex);
    };

    return (
        <div style={searchTabsStyle}>
            {!isShowingResults && (
                <>
                    <SearchTabComponent
                        activeTab={activeTab}
                        setActiveTab={handleTabClicked}
                        tabIndex={SearchTabEnum.FirstTab}
                        tabText={'KEYWORD SEARCH'}
                    />

                    <SearchTabComponent
                        activeTab={activeTab}
                        setActiveTab={handleTabClicked}
                        tabIndex={SearchTabEnum.SecondTab}
                        tabText={isAmr ? 'BROWSE' : 'PICO Beta'}
                    />
                </>
            )}
        </div>
    );
};
