import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { ResearchTeamMemberRoleEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { v4 as uuid } from 'uuid';
import { useResearchTeamCreate } from './use-research-team-create.hook';

export function useInitializeResearchTeamHook() {
    const createResearcher = useResearchTeamCreate();
    const transformDataRequest = (dataRequest: IDataRequest): IDataRequest => {
        // handle the case of a draft request where research team is empty
        let researchTeamDetails = dataRequest.researchTeam.researchTeamDetails || [];
        if (researchTeamDetails.length <= 0) {
            const lead = createResearcher(ResearchTeamMemberRoleEnum.Lead);
            const statistician = createResearcher(ResearchTeamMemberRoleEnum.Statistician);

            researchTeamDetails = [lead, statistician];
        }

        // handling the case of a draft data request where team members do not have team member IDs yet
        return {
            ...dataRequest,
            researchTeam: {
                ...dataRequest.researchTeam,
                researchTeamDetails: researchTeamDetails.map((rtd) => ({ ...rtd, id: uuid() })),
            },
        };
    };

    return { transformDataRequest };
}
