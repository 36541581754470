import React from 'react';
import { AdminTemplateStudyComponent } from './tabs/admin-template-study.component';
import { AdminAuditTrailComponent } from './tabs/admin-audit-trail.component';
import { AdminConfigSettingsComponent } from './tabs/admin-config-settings.component';
import { AdminDataRequestComponent } from './tabs/admin-data-request/admin-data-request.component';
import { AdminVirtualMachineComponent } from './tabs/admin-virtual-machine.component';
import { LoggingAreasComponent } from './tabs/logging-areas.component';

/* eslint-disable-next-line */
export interface AdminConsoleFeatureProps {
    activeTab: string;
}

export const AdminConsoleTabsComponent = ({ activeTab }: AdminConsoleFeatureProps) => {
    switch (activeTab) {
        case 'templateStudy':
            return <AdminTemplateStudyComponent />;
        case 'dataRequest':
            return <AdminDataRequestComponent />;
        case 'virtualMachine':
            return <AdminVirtualMachineComponent />;
        case 'configSettings':
            return <AdminConfigSettingsComponent />;
        case 'auditTrail':
            return <AdminAuditTrailComponent />;
        case 'logging':
            return <LoggingAreasComponent />;
        default:
            return null;
    }
};
