import React, { useEffect, useState } from 'react';
import { IDataRequest, IResearchTeamMemberDetail } from '@vivli/features/data-requests/infrastructure/interface';
import { useFormContext, useWatch } from 'react-hook-form';
import { TeamMemberRowComponent } from './team-member-row.component';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { first } from 'rxjs/operators';

interface TeamMemberRowComponentProps {
    onRemove?: (teamMember: IResearchTeamMemberDetail, fieldIndex: number) => void;
    onEdit?: (teamMember: IResearchTeamMemberDetail, fieldIndex: number) => void;
    onMakeOwner?: (teamMember: IResearchTeamMemberDetail, fieldIndex: number) => void;
    onDataRequestUpdated?: (dataRequest: IDataRequest) => void;
    isLoading: boolean;
    dataRequest: IDataRequest;
    fieldIndex: number;
    fieldName: string;
    teamMember: IResearchTeamMemberDetail;
}

export const DraftTeamMemberListItemComponent = ({
    onRemove,
    isLoading,
    dataRequest,
    teamMember,
    fieldIndex,
    fieldName,
    onEdit,
    onMakeOwner,
    onDataRequestUpdated,
}: TeamMemberRowComponentProps) => {
    const [updatingTeamMember, setUpdatingTeamMember] = useState(false);
    const [activeTeamMember, setActiveTeamMember] = useState(teamMember);
    const { setValue } = useFormContext();
    const formApi = useFormContext();
    const dataRequestsService = useDataRequestsService();
    const itemHasError = formApi.getFieldState(fieldName).invalid;

    const handleOnRemove = () => {
        onRemove && onRemove(activeTeamMember, fieldIndex);
    };

    const handleOnInviteToVm = () => {
        setValue(
            fieldName,
            {
                ...activeTeamMember,
                inviteToVirtualMachine: true,
            },
            { shouldDirty: true }
        );
    };

    const handleOnCancelInvite = () => {
        const updatedTeamMember = {
            ...activeTeamMember,
            inviteToVirtualMachine: false,
        };

        setValue(fieldName, updatedTeamMember, { shouldDirty: true });

        // if the user already has legit been invited, un-invite, otherwise no change needed except the updated member
        if (updatedTeamMember.teamMemberId) {
            setUpdatingTeamMember(true);
            dataRequestsService
                .revokeVmAccess(dataRequest.id, activeTeamMember.teamMemberId)
                .pipe(first())
                .subscribe(
                    (dr) => {
                        onDataRequestUpdated && onDataRequestUpdated(dr);
                        setUpdatingTeamMember(false);
                    },
                    () => setUpdatingTeamMember(false)
                );
        }
    };

    const handleOnMakeOwner = () => {
        onMakeOwner && onMakeOwner(teamMember, fieldIndex);
    };

    const handleRowClick = (teamMember: IResearchTeamMemberDetail) => {
        onEdit && onEdit(teamMember, fieldIndex);
    };

    useEffect(() => {
        if (teamMember) {
            setActiveTeamMember(teamMember);
        }
    }, [teamMember]);

    return (
        <TeamMemberRowComponent
            teamMember={activeTeamMember}
            isLoading={isLoading || updatingTeamMember}
            dataRequest={dataRequest}
            itemHasError={itemHasError}
            onRowClick={handleRowClick}
            onRemove={handleOnRemove}
            onInviteToVm={handleOnInviteToVm}
            onCancelInvite={handleOnCancelInvite}
            onMakeOwner={handleOnMakeOwner}
        />
    );
};
