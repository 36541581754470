export const virtualMachineTypesIntercept = [
    {
        title: 'Standard Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: null,
        machineSize: '2CPUx7GB',
        initialCost: 'No charge for 365 days',
        afterInitialPeriod: '$12/Day after 365 days, 2 concurrent logins',
        continuousExplanation: 'Can be extended by stopping the Research Environment when not in use',
        startTitle: 'Start Standard Environment',
        vmSize: 'Standard_DS2_v2',
        advanced: false,
        noCharge: 365,
    },
    {
        title: 'Premium Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: false,
        machineSize: '4CPUx14GB',
        initialCost: 'No charge for 90 days',
        afterInitialPeriod: '$25/Day after 90 days, 2 concurrent logins',
        continuousExplanation: '',
        startTitle: 'Start Premium Environment',
        vmSize: 'Standard_DS3_v2',
        advanced: false,
        noCharge: 90,
    },
    {
        title: 'Large Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: false,
        machineSize: '16CPUx64GB',
        initialCost: '$50/Day',
        afterInitialPeriod: '$50/Day',
        continuousExplanation: '',
        startTitle: 'Start Large Environment',
        vmSize: 'Standard_D16s_v3',
        advanced: true,
        noCharge: 0,
    },
    {
        title: 'Compute Optimized Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: false,
        machineSize: '32CPUx64GB',
        initialCost: '$85/Day',
        afterInitialPeriod: '$85/Day',
        continuousExplanation: '',
        startTitle: 'Start Optimized Environment',
        vmSize: 'Standard_F32s_v2',
        advanced: true,
        noCharge: 0,
    },
    {
        title: 'Memory Optimized Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: false,
        machineSize: '64CPUx128GB',
        initialCost: '$165/Day',
        afterInitialPeriod: '$165/Day',
        continuousExplanation: '',
        startTitle: 'Start Memory Optimized Environment',
        vmSize: 'Standard_F64s_v2',
        advanced: true,
        noCharge: 0,
    },
    {
        title: 'Compute Optimized (L) Environment',
        jupyterNotebook: true,
        pythonR: true,
        stata: false,
        sas: false,
        machineSize: '64CPUx504GB',
        initialCost: '$190/Day',
        afterInitialPeriod: '$190/Day',
        continuousExplanation: '',
        startTitle: 'Start Memory Optimized (L) Environment',
        vmSize: 'Standard_E64s_v4',
        advanced: true,
        noCharge: 0,
    },
];
