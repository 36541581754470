import React, { CSSProperties } from 'react';
import { IHistory } from '@vivli/shared/infrastructure/interface';

const containerStyle: CSSProperties = {
    margin: '0 -40px',
    backgroundColor: 'rgb(183, 183, 183)',
    padding: '5px 40px 20px',
};

interface VivliAdminCommentsComponentProps {
    isPrintModal: boolean;
    history: IHistory[];
}

export const VivliAdminCommentsComponent = ({ isPrintModal, history }: VivliAdminCommentsComponentProps) => {
    const adminComments = history
        .filter((entry: IHistory) => entry.action == 'VivliApprovedToAwaitingDataProvider' || entry.action == 'VivliApprovedToAwaitingIRP')
        .sort((a: IHistory, b: IHistory) => {
            const aDate = new Date(a.dateTime);
            const bDate = new Date(b.dateTime);
            const aTime = aDate.getTime();
            const bTime = bDate.getTime();

            return aTime > bTime ? -1 : aTime < bTime ? 1 : 0;
        });

    const commentToShow = adminComments.length ? adminComments[0].comment : null;
    if (!commentToShow) {
        return null;
    }

    const buildComment = (i: number, item: string, arrLength: number) => (
        <span key={i}>
            {item}
            {arrLength - 1 === i ? null : <br />}
        </span>
    );

    const comments = commentToShow.split('\n').map((item, i, arr) => buildComment(i, item, arr.length));

    return (
        <div style={isPrintModal ? {} : containerStyle}>
            <h2>Comments from the Vivli Team</h2>
            <div>{comments}</div>
        </div>
    );
};
