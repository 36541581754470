import React, { CSSProperties } from 'react';
import { SearchInputComponent } from '../search-input.component';
import { innerScrollStyle, searchBarContainerStyle, searchFiltersContainerStyle } from '../search-filters.styles';
import { useCtSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { CtFilterGroupComponent } from './ct-filter-group.component';

const ctContainerStyle: CSSProperties = {
    backgroundImage: 'linear-gradient(135deg, rgba(146, 172, 187, 0.65) 0%, rgb(146, 172, 187) 90%)',
    overflow: 'hidden auto',
};

export const CtSearchFiltersComponent = () => {
    const { setSearchString } = useCtSearchOptionsContext();

    return (
        <>
            <div style={searchBarContainerStyle}>
                <SearchInputComponent onChange={setSearchString} />
            </div>
            <div style={{ ...searchFiltersContainerStyle, ...ctContainerStyle }}>
                <div style={innerScrollStyle} className={'scrolly'}>
                    <CtFilterGroupComponent />
                </div>
            </div>
        </>
    );
};
