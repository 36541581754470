import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef, FirstDataRenderedEvent, GridApi, RowClickedEvent } from 'ag-grid-community';
import { GridCellListComponent } from './grid-cell-list.component';

import './grid.scss';
import { GridCheckboxComponent } from './grid-checkbox.component';
import { LoadIndicatorComponent } from '../load-indicator/load-indicator.component';
import { GridCellLargeTextComponent } from './grid-cell-large-text.component';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';
import { GridCellDateTimeComponent } from './grid-cell-date-time.component';
import { DTIGrid } from '@vivli/shared/infrastructure/constants';

const gridContainerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    position: 'relative',
};

const gridOverlayStyle = (paddingTop = 0): CSSProperties => ({
    margin: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: paddingTop,
});

const dataRefreshingStyle: CSSProperties = {
    position: 'absolute',
    top: -15,
    right: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '115px',
    fontSize: '10px',
};

interface GridComponentProps {
    rowData: any[];
    columns: (ColDef | ColGroupDef)[];
    onRowClick?: (data: any) => void;
    onGridReady?: (gridApi: GridApi) => void;
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
    customComponents?: { component: any; name: string }[];
    fullWidthColumns?: boolean;
    style?: CSSProperties;
    hideHeader?: boolean;
    hideFloatingFilter?: boolean;
    emptyMessage?: string;
    rowHeight?: number;
    autoHeight?: boolean;
    pagination?: boolean;
    pageSize?: number;
    asyncUpdateKey?: string;
    asyncUpdatePredicate?: (oldValue: any, newValue: any) => boolean;
    onRowCountChange?: (count: number) => void;
    rowSelection?: 'single' | 'multiple';
    highlightSelectedRow?: boolean;
    dataRefreshing?: boolean;
    dataRefreshingMessage?: string;
    flushPrevious?: boolean;
}

export const GridComponent = ({
    rowData,
    columns,
    onRowClick,
    onGridReady,
    customComponents,
    fullWidthColumns,
    style,
    hideHeader,
    emptyMessage,
    hideFloatingFilter,
    rowHeight,
    autoHeight,
    pagination,
    asyncUpdateKey,
    asyncUpdatePredicate,
    onRowCountChange,
    pageSize,
    rowSelection,
    highlightSelectedRow,
    onFirstDataRendered,
    dataRefreshing,
    dataRefreshingMessage = 'Refreshing Grid Data...',
    flushPrevious,
}: GridComponentProps) => {
    const gridApiRef = useRef<GridApi>();
    const [currentRowData, setCurrentRowData] = useState<any[]>(null);
    const [isGridReady, setIsGridReady] = useState(false);
    const flushPreviousRows = flushPrevious;

    const handleGridReady = (params) => {
        gridApiRef.current = params.api;
        onGridReady && onGridReady(params.api);
        setIsGridReady(true);
    };

    const overlayPadding = !hideHeader || !hideFloatingFilter ? (hideHeader && hideFloatingFilter ? 96 : 48) : 0;

    const frameworkComponents = {
        GridCellList: GridCellListComponent,
        GridCheckbox: GridCheckboxComponent,
        GridCellLargeText: GridCellLargeTextComponent,
        GridCellDateTime: GridCellDateTimeComponent,
        LoadingOverlay: () => <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Medium} style={gridOverlayStyle(overlayPadding)} />,
        NoRowsOverlay: () => <div style={gridOverlayStyle(overlayPadding)}>{emptyMessage || 'No Data Found'}</div>,
    };

    if (customComponents) {
        customComponents.forEach(({ component, name }) => {
            frameworkComponents[name] = component;
        });
    }

    const defaultColDef: ColDef = {
        flex: fullWidthColumns ? 1 : 0,
        autoHeight: !rowHeight,
        filter: 'text',
        filterParams: {
            suppressAndOrCondition: true,
        },
        floatingFilter: !hideFloatingFilter,
    };

    const containerStyle: CSSProperties = {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        ...style,
    };

    const handleGetRowNodeId = (data) => {
        return data[asyncUpdateKey];
    };

    const handleAsyncUpdate = (newRowData: any[]) => {
        gridApiRef.current.flushAsyncTransactions();
        currentRowData.forEach((currentRow) => {
            setTimeout(() => {
                const rowData = newRowData.find((newRow) => newRow[asyncUpdateKey] === currentRow[asyncUpdateKey]);
                if (rowData && (!asyncUpdatePredicate || asyncUpdatePredicate(currentRow, rowData))) {
                    gridApiRef.current.applyTransactionAsync({ update: [rowData] });
                }
            }, 0);
        });

        const addedRows = newRowData.filter((nr) => !currentRowData.some((cr) => cr[asyncUpdateKey] === nr[asyncUpdateKey]));
        addedRows?.forEach((ar) => {
            setTimeout(() => {
                gridApiRef.current.applyTransactionAsync({ add: [ar] });
            }, 0);
        });
    };

    const handleRowClick = (event: RowClickedEvent) => {
        const data = event.data;
        onRowClick && onRowClick(data);
    };

    const handleFilterChanged = () => {
        onRowCountChange && onRowCountChange(gridApiRef.current.getDisplayedRowCount());
    };

    useEffect(() => {
        if (!rowData || !gridApiRef.current) {
            return;
        }
        if (!currentRowData || !asyncUpdateKey) {
            setCurrentRowData(rowData);
        } else if (!flushPreviousRows) {
            handleAsyncUpdate(rowData);
        } else if (flushPreviousRows) {
            setCurrentRowData(rowData);
        }

        if (rowData.length <= 0) {
            // force no rows overlay, not automatically happening on 2nd load
            // 1 second delay to help with some of the larger grids since they load at the same time
            setTimeout(() => {
                gridApiRef.current.showNoRowsOverlay();
            }, 1000);
        }

        return () => {
            asyncUpdateKey && gridApiRef.current?.flushAsyncTransactions();
        };
    }, [rowData, isGridReady]);

    return (
        <div style={containerStyle}>
            <div className="ag-theme-alpine" data-test-id={DTIGrid.AgGrid} style={gridContainerStyle}>
                {/*commenting out until post-release when this feature can be looked at in more detail*/}
                {/*as refresh does not seem to be working correctly (aka pulling new data into the grid)*/}
                {/*{dataRefreshing && <div style={dataRefreshingStyle}>*/}
                {/*    {dataRefreshingMessage}*/}
                {/*    <LoadIndicatorComponent size={5} borderWidthEm={0.5}/>*/}
                {/*</div>}*/}
                <AgGridReact
                    domLayout={autoHeight ? 'autoHeight' : 'normal'}
                    loadingOverlayComponent="LoadingOverlay"
                    noRowsOverlayComponent="NoRowsOverlay"
                    headerHeight={hideHeader ? 0 : null}
                    rowData={currentRowData}
                    onGridReady={handleGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    rowHeight={rowHeight}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    columnDefs={columns}
                    onRowClicked={handleRowClick}
                    rowStyle={{ cursor: onRowClick ? 'pointer' : 'default' }}
                    pagination={pagination}
                    paginationPageSize={pageSize}
                    getRowNodeId={asyncUpdateKey ? handleGetRowNodeId : null}
                    onFilterChanged={handleFilterChanged}
                    rowSelection={rowSelection}
                    getRowClass={() => {
                        return highlightSelectedRow ? 'grid-highlight-selected' : '';
                    }}
                />
            </div>
        </div>
    );
};
