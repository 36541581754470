import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const ChatHintTextComponent = ({ isAmr }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                "Open Chat" provides a channel that facilitates communication among all of the individuals and organizations that will be
                involved with the review {!isAmr && <>and approval </>}of a specific data request.
            </div>
            <br />
            <div>For the Open Chat, all chat messages and all files will be visible to:</div>
            <ol>
                <li>The requestor of the data {!isAmr && <>and all members of the research team</>}</li>
                <li>All data contributors for studies requested as part of the data request</li>
                {!isAmr && <li>All IRPs or Approvers responsible for reviewing and approving the data request</li>}
                <li>Vivli Administrators</li>
            </ol>
            <br />
            <div>The chat will *not* be visible to users who are not involved in the data request.</div>
            <br />
            <div>
                <Trans>chatHelperText</Trans>
            </div>
            <br />
            <div>The information in the chat is part of the permanent record of the data request.</div>
        </div>
    );
};
