﻿import { createContext, useContext } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { IEnquiry } from '@vivli/features/enquiry/infastructure/interface';

interface IUserInfoContext {
    handleUpdateUserInfo?: () => void;
    isSavingNotes?: boolean;
    userDataRequests?: IDataRequest[];
    userEnquiries?: IEnquiry[];
}

export const UserInfoContext = createContext<IUserInfoContext>(null);

export const useUserInfoContext = () => useContext(UserInfoContext);
