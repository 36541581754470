import React, { createContext, MutableRefObject, useContext } from 'react';
import { UseFieldArrayAppend, UseFormReturn } from 'react-hook-form';
import { IDataInfoRequest, IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { Subject } from 'rxjs';
import { EnquiryStatusEnum } from '@vivli/features/enquiry/infastructure/enum';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';

interface IEnquiryContext {
    enquiry: IEnquiry;
    setEnquiryState: (enquiry: IEnquiry) => void;
    handleFormSave: (formApi: UseFormReturn<IEnquiry, object>, isSubmit: boolean, notify: boolean) => Subject<IEnquiry>;
    isLoading: boolean;
    handleGetStudy: (nctIdorsponsorId: string, index: number, formApi: UseFormReturn<IEnquiry>) => void;
    getEnquiry: (id: string) => void;
    handleError: (e: any) => void;
    updateStatus: (status: EnquiryStatusEnum, formApi: UseFormReturn<IEnquiry, object>) => void;
    addComment: (dir: IDataInfoRequest, dirIndex: number, comment: string) => void;
    formatStatus: (status: EnquiryStatusEnum) => string;
    setNotify: (dir: IDataInfoRequest, dirIndex: number) => void;
    nctError: boolean;
    sponsorIdError: boolean;
    getAddDirStudy: (enquiryId: number, dirId: number, receivedTitle: string, isNewRequest: boolean) => void;
    displaySuccessToastAndScrollToBottom: (message: string, dirIndex: number) => void;
    pageEndRef: React.RefObject<HTMLDivElement>;
    setDirAsOpen: (dirIndex: number) => void;
    setDirAsClosed: (dirIndex: number) => void;
    isDirOpen: (dirIndex: number) => boolean;
    toggleDir: (dirIndex: number) => void;
    openDirs: number[];
    closeAllDirs: () => void;
    addDirRef: (dirRef: MutableRefObject<HTMLDivElement>, dirIndex: number) => void;
    removeDirRef: (dirIndex: number) => void;
    handleDirArrayInit: (onAppend: UseFieldArrayAppend<IEnquiry>) => void;
    addNewDir: (dir: IDataInfoRequest) => void;
    study: IStudy;
    initialGetStudy: (nctIdorsponsorId: string, index: number, formApi: UseFormReturn<IEnquiry>) => void;
}

export const EnquiryContext = createContext<IEnquiryContext>(null);

export const useEnquiryContext = () => useContext(EnquiryContext);
