import React, { CSSProperties } from 'react';
import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { ICellRendererParams } from 'ag-grid-community';
import { DataRequestFormatService } from '@vivli/features/data-requests/infrastructure/service';
import { Size } from '@vivli/shared/theme';
import { TranslationComponent } from '@vivli/shared/components';
import { useTranslation } from 'react-i18next';
import { AssignedAppTypeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { DTIDataRequest } from '@vivli/shared/infrastructure/constants';

const titleStyle: CSSProperties = {
    paddingRight: '15px',
    fontSize: '15px',
    fontWeight: 600,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-all',
    overflow: 'hidden',
    minHeight: '30px',
};

const idStyle: CSSProperties = {
    fontSize: '12px',
    fontWeight: 600,
};

interface DataRequestTitleRendererComponentProps extends ICellRendererParams {
    data: IDataRequestSummary;
}

export const MyDataRequestTitleRendererComponent = ({ data }: DataRequestTitleRendererComponentProps) => {
    const singular = <TranslationComponent style={{ textTransform: 'uppercase' }}>study</TranslationComponent>;
    const plural = <TranslationComponent style={{ textTransform: 'uppercase' }}>studies</TranslationComponent>;
    const hasStudies = data?.requestedStudies?.length > 0;
    const hasMultipleStudies = data?.requestedStudies?.length > 1;
    const studiesStr = hasMultipleStudies ? plural : singular;
    const { t } = useTranslation();
    const duaTranslation = t(TranslationKeyEnum.duaValidatedStatusString);
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const statusMessage = DataRequestFormatService.mapStatusToMessage(DataRequestStatusEnum[data.status], isAmr, duaTranslation);
    const dataTitleTestId = DTIDataRequest.DataRequestTitle + `${data.requestTitle}`;
    const dataStatusTestId = DTIDataRequest.DataRequestStatus + `${data.requestTitle}`;

    return (
        <div data-test-id={dataTitleTestId}>
            <div title={data.requestTitle} style={titleStyle}>
                {data.requestTitle?.toUpperCase()} {hasStudies && '|'} {data?.requestedStudies?.length || ''} {studiesStr}
            </div>
            {data.dataRequestDoi && (
                <div style={idStyle}>
                    <TranslationComponent>Vivli</TranslationComponent> ID: {data.dataRequestDoi}
                </div>
            )}
            <div style={{ fontWeight: Size.FontWeight.SemiBold }}>
                Status:{' '}
                <span style={{ fontWeight: 'initial' }} data-test-id={dataStatusTestId}>
                    {' '}
                    {statusMessage}{' '}
                </span>
            </div>
        </div>
    );
};
