﻿import React, { useEffect, useState, useMemo } from 'react';
import { DashboardFeature, DashboardResearcherFeature } from '@vivli/features/dashboard/feature';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useStudiesAwaitingUpload } from '@vivli/features/studies/infrastructure/hook';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';

export const DashboardFeatureRouteWrapper = () => {
    const user = useActiveUser();
    const [isResearcher, setisResearcher] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { organizations } = useStudiesAwaitingUpload();
    const { isOrgAdmin, isDataProvider, isAnnotator, isIRPApprover } = useUserPermissions();

    useEffect(() => {
        if (!organizations) {
            return;
        }

        const isVivliAdmin = user.isVivliAdmin;
        if (isVivliAdmin) {
            setisResearcher(false);
            setIsLoading(false);
        } else {
            const userHasOrgRoles = isOrgAdmin || isDataProvider || isIRPApprover || isAnnotator;

            const shouldShowNew = user.orgMemberships.length < 1 || !userHasOrgRoles;

            if (isResearcher !== shouldShowNew) {
                setisResearcher(shouldShowNew);
            }

            setIsLoading(false);
        }
    }, [organizations]);

    if (isLoading) {
        return <LoadIndicatorCenteredComponent />;
    }

    return isResearcher ? <DashboardResearcherFeature /> : <DashboardFeature />;
};
