import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import moment from 'moment';
import { IChatMessage } from '@vivli/features/chat/infrastructure/interface';
import { HintPopoverComponent } from '@vivli/shared/components';
import { AssetsConstant, DTIChatFeature } from '@vivli/shared/infrastructure/constants';

interface IChatMessageComponentProps {
    displayName: string;
    organizationNames?: string[];
    message: IChatMessage;
    isSelf: boolean;
}

const messageContainerStyle = (isSelf: boolean): CSSProperties => ({
    display: 'flex',
    flexDirection: isSelf ? 'row' : 'row-reverse',
    marginBottom: Size.PADDING,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    fontSize: Size.FontSize.Large,
});

const messageStyle = (isSelf: boolean): CSSProperties => ({
    borderRadius: 8,
    backgroundColor: isSelf ? Color.LIGHT_GRAY : Color.TEXT_DARK_BLUE,
    color: isSelf ? Color.TEXT_DARK_GRAY : Color.WHITE,
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    maxWidth: '80%',
    wordWrap: 'break-word',
});

const hintIconStyle = (isSelf: boolean): CSSProperties => ({
    width: 14,
    borderRadius: 7,
    backgroundColor: isSelf ? Color.TEXT_LIGHT_BLUE : Color.VIVLI_DARK_BLUE,
    overflow: 'hidden',
});

const timestampStyle = (isSelf: boolean): CSSProperties => ({
    fontSize: Size.FontSize.Medium,
    display: 'flex',
    alignItems: 'center',
    paddingRight: isSelf ? Size.PADDING : 0,
    paddingLeft: isSelf ? Size.PADDING : 0,
});

const userNameStyle: CSSProperties = {
    fontWeight: Size.FontWeight.Bold,
};

export const ChatMessageComponent = ({ message, isSelf, displayName, organizationNames }: IChatMessageComponentProps) => {
    if (!message || !message.text) {
        return null;
    }

    const orgNamesToUse = displayName === 'Vivli Administrator' ? 'Vivli' : organizationNames?.join(', ');
    const hintMessage = `Orgs: ${orgNamesToUse}`;

    // @ts-ignore
    return (
        <div style={messageContainerStyle(isSelf)}>
            <div style={messageStyle(isSelf)}>
                <div>
                    <div>
                        <span data-test-id={DTIChatFeature.ChatMessage('username')} style={userNameStyle}>
                            {displayName}
                        </span>
                        {organizationNames && (
                            <HintPopoverComponent
                                position={'top'}
                                hintText={hintMessage}
                                maxWidth={Size.POPOVER_MAX_WIDTH}
                                popoverColor={isSelf ? Color.TEXT_LIGHT_BLUE : Color.VIVLI_DARK_BLUE}
                                popoverArrowColor={isSelf ? Color.TEXT_LIGHT_BLUE : Color.VIVLI_DARK_BLUE}
                                icon={AssetsConstant.INFO_BANG}
                                iconStyle={hintIconStyle(isSelf)}
                            />
                        )}
                    </div>
                </div>
                <div data-test-id={DTIChatFeature.ChatMessage('text')} style={{ marginTop: Size.HALF_PADDING }}>
                    {message?.text?.split('\n').map(function (item, i, arr) {
                        return (
                            <span key={i}>
                                {item}
                                {arr.length - 1 === i ? null : <br />}
                            </span>
                        );
                    })}
                </div>
            </div>
            <div style={timestampStyle(isSelf)}>
                {navigator.language === 'en-US'
                    ? moment(message.sent).format('M/D/YY h:mm a')
                    : moment(message.sent).format('D/M/YY h:mm a')}
            </div>
        </div>
    );
};
