﻿import { IFundingOrganization, IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { RorOrgSearchComponent } from './ror-org-search.component';
import { RorFundersSearchComponent } from './ror-funders-search.component';

interface RorSearchComponentProps {
    organization?: IOrganization;
    fundingOrganizations?: IFundingOrganization[];
    handleFunderChange?: (fundingOrganizations: IFundingOrganization[]) => void;
}

export const RorSearchComponent = ({ organization, fundingOrganizations, handleFunderChange }: RorSearchComponentProps) => {
    if (organization) {
        return <RorOrgSearchComponent organization={organization} />;
    }

    return <RorFundersSearchComponent fundingOrganizations={fundingOrganizations} handleFunderChange={handleFunderChange} />;
};
