﻿import { IRor, IRorResponseItem } from '@vivli/features/organizations/infrastructure/interface';

export function useRorOrganizations() {
    const getRorInformationFromSelection = (selections: any, suggestedMatches: IRorResponseItem[], currentRor: IRor) => {
        const selectedString: string = selections;
        const selectedRor = suggestedMatches.find((r) => r.id === selectedString);
        if (selectedRor) {
            let newRorInfo: IRor = { ...currentRor };
            newRorInfo.rorId = selectedRor.id;
            newRorInfo.rorName = selectedRor.name;
            if (selectedRor.relationships.find((r) => r.type === 'Parent')) {
                newRorInfo.parentRorId = selectedRor.relationships.find((r) => r.type === 'Parent').id;
                newRorInfo.parentRorName = selectedRor.relationships.find((r) => r.type === 'Parent').label;
            } else {
                newRorInfo.parentRorId = null;
                newRorInfo.parentRorName = '';
            }
            return newRorInfo;
        }
        return null;
    };

    return {
        getRorInformationFromSelection,
    };
}
