﻿import { useAmrSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { ISelectOption } from '@vivli/features/search/infrastructure/interface';
import { AdvancedSelectComponent } from '@vivli/shared/components';
import React from 'react';

/*special purpose component */
interface YearAdvancedSelectComponentProps {
    options?: ISelectOption[];
    menuPlacement?: 'auto' | 'bottom' | 'top';
}

export const YearAdvancedSelectComponent = ({ options, menuPlacement }: YearAdvancedSelectComponentProps) => {
    const { yearsDataCollectedTo, setYearsDataCollectedTo } = useAmrSearchOptionsContext();

    const yearOptionsReversed = options;

    return (
        <AdvancedSelectComponent
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            onChange={setYearsDataCollectedTo}
            options={yearOptionsReversed}
            selectedOptions={yearsDataCollectedTo}
            menuPlacement={menuPlacement}
        />
    );
};
