import { useRequestedStudies } from './use-requested-studies.hook';
import { useAwaitingUserActionDataRequests, useDataRequestSummaries } from '@vivli/features/data-requests/infrastructure/hook';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';

export function useStudiesAwaitingUpload() {
    const { dataRequestSummaries, isLoadingDataRequestSummaries } = useDataRequestSummaries();
    const { organizations } = useOrganizations();
    const requestedStudies = useRequestedStudies(dataRequestSummaries);
    const awaitingDataRequests = useAwaitingUserActionDataRequests(dataRequestSummaries);

    return {
        organizations,
        dataRequestSummaries,
        awaitingDataRequests,
        requestedStudies,
        isLoadingDataRequestSummaries,
    };
}
