import React from 'react';
import { Color } from './colors';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import tinycolor from 'tinycolor2';
import { Size } from './size';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

export const CENTERED_IN_BLOCK: React.CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    minWidth: 400,
    transform: 'translate(-50%, -50%)',
};

const OPACITY_TRANSITION = {
    transition: `opacity ${Size.HOVER_TRANSITION_TIME}s`,
};

const HOVER_OPACITY_TRANSITION: React.CSSProperties = {
    ...OPACITY_TRANSITION,
    ':hover': {
        opacity: 0.8,
    },
} as React.CSSProperties;

const VIVLI_COPYRIGHT: React.CSSProperties = {
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    color: Color.LIGHT_GRAY,
    position: 'absolute',
    bottom: 0,
    right: 0,
    fontSize: Size.FontSize.Small,
};

// export const fadeInOutOnDisplay: React.CSSProperties = {
//     transition: `opacity ${Size.HOVER_OPACITY_TRANSITION_TIME}s`,
// };

const NO_SELECT: React.CSSProperties = {
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    KhtmlUserSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouchCallout: 'none',
};

const CENTERED_FLEX: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const HEADER_LINK: React.CSSProperties = {
    ...NO_SELECT,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    color: Color.WHITE,
    fontSize: Size.FontSize.Medium,
    cursor: 'pointer',
};

const TAB_BUTTON: React.CSSProperties = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    position: 'absolute',
    right: 0,
    maxHeight: 'initial',
    height: '100%',
};

const POPOUT_BODY_STYLE: React.CSSProperties = {
    fontSize: Size.FontSize.Small,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
};

const SMALL_GREY_TEXT: React.CSSProperties = {
    color: Color.TEXT_GRAY,
    fontSize: Size.FontSize.Large,
};

const BUTTON_VIVLI_BLUE: React.CSSProperties = {
    ...CENTERED_FLEX,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    outline: 'none',
    fontSize: Size.FontSize.Large,
    minWidth: 200,
    maxHeight: 40,
    minHeight: 40,
    backgroundColor: Color.VIVLI_BLUE,
    color: Color.WHITE,
    cursor: 'pointer',
    borderTopRightRadius: Size.BORDER_RADIUS,
    borderBottomRightRadius: Size.BORDER_RADIUS,
    borderTopLeftRadius: Size.BORDER_RADIUS,
    borderBottomLeftRadius: Size.BORDER_RADIUS,
    boxShadow: Size.BOX_SHADOW_LIGHT,
    marginLeft: 'auto',
    marginRight: 'auto',
};

const BUTTON_LIGHT_VIVLI_BLUE: React.CSSProperties = {
    ...BUTTON_VIVLI_BLUE,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
};

const BUTTON_RED_X: React.CSSProperties = {
    borderBottom: `1px solid ${Color.ERROR_RED}`,
    borderLeft: `1px solid ${Color.ERROR_RED}`,
    borderRight: `1px solid ${Color.ERROR_RED}`,
    borderTop: `1px solid ${Color.ERROR_RED}`,
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.RED_X_ICON})`,
    backgroundPosition: 'center right 11px',
    backgroundRepeat: 'no-repeat',
    color: Color.ERROR_RED,
    marginRight: Size.PADDING,
    boxShadow: 'none',
    paddingRight: 0,
    paddingLeft: 0,
    height: 40,
};

const BUTTON_GREEN_CHECK: React.CSSProperties = {
    ...CENTERED_FLEX,
    maxHeight: 40,
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.GREEN_CHECK_MARK})`,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    borderBottom: `1px solid ${Color.VIVLI_GREEN}`,
    borderLeft: `1px solid ${Color.VIVLI_GREEN}`,
    borderRight: `1px solid ${Color.VIVLI_GREEN}`,
    borderTop: `1px solid ${Color.VIVLI_GREEN}`,
    color: Color.VIVLI_GREEN,
    boxShadow: 'none',
    paddingBottom: 0,
    paddingLeft: 40,
    paddingRight: 10,
    paddingTop: 0,
};

const BUTTON_DARKER_GREEN_CHECK: React.CSSProperties = {
    ...CENTERED_FLEX,
    maxHeight: 40,
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.GREEN_CHECK_MARK})`,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    borderBottom: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderLeft: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderRight: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderTop: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    color: Color.VIVLI_DARKER_GREEN,
    boxShadow: 'none',
    paddingBottom: 0,
    paddingLeft: 40,
    paddingRight: 10,
    paddingTop: 0,
};

const FORM_ROW: React.CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
};

const FORM_ROW_BOLD: React.CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
    color: Color.DARK_GRAY,
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.SemiBold,
};

const FULL_WIDTH_TABLE: React.CSSProperties = {
    width: '100%',
    backgroundColor: 'white',
    // justifyContent: 'space-between',
    marginBottom: 10,
    textAlign: 'left',
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 0,
};

const TABLE_HEADER: React.CSSProperties = {
    paddingTop: 10,
    color: Color.DARK_GRAY,
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.Regular,
};

const TABLE_COLUMN_FILLER: React.CSSProperties = {
    width: '2%',
};

/*const TABLE_CELL_TEXT: React.CSSProperties = {
    textAlign: 'top' as 'top',
};*/

const NON_BULLET_LIST: React.CSSProperties = {
    listStyle: 'none',
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
};

const NON_BULLET_LIST_NO_TOP_PADDING: React.CSSProperties = {
    listStyle: 'none',
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
};

const CONTENT_BORDER: React.CSSProperties = {
    borderBottom: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderLeft: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderRight: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    borderTop: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
    paddingBottom: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 0,
    marginBottom: 15,
};

const METRICS_REPORT_ROW: React.CSSProperties = {
    fontSize: 13,
    paddingLeft: Size.PADDING,
};

const METRICS_SUB_ROW: React.CSSProperties = {
    fontSize: 12,
    fontWeight: 'normal',
};

const COLUMN_ROW: React.CSSProperties = {
    fontSize: 20,
    marginRight: 20,
    paddingTop: 6,
};

const COLUMN_ROW_DEF: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
};

const HARDCODED_HINT_POPOVER: React.CSSProperties = {
    position: 'absolute',
    top: '-22px',
    right: '10px',
    marginBottom: '10px',
};

const CONTENT_BACKGROUND_DARK_GREY: React.CSSProperties = {
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    marginBottom: 15,
    backgroundColor: Color.TRANSPARENT_ONE,
};

export const Styles = {
    FORM_ROW,
    FORM_ROW_BOLD,
    CENTERED_IN_BLOCK,
    CENTERED_FLEX,
    NO_SELECT,
    HEADER_LINK,
    VIVLI_COPYRIGHT,
    FULL_WIDTH_TABLE,
    TABLE_HEADER,
    TABLE_COLUMN_FILLER,
    // TABLE_CELL_TEXT,
    NON_BULLET_LIST,
    NON_BULLET_LIST_NO_TOP_PADDING,
    METRICS_REPORT_ROW,
    METRICS_SUB_ROW,
    COLUMN_ROW,
    COLUMN_ROW_DEF,
    CONTENT_BORDER,
    POPOVER_DROP_SHADOW: 'drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 2px)',
    SMALL_GREY_TEXT,
    HARDCODED_HINT_POPOVER,
    CONTENT_BACKGROUND_DARK_GREY,
    Transition: {
        OPACITY_TRANSITION,
        HOVER_OPACITY_TRANSITION,
    },
    Button: {
        BUTTON_VIVLI_BLUE,
        BUTTON_LIGHT_VIVLI_BLUE,
        BUTTON_RED_X,
        BUTTON_GREEN_CHECK,
        BUTTON_DARKER_GREEN_CHECK,
        TAB_BUTTON,
    },
    Popout: {
        POPOUT_BODY_STYLE,
    },
    borderRadius: (size: number): React.CSSProperties => ({
        borderTopLeftRadius: size,
        borderTopRightRadius: size,
        borderBottomLeftRadius: size,
        borderBottomRightRadius: size,
    }),
    termBackdropStyle: (picoType: PicoTypeEnum): React.CSSProperties => ({
        backgroundImage: `linear-gradient(to bottom, ${tinycolor(Color.Pico[picoType]).clone().setAlpha(0.3).toRgbString()} 0%, ${tinycolor(
            Color.Pico[picoType]
        )
            .clone()
            .setAlpha(0.3)
            .toRgbString()} 11%, ${tinycolor(Color.Pico[picoType])
            .clone()
            .setAlpha(0.79 - 0.6)
            .toRgbString()} 73%, ${tinycolor(Color.Pico[picoType]).clone().setAlpha(0.03).toRgbString()} 99%, transparent 100%)`,
        // opacity: 0.3,
    }),
    termBackdropStyleByColor: (color): React.CSSProperties => ({
        backgroundImage: `linear-gradient(to bottom, ${color.clone().setAlpha(0.3)} 0%, ${color.clone().setAlpha(0.3)} 11%, ${color
            .clone()
            .setAlpha(0.79 - 0.6)} 73%, ${color.clone().setAlpha(0.03)} 99%, transparent 100%)`,
    }),
};
