import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { LoadIndicatorComponent } from '../load-indicator/load-indicator.component';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';

export interface ButtonProps {
    disabled?: boolean;
    isLoading?: boolean;
    title?: string;
    dataId?: string;
    wrapText?: boolean;
    style?: CSSProperties;
    children?: any;
    onClick?: (e) => any;
    className?: string;
    loaderColor?: LoadIndicatorColorEnum;
}

const defaultButtonStyle = {
    height: 40,
    outline: 'none',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.WHITE,
    minWidth: 100,
    boxShadow: Size.BOX_SHADOW_LIGHT,
    backgroundColor: Color.TEXT_DARK_BLUE,
    borderTopRightRadius: Size.BORDER_RADIUS,
    borderBottomRightRadius: Size.BORDER_RADIUS,
    borderTopLeftRadius: Size.BORDER_RADIUS,
    borderBottomLeftRadius: Size.BORDER_RADIUS,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    ':active': { transform: 'scale(0.98)' },
    ':hover': { opacity: Size.HOVER_OPACITY },
    transition: `scale 0.35s, opacity ${Size.HOVER_TRANSITION_TIME}s`,
    width: 'initial',
};

const loadIndicatorStyle: CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

export const ButtonComponent = ({
    style,
    disabled,
    isLoading,
    children,
    title,
    dataId,
    wrapText,
    loaderColor,
    ...optionalProps
}: ButtonProps) => {
    const buttonStyle: any = {
        ...defaultButtonStyle,
        cursor: disabled || isLoading ? 'default' : 'pointer',
        pointerEvents: disabled || isLoading ? 'none' : null,
        opacity: disabled || isLoading ? Size.HOVER_OPACITY : 1,
        ...style,
    };

    const wrapStyle = wrapText ? 'normal' : 'nowrap';

    return (
        <div title={title ? title : ''} style={buttonStyle} data-test-id={dataId} {...optionalProps}>
            {isLoading && <LoadIndicatorComponent color={loaderColor || LoadIndicatorColorEnum.White} style={loadIndicatorStyle} />}
            <span style={{ visibility: isLoading ? 'hidden' : 'visible', whiteSpace: wrapStyle }}>{children}</span>
        </div>
    );
};
