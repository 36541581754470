import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { ButtonComponent } from './button.component';
import React, { CSSProperties } from 'react';
import { Color } from '@vivli/shared/theme';

const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
});

const editButtonStyle: CSSProperties = {
    '@media print': {
        display: 'none',
    },
    justifyContent: 'center',
    display: 'inline-flex',
    backgroundColor: 'white',
    color: Color.VIVLI_BLUE,
    border: `1px solid ${Color.VIVLI_BLUE}`,
    minWidth: 'initial',
} as React.CSSProperties;

const buttonImgStyle: CSSProperties = {
    width: '15px',
};

const buttonTxtStyle: CSSProperties = {
    marginRight: '10px',
};

interface IEditButtonComponent {
    style?: CSSProperties;
    onClick?: (e: any) => void;
    isLoading?: boolean;
    miniature?: boolean;
}

export const EditButtonComponent = ({ style, onClick, isLoading, miniature }: IEditButtonComponent) => {
    return (
        <ButtonComponent style={{ ...editButtonStyle, ...style }} onClick={onClick} isLoading={isLoading} className="do-not-print">
            <div style={buttonStyle(miniature)}>
                {!miniature && <span style={buttonTxtStyle}>Edit</span>}
                <img src={AssetsConstant.editIconActive} style={buttonImgStyle} />
            </div>
        </ButtonComponent>
    );
};
