import React, { CSSProperties, useState } from 'react';
import { IDataProvider, IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { FormProvider } from 'react-hook-form';
import { ButtonComponent } from '@vivli/shared/components';
import { useDataRequestProviders, useUnlistedStudyForm } from '@vivli/features/data-requests/infrastructure/hook';
import { AddUnlistedStudyFormComponent } from './add-unlisted-study-form.component';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { first } from 'rxjs/operators';
import { Color, Size } from '@vivli/shared/theme';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: Color.WHITE,
    minWidth: 300,
    borderRadius: 15,
};

const headerStyle: CSSProperties = {
    padding: Size.PADDING * 2,
    borderBottom: '1px solid white',
    fontSize: Size.FontSize.Larger,
    alignContent: 'center',
};

const bodyContainerStyle: CSSProperties = {
    padding: Size.PADDING * 2,
    fontSize: Size.FontSize.Medium,
};

const scrollContainerStyle: CSSProperties = {
    display: 'flex',
    padding: Size.PADDING,
    overflowY: 'scroll',
    height: 530,
    width: 850,
    flexDirection: 'column',
    color: 'rgb(96, 104, 111)',
    backgroundColor: 'rgb(246, 247, 251)',
};

const infoTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
    lineHeight: '1.4',
    fontFamily: 'Verdana, sans-serif',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
};

const resultContainerStyle: CSSProperties = {
    paddingTop: '40px',
    paddingBottom: '40px',
};

interface AddUnlistedStudyModalComponentProps {
    dataRequestId: string;
    onClose: () => void;
    onStudyAdded: (dataRequest?: IDataRequest) => void;
    isInProcess?: boolean;
}

export const AddUnlistedStudyModalComponent = ({
    dataRequestId,
    onStudyAdded,
    onClose,
    isInProcess,
}: AddUnlistedStudyModalComponentProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeProvider, setActiveProvider] = useState<IDataProvider>();
    const [studyError, setStudyError] = useState(false);

    const formApi = useUnlistedStudyForm();
    const dataRequestsService = useDataRequestsService();
    const providers = useDataRequestProviders(dataRequestId, isInProcess);

    const resetForm = () => {
        formApi.reset();
        setActiveProvider(null);
        setStudyError(false);
    };

    const handleOnCancel = () => {
        onClose && onClose();
    };

    const handleOnSubmit = () => {
        formApi.handleSubmit(({ dataProviderOrgId, nctOrSponsorProtocolId, studyTitle }) => {
            const isNct = nctOrSponsorProtocolId.toLowerCase().startsWith('nct');
            const requestData = {
                studyTitle,
                dataProviderOrgId,
                nctId: isNct && nctOrSponsorProtocolId,
                sponsorProtocolId: !isNct && nctOrSponsorProtocolId,
            };

            setIsSubmitting(true);
            if (!isInProcess) {
                dataRequestsService
                    .addUnlistedStudy(dataRequestId, requestData)
                    .pipe(first())
                    .subscribe(
                        (dataRequest) => {
                            const provider = providers.find((p) => p.id === dataProviderOrgId);
                            setActiveProvider(provider);

                            LoggerService.log('dataRequestAddEtag', dataRequest._etag);
                            onStudyAdded && onStudyAdded(dataRequest);
                        },
                        () => {
                            setStudyError(true);
                        },
                        () => {
                            setIsSubmitting(false);
                        }
                    );
            } else {
                //uses a route that is restricted to Vivli Admins, to guarantee
                //no one else is adding to in-process requests
                dataRequestsService
                    .addUnlistedStudyToInProcessRequest(dataRequestId, requestData)
                    .pipe(first())
                    .subscribe(
                        (dataRequest) => {
                            const provider = providers.find((p) => p.id === dataProviderOrgId);
                            setActiveProvider(provider);

                            LoggerService.log('dataRequestAddEtag', dataRequest._etag);
                            onStudyAdded && onStudyAdded(dataRequest);
                        },
                        () => {
                            setStudyError(true);
                        },
                        () => {
                            setIsSubmitting(false);
                        }
                    );
            }
        })();
    };

    return (
        <div>
            <div style={containerStyle}>
                <div style={headerStyle}>Request Studies, Data, or Tools not listed on Vivli</div>
                <div style={bodyContainerStyle}>
                    <div style={scrollContainerStyle} className="scrolly">
                        <div>
                            <div style={infoTextStyle}>
                                If you will be providing your own data, tools or scripts, then as the provider, select "I WILL BRING MY OWN"
                                and provide a name and a description for the data, tool, or script. You will be notified when to upload the
                                data, tool or script and the Vivli team will support you in this process.
                            </div>

                            <div style={infoTextStyle}>
                                If you are requesting clinical trial data from a Vivli member, then as the provider of the data select the
                                member’s name, provide the NCT ID or the Sponsor ID of the study, and a description of the study. Vivli does
                                not guarantee that the Vivli member will approve the data request. For more information, please see our{' '}
                                <a target="_blank" href="http://vivli.org/members/ourmembers">
                                    Members page.
                                </a>
                            </div>

                            {!studyError && !activeProvider && (
                                <FormProvider {...formApi}>
                                    <AddUnlistedStudyFormComponent providers={providers} />
                                </FormProvider>
                            )}

                            {!studyError && activeProvider && (
                                <div style={resultContainerStyle}>
                                    <div style={infoTextStyle}>
                                        A study, data or tool provided by {activeProvider.name} was successfully added to the Data Request.
                                    </div>
                                    {activeProvider.orgUrl && (
                                        <div style={infoTextStyle}>
                                            <p>
                                                If you will be providing the data or tools or are requesting them using the Vivli Request
                                                Form, you can dismiss this window by clicking on Back.
                                            </p>
                                            <a target="_blank" href={activeProvider.orgUrl}>
                                                To access more information on Vivli member data sharing click here.
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}

                            {studyError && (
                                <div style={resultContainerStyle}>
                                    <div style={infoTextStyle}>
                                        There was a problem adding an Vivli Partner Platform study to your Data Request. Please contact
                                        Vivli for help.
                                    </div>
                                </div>
                            )}

                            <div style={buttonContainerStyle}>
                                {!studyError && (
                                    <ButtonComponent
                                        onClick={!activeProvider ? handleOnSubmit : resetForm}
                                        disabled={!formApi.formState.isDirty || Object.keys(formApi.formState.errors).length > 0}
                                        isLoading={isSubmitting}
                                        dataId={DTICommonConst.SubmitButton}
                                    >
                                        {!activeProvider ? 'Submit' : 'Add Another Study, Data, or Tool'}
                                    </ButtonComponent>
                                )}
                                <ButtonComponent
                                    style={{ backgroundColor: Color.VIVLI_RED }}
                                    onClick={handleOnCancel}
                                    className="vivliInfoModal_close"
                                    dataId={DTICommonConst.CancelButton}
                                >
                                    {!activeProvider && !studyError ? 'Cancel' : 'Back'}
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
