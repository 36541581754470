import React, { CSSProperties, ReactElement } from 'react';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { StudiesProvidedComponent } from './studies-provided.component';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

const innerStyle: CSSProperties = {
    ...Styles.CENTERED_IN_BLOCK,
    textAlign: 'center',
};

const fieldHeaderStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Bold,
    justifyContent: 'space-around',
};

const imgStyle: CSSProperties = {
    marginBottom: '20px',
};

interface BlockMessageComponent {
    children?: ReactElement;
    message: string;
    showProvidedStudies?: boolean;
}

export const BlockMessageComponent = ({ children, message, showProvidedStudies }: BlockMessageComponent) => {
    return (
        <div style={containerStyle}>
            <div style={innerStyle}>
                {showProvidedStudies && <StudiesProvidedComponent />}

                <div style={imgStyle}>
                    <img src={AssetsConstant.SIGN_WARNING} />
                </div>

                <FieldHeaderComponent style={fieldHeaderStyle} title={message} />

                {children}
            </div>
        </div>
    );
};
