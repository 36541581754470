﻿import { createContext, useContext } from 'react';
import { INotification } from '@vivli/shared/features/notification/infrastructure/interface';
import { UseFormReturn } from 'react-hook-form';
import { NotificationTopicEnum } from '@vivli/shared/features/notification/infrastructure/enum';

interface INotificationContext {
    getNotifications?: (topicId) => void;
    notifications?: INotification[];
    firstAndLastDates?: string[];
    resetNotificationForm?: () => void;
    handleSubmit?: (topicType: NotificationTopicEnum) => void;
    showForm?: boolean;
    lastSentMessageState?: string;
    isSaving?: boolean;
    showSaveButton?: boolean;

    setNotificationFormApi(formApi: UseFormReturn<INotification>);
}

export const NotificationContext = createContext<INotificationContext>(null);

export const useNotificationContext = () => useContext(NotificationContext);
