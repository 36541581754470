﻿import { DeleteButtonComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { RorComponent } from '@vivli/features/organizations/components';

const rorRowGeneric: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
};

const rorSection: CSSProperties = {
    ...rorRowGeneric,
    flex: '1 1 90%',
    maxWidth: '90%',
    width: 'auto',
    marginLeft: '10px',
};

const rorRowButtons: CSSProperties = {
    ...rorRowGeneric,
    flex: '1 1 10%',
    maxWidth: '10%',
    marginTop: '35px',
    marginBottom: '10px',
    marginRight: '10px',
    paddingRight: '15px',
    justifyContent: 'space-between',
};

interface FundingOrgRowContainerComponentProps {
    fundingOrganization: IFundingOrganization;
    handleRemoveFundingOrg?: (fundingOrganization: IFundingOrganization) => void;
    onRemoveRow: (fundingOrganization: IFundingOrganization) => void;
    rowIndex?: number;
    canEdit?: boolean;
    baseFormField?: string;
}

export const FundingOrgRowContainerComponent = ({
    fundingOrganization,
    handleRemoveFundingOrg,
    rowIndex,
    canEdit,
    baseFormField,
}: FundingOrgRowContainerComponentProps) => {
    const handleRemoveButtonClick = (e) => {
        e.stopPropagation();
        handleRemoveFundingOrg(fundingOrganization);
    };

    return (
        <div className="ror-container">
            <div className="citation-row-body" style={rorRowGeneric}>
                <div className="citation-section" style={rorSection}>
                    <RorComponent
                        ror={fundingOrganization}
                        isFundingOrg={true}
                        fundingOrgIndex={rowIndex}
                        baseFormField={baseFormField}
                        canModify={canEdit}
                    />
                </div>
                {canEdit && (
                    <div className="citation-modify-buttons" style={rorRowButtons}>
                        <DeleteButtonComponent onClick={(e) => handleRemoveButtonClick(e)} />
                    </div>
                )}
            </div>
        </div>
    );
};
