import { RestApi } from '@vivli/core/infrastructure/rest';
import { Observable, of } from 'rxjs';
import { IExternalMenuItem, IVivliConfiguration, IVivliConfigurationApiService } from '@vivli/shared/infrastructure/interface';
import { environment } from '@vivli/core/environments';
import { intercepts } from '@vivli/shared/intercepts';

export class VivliConfigurationApiService extends RestApi implements IVivliConfigurationApiService {
    getVivliConfig = (): Observable<IVivliConfiguration> => {
        if (environment.useIntercepts) {
            return of(intercepts.vivliConfig);
        }

        return this.handleGet<IVivliConfiguration>('/vivliConfig');
    };

    getExternalMenuItems = (): Observable<IExternalMenuItem[]> => {
        return this.handleGet<IExternalMenuItem[]>('/vivliConfig/externalMenuItems', { bypassAuth: true });
    };
}
