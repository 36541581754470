import React, { CSSProperties } from 'react';
import { Size } from '@vivli/shared/theme';
import { FieldHeaderComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useSafetyConcernsContext } from '@vivli/features/data-requests/infrastructure/context';
import { ReportSafetyConcernListItemComponent } from './report-safety-concern-list-item.component';

const containerStyle: CSSProperties = {
    marginTop: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
};

export const ReportSafetyConcernListComponent = () => {
    const { safetyConcerns } = useSafetyConcernsContext();

    if (!safetyConcerns) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title="Previously Submitted Safety Concerns" />

            {safetyConcerns.map((safetyConcern, i) => (
                <ReportSafetyConcernListItemComponent safetyConcern={safetyConcern} key={i} />
            ))}
        </div>
    );
};
