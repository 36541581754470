import React, { useEffect, useRef, useState } from 'react';
import EllipsisText from 'react-ellipsis-text';
import { first } from 'rxjs/operators';
import { IDataRequest, IRequestedStudy, IRequestedStudyModification } from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestStatusEnum, RequestedStudyTypeEnum, StudyRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { DataRequestPermissionsService } from '@vivli/features/data-requests/infrastructure/service';
import { Size, Styles } from '@vivli/shared/theme';
import { ButtonComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { AvailableIpdContentTypeEnum, DataPackageStatusEnum, DataPackageTypeEnum } from '@vivli/features/studies/infrastructure/enum';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { RequestedStudyRowDetailComponent } from './requested-study-row-detail.component';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useDataPackageService } from '@vivli/shared/features/data-package/infrastructure/context';
import { DownloadOnlyDataPackageFeature, StudyIpdDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Subject } from 'rxjs';
import { useRefState } from '@vivli/shared/infrastructure/hook';
import { useRequestedStudyBehavior, useRequestedStudyPermissions } from '@vivli/features/studies/infrastructure/hook';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { useTranslation } from 'react-i18next';
import { AssignedAppTypeEnum, LoadIndicatorColorEnum, LoadIndicatorSizeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { DefaultBasicDataPackageListComponent } from './default-basic-data-package-list.component';
import { DefaultFullDataPackageListComponent } from './default-full-data-package-list.component';
import { AmrBasicDataPackageListComponent } from './amr-basic-data-package-list.component';
import { EditAdvancedOptionsButtonComponent } from './edit-advanced-options-button.component';
import {
    containerStyle,
    fullStudyDetailContainerStyle,
    myOrgContainerStyle,
    removeButtonStyle,
    statusMessageStyle,
    studyDetailContainerStyle,
    uploadContainerStyle,
} from './requested-study-row.styles';
import { AdvancedOptionsDetailComponent } from './advanced-options-detail.component';
import { DTICommonConst, DTIRequestedStudyRow } from '@vivli/shared/infrastructure/constants';

interface StudyRowComponentProps {
    study: IRequestedStudy;
    dataRequest: IDataRequest;
    onHeightChange?: (height: number) => void;
    onRemoveRow?: (dataRequest: IDataRequest) => void;
    updateDataRequest?: (dataRequest: IDataRequest) => void;
}

export const RequestedStudyRowComponent = ({
    study,
    dataRequest,
    onHeightChange,
    onRemoveRow,
    updateDataRequest,
}: StudyRowComponentProps) => {
    const defaultRowHeight = 100;
    const [dataPackageReady, setDataPackageReady] = useState(false);
    const [activeRequestedStudy, setActiveRequestedStudy] = useState<IRequestedStudy>(study);
    const [activeDataRequest, setActiveDataRequest] = useState<IDataRequest>(dataRequest);
    const [dataPackageId, setDataPackageId] = useState<string>();
    const [dataPackageStatus, setDataPackageStatus] = useState<boolean>(false);
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [showUploadRef, showUploadSection, setShowUploadSection] = useRefState(false);
    const [showUploadButton, setShowUploadButton] = useState(null);
    const [studyIsMyOrg, setStudyIsMyOrg] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [showMakeDataPackageAvailableButton, setShowMakeDataPackageAvailableButton] = useState(null);
    const [finalizingInProgress, setFinalizingInProgress] = useState(false);
    const [isRemovingStudy, setIsRemovingStudy] = useState(false);
    const [currentRowHeight, setCurrentRowHeight] = useState(defaultRowHeight);
    const [reqStudyOrg, setReqStudyOrg] = useState(null);
    const [thisRequestOnlyIpd, setThisRequestOnlyIpd] = useState(false);
    const [isSkipIrpReview, setIsSkipIrpReview] = useState(false);
    const [isDownloadableSetting, setIsDownloadableSetting] = useState(false);
    const [templateDataUploaded, setTemplateDataUploaded] = useState(false);
    const { t } = useTranslation();
    const studyDetailsString = t(TranslationKeyEnum.study).toLowerCase() + 'Details';
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const uploadDivRef = useRef<HTMLDivElement>();
    const heightChangeSubRef = useRef(new Subject());

    const dataRequestsService = useDataRequestsService();
    const dataPackageService = useDataPackageService();
    const orgsService = useOrganizationsService();
    const user = useActiveUser();
    const modal = useModalService();
    const { userCanDownloadIpd, userIsInStudyOrg, userIsDuaValidated } = useRequestedStudyPermissions();
    const { getStatusMessage, canEditAdvancedOptions, canShowAdvancedOptionsStatus } = useRequestedStudyBehavior();

    const statusText = getStatusMessage(activeRequestedStudy, activeDataRequest, dataPackageStatus, user);
    const canEdit = canEditAdvancedOptions(activeDataRequest, activeRequestedStudy, user);
    const showAdvancedOptionsStatus = canShowAdvancedOptionsStatus(activeDataRequest);
    const topTitleDisplay = isAmr ? `Program: ${study.program}` : study.studyTitle;

    const isEditable = () => activeDataRequest?.status === DataRequestStatusEnum.Draft;
    const userIsDataRequester = () => activeDataRequest?.userId === user.userId;

    const handleDataPackageError = (error: string) => {
        setUploadInProgress(false);
        modal.error(error);
    };

    const handleOrgError = (error: string) => {
        //data requester will not be allowed to look at study org
        //without other permissions to that org - swallow the
        //error in this case. Shouldn't get hit now but leaving it
        //just in case.
        if (!userIsDataRequester) {
            modal.error(error);
        }
    };

    const getRequestedStudy = (dataRequest: IDataRequest) => {
        return dataRequest.requestedStudies.find((rs) => rs.studyId === activeRequestedStudy.studyId);
    };

    const loadDataPackage = (ipdDataPackageId: string, dataRequest: IDataRequest, requestedStudy: IRequestedStudy) => {
        dataPackageService
            .getDataPackage(ipdDataPackageId)
            .pipe(first())
            .subscribe((dataPackage) => {
                setActiveRequestedStudy(requestedStudy);
                setActiveDataRequest(dataRequest);
                setShowUploadSection(true);
                if (dataPackage.dataPackageType === DataPackageTypeEnum.StudyIPD) {
                    const templateUploaded = dataPackage.status === DataPackageStatusEnum.Submitted;
                    setTemplateDataUploaded(templateUploaded);
                }
            }, handleDataPackageError);
    };

    const createDataPackage = () => {
        dataRequestsService
            .createDataPackageForStudy(activeDataRequest.id, activeRequestedStudy.studyId)
            .pipe(first())
            .subscribe((dataRequest) => {
                const requestedStudy = getRequestedStudy(dataRequest);

                if (requestedStudy.requestedStudyType === RequestedStudyTypeEnum.Unlisted || requestedStudy.thisRequestOnlyIpd) {
                    loadDataPackage(requestedStudy.ipdDataPackageId, dataRequest, requestedStudy);
                } else {
                    loadDataPackage(requestedStudy.studyIPDDataPackageId, dataRequest, requestedStudy);
                }
            }, handleDataPackageError);
    };

    const handleUploadButtonClick = (e) => {
        e.stopPropagation();
        //don't handle a click if makedatapackage available still loading
        if (dataPackageStatus) {
            return;
        }

        if (uploadInProgress) {
            setUploadInProgress(false);
            setShowUploadSection(false);
            return;
        }

        setUploadInProgress(true);
        createDataPackage();
    };

    const handleMakeDataPackageAvailableClick = (e) => {
        e.stopPropagation();
        setFinalizingInProgress(true);
        dataRequestsService
            .setDataRequestStudyStatus(activeDataRequest.id, activeRequestedStudy.studyId, StudyRequestStatusEnum.IPDProvided, '')
            .pipe(first())
            .subscribe(
                (dr) => {
                    setActiveDataRequest(dr);
                    //update the context too, for benefit
                    //of other tabs who are using it
                    updateDataRequest && updateDataRequest(dr);

                    modal.message(
                        `Thank you for uploading the data for this ${t(
                            TranslationKeyEnum.study
                        ).toLowerCase()}. It will now be available for further analysis.`
                    );
                },
                modal.error,
                () => setFinalizingInProgress(false)
            );
    };

    const handleOnPackageSubmitted = () => {
        dataRequestsService
            .getDataRequest(activeDataRequest.id)
            .pipe(first())
            .subscribe((dr) => {
                setShowUploadSection(false);
                setUploadInProgress(false);
                setShowUploadButton(false);
                setShowMakeDataPackageAvailableButton(false);
                setActiveDataRequest(dr);
                //update the context too, for benefit
                //of other tabs who are using it
                updateDataRequest && updateDataRequest(dr);

                const requestedStudy = getRequestedStudy(dr);
                setActiveRequestedStudy(requestedStudy);
            }, handleDataPackageError);
    };

    const handleRemoveButtonClick = (e) => {
        e.stopPropagation();
        setIsRemovingStudy(true);
        const titleDisplay = isAmr ? activeRequestedStudy.program : activeRequestedStudy.studyTitle;
        const message = `Are you sure you want to remove ${t(TranslationKeyEnum.study).toLowerCase()}: ${titleDisplay}?`;
        modal.confirm(message, {
            onConfirm: () => {
                dataRequestsService
                    .removeDataRequestStudy(activeDataRequest.id, activeRequestedStudy.studyId)
                    .pipe(first())
                    .subscribe((resultDataRequest) => {
                        setIsRemovingStudy(false);

                        setActiveDataRequest(resultDataRequest);
                        onRemoveRow && onRemoveRow(resultDataRequest);
                    });
            },
            onCancel: () => setIsRemovingStudy(false),
        });
    };

    const handleRowClick = (e) => {
        e.preventDefault();

        if (activeRequestedStudy.requestedStudyType === 'Unlisted' && !user.isVivliAdmin) {
            unListedDownloadable();
            return;
        }

        if (activeRequestedStudy.requestedStudyType !== 'Listed' && activeRequestedStudy.requestedStudyType !== 'External') {
            return;
        }

        if ((e.ctrlKey || e.shiftKey) && user.isVivliAdmin) {
            window.open(`/admin/studies/${activeRequestedStudy.studyId}/studyinformation`);
            return;
        }

        if (activeRequestedStudy.studyRequestStatus === StudyRequestStatusEnum.IPDProvided && isDownloadable) {
            window.open(
                `/${studyDetailsString}/${activeRequestedStudy.studyId}/${activeRequestedStudy.ipdDataPackageId}/${activeDataRequest.id}/${isDownloadable}`
            );
        } else {
            window.open(`/${studyDetailsString}/${activeRequestedStudy.studyId}`);
        }
    };

    // to download unlisted studies modal
    const unListedDownloadable = () => {
        if (activeRequestedStudy.downloadableRequestedStudyIPDDataPackage) {
            if (activeRequestedStudy.studyRequestStatus === 'New') {
                const message = 'Data for this study not yet provided.';
                modal.confirm(message, {
                    confirmText: 'Ok',
                    hideCancel: true,
                });
            }
            if (activeRequestedStudy.studyRequestStatus === 'IPDProvided') {
                if (!userIsDuaValidated(dataRequest)) {
                    const message = 'Your DUA must be validated to download data. Please contact your Vivli Administrator.';
                    modal.confirm(message, {
                        confirmText: 'Ok',
                        hideCancel: true,
                    });
                    return;
                }
                const modalId = modal.custom(
                    <DownloadOnlyDataPackageFeature
                        dataPackageId={activeRequestedStudy.ipdDataPackageId}
                        studyOrgId={activeRequestedStudy.studyId}
                        onClose={() => modal.dismiss(modalId)}
                    />,
                    { style: { width: '80%' } }
                );
            }
        } else {
            const message = 'Data for this study available only in a secure research environment.';
            modal.confirm(message, {
                confirmText: 'Ok',
                hideCancel: true,
            });
        }
    };
    const handleOnOptionsChange = (requestedStudyMods: IRequestedStudyModification, dataRequest: IDataRequest) => {
        const thisReqStudy = dataRequest.requestedStudies.find((a) => a.studyId === requestedStudyMods.studyId);
        setThisRequestOnlyIpd(thisReqStudy.thisRequestOnlyIpd);
        setIsDownloadableSetting(thisReqStudy.downloadableRequestedStudyIPDDataPackage);
        setIsSkipIrpReview(thisReqStudy.bypassIRPReview);
        setActiveRequestedStudy(thisReqStudy);
    };

    const triggerHeightChanged = () => {
        heightChangeSubRef.current.next();
    };

    const updateCurrentRowHeight = () => {
        const currentHeight = uploadDivRef?.current?.getBoundingClientRect().height;
        const height = showUploadRef.current ? currentHeight + defaultRowHeight + 50 : defaultRowHeight;
        if (height !== currentRowHeight) {
            setCurrentRowHeight(height);
            onHeightChange && onHeightChange(height);
        }
    };

    useEffect(() => {
        if (!activeRequestedStudy) {
            return;
        }

        const id =
            activeRequestedStudy.requestedStudyType === RequestedStudyTypeEnum.Unlisted || activeRequestedStudy.thisRequestOnlyIpd
                ? activeRequestedStudy.ipdDataPackageId
                : activeRequestedStudy.studyIPDDataPackageId;

        let canDownload = false;

        const studyIsUserOrg = userIsInStudyOrg(activeRequestedStudy);
        setStudyIsMyOrg(studyIsUserOrg);

        if (id) {
            setDataPackageId(id);
            canDownload = userCanDownloadIpd(activeRequestedStudy, activeDataRequest);
        }
        setThisRequestOnlyIpd(activeRequestedStudy.thisRequestOnlyIpd);
        setIsDownloadableSetting(activeRequestedStudy.downloadableRequestedStudyIPDDataPackage);
        setIsSkipIrpReview(activeRequestedStudy.bypassIRPReview);

        //older Pathway 3 studies may not have a thisRequestOnly setting, so force it to
        // show as true - this setting can't be changed for Pathway 3 studies.
        if (activeRequestedStudy.requestedStudyType === RequestedStudyTypeEnum.Unlisted) {
            setThisRequestOnlyIpd(true);
        }
        setIsDownloadable(canDownload);

        //finally, does Template Ipd exist or not?
        //We want to know this now so we can set the text accordingly
        //for non-TRO requested studies
        const templateId = activeRequestedStudy.studyIPDDataPackageId;
        let sub;
        if (templateId) {
            sub = dataPackageService.getDataPackageSubmitStatus(templateId).pipe(first()).subscribe(setTemplateDataUploaded);
        }
        return () => {
            sub?.unsubscribe();
        };
    }, [activeRequestedStudy, activeDataRequest, reqStudyOrg]);

    useEffect(() => {
        if (!dataPackageId) {
            return;
        }

        const sub = dataPackageService.getDataPackageSubmitStatus(dataPackageId).pipe(first()).subscribe(setDataPackageStatus);

        return () => {
            sub.unsubscribe();
        };
    }, [dataPackageId]);

    useEffect(() => {
        const userCanUpload = DataRequestPermissionsService.canUpload(activeDataRequest, activeRequestedStudy, user);

        //data package status true if there's already Submitted Template Ipd for a requestedStudy that
        //will use a copy of Template Ipd, otherwise false (no Template Ipd exists or Template Ipd won't be used)
        //note that unlisted study (P3) should never show data package available, if it does, there is
        //bad data
        const showUploadButtonResult = !dataPackageStatus && userCanUpload;
        const showDataPackageAvailableButtonResult = dataPackageStatus && userCanUpload;

        setShowUploadButton(showUploadButtonResult);
        setShowMakeDataPackageAvailableButton(showDataPackageAvailableButtonResult);
    }, [dataPackageStatus, activeDataRequest, activeRequestedStudy]);

    useEffect(() => {
        updateCurrentRowHeight();
    }, [showUploadSection, dataPackageReady]);

    useEffect(() => {
        if (!activeRequestedStudy) {
            return;
        }

        const heightChangedSub = heightChangeSubRef.current.subscribe(() => updateCurrentRowHeight());

        //don't look up req study org for data requester only
        //they don't have rights to it
        if (userIsDataRequester && !user.isVivliAdmin) {
            setReqStudyOrg(null);
            return () => {
                heightChangedSub.unsubscribe();
            };
        }

        const orgsSub = orgsService
            .getOrganization(activeRequestedStudy.organizationId)
            .pipe(first())
            .subscribe((org) => {
                setReqStudyOrg(org);
            }, handleOrgError);

        return () => {
            orgsSub.unsubscribe();
            heightChangedSub.unsubscribe();
        };
    }, []);

    return (
        <div style={containerStyle(activeRequestedStudy)} onClick={handleRowClick}>
            {studyIsMyOrg && (
                <div style={myOrgContainerStyle} title={`This ${t(TranslationKeyEnum.study).toLowerCase()} belongs to your organization`}>
                    &#10004;
                </div>
            )}

            <div style={fullStudyDetailContainerStyle}>
                <div style={{ marginBottom: '5px' }}>
                    <EllipsisText text={topTitleDisplay || ''} length={175} title={topTitleDisplay} />
                </div>
                <div style={studyDetailContainerStyle}>
                    <RequestedStudyRowDetailComponent study={activeRequestedStudy} dataRequest={dataRequest} />

                    <div>
                        {showAdvancedOptionsStatus && (
                            <EditAdvancedOptionsButtonComponent
                                requestedStudy={activeRequestedStudy}
                                dataRequest={dataRequest}
                                isVivliAdmin={user.isVivliAdmin}
                                isDisabled={showUploadSection || uploadInProgress}
                                updateDataRequest={updateDataRequest}
                                onOptionsChange={handleOnOptionsChange}
                                isEditable={canEdit}
                            />
                        )}
                        {showAdvancedOptionsStatus && (
                            <AdvancedOptionsDetailComponent
                                isThisRequestOnlySetting={thisRequestOnlyIpd}
                                isDownloadableSetting={isDownloadableSetting}
                                isSkipIrpReviewSetting={isSkipIrpReview}
                                templateDataUploaded={templateDataUploaded}
                                isVivliAdmin={user.isVivliAdmin}
                            />
                        )}
                    </div>

                    {showMakeDataPackageAvailableButton !== null && showUploadButton && (
                        <ButtonComponent
                            disabled={!showUploadSection && uploadInProgress}
                            isLoading={!showUploadSection && uploadInProgress}
                            style={{ height: 35, minWidth: '230px' }}
                            onClick={handleUploadButtonClick}
                            className="studyRow_uploadIpd"
                            dataId={DTIRequestedStudyRow.StudyRowUploadIpd}
                        >
                            {!showUploadSection ? 'Upload Data Package' : 'Cancel'}
                        </ButtonComponent>
                    )}

                    {showMakeDataPackageAvailableButton && (
                        <ButtonComponent
                            style={{ height: 35, minWidth: '230px' }}
                            onClick={handleMakeDataPackageAvailableClick}
                            isLoading={finalizingInProgress}
                            className="adminStudyRow_makeIPDAvailable"
                            dataId={DTIRequestedStudyRow.AdminstudyRowMakeIPDAvailable}
                        >
                            Make Data Package Available
                        </ButtonComponent>
                    )}

                    <div style={statusMessageStyle}>{statusText}</div>

                    <div style={{ alignSelf: 'center', display: 'flex' }}>
                        {isEditable() && userIsDataRequester() && (
                            <ButtonComponent
                                isLoading={isRemovingStudy}
                                style={removeButtonStyle}
                                onClick={handleRemoveButtonClick}
                                loaderColor={LoadIndicatorColorEnum.ErrorRed}
                                dataId={DTICommonConst.RemoveButton}
                            >
                                Remove
                            </ButtonComponent>
                        )}
                    </div>
                </div>
            </div>

            <div ref={uploadDivRef} style={uploadContainerStyle(uploadInProgress)} onClick={(e) => e.stopPropagation()}>
                {!dataPackageId && uploadInProgress && (
                    <div style={Styles.CENTERED_FLEX}>
                        <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Medium} />
                    </div>
                )}

                {dataPackageId && uploadInProgress && (
                    <div>
                        <div
                            style={{
                                paddingTop: Size.PADDING,
                                paddingLeft: '10px',
                                paddingRight: Size.PADDING,
                            }}
                        >
                            {activeRequestedStudy.availableIPD === AvailableIpdContentTypeEnum.Basic && (
                                <div>
                                    Basic Data Packages must include:
                                    {isAmr ? <AmrBasicDataPackageListComponent /> : <DefaultBasicDataPackageListComponent />}
                                </div>
                            )}
                            {activeRequestedStudy.availableIPD === AvailableIpdContentTypeEnum.Full && (
                                <div>
                                    Expanded Data Packages must include:
                                    <DefaultFullDataPackageListComponent />
                                </div>
                            )}
                        </div>
                        <StudyIpdDataPackageFeature
                            dataPackageId={dataPackageId}
                            ipdContentType={activeRequestedStudy.availableIPD}
                            studyId={activeRequestedStudy.studyId}
                            requestedStudyId={activeRequestedStudy.studyId}
                            dataRequestId={activeDataRequest.id}
                            thisRequestOnlyIpd={thisRequestOnlyIpd}
                            onFileAdding={() => triggerHeightChanged()}
                            onFileRemoved={() => triggerHeightChanged()}
                            onValidation={() => triggerHeightChanged()}
                            onPackageSubmitted={handleOnPackageSubmitted}
                            onReady={() => setDataPackageReady(true)}
                            showExternalLinks={false}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
