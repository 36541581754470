import React, { createContext, useContext } from 'react';

interface IAdminConsoleLookupIdContext {
    vmLookupId: string;
    setVmLookupId: React.Dispatch<React.SetStateAction<string>>;
    studyLookupId: string;
    setStudyLookupId: React.Dispatch<React.SetStateAction<string>>;
    dataRequestLookupId: string;
    setDataRequestLookupId: React.Dispatch<React.SetStateAction<string>>;
}

export const AdminConsoleLookupIdContext = createContext<IAdminConsoleLookupIdContext>(null);

export const useAdminConsoleLookupIdContext = () => useContext(AdminConsoleLookupIdContext);
