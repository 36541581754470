import { ButtonComponent, CheckboxComponent, FieldHeaderComponent, TextFieldComponent } from '@vivli/shared/components';
import React, { CSSProperties, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IOrganizationInvitation } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationDetailsContext } from '@vivli/features/organizations/infrastructure/context';
import { useInputValidation } from '@vivli/shared/infrastructure/hook';

const containerStyle: CSSProperties = {
    minHeight: 112,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flex: '1 0 0',
};
const emailRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};
const firstRowStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: Size.PADDING,
};
const secondRowStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginLeft: Size.PADDING,
};
const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse',
};

export const InviteMembersWithRightsComponent = ({ organization }) => {
    const user = useActiveUser();
    const inputValidation = useInputValidation();
    const { inviteOrgMember, isSaving } = useOrganizationDetailsContext();

    const showDataProviderCheckbox = user && organization?.isDataProvider;
    const showIrpApproverCheckbox = organization?.isIRPApprover;
    const showIsAnnotatorCheckbox = organization?.isAnnotator;
    const showIsQaReviewerCheckbox = organization?.isQaReviewer;
    const inviteEmailErrorText = 'The invitation email is in an invalid format.';

    const [isInviteEmailError, setIsInviteEmailError] = useState(false);
    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [inviteIsDataProvider, setInviteIsDataProvider] = useState(false);
    const [inviteIsIrpApprover, setInviteIsIrpApprover] = useState(false);
    const [inviteIsStudyCurator, setInviteIsStudyCurator] = useState(false);
    const [inviteIsQcReviewer, setInviteIsQcReviewer] = useState(false);
    const [inviteIsHeadCurator, setInviteIsHeadCurator] = useState(false);
    const [inviteIsOrgAdmin, setInviteIsOrgAdmin] = useState(false);

    const handleInviteEmailChange = (email) => {
        const isValid = inputValidation.validateEmail(email);
        setIsInviteEmailError(!isValid);
        setInviteEmail(email);
    };

    const handleInviteMember = () => {
        const orgInvite: IOrganizationInvitation = {
            email: inviteEmail,
            orgMembership: {
                orgId: organization.id,
                orgName: organization.name,
                isOrgAdmin: inviteIsOrgAdmin,
                isIRPApprover: inviteIsIrpApprover,
                isDataProvider: inviteIsDataProvider,
                isAnnotator: inviteIsStudyCurator,
                isQaReviewer: inviteIsQcReviewer,
                isHead: inviteIsHeadCurator,
            },
        };
        inviteOrgMember(orgInvite);
        setInviteEmail('');
    };

    return (
        <div>
            <FieldHeaderComponent title={'ADD MEMBER WITH RIGHTS'} />

            <div style={containerStyle}>
                <div style={emailRowStyle}>
                    <TextFieldComponent
                        label={'Email'}
                        value={inviteEmail}
                        readonly={!user.isVivliAdmin}
                        onChange={(e) => handleInviteEmailChange(e.target.value)}
                        style={{ width: '20em' }}
                        error={isInviteEmailError ? inviteEmailErrorText : ''}
                    />
                    <ButtonComponent
                        onClick={handleInviteMember}
                        disabled={!user.isVivliAdmin || isSaving || !inviteEmail || !organization.id || isInviteEmailError}
                        isLoading={isSaving}
                        className={'orgDetails_inviteMember'}
                        style={{ marginTop: 15 }}
                    >
                        Add Member
                    </ButtonComponent>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={firstRowStyle}>
                        {showDataProviderCheckbox && (
                            <CheckboxComponent
                                label={'Data Contributor'}
                                disabled={!user.isVivliAdmin}
                                checked={inviteIsDataProvider}
                                onChange={(e) => setInviteIsDataProvider(e.target.checked)}
                                style={checkboxStyle}
                            />
                        )}

                        {showIrpApproverCheckbox && (
                            <CheckboxComponent
                                label={'IRP/Reviewer'}
                                disabled={!user.isVivliAdmin}
                                checked={inviteIsIrpApprover}
                                onChange={(e) => setInviteIsIrpApprover(e.target.checked)}
                                style={checkboxStyle}
                            />
                        )}

                        {showIsAnnotatorCheckbox && (
                            <CheckboxComponent
                                label={'Study Curator'}
                                disabled={!user.isVivliAdmin}
                                checked={inviteIsStudyCurator}
                                onChange={(e) => setInviteIsStudyCurator(e.target.checked)}
                                style={checkboxStyle}
                            />
                        )}
                    </div>

                    <div style={secondRowStyle}>
                        {showIsQaReviewerCheckbox && (
                            <CheckboxComponent
                                label={'Curation QC Reviewer'}
                                checked={inviteIsQcReviewer}
                                disabled={!user.isVivliAdmin}
                                onChange={(e) => setInviteIsQcReviewer(e.target.checked)}
                                style={checkboxStyle}
                            />
                        )}

                        {showIsAnnotatorCheckbox && (
                            <CheckboxComponent
                                label={'Head Curator'}
                                checked={inviteIsHeadCurator}
                                disabled={!user.isVivliAdmin}
                                onChange={(e) => setInviteIsHeadCurator(e.target.checked)}
                                style={checkboxStyle}
                            />
                        )}

                        <CheckboxComponent
                            label={'Organization Administrator'}
                            checked={inviteIsOrgAdmin}
                            disabled={!user.isVivliAdmin}
                            onChange={(e) => setInviteIsOrgAdmin(e.target.checked)}
                            style={checkboxStyle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
