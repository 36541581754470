import React, { CSSProperties, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { ButtonComponent, MultiEntryTextFieldComponent } from '@vivli/shared/components';
import { IVivliAdminSummary } from '@vivli/features/dashboard/infrastructure/interface';
import { first } from 'rxjs/operators';
import { useMetricsService } from '@vivli/features/dashboard/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { AdminStatisticsSummaryTableComponent } from './admin-statistics-summary-table.component';

const containerStyle: CSSProperties = {
    display: 'flex',
    flex: 1,
};
const innerContainerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`,
};
const textDivStyle: CSSProperties = {
    flex: 2,
};
const buttonContainerStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
};
const reportContainerStyle: CSSProperties = {
    flex: 1,
    marginBottom: MetricsConstants.Sizing.SPACING,
};

export const AdminStatisticsSummaryDashboardComponent = () => {
    const [dataRequestIds, setDataRequestIds] = useState([]);
    const [adminSummaryReport, setAdminSummaryReport] = useState<IVivliAdminSummary>(null);
    const [isLoading, setIsLoading] = useState(false);
    const metricsService = useMetricsService();
    const modalService = useModalService();

    const getQueryString = () => {
        let paramArray = [];
        if (dataRequestIds?.length) {
            paramArray = [...paramArray, ...dataRequestIds.map((id) => `dataRequestId=${id}`)];
        }
        return paramArray.length ? paramArray.join('&') : null;
    };

    const handleError = () => {
        modalService.error;
        setIsLoading(false);
        setAdminSummaryReport(null);
    };

    const handleGenerateReport = () => {
        setIsLoading(true);
        setAdminSummaryReport(null);
        const queryString = getQueryString();

        metricsService
            .getAdminSummary(queryString)
            .pipe(first())
            .subscribe((result) => {
                setAdminSummaryReport(result);
                setIsLoading(false);
            }, handleError);
    };

    return (
        <div style={containerStyle}>
            <div style={innerContainerStyle} className={'do-not-print'}>
                <MultiEntryTextFieldComponent
                    label={'Data Request IDs'}
                    onChange={setDataRequestIds}
                    showClearAllButton={true}
                    clearButtonText={'Clear IDs'}
                    textDivStyle={textDivStyle}
                />
                <div style={buttonContainerStyle}>
                    <ButtonComponent onClick={handleGenerateReport} disabled={isLoading} isLoading={isLoading}>
                        Generate Admin Summary Report
                    </ButtonComponent>
                </div>
            </div>
            {adminSummaryReport && (
                <div style={reportContainerStyle}>
                    <AdminStatisticsSummaryTableComponent adminSummaryReport={adminSummaryReport} />
                </div>
            )}
        </div>
    );
};
