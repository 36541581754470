﻿import { FieldHeaderComponent, fieldHeaderStyle, leftHeaderContainerStyle } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import React from 'react';
import { DatasetUsageComponent } from '@vivli/features/datasets/components';
import { CitationsFeature } from '@vivli/shared/features/citations/feature';

const FORM_ROW_SHARED: React.CSSProperties = {
    display: 'flex',
    flex: '1 1 50%',
    maxWidth: '49%',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginBottom: 10,
};

interface UsageCitationsContainerComponentProps {
    studyMetadataDoi?: string;
    studyId?: string;
    studyPostedDate?: Date;
    studyApprovedDrs?: string[];
}

export const UsageCitationsContainerComponent = ({
    studyMetadataDoi,
    studyId,
    studyPostedDate,
    studyApprovedDrs,
}: UsageCitationsContainerComponentProps) => {
    return (
        <div style={leftHeaderContainerStyle}>
            {
                <>
                    <div style={FORM_ROW_SHARED}>
                        <div style={{ width: '95%' }}>
                            <FieldHeaderComponent title={'Usage'} style={fieldHeaderStyle} />
                            <div style={Styles.CONTENT_BACKGROUND_DARK_GREY}>
                                <DatasetUsageComponent
                                    studyId={studyId}
                                    studyMetadataDoi={studyMetadataDoi}
                                    studyPostedDate={studyPostedDate}
                                    studyApprovedDrs={studyApprovedDrs}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={FORM_ROW_SHARED}>
                        <div style={{ width: '95%', minWidth: '95%' }}>
                            <FieldHeaderComponent title={'Public Disclosures'} style={fieldHeaderStyle} />
                            <div style={Styles.CONTENT_BACKGROUND_DARK_GREY}>
                                <CitationsFeature objectId={studyId} isStudy={true} doUpdate={true} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
