import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { Color, Size } from '@vivli/shared/theme';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';

const textAreaStyle: CSSProperties = {
    width: '100%',
    border: 'none',
    resize: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    backgroundColor: 'inherit',
    padding: '5px 15px',
};

interface TextAreaComponentProps extends IFormField {
    rows?: number;
    showCount?: boolean;
    maxCount?: number;
    // onChange?: (value: string) => void;
}

export const TextAreaComponent = forwardRef((props: TextAreaComponentProps, ref) => {
    const {
        defaultValue,
        value,
        readonly = false,
        rows = 8,
        onFocus,
        onBlur,
        onChange,
        focusOnLoad,
        dataId,
        name,
        maxCount,
        showCount,
    } = props;

    const textAreaRef = useRef<HTMLInputElement>();
    const [characterLimit, setCharacterLimit] = useState<{ currentCount: number; max: number }>({
        currentCount: value?.length || 0,
        max: maxCount,
    });

    const handleOnChange = (e) => {
        onChange && onChange(e);

        setCharacterLimit({
            currentCount: e.currentTarget.value.length,
            max: maxCount,
        });
    };
    const handleInitialFocus = () => {
        if (focusOnLoad && textAreaRef.current) {
            textAreaRef.current.focus();
        }
    };

    useEffect(() => {
        handleInitialFocus();
    }, []);

    const buildInput = (props) => (
        <textarea
            {...props}
            onChange={handleOnChange}
            onBlur={onBlur}
            onFocus={onFocus}
            style={textAreaStyle}
            ref={mergeRefs([textAreaRef, ref])}
            readOnly={readonly}
            rows={rows}
            data-test-id={dataId || name}
        />
    );

    // prefer controlled value if it exists, then default
    const inputDisplay = value !== undefined ? buildInput({ value: value || '' }) : buildInput({ defaultValue });

    return (
        <BaseInputFieldComponent
            {...props}
            type={FormFieldTypeEnum.TextArea}
            inputRef={textAreaRef}
            showCharacterCount={showCount ? characterLimit : null}
        >
            {inputDisplay}
        </BaseInputFieldComponent>
    );
});
