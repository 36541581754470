import { CSSProperties, useEffect } from 'react';
import { EnquiryHeaderComponent } from './enquiry-header.component';
import { EnquiryMainContainerComponent } from './enquiry-main-container.component';
import { FormProvider } from 'react-hook-form';
import { useEnquiryContext } from '@vivli/features/enquiry/infastructure/context';
import { useEnquiryFormHook } from '@vivli/features/enquiry/infastructure/hook';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { EnquiryDataInfoRequestsComponent } from './enquiry-data-info-requests.component';

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
};

const enquiryContainerStyle: CSSProperties = {
    padding: '20px',
    height: '100%',
    width: '100%',
    overflowX: 'auto',
};

const dirMainContainer: CSSProperties = {
    minHeight: '400px',
    marginTop: '40px',
};

export const EnquiryComponent = () => {
    const formApi = useEnquiryFormHook();
    const { enquiry, pageEndRef, isLoading } = useEnquiryContext();

    useEffect(() => {
        if (enquiry) {
            formApi.reset(enquiry);
            if (!formApi.formState.isValid) {
                // force initial validation
                formApi.trigger();
            }
        }
    }, [enquiry]);

    return (
        <FormProvider {...formApi}>
            {isLoading && <LoadIndicatorCenteredComponent />}
            <div style={containerStyle}>
                <EnquiryHeaderComponent />
                <div style={enquiryContainerStyle} className="scrolly">
                    <EnquiryMainContainerComponent />
                    <div style={dirMainContainer}>
                        <EnquiryDataInfoRequestsComponent />
                    </div>
                    <div ref={pageEndRef}></div>
                </div>
            </div>
        </FormProvider>
    );
};
