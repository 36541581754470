import React, { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { usePathEndingHook } from '@vivli/shared/infrastructure/hook';

const innerContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
};

interface AmrDataRequestTabComponentProps {
    title: string;
}

const urlify = (url) => url.replace(/\s/gi, '').replace('/', '');

export const AmrDataRequestTabComponent = ({ title }: AmrDataRequestTabComponentProps) => {
    const navigate = useNavigate();
    const requestTab = usePathEndingHook();
    const parsedTitle = urlify(title);
    const [isActive, setIsActive] = useState(false);

    const tabContainerStyle: CSSProperties = {
        ...Styles.NO_SELECT,
        maxHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT,
        minHeight: Size.ADMIN_LEFT_PANEL_ITEM_HEIGHT - 40,
        marginBottom: 0,
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        paddingLeft: Size.PADDING,
        fontSize: Size.FontSize.Large,
        backgroundColor: isActive ? 'rgb(233, 234, 239)' : Color.WHITE,
        cursor: 'pointer',
        pointerEvents: 'inherit',
        boxShadow: isActive ? 'none' : 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
        position: 'relative',
        zIndex: isActive ? 0 : 12,
    };

    const textStyle: CSSProperties = {
        color: isActive ? Color.TEXT_DARK_BLUE : tinycolor(Color.TEXT_DARK_BLUE).clone().lighten(20).toRgbString(),
        fontWeight: isActive ? Size.FontWeight.Bold : Size.FontWeight.Regular,
    };

    const handleTabClick = () => {
        navigate(parsedTitle, { replace: true });
    };

    useEffect(() => {
        if (!requestTab) {
            return;
        }
        setIsActive(parsedTitle.toLowerCase() === requestTab.toLowerCase());
    }, [requestTab]);

    return (
        <div style={tabContainerStyle} onClick={handleTabClick}>
            <div style={innerContainerStyle}>
                <span style={textStyle}>{title}</span>
            </div>
        </div>
    );
};
