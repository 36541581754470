﻿import React, { CSSProperties } from 'react';
import { Color } from '@vivli/shared/theme';
import { ITimeIntervalMetrics } from '@vivli/features/dashboard/infrastructure/interface';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { AverageTimeChartComponent } from './average-time-chart.component';
import { AverageTimeListComponent } from './average-time-list.component';
import { AveragesSummaryComponent } from './averages-summary.component';
import { MetricsHeaderComponent } from './metrics-header.component';

const flexOneStyle: CSSProperties = {
    flex: 1,
};
const outerContainerStyle: CSSProperties = {
    marginTop: MetricsConstants.Sizing.DOUBLE_SPACING,
};
const containerStyle: CSSProperties = {
    marginTop: MetricsConstants.Sizing.SPACING,
};
const innerContainerTwoStyle: CSSProperties = {
    width: MetricsConstants.Sizing.SPACING,
};
const innerContainerThreeStyle: CSSProperties = {
    ...flexOneStyle,
    backgroundColor: 'white',
};
const headerStyle: CSSProperties = {
    color: Color.VIVLI_DARK_BLUE,
    fontSize: MetricsConstants.Sizing.SECTION_HEADER_FONT_SIZE,
};

interface AveragesWrapperComponentProps {
    title: string;
    chartData: ITimeIntervalMetrics;
    color: string;
    orgName: string;
    width: number;
    isAggregateReport?: boolean;
}

export const AveragesWrapperComponent = ({ title, chartData, color, orgName, width, isAggregateReport }: AveragesWrapperComponentProps) => {
    const chartTitle = isAggregateReport ? MetricsConstants.Text.CHART_TITLE_ALL : MetricsConstants.Text.CHART_TITLE(orgName);
    const listTitle = isAggregateReport ? MetricsConstants.Text.TIME_LIST_TITLE_ALL : MetricsConstants.Text.TIME_LIST_TITLE;

    return (
        <div style={outerContainerStyle}>
            <h3 style={headerStyle}>{title}</h3>
            <MetricsHeaderComponent color={color} title={chartTitle} />
            <AverageTimeChartComponent color={color} data={chartData} width={width} isAggregateReport={isAggregateReport} />
            <div style={containerStyle} />
            <div style={{ display: 'flex' }}>
                <div style={flexOneStyle}>
                    <MetricsHeaderComponent color={color} title={listTitle} />
                    <AverageTimeListComponent data={chartData} isAggregateReport={isAggregateReport} />
                </div>
                <div style={innerContainerTwoStyle} />
                <div style={innerContainerThreeStyle}>
                    <MetricsHeaderComponent color={color} title={MetricsConstants.Text.AVERAGE_SUMMARY_TITLE} />
                    <AveragesSummaryComponent
                        color={color}
                        data={chartData[MetricsConstants.Text.OVERALL_TIME_TO_PUBLICATION_KEY]}
                        isAggregateReport={isAggregateReport}
                    />
                </div>
            </div>
        </div>
    );
};
