﻿import React, { CSSProperties } from 'react';
import { ICurrentDataRequestMetrics, ITotalDataRequestMetrics } from '@vivli/features/dashboard/infrastructure/interface';
import { CurrentDataRequestMetricsEnum, TotalDataRequestMetricsEnum } from '@vivli/features/dashboard/infrastructure/enum';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { Size } from '@vivli/shared/theme';
import { MetricsHeaderComponent } from './metrics-header.component';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
};
const ulStyle: CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: MetricsConstants.Sizing.SPACING,
    background: 'white',
    flex: 1,
};
const liStyle = (index): CSSProperties => ({
    display: 'flex',
    fontSize: `${Size.FontSize.Large}px`,
    marginTop: index > 0 ? '4px' : 0,
    color: MetricsConstants.Colors.DARK_BLUE_TEXT,
    alignItems: 'baseline',
    fontWeight: index > 0 ? Size.FontWeight.Regular : Size.FontWeight.Bold,
});
const fillerStyle: CSSProperties = {
    borderBottom: `2px dotted ${MetricsConstants.Colors.DARK_BLUE_TEXT}`,
    flex: 1,
    margin: `0 ${Size.FontSize.Medium * 0.5}px`,
};

interface SimpleMetricsListComponentProps {
    title: string;
    metrics: ICurrentDataRequestMetrics | ITotalDataRequestMetrics;
}

export const SimpleMetricsListComponent = ({ title, metrics }: SimpleMetricsListComponentProps) => {
    const hasCurrentMetricsEnumKeys = Object.keys(CurrentDataRequestMetricsEnum).every((k) => Object.keys(metrics).indexOf(k) >= 0);
    const hasTotalMetricsEnumKeys = Object.keys(TotalDataRequestMetricsEnum).every((k) => Object.keys(metrics).indexOf(k) >= 0);
    const chosenTotalMetricsEnum = hasTotalMetricsEnumKeys ? TotalDataRequestMetricsEnum : null;
    const metricEnum = hasCurrentMetricsEnumKeys ? CurrentDataRequestMetricsEnum : chosenTotalMetricsEnum;

    if (!metricEnum) {
        return null;
    }

    return (
        <div style={containerStyle}>
            <MetricsHeaderComponent title={title} color={MetricsConstants.Colors.SIMPLE_METRICS_PRIMARY} />
            <ul style={ulStyle}>
                {Object.keys(metricEnum).map((key, index) => {
                    return (
                        <li key={index} style={liStyle(index)}>
                            <div>{metricEnum[key]}</div>
                            <div style={fillerStyle} className={'filler'} />
                            <div>{metrics[key]}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
