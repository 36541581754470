import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';

import { IDataRequest, IResearchTeamMemberDetail } from '@vivli/features/data-requests/infrastructure/interface';
import { DataRequestPermissionsService } from '@vivli/features/data-requests/infrastructure/service';
import {
    DropdownFormFieldComponent,
    InfoPopoverComponent,
    TextAreaFormFieldComponent,
    TextFormFieldComponent,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import { AdminApprovalConstant, DataRequestHintsConstant } from '@vivli/shared/infrastructure/constants';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'left',
};

const fieldStyle: CSSProperties = {
    maxWidth: '32%',
};

const infoPopupStyle: CSSProperties = {
    minHeight: '30px',
    justifyContent: 'flex-end',
};

const orcIdIdHint = (
    <div>
        ORCID iD # is a persistent digital identifier that distinguishes you from every other researcher and, through integration in key
        research workflows such as manuscript and grant submission, supports automated linkages between you and your professional activities
        ensuring that your work is recognized.
    </div>
);

const conflictsContent = (
    <div>
        <div>
            For each member of the research team, please provide information on financial relationships that could be perceived to influence
            the planning, conduct or interpretation of the proposed research. This should not be limited to financial relationships with the
            study sponsors involved in this initiative and other pharmaceutical or biotechnology companies within the last three years. This
            may include but is not limited to:
        </div>
        <ul>
            <li>Research grants from governments or government agencies</li>
            <li>Other grants or donations</li>
            <li>Funding from employers through employment contracts</li>
            <li>Other contracts, consultancies, honoraria and payments that will be used for the proposed research</li>
            <li>Board memberships</li>
            <li>Patents (planned, pending or issued)</li>
            <li>Royalties - Stocks or shares (including options)</li>
        </ul>
        <div>
            Please also include any other (e.g. non-financial) real or potential conflicts of interest that could be perceived to influence
            the planning, conduct or interpretation of the proposed research. For example, potential biases based on pre-existing personal
            views, academic or commercial competition, personal relationships or institutional affiliations.
        </div>
        <br />
        <div>
            For each conflict, please summarize how real or potential conflicts of interest related to funding of the proposed research,
            other financial relationships or other real or potential conflicts of interest will be managed. For example, through disclosure
            of interests when the research is presented and published.
        </div>
        <br />
        <div>If none please enter "None"</div>
    </div>
);

interface TeamMemberFormComponentProps {
    baseFieldName?: string;
    readonly?: boolean;
    isLoading?: boolean;
    children?: ReactNode;
    dataRequest: IDataRequest;
    teamMember: IResearchTeamMemberDetail;
}

export const TeamMemberDetailsFormComponent = ({
    dataRequest,
    baseFieldName,
    readonly,
    children,
    isLoading,
    teamMember,
}: TeamMemberFormComponentProps) => {
    const user = useActiveUser();
    const vivliConfig = useVivliConfig();
    const [countryItems, setCountryItems] = useState<IDropdownMenuItem[]>();

    const userIsAdmin = dataRequest.researchTeam.researchTeamDetails.some((tm) => tm.userId === user?.userId && tm.isAdmin);
    const userIsOwner = dataRequest.userId === user?.userId;
    const userCanEditAllFields = userIsAdmin || userIsOwner;

    const isEmailEditable = DataRequestPermissionsService.canEditEmailField(user, teamMember) || userCanEditAllFields;
    const isNonEmailEditable = DataRequestPermissionsService.canEditNonEmailFields(user, teamMember) || userCanEditAllFields;
    const labels = AdminApprovalConstant.VmAdminApprovalLabels;
    const menuItems = Object.keys(labels).map<IDropdownMenuItem>((key) => {
        return {
            title: labels[key],
            value: key,
        };
    });
    useEffect(() => {
        if (vivliConfig?.countries?.length > 0) {
            const countryItems = vivliConfig.countries.map(({ name, code, numberSites }) => ({
                value: { name, code, numberSites },
                title: name,
            }));
            setCountryItems(countryItems);
        }
    }, [vivliConfig]);

    return (
        <div style={containerStyle}>
            <div style={Styles.FORM_ROW} />
            <div style={{ ...Styles.FORM_ROW, maxHeight: '60px' }}>
                <TextFormFieldComponent
                    name={`${baseFieldName}firstName`}
                    label={'First Name'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={fieldStyle}
                />
                <TextFormFieldComponent
                    name={`${baseFieldName}lastName`}
                    label={'Last Name'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={fieldStyle}
                />
                <TextFormFieldComponent
                    name={`${baseFieldName}orcidId`}
                    label={'ORCID iD'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={fieldStyle}
                    hint={orcIdIdHint}
                />
            </div>
            <div style={{ ...Styles.FORM_ROW, maxHeight: '60px' }}>
                <TextFormFieldComponent
                    name={`${baseFieldName}email`}
                    label={'Email (editable until user is invited to data request)'}
                    readonly={!isEmailEditable || readonly || isLoading}
                    style={twoAcrossStyle}
                    truncateLabel={false}
                />
                <TextFormFieldComponent
                    name={`${baseFieldName}position`}
                    label={'Position'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={twoAcrossStyle}
                />
            </div>
            <div style={{ ...Styles.FORM_ROW, maxHeight: '60px' }}>
                <TextFormFieldComponent
                    name={`${baseFieldName}employerOrAffiliation`}
                    label={'Employer, Company, Research Institute, or Primary Affiliation'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={twoAcrossStyle}
                    truncateLabel={false}
                />
                <DropdownFormFieldComponent
                    name={`${baseFieldName}country`}
                    label={'Country'}
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={twoAcrossStyle}
                    objectKey={'name'}
                    items={countryItems}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextAreaFormFieldComponent
                    name={`${baseFieldName}educationOrQualifications`}
                    label={
                        'Education, including the degree, discipline and institution where the degree was granted, and professional qualifications that are relevant to the proposed research and are specific to clinical data analysis.'
                    }
                    readonly={!isNonEmailEditable || readonly || isLoading}
                    style={{ marginTop: 10 }}
                    hint={DataRequestHintsConstant.researchTeam.leadResearcherTeamMemberDetail.qualifications}
                    showCount={true}
                    maxCount={1000}
                />
            </div>
            <div style={Styles.FORM_ROW} />
            <InfoPopoverComponent content={conflictsContent} style={infoPopupStyle} />
            <div style={{ position: 'relative' }}>
                <TextAreaFormFieldComponent
                    name={`${baseFieldName}conflictsOfInterest`}
                    label={
                        'Please list any real or potential conflicts of interest and describe how these will be managed. If none, please enter None.'
                    }
                    readonly={!isNonEmailEditable || readonly || isLoading}
                />

                {children}
            </div>

            <div style={Styles.FORM_ROW} />

            <DropdownFormFieldComponent
                name={`${baseFieldName}adminApprovalStatus`}
                label={'VM Access Admin Approval Based on Approved DUA'}
                readonly={!isNonEmailEditable || !user?.isVivliAdmin || readonly || isLoading}
                items={menuItems}
            />
        </div>
    );
};
