import React, { CSSProperties } from 'react';
import { AttachmentsDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Size } from '@vivli/shared/theme';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

const containerStyle: CSSProperties = {
    marginLeft: Size.INSET_SIZE,
    display: 'flex',
    flexGrow: 1,
};

export const DataRequestAttachmentsComponent = () => {
    const { dataRequest } = useDataRequestContext();

    return (
        <div style={containerStyle}>
            <AttachmentsDataPackageFeature dataPackageId={dataRequest.metaDataDataPackageId} allowUpload={false} />
        </div>
    );
};
