﻿import React, { CSSProperties } from 'react';
import { useQuickSearchContext } from '@vivli/features/search/infrastructure/context';
import { ISearchStudy } from '@vivli/features/dashboard/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { Color, Size } from '@vivli/shared/theme';
import { SearchResultRowsComponent } from '../search-result-row/search-result-rows.component';

const searchContainerStyle: CSSProperties = {
    display: 'grid',
    overflow: 'hidden',
    gridTemplateColumns: 'auto',
    backgroundColor: 'white',
    borderTopRightRadius: Size.BORDER_RADIUS * 2,
    borderTopLeftRadius: Size.BORDER_RADIUS * 2,
};
const searchResultsContainerStyle: CSSProperties = {
    height: '100%',
    overflow: 'auto',
    padding: '0 15px',
};
const closeContainerStyle: CSSProperties = {
    minHeight: Size.SEARCH_HEIGHT,
    padding: '15px 0px',
    display: 'flex',
    backgroundColor: Color.WHITE,
};
const closeInnerContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: Size.PADDING,
    cursor: 'pointer',
};
const textStyle: CSSProperties = {
    padding: Size.PADDING,
    alignItems: 'center',
};

export const QuickSearchResultsComponent = ({ isOpen }) => {
    const user = useActiveUser();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const { searchResults, searchText, closeResults } = useQuickSearchContext();

    const handleViewStudyClick = (e, searchResult: ISearchStudy) => {
        e.preventDefault();
        if ((e.ctrlKey || e.shiftKey) && user.isVivliAdmin) {
            window.open(`/admin/studies/${searchResult.id}/studyinformation`);
        } else {
            const detailsPath = isAmr ? 'datasetDetails' : 'studyDetails';
            window.open(`/${detailsPath}/fromSearch/${searchResult.id}`);
        }
    };

    const containerStyle: CSSProperties = {
        position: 'fixed',
        left: '0',
        right: '0',
        bottom: isOpen ? 0 : '-100%',
        height: '100%',
        transitionProperty: 'all',
        transitionDuration: '1s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
        display: 'grid',
        gridTemplateRows: '30% 1fr',
        zIndex: 200,
    };

    const transparentBackgroundDiv: CSSProperties = {
        pointerEvents: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: isOpen ? 'initial' : 'none',
        zIndex: 1,
    };

    return (
        <>
            <div style={transparentBackgroundDiv}></div>
            <div style={containerStyle}>
                <div>{/* Buffer Div */}</div>
                <div style={searchContainerStyle}>
                    <div style={searchResultsContainerStyle}>
                        <div style={closeContainerStyle}>
                            <div style={textStyle}>
                                Quick Study Lookup results for
                                <span style={{ fontWeight: 'bold' }}> {searchText}</span>
                            </div>
                            <div style={closeInnerContainerStyle} onClick={closeResults}>
                                CLOSE
                            </div>
                        </div>
                        <div style={{ padding: 10 }}>
                            <SearchResultRowsComponent
                                searchResults={searchResults}
                                onRowClick={handleViewStudyClick}
                                isOpen={isOpen}
                                innerStyle={{ boxShadow: Size.BOX_SHADOW }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
