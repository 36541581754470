import React, { CSSProperties } from 'react';
import { Color } from '@vivli/shared/theme';

interface BadgeComponentProps {
    value: number;
    style?: React.CSSProperties;
}

const badgeStyle = (style: CSSProperties): CSSProperties => ({
    display: 'inline-block',
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 2,
    borderRadius: 2,
    backgroundColor: Color.VIVLI_GREEN,
    color: Color.WHITE,
    lineHeight: 1,
    ...style,
});

export const BadgeComponent = ({ value, style }: BadgeComponentProps) => {
    return <div style={badgeStyle(style)}>{value}</div>;
};
