import Tippy from '@tippyjs/react';
import React, { CSSProperties, useState } from 'react';
import { Color } from '@vivli/shared/theme';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { DropdownMenuContentComponent } from './dropdown-menu/dropdown-menu-content.component';
import { Placement } from 'tippy.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const iconStyle: CSSProperties = {
    fontSize: '2em',
    padding: '10px 20px',
    color: Color.VIVLI_GREY,
};

interface EllipsisDropdownMenuComponentProps {
    items: IDropdownMenuItem[];
    position?: Placement;
    onItemClick?: (item: IDropdownMenuItem) => void;
    dataIdPrefix?: string;
}

export const EllipsisDropdownMenuComponent = ({
    position = 'bottom-end',
    items,
    onItemClick,
    dataIdPrefix,
}: EllipsisDropdownMenuComponentProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (item: IDropdownMenuItem) => {
        setIsOpen(false);
        onItemClick && onItemClick(item);
    };

    const handleMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(!isOpen);

        return false;
    };
    return (
        <Tippy
            content={<DropdownMenuContentComponent items={items} onItemClick={handleItemClick} />}
            placement={position}
            visible={isOpen}
            arrow={false}
            className="tippy-dropdown-menu"
            onClickOutside={(i, e) => {
                setIsOpen(false);
            }}
            animation="shift-away-extreme"
            interactive={true}
        >
            <div onClick={handleMenuClick} data-test-id={`${dataIdPrefix || 'default'}-ellipsis-menu`}>
                <FontAwesomeIcon aria-hidden="true" style={iconStyle} icon={faEllipsisVertical} />
            </div>
        </Tippy>
    );
};
