import { ButtonComponent } from '../buttons';
import { FieldHeaderComponent } from '../field-header.component';
import { GridComponent } from '../grid/grid.component';

import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import { ExternalLinkRendererComponent } from './external-link-renderer.component';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { IExternalLink } from '@vivli/shared/infrastructure/interface';
import { ExternalLinkComponent } from './external-link.component';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const customComponents = [
    {
        component: ExternalLinkRendererComponent,
        name: 'ExternalLink',
    },
];

const handleRowClick = (data: IExternalLink, e) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(data.uri, '_blank');
};

const headerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: '20px',
};

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    rowGap: '10px',
};

interface IDataPackageLinksComponent {
    editable?: boolean;
    externalLinks: IExternalLink[];
    onLinksUpdated: (externalLinks: IExternalLink[], updateType: 'added' | 'edited' | 'deleted') => Promise<IStudy>;
}

export const ExternalLinksComponent = ({ editable, externalLinks, onLinksUpdated }: IDataPackageLinksComponent) => {
    const modalService = useModalService();
    const [rowData, setRowData] = useState<IExternalLink[]>(externalLinks || []);
    const rowDataRef = useRef(externalLinks || []);

    const _updateRowData = (_rowData: IExternalLink[]) => {
        rowDataRef.current = _rowData;
        setRowData(_rowData);
    };

    const _handleLinksUpdated = (_externalLinks: IExternalLink[], updateType: 'added' | 'edited' | 'deleted') => {
        onLinksUpdated(_externalLinks, updateType).then((study) => {
            _updateRowData(study.externalLinks);
        });
    };

    const updateExternalLink = (currentLink: IExternalLink, updatedLink: IExternalLink) => {
        const rowToUpdateIndex = rowDataRef.current.findIndex((rd) => rd.title === currentLink.title);

        const updatedRowData = [...rowDataRef.current];

        updatedRowData[rowToUpdateIndex] = updatedLink;

        _handleLinksUpdated(updatedRowData, 'edited');
    };

    const handleEditClick = (currentLink: IExternalLink, e) => {
        e.preventDefault();
        e.stopPropagation();

        const modalId = modalService.custom(
            <ExternalLinkComponent
                onCancel={() => modalService.dismiss(modalId)}
                onSave={(updatedLink) => {
                    modalService.dismiss(modalId);
                    updateExternalLink(currentLink, updatedLink);
                }}
                externalLink={currentLink}
                existingLinks={rowData}
                title={'Edit Link'}
            />
        );
    };

    const addExternalLink = (data: IExternalLink) => {
        _handleLinksUpdated([...rowDataRef.current, data], 'added');
    };

    const handleAddClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const modalId = modalService.custom(
            <ExternalLinkComponent
                onCancel={() => modalService.dismiss(modalId)}
                onSave={(data) => {
                    modalService.dismiss(modalId);
                    addExternalLink(data);
                }}
                existingLinks={rowData}
                title={'Add New Link'}
            />
        );
    };

    const removeExternalLink = (data: IExternalLink) => {
        const updatedRowData = rowDataRef.current.filter((rd) => rd.uri !== data.uri && rd.title !== data.title);
        _handleLinksUpdated(updatedRowData, 'deleted');
    };

    const handleDeleteClick = (data: IExternalLink, e) => {
        e.preventDefault();
        e.stopPropagation();

        modalService.confirm(`Are you sure you want to delete the link "${data.title}"?`, {
            onConfirm: () => removeExternalLink(data),
        });
    };

    const columnDefs = useMemo(
        () => [
            {
                field: 'title',
                headerName: 'Title',
                initialSort: 'asc',
                cellRenderer: 'ExternalLink',
                cellRendererParams: {
                    handleDeleteClick,
                    handleEditClick,
                    handleRowClick,
                    editable,
                },
                flex: 1,
                cellStyle: { height: '50px' },
            },
        ],
        []
    );

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <FieldHeaderComponent title={'Links to Documents located elsewhere'} />
                {editable && (
                    <ButtonComponent dataId={DTICommonConst.AddButton} style={{ maxWidth: '150px' }} onClick={handleAddClick}>
                        Add New Link
                    </ButtonComponent>
                )}
            </div>
            <GridComponent
                style={{ padding: '0px 15px', minHeight: '100px' }}
                columns={columnDefs}
                rowData={rowData}
                hideHeader={true}
                customComponents={customComponents}
                hideFloatingFilter={true}
                emptyMessage={'No Links to Documents found.'}
            />
        </div>
    );
};
