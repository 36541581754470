﻿import React, { CSSProperties } from 'react';
import { CheckboxFormFieldComponent, fourAcrossStyle, threeAcrossStyle } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import { DTICTFormFields } from '@vivli/shared/infrastructure/constants';

const fourAcrossNoShadowStyle: CSSProperties = {
    ...fourAcrossStyle,
    boxShadow: 'none',
};

const threeAcrossNoShadowStyle: CSSProperties = {
    ...threeAcrossStyle,
    boxShadow: 'none',
};

interface StudyDatasetIpdOptionsComponentProps {
    extendedMetadataOption: boolean;
}

export const StudyDatasetIpdOptionsComponent = ({ extendedMetadataOption }: StudyDatasetIpdOptionsComponentProps) => {
    //extendedMetadataOption is for CT only, adds checkbox for setting this
    const styleToUse: CSSProperties = extendedMetadataOption ? fourAcrossNoShadowStyle : threeAcrossNoShadowStyle;

    return (
        <>
            <div style={Styles.FORM_ROW}>
                <CheckboxFormFieldComponent
                    name={'downloadableStudyIPDDataPackage'}
                    label={'Data Package is downloadable'}
                    truncateLabel={false}
                    style={styleToUse}
                    inputStyle={{ background: 'transparent' }}
                    dataId={DTICTFormFields.FormCheckBox('IpdDataPackage')}
                />
                {extendedMetadataOption && (
                    <CheckboxFormFieldComponent
                        name={'showExtendedStudyMetadata'}
                        label={'Show extended Study Metadata in User Search Screen'}
                        truncateLabel={false}
                        style={styleToUse}
                        dataId={DTICTFormFields.FormCheckBox('StudyMetaData')}
                    />
                )}
                <CheckboxFormFieldComponent
                    name={'bypassDataContributorReview'}
                    label={'Bypass Data Contributor Review'}
                    truncateLabel={false}
                    style={styleToUse}
                    dataId={DTICTFormFields.FormCheckBox('ContributorReview')}
                />
                <CheckboxFormFieldComponent
                    name={'bypassIRPReview'}
                    label={'Bypass IRP Review'}
                    truncateLabel={false}
                    style={styleToUse}
                    dataId={DTICTFormFields.FormCheckBox('IRPReview')}
                />
            </div>
        </>
    );
};
