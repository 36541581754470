﻿import { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';

export const outerTabStyle: CSSProperties = {
    overflowY: 'scroll',
    height: '100%',
};

export const tabContentContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Size.INSET_SIZE,
    marginRight: Size.INSET_SIZE,
    marginTop: Size.INSET_SIZE,
};

export const adminDivStyle: CSSProperties = {
    borderTop: '2px solid #cecfd6',
};

export const nextButtonStyle: CSSProperties = {
    width: '25%',
};

export const loginSignUpLandingButtonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_YELLOW,
    color: Color.VIVLI_BLUE,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Color.VIVLI_BLUE,
    cursor: 'pointer',
    padding: '1%',
    marginRight: '5%',
    fontWeight: Size.FontWeight.SemiBold,
};
