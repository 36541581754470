﻿import React, { CSSProperties } from 'react';
import { IOrganizationMember } from '@vivli/features/organizations/infrastructure/interface';

const divStyle: CSSProperties = {
    lineHeight: 1,
    marginBottom: 5,
    marginTop: 5,
};
const nameStyle: CSSProperties = {
    fontSize: 21,
    marginBottom: 4,
};
const emailStyle: CSSProperties = {
    fontSize: 15,
};

interface NameRowRendererComponentProps {
    data: IOrganizationMember;
}

export const NameRowRendererComponent = ({ data }: NameRowRendererComponentProps) => {
    return (
        <div style={divStyle}>
            <div style={nameStyle}>{data.displayName}</div>
            <div style={emailStyle}>{data.email}</div>
        </div>
    );
};
