﻿import { ButtonComponent } from '../buttons/button.component';
import { WhiteBackgroundLogoComponent } from '../white-background-logo.component';
import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { useNavigate } from 'react-router-dom';
import { DTIAMRCommonConst } from '@vivli/shared/infrastructure/constants';

const containerStyle = (style): CSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: Color.WHITE,
    minWidth: 300,
    maxWidth: 500,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 15px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    padding: '2em',
    ...style,
});
const textStyle: CSSProperties = {
    padding: '15px',
    fontSize: Size.FontSize.Large,
    textAlign: 'center',
};
const buttonContainerStyle = (buttonRowStyle): CSSProperties => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    ...buttonRowStyle,
});
const buttonStyle: CSSProperties = {
    width: '200px',
};
const logoStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '30px',
};

interface NoSearchResultsFoundModalComponentProps {
    onClose: () => void;
    noneFoundMessage: string;
    hideCancelButton?: boolean;
    style?: CSSProperties;
    buttonRowStyle?: CSSProperties;
}

export const NoSearchResultsFoundModalComponent = ({
    onClose,
    noneFoundMessage,
    hideCancelButton,
    style,
    buttonRowStyle,
}: NoSearchResultsFoundModalComponentProps) => {
    const navigate = useNavigate();
    const handleCloseClick = () => {
        onClose && onClose();
    };
    const handleEnquiryClick = () => {
        navigate('/enquiries');
        handleCloseClick();
    };

    return (
        <div style={containerStyle(style)}>
            <div style={logoStyle}>
                <WhiteBackgroundLogoComponent />
            </div>
            <div style={textStyle}>
                <p>{noneFoundMessage}</p>
                <p>
                    If the study you are searching for is not listed on Vivli, click below to fill out an enquiry form to ask if it can be
                    made available.
                </p>
            </div>
            <div style={buttonContainerStyle(buttonRowStyle)}>
                <ButtonComponent dataId={DTIAMRCommonConst.EnquiryFormButton} onClick={handleEnquiryClick} style={buttonStyle}>
                    Enquiry Form
                </ButtonComponent>
                {!hideCancelButton && (
                    <ButtonComponent dataId={DTIAMRCommonConst.CancelButton} onClick={handleCloseClick} style={buttonStyle}>
                        Cancel
                    </ButtonComponent>
                )}
            </div>
        </div>
    );
};
