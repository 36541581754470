import React, { CSSProperties } from 'react';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { VmProvisionedButtonComponent } from './vm-provisioned-button.component';
import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { Color, Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = { marginBottom: Size.PADDING };

const buttonsContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '25px',
    marginBottom: '15px',
};

const innerContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: 810,
    gap: 50,
    justifyContent: 'space-between',
    marginBottom: 15,
};

const hintTextStyle: CSSProperties = {
    color: '#939090',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '24px',
    gap: '20px',
    height: 370,
    marginTop: '35px',
    boxShadow: '0 0 5px #aeabab',
    padding: '20px',
};

const buttonSectionStyle: CSSProperties = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export const VmProvisionedButtonsComponent = () => {
    const { addDataPackagesToVm, provisionStatus, startVm, stopVm } = useResearchEnvironmentContext();

    return (
        <div style={containerStyle}>
            <div style={buttonsContainerStyle}>
                <div style={innerContainerStyle}>
                    <div style={buttonSectionStyle}>
                        <div style={{ width: '70%' }}>
                            <VmProvisionedButtonComponent
                                title={'Add Data Packages'}
                                color={Color.VIVLI_BLUE}
                                onClick={addDataPackagesToVm}
                            />
                        </div>

                        <div style={hintTextStyle}>
                            <div>
                                {' '}
                                If you have been informed that data has recently been provided for any of your studies, click "Add Data
                                Packages” to add that new data to your research environment.
                            </div>
                            <div>
                                If you want to bring data or scripts into the research environment, reach out to{' '}
                                <a href="mailto:support@vivli.org">support@vivli.org</a> and we will assist you.
                            </div>
                        </div>
                    </div>

                    <div style={buttonSectionStyle}>
                        {provisionStatus === VmStatusEnum.Stopped && (
                            <VmProvisionedButtonComponent
                                title={'Start Research Environment'}
                                color={Color.VIVLI_GREEN}
                                onClick={startVm}
                            />
                        )}

                        {provisionStatus === VmStatusEnum.Running && (
                            <VmProvisionedButtonComponent title={'Stop Environment'} color={Color.VIVLI_RED} onClick={stopVm} />
                        )}

                        <div style={hintTextStyle}>
                            <div>Stopping your environment simply shuts it down, or pauses it. You can restart it at anytime.</div>
                            <div>Note that stopping your environment will not affect your no-charge days.</div>
                            <div>
                                Questions? Please reach out to us at <a href="mailto:support@vivli.org">support@vivli.org</a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
